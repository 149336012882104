import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const NuevoElementoLink = () => {
  // Valores
  const { id } = useParams();
  const [name, setName] = useState("");
  const [links, setLinks] = useState("");

  const navigate = useNavigate();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleLinkChange = (e) => {
    setLinks(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(`${BACKEND}/api/links/${id}`);
      const link = response.data;
      if (!link.elemento) {
        link.elemento = [];
      }
      const nuevoElemento = {
        name,
        link: links,
        id: Date.now().toString(),
      };
      link.elemento.push(nuevoElemento);
      await axios.put(`${BACKEND}/api/links/${id}`, link);
      console.log("Elemento añadido:", nuevoElemento);
      navigate(`/home/links/editar-link/${id}`);
    } catch (error) {
      console.error("Error al añadir el elemento:", error);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">Añadir Link</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">
              Título
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="link" className="block mb-2">
              Link
            </label>
            <input
              type="text"
              id="link"
              value={links}
              onChange={handleLinkChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="flex justify-end">
            <Link
              to={`/home/links/editar-link/${id}`}
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Añadir
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NuevoElementoLink;
