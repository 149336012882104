import React, { useState } from "react";
import Modal from "../ui/Modal";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";
import InputText from "../ui/InputText";
import { FaCheckCircle, FaPlus, FaTrash } from "react-icons/fa";
import {toast} from "react-toastify"
import FormLoader from "../ui/FormLoader";
import { MdClose } from "react-icons/md";
const NuevaPreguntaFormularioDialog = ({
  open,
  onClose,
  title,
  idFormulario,
  refetchPreguntas
}) => {

  const[isLoading,setIsLoading]=useState(false)
  const [formValues, setFormValues] = useState({
    pregunta: "",
    tipo: "respuesta",
    beneficiado: "Comercial",
    linkYoutube:"",
    habilitarMedia:false
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState("");

  const [itemDescripciones, setitemDescripciones] = useState([]);
  const [itemDescripcion, setItemDescripcion] = useState({
    respuesta: "",
    puntos: 0,
  });

  const handleAddItemDescripcion = () => {
    setitemDescripciones([...itemDescripciones, itemDescripcion]);
    setItemDescripcion({ respuesta: "", puntos: 0 });
  };

  const handleDeleteImteDescripcion = (index) => {
    const updatedPairs = [...itemDescripciones];
    updatedPairs.splice(index, 1);
    setitemDescripciones(updatedPairs);
  };

  const handleInputChangeItemDescripciones = (index, key, value) => {
    const updatedPairs = [...itemDescripciones];
    updatedPairs[index][key] = value;
    setitemDescripciones(updatedPairs);
  };
  const registrarPregunta = async (event) => {
    event.preventDefault();
    setIsLoading(true)
    try {
      if(formValues.tipo==="respuesta"){

        const fileResponse = await uploadImage()
        const responsePlanes = await axios.post(
          `${BACKEND}/api/formularioServicioPregunta`,{
              formulario:idFormulario,
              tipo:formValues.tipo,
              pregunta:formValues.pregunta,
              media:fileResponse ? fileResponse.doc.id : null,
              linkYoutube:formValues.linkYoutube,
              habilitarMedia:formValues.habilitarMedia

          }
        );

        onClose();
        resetAllValues();
        refetchPreguntas();
        toast.success("Pregunta registrada")
      }

      if(formValues.tipo==="alternativas" || formValues.tipo==="seleccionMultiple"){
        const fileResponse = await uploadImage()

        const responsePlanes = await axios.post(
          `${BACKEND}/api/formularioServicioPregunta`,{
              formulario:idFormulario,
              tipo:formValues.tipo,
              pregunta:formValues.pregunta,
              beneficiado:formValues.beneficiado,
              alternativas:itemDescripciones,
              media:fileResponse ? fileResponse.doc.id : null,
              linkYoutube:formValues.linkYoutube,
              habilitarMedia:formValues.habilitarMedia


          }
        );


        onClose();
        resetAllValues();
        refetchPreguntas();
        toast.success("Pregunta registrada")
      }
      
    } catch (error) {
      toast.error("Error al registrar pregunta",{style:{zIndex:9999},position:"top-center"})
    } finally{
      setIsLoading(false)
    }
  };

  const resetAllValues=()=>{
    setFormValues({pregunta: "",
    tipo: "respuesta",
    beneficiado: "Comercial",
    linkYoutube:""})
    setSelectedFile(null)
    setSelectedFileType("")
    setitemDescripciones([])
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file.size > 5 * 1000000) {
      window.alert("Archivo superior a 5mb");
      return;
    }
    setSelectedFile(file);
    if (file) {
      // Update state with the selected file
      setSelectedFile(file);
      setSelectedFileType(file.type);
    }
  };

  const uploadImage = async () => {
    if(!selectedFile)return null
    try {
      // First request
      const formData = new FormData();
      formData.append("file", selectedFile);

      const dataImage = await axios.post(
        `${BACKEND}/api/formularioServicioPreguntaFoto`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );


      return dataImage.data

   
    } catch (error) {
      // Handle errors from either the first or second request
      console.error("Error in uploadImage:", error);
      toast.error("Error al subir esta imagen", {
        style: { zIndex: 9999 },
        position: "top-center",
      });
      return null
    }
  };

  
  return (
    <Modal activeModal={open} onClose={onClose} title={title}>
      <form onSubmit={registrarPregunta}>
        <InputText
          value={formValues.pregunta}
          required={true}
          setValue={(e) => setFormValues((prev) => ({ ...prev, pregunta: e }))}
          title={"Pregunta"}
        />


        <div className=" my-4">
          <h3 class="block mb-2 text-sm font-medium text-gray-900 ">Tipo</h3>
          <div className=" grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="flex items-center ps-4 border border-gray-200 rounded ">
              <input
                id="bordered-radio-1"
                type="radio"
                value={"respuesta"}
                checked={formValues.tipo === "respuesta"}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, tipo: e.target.value }))
                }
                name="bordered-radio"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2 "
              />
              <label
                htmlFor="bordered-radio-1"
                className="w-full py-4 ms-2 text-sm font-medium text-gray-900 "
              >
                Respuesta única
              </label>
            </div>
            <div className="flex items-center ps-4 border border-gray-200 rounded ">
              <input
                id="bordered-radio-2"
                type="radio"
                value={"alternativas"}
                checked={formValues.tipo === "alternativas"}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, tipo: e.target.value }))
                }
                name="bordered-radio"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2 "
              />
              <label
                htmlFor="bordered-radio-2"
                className="w-full py-4 ms-2 text-sm font-medium text-gray-900 "
              >
                Alternativas
              </label>
            </div>

            <div className="flex items-center ps-4 border border-gray-200 rounded ">
              <input
                id="bordered-radio-3"
                type="radio"
                value={"seleccionMultiple"}
                checked={formValues.tipo === "seleccionMultiple"}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, tipo: e.target.value }))
                }
                name="bordered-radio3"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2 "
              />
              <label
                htmlFor="bordered-radio-3"
                className="w-full py-4 ms-2 text-sm font-medium text-gray-900 "
              >
                Selección Múltiple
              </label>
            </div>
          </div>
        </div>
        {(formValues.tipo === "alternativas" || formValues.tipo === "seleccionMultiple") && (
          <div className=" my-4">
            <h3 class="block mb-2 text-sm font-medium text-gray-900 ">
              Beneficiado
            </h3>
            <div className=" grid grid-cols-2 gap-4">
              <div className="flex items-center ps-4 border border-gray-200 rounded ">
                <input
                  id="comercial"
                  type="radio"
                  value={"Comercial"}
                  checked={formValues.beneficiado === "Comercial"}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      beneficiado: e.target.value,
                    }))
                  }
                  name="beneficiado"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2 "
                />
                <label
                  htmlFor="comercial"
                  className="w-full py-4 ms-2 text-sm font-medium text-gray-900 "
                >
                  Comercial
                </label>
              </div>
              <div className="flex items-center ps-4 border border-gray-200 rounded ">
                <input
                  id="especialista"
                  type="radio"
                  value={"Especialista"}
                  checked={formValues.beneficiado === "Especialista"}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      beneficiado: e.target.value,
                    }))
                  }
                  name="beneficiado"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2 "
                />
                <label
                  htmlFor="especialista"
                  className="w-full py-4 ms-2 text-sm font-medium text-gray-900 "
                >
                  Especialista
                </label>
              </div>
            </div>
          </div>
        )}
        {(formValues.tipo === "alternativas" || formValues.tipo === "seleccionMultiple") && (
          <div className=" mt-6">
            <div className=" flex justify-center ">
              <button
                type="button"
                className=" pb-1 text-sm dark:hover:text-blue-500 dark:hover:border-blue-500 dark:border-b  text-gray-700 hover:text-blue-600 hover:border-blue-600 hover:border-b  dark:text-blue-100  flex justify-start gap-2 items-center "
                onClick={handleAddItemDescripcion}
              >
                Agregar alternativa
                <FaPlus />
              </button>
            </div>
            <div className=" flex flex-col gap-4 pt-4">
              {itemDescripciones.map((preciounitario, index) => (
                <div
                  className="flex flex-col  gap-2 w-full shadow-md bg-gray-50  rounded-md p-2"
                  key={index}
                >
                  <div className=" inline-flex w-full gap-2">
                    <div className=" flex-1">
                      <label
                        htmlFor={"alternativa"}
                        class="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Alternativa
                      </label>

                      <input
                        type="text"
                        id="alternativa"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        placeholder="Alternativa"
                        value={preciounitario.respuesta}
                        onChange={(e) =>
                          handleInputChangeItemDescripciones(
                            index,
                            "respuesta",
                            e.target.value
                          )
                        }
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor={"puntos"}
                        class="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Puntos
                      </label>
                      <input
                        type="number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        placeholder="Puntos"
                        value={preciounitario.puntos}
                        onChange={(e) =>
                          handleInputChangeItemDescripciones(
                            index,
                            "puntos",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className=" flex justify-center  py-2">
                    <button
                      className=" flex justify-center w-full md:max-w-xs items-center bg-red-50 text-red-800 p-2 rounded-lg border border-red-400 hover:bg-red-100"
                      type="button"
                      onClick={() => handleDeleteImteDescripcion(index)}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <InputText
          value={formValues.linkYoutube}
          required={false}
          setValue={(e) => setFormValues((prev) => ({ ...prev, linkYoutube: e }))}
          title={"Link de video (opcional)"}
          placeholder={"https://www.youtube.com/watch?v=qTsaS1Tm-Ic"}
        />


        {/**FILE INPUT */}
        <div className="flex flex-col gap-4 items-center justify-center w-full">
        {selectedFile && selectedFileType.startsWith("image/") && (
          <div>
            <img
              alt="upload"
              src={URL.createObjectURL(selectedFile)} // Use createObjectURL for local files
              style={{ maxWidth: "100%", maxHeight: "300px" }}
              className=" rounded-md"
            />
          </div>
        )}
        {selectedFile && (
          <div className=" w-full flex justify-center items-center gap-4">
            <div className=" flex justify-center gap-2 items-center bg-green-50 text-green-700 rounded-md p-2">
              {selectedFile.name}
              <FaCheckCircle />
            </div>
            <button
              onClick={() => {
                setSelectedFileType("");
                setSelectedFile(null);
              }}
              className="p-2 bg-red-50 rounded-md flex justify-center text-red-700 hover:text-red-800"
            >
              <MdClose />
            </button>
          </div>
        )}
        {!selectedFile && (
          <label
            htmlFor="dropzone-file"
            onDrop={handleFileUpload}
            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">
                  Click para subir tu archivo
                </span>
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                SVG, PNG, JPG , WEBP, PDF, DOC, DOCX, XLS (MAX. 5MB)
              </p>
            </div>
            <input
              onChange={handleFileUpload}
              id="dropzone-file"
              accept=".pdf, .doc, .docx, .xls, image/*"
              type="file"
              className="hidden"
            />
          </label>
        )}
      </div>

      <div class="flex items-center  my-8">
          <input
            checked={formValues.habilitarMedia}
            id="checkbox-1"
            type="checkbox"
            value={formValues.habilitarMedia}
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                habilitarMedia: !formValues.habilitarMedia,
              }))
            }
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="checkbox-1"
            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Habilitar subida de archivos para esta pregunta
          </label>
        </div>

        <div className=" flex justify-center py-6">
          <button
            type="submit"
            disabled={isLoading}
            className={ ` ${isLoading ? "opacity-80" :"hover:bg-orange-600 "}  w-full md:max-w-xs rounded-md font-semibold p-2 text-white bg-[#FF6400] text-sm  ` }
          >
            {isLoading ? <div className=" flex justify-center w-full"><FormLoader className={"w-12 h-6"} colour={"white"}/></div> : "Registrar"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default NuevaPreguntaFormularioDialog;
