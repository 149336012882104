import React, { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND } from "../config/configEnvs";
import TablePlanillaCurrentMonth from "../blockComponents/planilla/currentMonth/TablePlanillaCurrentMonth";
import RegistrosPlanilla from "../blockComponents/planilla/registrosPlanilla/RegistrosPlanilla";
import { MdCheck } from "react-icons/md";
const Planilla = () => {
  const [trabajadoresList, setTrabajadoresList] = useState([]);
  const[isFetchedPlanilla,setIsFetchedPlanilla]=useState(false)
  const [planillaMesList, setPlanillaMesList] = useState([]);
  const [planillaMetadata, setPlanillaMetadata] = useState({
    totalPages: 0,
    page: 0,
    hasPrevPage: false,
    hasNextPage: false,
  });
  const getTrabajadoresList = async () => {
    try {
      const responseTrabajadores = await axios.get(
        `${BACKEND}/api/trabajadores?limit=1000`
      );
      setTrabajadoresList(responseTrabajadores.data.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const getPlanillaMes = async () => {

    try {
      const response = await axios.get(
        `${BACKEND}/api/planillaMes?sort=-fechaInicio`
      );
      setPlanillaMesList(response.data.docs);
      setPlanillaMetadata({
        totalPages: response.data.totalPages,
        page: response.data.page,
        hasNextPage: response.data.hasNextPage,
        hasPrevPage: response.data.hasPrevPage,
      });
    } catch (error) {
      console.log(error);
    }finally{
        setIsFetchedPlanilla(true)
    }
  };

  useEffect(() => {
    getTrabajadoresList();
    getPlanillaMes();
  }, []);

  const tabs = [
    { id: 1, name: "Estimacion mensual", value: "Estimacion mensual" },
    { id: 2, name: "Registro de planilla", value: "egistro de planilla" },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <div>
      <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
        Planilla
      </h2>

      <div>
        <ul className="flex flex-wrap justify-center text-sm font-medium text-center text-gray-500 border-b border-gray-200 ">
          {tabs.map((e) => (
            <li key={e.id} className="me-2">
              <button
                onClick={() => {
                  setActiveTab(e);
                }}
                type="button"
                className={` inline-flex gap-2 items-center justify-center p-4 ${
                  activeTab.id === e.id
                    ? "text-blue-600  bg-gray-200 "
                    : " text-gray-700 hover:bg-gray-50 "
                }   rounded-t-lg active `}
              >
                {`${e.name}`}
              </button>
            </li>
          ))}
        </ul>
      </div>

      { isFetchedPlanilla&& activeTab.id === tabs[0].id && (
        <div>
          {planillaMesList.some(
            (obj) =>
              obj.fechaInicio < new Date().toISOString() &&
              obj.fechaFin > new Date().toISOString()
          ) ? (
            <div className=" py-6 max-w-md mx-auto ">
                <div className=" flex justify-center gap-2 items-center bg-green-600 bg-opacity-10 font-semibold p-4 text-green-500 ">
                    <div className=" bg-white rounded-full p-2 ">
                    <MdCheck/>
                    </div>
                    <span> Estimación para este mes guardado</span>
                </div>
            </div>
          ) : (
            <TablePlanillaCurrentMonth
              trabajadores={trabajadoresList}
              getPlanillaMes={getPlanillaMes}
            />
          )}
        </div>
      )}

      {activeTab.id === tabs[1].id && (
        <div>
          <RegistrosPlanilla
            planillaMesList={planillaMesList}
            planillaMetadata={planillaMetadata}
          />
        </div>
      )}
    </div>
  );
};

export default Planilla;
