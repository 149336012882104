import React, { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";
import { FaPlus } from "react-icons/fa";
import NuevaMetaGrupal from "../dialogs/NuevaMetaGrupal";
import ItemTableMetaGrupal from "./ItemTableMetaGrupal";
const MetaGrupalComercialContainer = () => {
  const [listData, setListData] = useState([]);
  const[openModal,setOpenModal]=useState(false)
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await axios.get(
      `${BACKEND}/api/metaGrupalComercial?limit=1000`
    );
    setListData(response.data.docs);
  };
  return (
    <div>
      <NuevaMetaGrupal
        open={openModal}
        onClose={() => setOpenModal(false)}
        refetch={getData}
      />
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className=" text-gray-600 font-bold text-lg ">
              Metas grupales
            </div>
            <button
              type="button"
           onClick={() => setOpenModal(true)}
              className="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2  focus:outline-none "
            >
              <FaPlus className=" mr-2" />
              Agregar meta
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Nombre
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Meta
                  </th>

                  <th scope="col" className="px-4 py-3">
                    % comision
                  </th>

                  <th scope="col" className="px-4 py-3">
                    <span className="sr-only">Acciones</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {listData.map((data) => (
                  <ItemTableMetaGrupal
                    key={data.id}
                    data={data}
                    refetch={() => getData()}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaGrupalComercialContainer;
