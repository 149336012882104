import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const NuevoTrabajadorMes = () => {
  const [trabajadores, setTrabajadores] = useState([]);
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const anios = [2023, 2024, 2025];
  // Valores
  const [trabajador, setTrabajador] = useState("");
  const [mes, setMes] = useState("");
  const [anio, setAnio] = useState("");
  const [premio, setPremio] = useState("");
  const [first, setFirst] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrabajadores = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/trabajadores`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setTrabajadores(data);
        setFirst(true);
      } catch (error) {
        console.log(error);
      }
    };

    if (!first) {
      fetchTrabajadores();
    }
  });

  const handleTrabajadorChange = (e) => {
    const selectedTrabajador = JSON.parse(e.target.value);
    setTrabajador(selectedTrabajador);
  };

  const handleMesChange = (e) => {
    setMes(e.target.value);
  };

  const handleAnioChange = (e) => {
    setAnio(e.target.value);
  };

  const handlePremioChange = (e) => {
    setPremio(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fechaCompleta = `${mes} ${anio}`;

    try {
      console.log(trabajador);
      const response = await axios.post(`${BACKEND}/api/trabajadormes`, {
        trabajador: trabajador.id,
        mes: fechaCompleta,
        premio,
      });

      const nuevoTrabajador = response.data;
      console.log("Trabajador añadido:", nuevoTrabajador);
      navigate("/home/anuncios/trabajador-mes");
    } catch (error) {
      console.error("Error al añadir el trabajador:", error);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">Añadir Trabajador</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="trabajador" className="block mb-2">
              Trabajador <span className=" text-red-500">*</span>
            </label>
            <select
              type="text"
              id="trabajador"
              value={JSON.stringify(trabajador)}
              onChange={handleTrabajadorChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            >
              <option value=""></option>
              {trabajadores.map((trabajador) => (
                <option key={trabajador.id} value={JSON.stringify(trabajador)}>
                  {trabajador.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="mes" className="block mb-2">
              Mes y Año <span className=" text-red-500">*</span>
            </label>
            <div className="flex">
              <select
                id="mes"
                value={mes}
                onChange={handleMesChange}
                className="w-1/2 mr-2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                required
              >
                <option value="" disabled>
                  Selecciona el mes
                </option>
                {meses.map((m) => (
                  <option key={m} value={m}>
                    {m}
                  </option>
                ))}
              </select>
              <select
                id="anio"
                value={anio}
                onChange={handleAnioChange}
                className="w-1/2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                required
              >
                <option value="" disabled>
                  Selecciona el año
                </option>
                {anios.map((a) => (
                  <option key={a} value={a}>
                    {a}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="premio" className="block mb-2">
              Premio <span className=" text-red-500">*</span>
            </label>
            <input
              type="text"
              id="premio"
              value={premio}
              onChange={handlePremioChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="flex justify-end">
            <Link
              to="/home/anuncios/trabajador-mes"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Añadir
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NuevoTrabajadorMes;
