import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { BACKEND } from "../config/configEnvs";

const EditarPerfil = () => {
  // Valores
  const user = useSelector((state) => state.auth.user);
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [foto, setFoto] = useState(null);
  const [password, setPassword] = useState(user.password);
  const [changePassword, setChangePassword] = useState(false);

  const navigate = useNavigate();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFotoChange = (e) => {
    const file = e.target.files[0];
    setFoto(file);
  };

  const handleTogglePasswordChange = () => {
    setChangePassword(!changePassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleImageUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", foto);

      const response = await axios.post(`${BACKEND}/api/usersfotos`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const mediaId = response.data.doc.id; // Obtener el ID de la imagen subida
      return mediaId;
    } catch (error) {
      console.error("Error al subir la imagen:", error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let mediaId = null;
      if (user.foto?.id) {
        mediaId = user.foto.id;
      }
      if (foto) {
        mediaId = await handleImageUpload();
      }
      let updatedPassword = password;
      if (changePassword && password.trim() !== "") {
        updatedPassword = password;
      }
      console.log(mediaId);
      const response = await axios.put(`${BACKEND}/api/users/${user.id}`, {
        name,
        email,
        foto: mediaId,
        password: updatedPassword,
      });
      const userActualizado = response.data;
      console.log("User actualizado:", userActualizado);
      navigate("/home/dashboard");
    } catch (error) {
      console.error("Error al actualizar el user:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-lg">
        <h2 className="text-2xl mb-4">Editar Perfil</h2>
        <p className="text-xs mb-4 text-blue-600">
          Si los cambios no se visualizan, vuelve a iniciar sesión
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">
              Nombre <span className=" text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">
              Correo Electrónico <span className=" text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="flex w-full mb-4">
            <div className="w-1/2">
              <label htmlFor="email" className="block mb-2">
                Foto de Perfil
              </label>
              <input
                type="file"
                id="foto"
                onChange={handleFotoChange}
                className="hidden"
              />
              <label
                htmlFor="foto"
                className="text-xl text-gray-600 hover:text-blue-500"
              >
                <span className="text-3xl font-bold mr-2">+</span> Añadir foto
              </label>
            </div>
            <div className="w-1/2">
              <label htmlFor="email" className="block mb-2">
                Foto actual
              </label>
              {user.foto?.url ? (
                <img
                  className="max-h-12 rounded-md"
                  src={user.foto.url}
                  alt={user.foto?.alt}
                />
              ) : (
                <p>Aún no tienes una foto</p>
              )}
              {console.log(user.foto?.url)}
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block mb-2">
              Contraseña
            </label>
            <div className="mb-4">
              <button
                type="button"
                className={`px-3 py-1 bg-${
                  changePassword ? "red-500" : "green-500"
                } rounded text-white`}
                onClick={handleTogglePasswordChange}
              >
                {changePassword ? "Cancelar Cambio" : "Cambiar contraseña?"}
              </button>
            </div>
            <input
              type="text"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className={`w-full px-3 py-2 border ${
                changePassword ? "block" : "hidden"
              } border-gray-300 rounded focus:outline-none focus:border-blue-500`}
              disabled={!changePassword}
            />
          </div>
          <div className="flex justify-end">
            <Link
              to="/home/dashboard"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Actualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarPerfil;
