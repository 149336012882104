import { createSlice } from "@reduxjs/toolkit";

const tareaSlice = createSlice({
  name: "tarea",
  initialState: {
    register: localStorage.getItem("registerStatus") ? localStorage.getItem("registerStatus") : [],
    error: null,
    loading: false,
  },
  reducers: {
    addTareaStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    addTareaSuccess: (state, action) => {
      state.loading = false;
      state.register = action.payload;
      localStorage.setItem("registerStatus", action.payload);
    },
    addTareaFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  addTareaStart,
  addTareaSuccess,
  addTareaFailure,
} = tareaSlice.actions;

export default tareaSlice.reducer;