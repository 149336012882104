import React from "react";

const InputText = ({ value, setValue,placeholder,title,required=true}) => {
  return (
    <div className="my-3">
      <label
        htmlFor={title}
        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        {title}
      </label>
      <input
        type="text"
        id={title}
        value={value}
        onChange={(e)=>setValue(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        placeholder={placeholder}
        required={required}
      />
    </div>
  );
};

export default InputText;
