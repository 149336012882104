import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const EditarRegla = () => {
  // Valores
  const { id } = useParams();
  const [name, setName] = useState("");
  const [sancion, setSancion] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRegla = async () => {
      try {
        const response = await axios.get(
          `${BACKEND}/api/reglasgenerales/${id}`
        );
        const regla = response.data;
        setName(regla.name || "");
        setSancion(regla.sancion || "");
      } catch (error) {
        console.error("Error al obtener la regla:", error);
      }
    };

    if (id) {
      fetchRegla();
    }
  }, [id]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSancionChange = (e) => {
    setSancion(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${BACKEND}/api/reglasgenerales/${id}`, {
        name,
        sancion,
      });

      const reglaActualizado = response.data;
      console.log("Regla actualizado:", reglaActualizado);
      navigate("/home/reglas");
    } catch (error) {
      console.error("Error al actualizar la regla:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-lg">
        <h2 className="text-2xl mb-4">Editar Regla</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">
              Regla <span className=" text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="sancion" className="block mb-2">
              Sanción
            </label>
            <textarea
              id="sancion"
              value={sancion}
              onChange={handleSancionChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="flex justify-end">
            <Link
              to="/home/reglas"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Actualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarRegla;
