import React, { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import { toast } from "react-toastify";

const ModalFilters = ({ open, setOpen, activeCols, setActiveCols }) => {

  const updateShowStateById = (campo) => {
    setActiveCols(prevHeaders =>
      prevHeaders.map(header =>
        campo.id === header.id ? { ...header, show: !header.show } : header
      )
    );
  };

  return (
    <Modal title="Filtros" activeModal={open} onClose={() => setOpen(false)}>
      <div className="flex flex-col gap-2">
        {activeCols.map((e) => {
          return (
            <label
              key={e.id}
              className="relative inline-flex items-center cursor-pointer"
            >
              <input
                type="checkbox"
                value={""}
                onChange={() => updateShowStateById(e)}
                className="sr-only peer"
                checked={e.show}
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-orange-200 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 ">
                {e.name}
              </span>
            </label>
          );
        })}
      </div>

      <div className="pt-6">
        <div className="flex justify-center">
          <button
            className="bg-orange-500 text-white rounded-lg shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition duration-300 ease-in-out"
            onClick={() => setOpen(false)}
          >
            <span className="flex items-center justify-center w-full px-4 py-3 text-white">
              Cerrar
            </span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFilters;
