import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import "moment-timezone";
import { Link, useParams } from "react-router-dom";
import { BACKEND } from "../config/configEnvs";

function RegistrosTareasAdmin() {
  // Registros
  const { id } = useParams();
  const [registrosFiltrados, setRegistrosFiltrados] = useState([]);

  const formatDate = (dateString) => {
    const formattedDate = moment(dateString)
      .tz("America/Lima") // Ajusta al horario de Perú
      .format("YYYY-MM-DD HH:mm:ss"); // Formato deseado
    return formattedDate;
  };

  useEffect(() => {
    const fetchRegistrosTareas = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/tareasregistroadmin`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setRegistrosFiltrados(
          data.filter((registro) => registro.tarea?.id === id)
        );
      } catch (error) {
        console.log(error);
      }
    };

    fetchRegistrosTareas();
  });

  return (
    <div className="w-full flex flex-col">
      <div className="">
        <Link
          to="/home/tareas-admin"
          className="px-4 py-2 rounded-lg bg-zinc-300"
        >
          Volver
        </Link>
      </div>
      <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
        Registro de Tareas
      </h2>

      <div className="flex md:hidden px-8">
        <table className="w-full max-w-max mx-auto border-collapse">
          <thead>
            <tr>
              <th className="border-b-2 border-gray-800 p-2">Registros</th>
            </tr>
          </thead>
          <tbody>
            {registrosFiltrados.map((registro, index) => {
              return (
                <tr
                  key={registro.id}
                  className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
                >
                  <td className="border px-6 flex items-center justify-center border-gray-300 p-2">
                    <div>
                      <div className="flex items-center mb-2">
                        <p className="w-28 pr-2 font-semibold">Tarea:</p>
                        <p className="pl-2">{registro.tarea.tarea}</p>
                      </div>
                      <div className="flex items-center mb-2">
                        <p className="w-28 pr-2 font-semibold">Fecha:</p>
                        <p className="pl-2">{formatDate(registro.fecha)}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="hidden md:flex px-8">
        <table className="w-full max-w-4xl mx-auto border-collapse">
          <thead>
            <tr>
              <th className="border-b-2 border-gray-800 p-2">Tarea</th>
              <th className="border-b-2 border-gray-800 p-2">Estado</th>
              <th className="border-b-2 border-gray-800 p-2">Fecha y hora</th>
            </tr>
          </thead>
          <tbody>
            {registrosFiltrados.map((registro, index) => (
              <tr
                key={registro.id}
                className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
              >
                <td className="border border-gray-300 p-2">
                  {registro.tarea.tarea}
                </td>
                <td className="border border-gray-300 p-2">
                  {registro.estado}
                </td>
                <td className="border border-gray-300 p-2">
                  {formatDate(registro.fecha)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RegistrosTareasAdmin;
