import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { BACKEND } from "../config/configEnvs";
import { FaEdit, FaExclamationCircle, FaUserCircle, FaFilter, FaTrashAlt, FaUserAlt, FaUsers, FaUserTie } from "react-icons/fa";
import { toast } from "react-toastify";
import ModalFilters from "../blockComponents/modals/ModalFilters";


function Trabajadores() {
  const [trabajadores, setTrabajadores] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const registrosPerPage = 50;

  // Modal de filtros
  const [ openFilter, setOpenFilter ] = useState(false)
  const [ activeCols, setHeadersTableActive ] = useState([]);

  const headers = [
    { id: 1, name: "Foto", show: true },
    { id: 2, name: "DNI", show: true },
    { id: 3, name: "Nombre", show: true },
    { id: 4, name: "Nacimiento", show: true },
    { id: 5, name: "Correo", show: true },
    { id: 6, name: "Número agencia", show: true },
    { id: 7, name: "Estado trabajador", show: true },
    { id: 8, name: "Área", show: true },
    { id: 9, name: "Subárea", show: true },
    { id: 10, name: "Puesto", show: true },
    { id: 11, name: "Género", show: false },
    { id: 12, name: "País", show: false },
    { id: 13, name: "Ciudad", show: false },
    { id: 14, name: "Dirección", show: false },
    { id: 15, name: "Banco", show: true },
    { id: 16, name: "Sueldo", show: true },
    { id: 17, name: "N° cuenta bancaria", show: true },
    { id: 18, name: "N° cuenta interbancaria", show: false },
    { id: 19, name: "Tel. emergencia", show: false },
    { id: 20, name: "Correo personal", show: false },
    { id: 21, name: "Hijos", show: false },
    { id: 22, name: "Mascotas", show: false },
    { id: 23, name: "Grado estudios", show: false },
    { id: 24, name: "Carrera o especialidad", show: false },
    { id: 25, name: "Vínculo Laboral", show: true },
    { id: 26, name: "Discapacidad", show: false },
    { id: 27, name: "Hobbies", show: false },
    { id: 28, name: "Link Facebook", show: false },
    { id: 29, name: "Link Instagram", show: false },
    { id: 30, name: "Link Linkedin", show: true },
  ];

  useEffect(() => {
        setHeadersTableActive(headers);
  }, []);

  const fetchTrabajadores = async () => {
    try {
      const response = await axios.get(`${BACKEND}/api/trabajadores?limit=0`);
      const data = response.data.docs;
      setTrabajadores(data);
    } catch (error) {
      toast.error("Error obteniendo a los trabajadores: " + error);
    }
  };

  useEffect(() => {
    fetchTrabajadores()
  }, []);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPageNumber(0);
  };

  const handleDelete = async (trabajadorId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este trabajador?"
    );

    if (confirmed) {
      try {
        await axios.delete(`${BACKEND}/api/trabajadores/${trabajadorId}`);
        // const updatedTrabajadores = trabajadores.filter(
        //   (trabajador) => trabajador._id !== trabajadorId
        // );
        // setTrabajadores(updatedTrabajadores);
        fetchTrabajadores()
        toast.success("Registro eliminado exitosamente")
      } catch (error) {
        toast.error("Error al eliminar el trabajador:", error);
      }
    }
  };

  const filteredRegistros = trabajadores.filter((trabajador) => {
    const searchTermLower = searchTerm.toLowerCase();
    return trabajador.name?.toLowerCase().includes(searchTermLower);
  });

  const pageCount = Math.ceil(filteredRegistros.length / registrosPerPage);
  const offset = pageNumber * registrosPerPage;
  const currentRegistros = filteredRegistros.slice(
    offset,
    offset + registrosPerPage
  );

  const formatToReadableDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    return date.toLocaleDateString(); 
  };

  return (
    <>
      <ModalFilters 
        open={openFilter}
        setOpen={setOpenFilter}
        activeCols={activeCols}
        setActiveCols={setHeadersTableActive}
      />

      <div className="w-full flex flex-col">
        <div className="flex flex-col px-8 py-2 bg-zinc-800 mb-4">
          <div className="flex flex-col md:flex-row items-center justify-between">

            <div className="flex items-center gap-4 p-4">
              <FaUsers className="text-white text-2xl" />
              <h2 className="text-2xl font-bold text-white">Trabajadores</h2>
            </div>

            <div className="flex flex-col md:flex-row gap-4">
              <div className=" mx-4 md:mx-8">
                <input
                  type="text"
                  placeholder="Buscar trabajador"
                  value={searchTerm}
                  onChange={handleSearch}
                  className="border border-gray-300 px-4 py-2 rounded-md"
                />
              </div>
              <button>
                <Link
                  to="/home/trabajadores/nuevo-trabajador"
                  className="inline-flex items-center px-6 py-2 text-sm font-semibold text-white bg-orange-500 rounded-md shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition-colors duration-300"
                  role="menuitem"
                >
                  Añadir Trabajador
                </Link>
                </button>
                <button className="text-orange-600 bg-orange-500 bg-opacity-10 hover:bg-opacity-20 rounded-md p-3" type="button" onClick={() => setOpenFilter(true)}>
                    <FaFilter />
                </button>
            </div>

          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full border-collapse text-sm">
            <thead className="bg-gray-200 text-black">
              <tr>
                <th>
                  Acciones
                </th>
              {activeCols.find((e) => e.id === 1)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Foto</th>
              )}
              {activeCols.find((e) => e.id === 2)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">DNI</th>
              )}
                {activeCols.find((e) => e.id === 3)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Nombre</th>
              )}
                {activeCols.find((e) => e.id === 4)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Nacimiento</th>
              )}
                {activeCols.find((e) => e.id === 5)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Correo</th>
              )}

                {activeCols.find((e) => e.id === 6)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Número agencia</th>
              )}
                {activeCols.find((e) => e.id === 7)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Estado trabajador</th>
              )}


                {activeCols.find((e) => e.id === 8)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Area</th>
              )}
                {activeCols.find((e) => e.id === 9)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Subarea </th>
              )}
                {activeCols.find((e) => e.id === 10)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Puesto </th>
              )}
                {activeCols.find((e) => e.id === 11)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Género </th>
              )}
                {activeCols.find((e) => e.id === 12)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">País</th>
              )}
                {activeCols.find((e) => e.id === 13)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Ciudad</th>
              )}
                {activeCols.find((e) => e.id === 14)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Direccion</th>
              )}
                {activeCols.find((e) => e.id === 15)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Banco</th>
              )}
                {activeCols.find((e) => e.id === 16)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Sueldo</th>
              )}
                {activeCols.find((e) => e.id === 17)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">N° cuenta bancaria</th>
              )}
                {activeCols.find((e) => e.id === 18)?.show && (            
                <th className="p-4 border-b border-gray-300 truncate font-semibold">N° cuenta interbancaria</th> 
              )} 
                {activeCols.find((e) => e.id === 19)?.show && (            
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Tel. emergencia</th>
              )}
                {activeCols.find((e) => e.id === 20)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Correo personal</th>
              )}
                {activeCols.find((e) => e.id === 21)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Hijos</th>
              )}
                {activeCols.find((e) => e.id === 22)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Mascotas</th>
              )}
                {activeCols.find((e) => e.id === 23)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Grado estudios</th>
              )}
                {activeCols.find((e) => e.id === 24)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Carrera o especialidad</th>
              )}
                {activeCols.find((e) => e.id === 25)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Vinculo Laboral</th>
              )}
                {activeCols.find((e) => e.id === 26)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Discapacidad</th>
              )}
                {activeCols.find((e) => e.id === 27)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Hobbies</th>
              )}
                {activeCols.find((e) => e.id === 28)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Link Facebook</th>
              )}
                {activeCols.find((e) => e.id === 29)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Link Instagram</th>
              )}
                {activeCols.find((e) => e.id === 30)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Link Linkedin</th>
              )}


              </tr>
            </thead>
            <tbody>
              {currentRegistros.map((trabajador, index) => {
                return (
                  <tr
                    key={trabajador.id}
                    className="hover:bg-gray-100 transition-colors duration-100"
                  >
                    <td className="p-4 flex border-b justify-center border-gray-300 truncate">
                      <Link
                        to={`/home/trabajadores/editar-trabajador/${trabajador.id}`}
                        className="mx-2 my-1 flex items-center text-white bg-green-500 hover:bg-green-600 px-4 py-2 rounded-lg shadow-md transition duration-300 ease-in-out"
                      >
                        <FaEdit className="mr-2" />
                        Editar
                      </Link>
                      <button
                        onClick={() => handleDelete(trabajador.id)}
                        className="mx-2 my-1 flex items-center text-white bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg shadow-md transition duration-300 ease-in-out"
                      >
                        <FaTrashAlt className="mr-2" />
                        Eliminar
                      </button>
                    </td>

                    {activeCols.find((e) => e.id === 1)?.show && (
                      <td className="p-4 border-b border-gray-300 truncate font-semibold">
                      <div className="w-10 h-10 rounded-full overflow-hidden border border-gray-300">
                          {trabajador.foto?.url ? (
                            <img
                              src={trabajador.foto.url}
                              alt="Foto de perfil"
                               className="w-full h-full object-cover"
                            />
                          ) : (
                            <FaUserCircle className="w-10 h-10 text-gray-500" />
                          )}
                        </div>
                      </td>
                    )}
                    {activeCols.find((e) => e.id === 2)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.dni}
                      </td>
                    )}
                    {activeCols.find((e) => e.id === 3)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.name}
                      </td>
                    )}                    
                    {activeCols.find((e) => e.id === 4)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {formatToReadableDate(trabajador.birthday)}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 5)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.email}
                      </td>
                    )}          
                     {activeCols.find((e) => e.id === 6)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.numeroAgencia}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 7)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.activo ? "Activo" : "Inactivo"}
                      </td>
                    )}
                    {activeCols.find((e) => e.id === 8)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.area}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 9)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.subarea}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 10)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.puesto}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 11)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.sexo}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 12)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.pais}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 13)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.ciudad}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 14)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.direccion}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 15)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.banco}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 16)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        S/. {trabajador.sueldo}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 17)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.cuentaBancaria}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 18)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.cuentaInterbancaria}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 19)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.telefonoEmergencia}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 20)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.correoPersonal}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 21)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.hijos}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 22)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.mascotas}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 23)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.gradoEstudios}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 24)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.carreraEspecialidad}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 25)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.vinculoLaboral}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 26)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.discapacidad}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 27)?.show && (
                      <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                        {trabajador.hobbies}
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 28)?.show && (
                      <td className="p-4 pl-8 justify-center border-b border-gray-300 max-w-xs truncate">
                        <a
                          href={trabajador.linkFacebook}
                          className="text-blue-500 underline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {trabajador.linkFacebook}
                        </a>
                      </td>
                    )}
                     {activeCols.find((e) => e.id === 29)?.show && (
                      <td className="p-4 pl-8 justify-center border-b border-gray-300 max-w-xs truncate">
                       <a
                         href={trabajador.linkInstagram}
                         className="text-blue-500 underline"
                         target="_blank"
                         rel="noopener noreferrer"
                       >
                         {trabajador.linkInstagram}
                       </a>
                     </td>
                    )}
                     {activeCols.find((e) => e.id === 30)?.show && (
                      <td className="p-4 pl-8 justify-center border-b border-gray-300 max-w-xs truncate">
                        <a
                          href={trabajador.linkLinkedin}
                          className="text-blue-500 underline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {trabajador.linkLinkedin}
                        </a>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="flex justify-center mt-4">
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="flex items-center justify-center mt-4"
            pageClassName="cursor-pointer mx-2"
            previousClassName="cursor-pointer mx-2"
            nextClassName="cursor-pointer mx-2"
            activeClassName="bg-[#ff6400] text-white rounded-lg px-3 py-1"
            disabledClassName="text-gray-500 cursor-not-allowed mx-2"
            previousLinkClassName="pagination-link"
            nextLinkClassName="pagination-link"
            pageLinkClassName="pagination-link"
            activeLinkClassName="pagination-link-active"
          />
        </div>
      </div>
    </>
  );
}

export default Trabajadores;
