import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";
import { FaCheckCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";

const NuevoAnuncio = () => {
  // Valores
  const [title, setTitle] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [link, setLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState("");
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescripcionChange = (e) => {
    setDescripcion(e.target.value);
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const fileResponse = await uploadImage();

      const response = await axios.post(`${BACKEND}/api/anuncios`, {
        autor: user.id,
        title,
        descripcion: descripcion,
        imagen: fileResponse ? fileResponse.doc.id : null,
      });

      const nuevoAnuncio = response.data;
      console.log("Anuncio añadido:", nuevoAnuncio);
      navigate("/home/anuncios");
    } catch (error) {
      console.error("Error al añadir el anuncio:", error);
      setErrorMessage("Error al añadir el anuncio");
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file.size > 5 * 1000000) {
      window.alert("Archivo superior a 5mb");
      return;
    }
    setSelectedFile(file);
    if (file) {
      // Update state with the selected file
      setSelectedFile(file);
      setSelectedFileType(file.type);
    }
  };

  const uploadImage = async () => {
    if (!selectedFile) return null;
    try {
      // First request
      const formData = new FormData();
      formData.append("file", selectedFile);

      const dataImage = await axios.post(
        `${BACKEND}/api/anunciosImagenes`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return dataImage.data;
    } catch (error) {
      // Handle errors from either the first or second request
      console.error("Error in uploadImage:", error);
      toast.error("Error al subir esta imagen", {
        style: { zIndex: 9999 },
        position: "top-center",
      });
      return null;
    }
  };
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">Añadir Anuncio</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="title" className="block mb-2">
              Título <span className=" text-red-500">*</span>
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={handleTitleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="descripcion" className="block mb-2">
              Descripción
            </label>
            <textarea
              id="descripcion"
              value={descripcion}
              onChange={handleDescripcionChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>

          {/**FILE INPUT */}
          <div className="flex flex-col gap-4 items-center justify-center w-full">
            {selectedFile && selectedFileType.startsWith("image/") && (
              <div>
                <img
                  alt="upload"
                  src={URL.createObjectURL(selectedFile)} // Use createObjectURL for local files
                  style={{ maxWidth: "100%", maxHeight: "300px" }}
                  className=" rounded-md"
                />
              </div>
            )}
            {selectedFile && (
              <div className=" w-full flex justify-center items-center gap-4">
                <div className=" flex justify-center text-justify gap-2  items-center bg-green-50 text-green-700 rounded-md p-2">
                  <p className=" max-w-xs truncate">{selectedFile.name}</p>
                  <FaCheckCircle />
                </div>
                <button
                  onClick={() => {
                    setSelectedFileType("");
                    setSelectedFile(null);
                  }}
                  className="p-2 bg-red-50 rounded-md flex justify-center text-red-700 hover:text-red-800"
                >
                  <MdClose />
                </button>
              </div>
            )}
            {!selectedFile && (
              <label
                htmlFor="dropzone-file"
                onDrop={handleFileUpload}
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">
                      Click para subir tu archivo
                    </span>
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG , WEBP, PDF, DOC, DOCX, XLS (MAX. 5MB)
                  </p>
                </div>
                <input
                  onChange={handleFileUpload}
                  id="dropzone-file"
                  accept=".pdf, .doc, .docx, .xls, image/*"
                  type="file"
                  className="hidden"
                />
              </label>
            )}
          </div>

          {errorMessage && (
            <div className="bg-red-200 text-red-800 py-2 px-4 mb-4 rounded">
              {errorMessage}
            </div>
          )}
          <div className="flex justify-end pt-10">
            <Link
              to="/home/anuncios"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Añadir
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NuevoAnuncio;
