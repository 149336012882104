import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BACKEND } from "../config/configEnvs";
import NuevaCategoria from "../forms/Recursos/NuevaCategoria";
import { FaList, FaPlus } from "react-icons/fa";

function Recursos() {
  const [recursos, setRecursos] = useState([]);
  const [openedNewCategoria, setOpenedNewCategoria] = useState(false);
  useEffect(() => {
    
    fetchRecursos();
  }, []);
  const fetchRecursos = async () => {
    try {
      const response = await axios.get(`${BACKEND}/api/recursos`, {
        params: {
          limit: 1000,
        },
      });
      const data = response.data.docs;
      setRecursos(data);
    } catch (error) {
      console.log(error);
    }
  };



  const handleDelete = async (recursoId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este recurso?"
    );

    if (confirmed) {
      try {
        await axios.delete(`${BACKEND}/api/recursos/${recursoId}`);
        const updatedRecursos = recursos.filter(
          (recurso) => recurso.id !== recursoId
        );
        setRecursos(updatedRecursos);
      } catch (error) {
        console.error("Error al eliminar el recurso:", error);
      }
    }
  };

  return (
    <div className="w-full flex flex-col">
      <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
        Recursos
      </h2>
      <NuevaCategoria

        activeModal={openedNewCategoria}
        onClose={() => setOpenedNewCategoria(false)}
      />


<div className="mx-auto max-w-screen-xl w-full px-4 lg:px-12">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
          <Link
            to="/home/recursos/nuevo-recurso"
            className="flex items-center justify-center text-gray-700 bg-white  hover:bg-gray-50 border border-gray-300 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2  focus:outline-none "
            role="menuitem"
          >              <FaPlus className=" mr-2" />

            Añadir recurso
          </Link>
            <button
              type="button"
              onClick={()=>setOpenedNewCategoria(true)}
              className="flex items-center justify-center text-gray-700 bg-white  hover:bg-gray-50 border border-gray-300 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2  focus:outline-none "
            >
              <FaList className=" mr-2" />
              Categorías
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                <th className="p-2">Plataforma</th>
              <th className="p-2">Acciones</th>
                </tr>
              </thead>
              <tbody>
              {recursos.map((recurso, index) => {
              return (
                <tr
                  key={recurso.id}
                  className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
                >
                  <td className="border border-gray-300 p-2">
                    {recurso.title}
                  </td>
                  <td className="border font-semibold border-gray-300 p-2">
                    <Link
                      to={`/home/recursos/editar-recurso/${recurso.id}`}
                      className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                    >
                      Editar
                    </Link>
                    <Link
                      to={`/home/recursos/acceso-recurso/${recurso.id}`}
                      className="mx-2 my-1 text-blue-500  rounded-lg mr-2"
                    >
                      Acceso
                    </Link>
                    <button
                      onClick={() => handleDelete(recurso.id)}
                      className="mx-2 my-1 text-red-600  rounded-lg"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              );
            })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    

    </div>
  );
}

export default Recursos;
