import React, { useEffect, useState } from "react";
import Modal from "../../blockComponents/ui/Modal";
import { BACKEND } from "../../config/configEnvs";
import axios from "axios";
import { toast } from "react-toastify";
import FormLoader from "../../blockComponents/ui/FormLoader";
import { FaRegSave, FaTrash } from "react-icons/fa";
import { MdDeleteOutline, MdOutlineSave } from "react-icons/md";

const NuevaCategoria = ({ activeModal, onClose }) => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [categoriesList, setCategoriesList] = useState([]);

  const fetchRecursosCategories = async () => {
    try {
      const response = await axios.get(`${BACKEND}/api/recursosCategories`, {
        params: {
          limit: 1000,
        },
      });
      const data = response.data.docs;
      setCategoriesList(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRecursosCategories();
  }, []);
  const submitCategory = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${BACKEND}/api/recursosCategories`, { title });

      toast.success("Agregado");
      setTitle("");
      fetchRecursosCategories();
    } catch (error) {
      // Handle errors from either the first or second request
      toast.error("Error al agregar esta categoría", {
        style: { zIndex: 9999 },
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal onClose={onClose} title="Categorías" activeModal={activeModal}>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200  mb-10">
        <ul className="flex flex-wrap -mb-px">
          <li className="me-2">
            <button
              type="button"
              onClick={() => setCurrentTab(1)}
              className={` ${
                currentTab === 1
                  ? " text-blue-600 border-b-2 border-blue-600 rounded-t-lg "
                  : " border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 "
              } inline-block p-4   `}
            >
              Nueva categoria
            </button>
          </li>
          <li class="me-2">
            <button
              type="button"
              onClick={() => setCurrentTab(2)}
              className={` ${
                currentTab === 2
                  ? " text-blue-600 border-b-2 border-blue-600 rounded-t-lg "
                  : " border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 "
              } inline-block p-4   `}
              aria-current="page"
            >
              Lista de categorías
            </button>
          </li>
        </ul>
      </div>
      {currentTab === 2 && (
        <div className=" flex flex-col w-full gap-2">
          {categoriesList.map((category) => (
            <ItemRowCategory refetch={()=>fetchRecursosCategories()} category={category} key={category.id} />
          ))}
        </div>
      )}
      {currentTab === 1 && (
        <form onSubmit={submitCategory}>
          <label
            htmlFor="category"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Título
          </label>
          <input
            type="text"
            id="category"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5 "
            placeholder=""
            required
          />

          <div className="  mt-10 flex justify-center w-full">
            <button
              type="submit"
              className=" text-sm py-2 px-8 font-semibold bg-orange-500 text-orange-600 bg-opacity-10 hover:bg-opacity-20 rounded-md "
            >
              {loading ? (
                <FormLoader className={" w-full h-6 "} colour={"#FF6400"} />
              ) : (
                "Agregar"
              )}
            </button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default NuevaCategoria;

const ItemRowCategory = ({ category,refetch }) => {
  const [title, setTitle] = useState(category.title);


  const updateCategory = async () => {
    try {
      await axios.put(`${BACKEND}/api/recursosCategories/${category.id}`, { title });

      toast.success("Actualizado");
      refetch();
    } catch (error) {
      // Handle errors from either the first or second request
      toast.error("Error al actualizar categoría", {
        style: { zIndex: 9999 },
        position: "top-center",
      });
    } finally {
    }
  };

  const deleteCategory = async () => {
    if(!window.confirm("¿Estás seguro de eliminar este item?")){
      return
    }
    try {
      await axios.delete(`${BACKEND}/api/recursosCategories/${category.id}`);

      toast.success("Eliminado");
      refetch();
    } catch (error) {
      // Handle errors from either the first or second request
      toast.error("Error al eliminar", {
        style: { zIndex: 9999 },
        position: "top-center",
      });
    } finally {
    }
  };
  return (
    <div className=" shadow-sm w-full flex justify-between items-center gap-4 text-sm p-2 bg-gray-50 rounded-md">
      <div>
        {" "}
        <input
          type="text"
          id="first_name"
          value={title}
          onChange={(e)=>setTitle(e.target.value)}
          className="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  py-1 px-2.5 "
          placeholder=""
          required
        />
      </div>
      <div className=" flex justify-center gap-2 items-center">
        <button
          type="button"
          onClick={updateCategory}
          className=" p-2 rounded-md bg-green-500 bg-opacity-10 hover:bg-opacity-20 text-green-500"
        >
          <MdOutlineSave />
        </button>
        <button
          type="button"
          onClick={deleteCategory}

          className=" p-2 rounded-md bg-red-500 bg-opacity-10 hover:bg-opacity-20 text-red-500"
        >
          <MdDeleteOutline />
        </button>
      </div>
    </div>
  );
};
