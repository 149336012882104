import { startOfMonth } from "date-fns";
import React from "react";

const ItemRowCostosRegistro = ({ index, data }) => {

  return (
    <tr className="border-b dark:border-gray-700">
      <th
        scope="row"
        className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap "
      >
        {data?.servicio}
      </th>
      <td className="px-4 py-3">S/. {data?.precio}</td>


     
    </tr>
  );
};

export default ItemRowCostosRegistro;
