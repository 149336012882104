import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const AccesoElementoLink = () => {
  // Valores
  const { id, elementoId } = useParams();
  const [name, setName] = useState("");
  const [accessList, setAccessList] = useState([]);
  const [areas, setAreas] = useState([]);
  const [subareas, setSubareas] = useState([]);
  const [area, setArea] = useState("");
  const [subarea, setSubarea] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/links/${id}`);
        const linkData = response.data;
        if (linkData.elemento && elementoId) {
          const elementoEspecifico = linkData.elemento.find(
            (item) => item.id === elementoId
          );
          if (elementoEspecifico) {
            setName(elementoEspecifico.name || "");
            setAccessList(elementoEspecifico.access || []);
          }
        }
      } catch (error) {
        console.error("Error al obtener el link:", error);
      }
    };

    const fetchAreas = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/areas`);
        setAreas(response.data.docs);
      } catch (error) {
        console.error("Error al obtener las areas:", error);
      }
    };

    if (id) {
      fetchData();
      fetchAreas();
    }
  }, [id, elementoId]);

  const handleAddAccess = () => {
    if (subarea.trim() !== "") {
      const subareaExists = accessList.some(
        (access) => access.subarea === subarea
      );
      if (!subareaExists) {
        setAccessList([...accessList, { subarea: subarea }]);
        setSubarea("");
      } else {
        alert("La subárea ya existe en la lista.");
      }
    }
  };

  const handleRemoveAccess = (index) => {
    const newAccessList = [...accessList];
    newAccessList.splice(index, 1);
    setAccessList(newAccessList);
  };

  const handleAreaChange = (e) => {
    const areaSeleccionada = e.target.value;
    setArea(areaSeleccionada);

    const areaEncontrada = areas.find((area) => area.name === areaSeleccionada);
    if (areaEncontrada) {
      setSubarea("");
      setSubareas(areaEncontrada.subarea);
    } else {
      setSubarea("");
      setSubareas([]);
    }
  };

  const handleSubareaChange = (e) => {
    setSubarea(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${BACKEND}/api/links/${id}`);
      const link = response.data;
      const elementoActualizado = link.elemento.map((elemento) => {
        if (elemento.id === elementoId) {
          return {
            ...elemento,
            name,
            access: accessList,
          };
        }
        return elemento;
      });
      const updatedLink = { ...link, elemento: elementoActualizado };
      await axios.put(`${BACKEND}/api/links/${id}`, updatedLink);
      console.log("Elemento actualizado:", elementoActualizado);
      navigate(`/home/links/editar-link/${id}`);
    } catch (error) {
      console.error("Error al actualizar el elemento:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">Acceso "{name}"</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="mb-4">
              <label htmlFor="subarea" className="block mb-2">
                Subáreas
              </label>
              {accessList.length === 0 ? (
                <p className="font-semibold text-green-600">
                  Todas las subáreas tienen acceso a este elemento.
                </p>
              ) : (
                <table className="w-full max-w-4xl mx-auto border-collapse">
                  <tbody>
                    {accessList.map((access, index) => (
                      <tr
                        key={access.id}
                        className="bg-gray-200 px-2 py-1 rounded-full mr-2 mb-2"
                      >
                        <td className="border text-xs border-gray-300 p-2">
                          {access.subarea}
                        </td>
                        <td className="border font-semibold border-gray-300 p-2">
                          <button
                            type="button"
                            className="ml-2 text-red-600"
                            onClick={() => handleRemoveAccess(index)}
                          >
                            X
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="area" className="block mb-2">
                Area
              </label>
              <select
                type="text"
                id="area"
                value={area}
                onChange={handleAreaChange}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              >
                <option value="">Seleccionar area</option>
                {areas.map((area) => (
                  <option key={area.id} value={area.name}>
                    {area.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="subarea" className="block mb-2">
                Subarea
              </label>
              <select
                type="text"
                id="subarea"
                value={subarea}
                onChange={handleSubareaChange}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              >
                <option value="">Seleccionar subarea</option>
                {subareas.map((subarea) => (
                  <option key={subarea.id} value={subarea.name}>
                    {subarea.name}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="button"
              onClick={handleAddAccess}
              className="mt-2 px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Agregar Subárea
            </button>
          </div>
          <div className="flex justify-end">
            <Link
              to={`/home/links/editar-link/${id}`}
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Actualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccesoElementoLink;
