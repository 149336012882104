import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const EditarSancion = () => {
  // Sanciones
  const { id } = useParams();
  const [name, setName] = useState("");
  const [descripcion, setDescripcion] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSancion = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/sanciones/${id}`);
        const sancion = response.data;
        setName(sancion.name || "");
        setDescripcion(sancion.descripcion || "");
      } catch (error) {
        console.error("Error al obtener el sancion:", error);
      }
    };

    if (id) {
      fetchSancion();
    }
  }, [id]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescripcionChange = (e) => {
    setDescripcion(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${BACKEND}/api/sanciones/${id}`, {
        name,
        descripcion,
      });

      const sancionActualizado = response.data;
      console.log("Sancion actualizado:", sancionActualizado);
      navigate("/home/reglas");
    } catch (error) {
      console.error("Error al actualizar el sancion:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-lg">
        <h2 className="text-2xl mb-4">Editar Sanción</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">
              Sanción <span className=" text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="descripcion" className="block mb-2">
              Descripción
            </label>
            <textarea
              id="descripcion"
              value={descripcion}
              onChange={handleDescripcionChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="flex justify-end">
            <Link
              to="/home/reglas"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Actualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarSancion;
