import React, { useState } from "react";
import { Navigate } from "react-router-dom";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { loginSuccess } from "../features/authSlice";
import { BACKEND } from "../config/configEnvs";

const Login = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  console.log(user);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BACKEND}/api/users/login`,
        {
          email: email,
          password: password,
        },
        { withCredentials: "include" }
      );

      dispatch(loginSuccess(response.data.user));
      setRedirect(true);
    } catch (error) {
      setError("Correo o contraseña incorrectos"); // Establecer el mensaje de error en caso de error en la respuesta
      console.log(error);
    }
  };

  if (redirect) {
    return <Navigate to={"/home"} />;
  }

  if (user !== null) {
    return <Navigate to={"/home"} />;
  }

  return (
    <div
      className="md:bg-white bg-[#ff6400] min-h-screen flex flex-col md:flex-row"
      style={{
        backgroundImage:
          'url("https://www.obsbusiness.school/sites/obsbusiness.school/files/images/reunion-productiva.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
      />
      <div className="w-full mt-16 md:mt-0 md:w-1/2 bg-[#ff6400] z-10 md:rounded-r-[50%]">
        <div className="flex justify-center items-center h-full"></div>
      </div>

      <div className="h-full w-full flex items-center justify-center absolute z-20">
        <div className="w-full h-[80%] md:h-full mx-auto my-auto md:mt-0 md:w-1/2">
          <div className="flex bg-white md:bg-inherit justify-center items-center h-full">
            <div className="flex md:bg-white flex-col items-center justify-center py-8 px-12 rounded-2xl">
              <div className="md:top-8 md:left-8 mb-2">
                <img
                  className="h-20 w-20"
                  src="/images/logo-color.png"
                  alt=""
                />
              </div>
              <h1 className="text-2xl text-center text-black mx-auto font-medium">
                CONTROL DE ASISTENCIA
              </h1>
              <form
                className="flex w-full max-w-sm flex-col mt-6"
                onSubmit={handleSubmit}
              >
                <label
                  className="mb-2 text-gray-800 font-bold"
                  htmlFor="username"
                >
                  Usuario
                </label>
                <input
                  className="px-4 py-2 rounded border border-gray-300 mb-4"
                  type="text"
                  id="username"
                  name="username"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label
                  className="mb-2 text-gray-800 font-bold"
                  htmlFor="password"
                >
                  Contraseña
                </label>
                <input
                  className="px-4 py-2 rounded border border-gray-300 mb-4"
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  className="bg-[#ff6400] max-w-max mx-auto mt-6 rounded-full text-white font-bold py-2 px-12"
                  type="submit"
                >
                  Ingresar
                </button>
                {error && <p className="text-red-500 mt-2">{error}</p>}{" "}
                {/* Mostrar el mensaje de error si existe */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
