import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addTareaSuccess } from "../features/tareaSlice";
import moment from "moment";
import "moment-timezone";
import { BACKEND } from "../config/configEnvs";
import KanBanContainer from "../blockComponents/tareas/KanBanContainer";

function ListaTareas() {
  // Registros
  const [tareas, setTareas] = useState([]);
  // Filtros
  const [filtroNombre, setFiltroNombre] = useState("");
  const [trabajadores, setTrabajadores] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  // Búsqueda
  const [searchTerm, setSearchTerm] = useState("");

  const [dropdownOpen, setDropdownOpen] = useState({});

  const toggleDropdown = (tareaId) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [tareaId]: !prevState[tareaId] || false,
    }));
  };

  useEffect(() => {
    const fetchTareas = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/tareas`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setTareas(data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchTrabajadores = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/trabajadores`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setTrabajadores(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchTareas();
    fetchTrabajadores();
  }, []);

  const handleNombreFiltroChange = (event) => {
    setFiltroNombre(event.target.value);
  };

  const handleFechaInicioChange = (event) => {
    setFechaInicio(event.target.value);
  };

  const handleFechaFinChange = (event) => {
    setFechaFin(event.target.value);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDelete = async (tareaId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar esta tarea?"
    );

    if (confirmed) {
      try {
        await axios.delete(`${BACKEND}/api/tareas/${tareaId}`);
        const updatedTareas = tareas.filter((tarea) => tarea.id !== tareaId);
        setTareas(updatedTareas);
      } catch (error) {
        console.error("Error al eliminar el tarea:", error);
      }
    }
  };

  const handleEstadoChange = async (tareaId, nuevoEstado) => {
    try {
      await axios.put(`${BACKEND}/api/tareas/${tareaId}`, {
        estado: nuevoEstado,
      });
      // Actualiza las tareas después de cambiar el estado
      const updatedTareas = tareas.map((tarea) => {
        if (tarea.id === tareaId) {
          return { ...tarea, estado: nuevoEstado };
        }
        return tarea;
      });
      setTareas(updatedTareas);
      // Cierra el menú desplegable
      setDropdownOpen(false);
      handleRegistroTarea(tareaId, nuevoEstado);
    } catch (error) {
      console.error("Error al cambiar el estado de la tarea:", error);
    }
  };

  const registrosFiltrados = tareas.filter((registro) => {
    return (
      registro.tarea &&
      !(
        (filtroNombre !== "" &&
          registro.usuario &&
          registro.usuario.name !== filtroNombre) ||
        (fechaInicio !== "" &&
          moment(registro.fecha_limite).isBefore(fechaInicio)) ||
        (fechaFin !== "" && moment(registro.fecha_limite).isAfter(fechaFin))
      )
    );
  });

  const filteredRegistros = registrosFiltrados.filter((tarea) => {
    const searchTermLower = searchTerm.toLowerCase();
    return tarea.tarea.toLowerCase().includes(searchTermLower);
  });

  // Organiza las tareas en cuatro columnas según su estado
  const pendientes = filteredRegistros.filter(
    (tarea) => tarea.estado === "pendiente"
  );
  const enProceso = filteredRegistros.filter(
    (tarea) => tarea.estado === "en proceso"
  );
  const finalizados = filteredRegistros.filter(
    (tarea) => tarea.estado === "finalizado"
  );
  const incumplidos = filteredRegistros.filter(
    (tarea) => tarea.estado === "incumplido"
  );

  const dispatch = useDispatch();

  const handleRegistroTarea = async (tarea, estado) => {
    const currentTime = new Date().toISOString();

    await axios
      .post(
        `${BACKEND}/api/tareasregistro`,
        {
          tarea: tarea,
          estado: estado,
          fecha: currentTime,
        },
        { withCredentials: "include" }
      )
      .then(({ data }) => dispatch(addTareaSuccess(data.register)))
      .catch((err) => console.log(err));
  };

  return (
    <div className="w-full">

      <div>
        <KanBanContainer/>
      </div>
      <div className=" max-w-8xl flex flex-col mx-auto  pt-40">
        <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
          Lista de Tareas (Trabajadores)
        </h2>
        <div className="flex flex-col px-8 my-6 justify-center items-center">
          <div
            className={`flex flex-col md:flex-row filters-container transition-all`}
          >
            <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4">
              <div className="flex items-center mx-4 my-2">
                <p htmlFor="filtroNombre" className="mr-2 w-28">
                  Nombre:
                </p>
                <select
                  id="filtroNombre"
                  name="filtroNombre"
                  value={filtroNombre}
                  onChange={handleNombreFiltroChange}
                  className="w-40"
                >
                  <option value="">Todos</option>
                  {trabajadores.map((trabajador) => (
                    <option key={trabajador.id} value={trabajador.name}>
                      {trabajador.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center mx-4 my-2">
                <p htmlFor="filtroFechaInicio" className="mr-2 w-28">
                  Fecha inicio:
                </p>
                <input
                  type="date"
                  id="filtroFechaInicio"
                  name="filtroFechaInicio"
                  value={fechaInicio}
                  onChange={handleFechaInicioChange}
                  className="w-40"
                />
              </div>
              <div className="flex items-center mx-4 my-2">
                <p htmlFor="filtroFechaFin" className="mr-2 w-28">
                  Fecha fin:
                </p>
                <input
                  type="date"
                  id="filtroFechaFin"
                  name="filtroFechaFin"
                  value={fechaFin}
                  onChange={handleFechaFinChange}
                  className="w-40"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex mb-4 mx-4 md:mx-8">
            <Link
              to="/home/registros-tareas/nueva-tarea"
              className="block mx-4 px-4 py-2 text-sm rounded-lg hover:bg-[#ff6400] bg-zinc-500 text-white"
              role="menuitem"
            >
              Crear Tarea
            </Link>
          </div>

          <div className="mb-4 mx-4 md:mx-8">
            <input
              type="text"
              placeholder="Buscar tarea"
              value={searchTerm}
              onChange={handleSearch}
              className="border border-zinc-300 px-4 py-2 rounded-md"
            />
          </div>
        </div>

        <div className="flex md:hidden px-4 md:px-8">
          <table className="w-full max-w-max mx-auto border-collapse">
            <thead>
              <tr>
                <th className="border-b-2 border-zinc-800 p-2">Registros</th>
              </tr>
            </thead>
            <tbody>
              {filteredRegistros.map((tarea, index) => {
                return (
                  <tr
                    key={tarea.id}
                    className={`${
                      index % 2 === 0 ? "bg-zinc-100" : "bg-white"
                    }`}
                  >
                    <td className="border px-6 flex items-center justify-start border-zinc-300 p-2">
                      <div>
                        <div className="flex items-center mb-2">
                          <p className="w-20 font-semibold">Tarea:</p>
                          <p className="">{tarea.tarea}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="hidden md:flex px-8">
          <table className="w-full max-w-7xl mx-auto border-collapse border-y-2 border-zinc-800">
            <thead>
              <tr className="text-white text-xl font-bold">
                <th className="border-x-2 border-zinc-800 bg-yellow-500 p-2 w-1/4">
                  Pendientes
                  <br />
                  <span className="text-sm">({pendientes.length} tareas)</span>
                </th>
                <th className="border-x-2 border-zinc-800 bg-blue-500 p-2 w-1/4">
                  En Proceso
                  <br />
                  <span className="text-sm">({enProceso.length} tareas)</span>
                </th>
                <th className="border-x-2 border-zinc-800 bg-green-500 p-2 w-1/4">
                  Finalizado
                  <br />
                  <span className="text-sm">({finalizados.length} tareas)</span>
                </th>
                <th className="border-x-2 border-zinc-800 bg-red-500 p-2 w-1/4">
                  Incumplido
                  <br />
                  <span className="text-sm">({incumplidos.length} tareas)</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-x-2 border-zinc-800 bg-yellow-500 p-2 align-top">
                  {pendientes.map((tarea) => (
                    <div
                      key={tarea.id}
                      className="border-2 flex flex-col justify-center items-center text-center rounded-xl border-zinc-800 bg-white p-2 relative"
                    >
                      <p>
                        Fecha Límite:{" "}
                        {tarea.fecha_limite
                          ? moment.utc(tarea.fecha_limite).format("DD/MM/YYYY")
                          : ""}
                      </p>
                      <h2 className="my-2 font-semibold">
                        {tarea.tarea}{" "}
                        <span className="bg-zinc-200 font-bold">
                          {tarea.marca}
                        </span>
                      </h2>
                      <h2 className="text-sm">
                        Trabajador: {tarea.usuario?.name}
                      </h2>
                      <div className="text-xs text-white">
                        <Link
                          to={`/home/registros-tareas/trazabilidad/${tarea.id}`}
                        >
                          <button className="px-2 py-1 bg-green-600 rounded-lg">
                            Trazabilidad
                          </button>
                        </Link>
                        <button
                          onClick={() => handleDelete(tarea.id)}
                          className="px-2 py-1 bg-red-600 rounded-lg"
                        >
                          Eliminar
                        </button>
                        <button
                          onClick={() => toggleDropdown(tarea.id)}
                          className="px-2 py-1 bg-black rounded-lg mt-2"
                        >
                          Nuevo Estado
                        </button>
                        {/* Menú desplegable */}
                        <div
                          className={`${
                            dropdownOpen[tarea.id] ? "block" : "hidden"
                          } absolute z-50 top-full right-0 bg-white text-black border border-zinc-300 mt-2 py-2 rounded-md shadow-lg`}
                        >
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "pendiente")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            Pendiente
                          </button>
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "en proceso")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            En Proceso
                          </button>
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "finalizado")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            Finalizado
                          </button>
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "incumplido")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            Incumplido
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </td>
                <td className="border-x-2 border-zinc-800 bg-blue-500 p-2 align-top">
                  {enProceso.map((tarea) => (
                    <div
                      key={tarea.id}
                      className="border-2 flex flex-col justify-center items-center text-center rounded-xl border-zinc-800 bg-white p-2 relative"
                    >
                      <p>
                        Fecha Límite:{" "}
                        {tarea.fecha_limite
                          ? moment.utc(tarea.fecha_limite).format("DD/MM/YYYY")
                          : ""}
                      </p>
                      <h2 className="my-2 font-semibold">
                        {tarea.tarea}{" "}
                        <span className="bg-zinc-200 font-bold">
                          {tarea.marca}
                        </span>
                      </h2>
                      <h2 className="text-sm">
                        Trabajador: {tarea.usuario?.name}
                      </h2>
                      <div className="text-xs text-white">
                        <Link
                          to={`/home/registros-tareas/trazabilidad/${tarea.id}`}
                        >
                          <button className="px-2 py-1 bg-green-600 rounded-lg">
                            Trazabilidad
                          </button>
                        </Link>
                        <button
                          onClick={() => handleDelete(tarea.id)}
                          className="px-2 py-1 bg-red-600 rounded-lg"
                        >
                          Eliminar
                        </button>
                        {/* Menú desplegable */}
                        <div
                          className={`${
                            dropdownOpen[tarea.id] ? "block" : "hidden"
                          } absolute z-50 top-full right-0 bg-white text-black border border-zinc-300 mt-2 py-2 rounded-md shadow-lg`}
                        >
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "pendiente")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            Pendiente
                          </button>
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "en proceso")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            En Proceso
                          </button>
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "finalizado")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            Finalizado
                          </button>
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "incumplido")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            Incumplido
                          </button>
                        </div>
                        {/* Botón "Nuevo Estado" */}
                        <button
                          onClick={() => toggleDropdown(tarea.id)}
                          className="px-2 py-1 bg-black rounded-lg mt-2"
                        >
                          Nuevo Estado
                        </button>
                      </div>
                    </div>
                  ))}
                </td>
                <td className="border-x-2 border-zinc-800 bg-green-500 p-2 align-top">
                  {finalizados.map((tarea) => (
                    <div
                      key={tarea.id}
                      className="border-2 flex flex-col justify-center items-center text-center rounded-xl border-zinc-800 bg-white p-2 relative"
                    >
                      <p>
                        Fecha Límite:{" "}
                        {tarea.fecha_limite
                          ? moment.utc(tarea.fecha_limite).format("DD/MM/YYYY")
                          : ""}
                      </p>
                      <h2 className="my-2 font-semibold">
                        {tarea.tarea}{" "}
                        <span className="bg-zinc-200 font-bold">
                          {tarea.marca}
                        </span>
                      </h2>
                      <h2 className="text-sm">
                        Trabajador: {tarea.usuario?.name}
                      </h2>
                      <div className="text-xs text-white">
                        <Link
                          to={`/home/registros-tareas/trazabilidad/${tarea.id}`}
                        >
                          <button className="px-2 py-1 bg-green-600 rounded-lg">
                            Trazabilidad
                          </button>
                        </Link>
                        <button
                          onClick={() => handleDelete(tarea.id)}
                          className="px-2 py-1 bg-red-600 rounded-lg"
                        >
                          Eliminar
                        </button>
                        {/* Menú desplegable */}
                        <div
                          className={`${
                            dropdownOpen[tarea.id] ? "block" : "hidden"
                          } absolute z-50 top-full right-0 bg-white text-black border border-zinc-300 mt-2 py-2 rounded-md shadow-lg`}
                        >
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "pendiente")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            Pendiente
                          </button>
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "en proceso")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            En Proceso
                          </button>
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "finalizado")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            Finalizado
                          </button>
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "incumplido")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            Incumplido
                          </button>
                        </div>
                        {/* Botón "Nuevo Estado" */}
                        <button
                          onClick={() => toggleDropdown(tarea.id)}
                          className="px-2 py-1 bg-black rounded-lg mt-2"
                        >
                          Nuevo Estado
                        </button>
                      </div>
                    </div>
                  ))}
                </td>
                <td className="border-x-2 border-zinc-800 bg-red-500 p-2 align-top">
                  {incumplidos.map((tarea) => (
                    <div
                      key={tarea.id}
                      className="border-2 flex flex-col justify-center items-center text-center rounded-xl border-zinc-800 bg-white p-2 relative"
                    >
                      <p>
                        Fecha Límite:{" "}
                        {tarea.fecha_limite
                          ? moment.utc(tarea.fecha_limite).format(" ")
                          : ""}
                      </p>
                      <h2 className="my-2 font-semibold">
                        {tarea.tarea}{" "}
                        <span className="bg-zinc-200 font-bold">
                          {tarea.marca}
                        </span>
                      </h2>
                      <h2 className="text-sm">
                        Trabajador: {tarea.usuario?.name}
                      </h2>
                      <div className="text-xs text-white">
                        <Link
                          to={`/home/registros-tareas/trazabilidad/${tarea.id}`}
                        >
                          <button className="px-2 py-1 bg-green-600 rounded-lg">
                            Trazabilidad
                          </button>
                        </Link>
                        <button
                          onClick={() => handleDelete(tarea.id)}
                          className="px-2 py-1 bg-red-600 rounded-lg"
                        >
                          Eliminar
                        </button>
                        {/* Menú desplegable */}
                        <div
                          className={`${
                            dropdownOpen[tarea.id] ? "block" : "hidden"
                          } absolute z-50 top-full right-0 bg-white text-black border border-zinc-300 mt-2 py-2 rounded-md shadow-lg`}
                        >
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "pendiente")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            Pendiente
                          </button>
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "en proceso")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            En Proceso
                          </button>
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "finalizado")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            Finalizado
                          </button>
                          <button
                            onClick={() =>
                              handleEstadoChange(tarea.id, "incumplido")
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-zinc-100"
                          >
                            Incumplido
                          </button>
                        </div>
                        {/* Botón "Nuevo Estado" */}
                        <button
                          onClick={() => toggleDropdown(tarea.id)}
                          className="px-2 py-1 bg-black rounded-lg mt-2"
                        >
                          Nuevo Estado
                        </button>
                      </div>
                    </div>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ListaTareas;
