import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import "moment-timezone";
import { BACKEND } from "../config/configEnvs";
import {
  FaExclamationCircle,
  FaPlus,
  FaEye,
  FaClone,
  FaTrashAlt,
  FaEdit,
  FaFilter
} from "react-icons/fa";
import { toast } from "react-toastify";
import ModalDetails from "../forms/Incidencias/ModalDetails";
import ModalFilters from "../blockComponents/modals/ModalFilters";

function Incidencias() {
  const [registros, setRegistros] = useState([]);
  const [filtroNombre, setFiltroNombre] = useState("");
  const [trabajadores, setTrabajadores] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [registrosPerPage, setRegistrosPerPage] = useState(10)
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [selectedRegistro, setSelectedRegistro] = useState(null);

  // Modal de filtros

  const [ openFilter, setOpenFilter ] = useState(false)
  const [ activeCols, setHeadersTableActive ] = useState([]);

  const headers = [
    { id: 1, name: "Detalles", show: true },
    { id: 2, name: "Gravedad", show: true },
    { id: 3, name: "Trabajador", show: true },
    { id: 4, name: "Motivo", show: true },
    { id: 5, name: "Comentario", show: true },
    { id: 6, name: "Descuento", show: true },
    { id: 7, name: "Fecha", show: true },
    { id: 8, name: "Subsanado", show: false },
    { id: 9, name: "Fecha subsanación", show: false },
    { id: 10, name: "Comentario subsanación", show: false },
    { id: 11, name: "Acciones", show: true }
  ];

  // Lógica de paginación

  const registrosFiltrados = registros.filter((registro) => {
    return !(
      (filtroNombre !== "" &&
        registro.trabajador &&
        registro.trabajador.name !== filtroNombre) ||
      (fechaInicio !== "" && moment(registro.fecha).isBefore(fechaInicio)) ||
      (fechaFin !== "" && moment(registro.fecha).isAfter(fechaFin))
    );
  });

  const pageCount = Math.ceil(registrosFiltrados.length / registrosPerPage);
  const offset = pageNumber * registrosPerPage;

  const currentRegistros = registrosFiltrados.slice(
    offset,
    offset + registrosPerPage
  );

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  //Suma total de descuentos

  const descuentoTotal = registrosFiltrados.reduce((total, registro) => {
    return total + (registro.descuento || 0);
  }, 0);

  // Handlers de modal

  const openModalDetails = (registro) => {
    setSelectedRegistro(registro);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedRegistro(null); // Limpia el registro seleccionado
  };

  // Funciones para filtrar por fecha y nombre de trabajador

  const handleNombreFiltroChange = (event) => {
    setFiltroNombre(event.target.value);
    setPageNumber(0);
  };

  const handleFechaInicioChange = (event) => {
    setFechaInicio(event.target.value);
    setPageNumber(0);
  };

  const handleFechaFinChange = (event) => {
    setFechaFin(event.target.value);
    setPageNumber(0);
  };


  // Funciones para duplicar y eliminar registros

  const handleDelete = async (incidencia) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar esta incidencia? Se eliminarán las pruebas"
    );
  
    if (confirmed) {
      try {
        if (incidencia.pruebas && incidencia.pruebas.length) {
          await Promise.all(
            incidencia.pruebas.map(async (prueba) => {
              try {
                await axios.delete(`${BACKEND}/api/incidenciasMedia/${prueba.id}`);
              } catch (deleteError) {
                toast.warning(`Error al eliminar imagen con ID ${prueba.id}:`, deleteError);
              }
            })
          );
        }
  
        const response = await axios.delete(`${BACKEND}/api/incidencias/${incidencia.id}`);
        
        if (response.status === 200) {
          const updatedRegistros = registros.filter(
            (item) => item.id !== incidencia.id
          );
          setRegistros(updatedRegistros);
          fetchRegistros();
          toast.success("Incidencia eliminada exitosamente");
        }
      } catch (error) {
        console.error("Error al eliminar la incidencia:", error);
        toast.error("Error al eliminar la incidencia");
      }
    }
  };

  const handleDuplicate = async (registerId) => {
    try {
      const originalRegister = registros.find((reg) => reg.id === registerId)
      const newRegister = {
        ...originalRegister,
        id: undefined,
        //Duplicamos las relaciones de las imagenes? recomendación, NO, las seteamos en un array vacío
        pruebas: [],
        trabajador: originalRegister.trabajador.id,
        titulo: `${originalRegister.titulo} (copia)`,
        createdAt: undefined,
        updatedAt: undefined,
      };
      const response = await axios.post(`${BACKEND}/api/incidencias`, newRegister);

      if (response.status === 201) {
        toast.success("Registro duplicado exitosamente");
      }
      fetchRegistros();
  
    } catch (error) {
      toast.error("Hubo un error al crear el registro");
      console.log(error);
    }
  };

  // Funciones al montar el componente

  const fetchRegistros = async () => {
    try {
      const response = await axios.get(`${BACKEND}/api/incidencias`, {
        params: {
          limit: 1000,
        },
      });
      const data = response.data.docs;
      setRegistros(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTrabajadores = async () => {
    try {
      const response = await axios.get(`${BACKEND}/api/trabajadores`, {
        params: {
          limit: 1000,
        },
      });
      const data = response.data.docs;
      setTrabajadores(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRegistros();
    fetchTrabajadores();
    setHeadersTableActive(headers);
  }, []);
  

  return (
    <div className="w-full flex flex-col">

      <ModalFilters 
        open={openFilter}
        setOpen={setOpenFilter}
        activeCols={activeCols}
        setActiveCols={setHeadersTableActive}
      />

      <div className="flex flex-col px-2 py-2 overflow-x-auto bg-zinc-800 mb-4">
        <div className="flex flex-col md:flex-row items-center gap-4 justify-between">

          <div className="flex items-center gap-4 p-4">
            <FaExclamationCircle className="text-white text-2xl" />
            <h2 className="text-2xl font-bold text-white">Incidencias</h2>
          </div>

          <div className="flex items-center gap-4 p-4">
            <span className="text-md text-white">Descuento total: {" S/." + descuentoTotal}</span>
          </div>

          <div className="flex flex-col md:flex-row gap-4 px-4">

            <div className="flex items-center mx-2 my-2">
              <label htmlFor="filtroNombre" className="mr-2 text-white font-medium">
                Nombre:
              </label>
              <select
                id="filtroNombre"
                name="filtroNombre"
                value={filtroNombre}
                onChange={handleNombreFiltroChange}
                className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                <option value="">Todos</option>
                {trabajadores.map((trabajador) => (
                  <option key={trabajador.id} value={trabajador.name}>
                    {trabajador.name}
                  </option>
                ))}
              </select>
            </div>


            <div className="flex items-center mx-2 my-2">
              <label htmlFor="filtroFechaInicio" className="mr-2 text-white font-medium">
                Fecha inicio:
              </label>
              <input
                type="date"
                id="filtroFechaInicio"
                name="filtroFechaInicio"
                value={fechaInicio}
                onChange={handleFechaInicioChange}
                className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              />
            </div>

            <div className="flex items-center mx-2 my-2">
              <label htmlFor="filtroFechaFin" className="mr-2 text-white font-medium">
                Fecha fin:
              </label>
              <input
                type="date"
                id="filtroFechaFin"
                name="filtroFechaFin"
                value={fechaFin}
                onChange={handleFechaFinChange}
                className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              />
            </div>

            <div className="flex items-center mx-2 my-2">
              <label htmlFor="limit" className="mr-2 text-white font-medium">
                Límite:
              </label>
              <select
                id="limit"
                name="limit"
                value={registrosPerPage}
                onChange={(e) => setRegistrosPerPage(e.target.value)}
                className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                <option key={10} value={10}>10</option>
                <option key={20} value={20}>20</option>
                <option key={30} value={30}>30</option>
                <option key={40} value={40}>40</option>
                <option key={50} value={50}>50</option>
              </select>
            </div>
          </div>

          <Link
            to="/home/incidencias/nueva-incidencia"
            className="inline-flex items-center px-6 py-2 text-sm font-semibold text-white bg-orange-500 rounded-md shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition-colors duration-300"
          >
            <FaPlus className="mr-2" />
            Crear Incidencia
          </Link>

          <button className="text-orange-600 mr-2 bg-orange-500 bg-opacity-10 hover:bg-opacity-20 rounded-md p-3" type="button" onClick={() => setOpenFilter(true)}>
            <FaFilter />
          </button>

        </div>
      </div>


      <div className="overflow-x-auto">
      
        <table className="w-full border-collapse text-sm">
          <thead className="bg-gray-200 text-black">
            <tr>
              {activeCols.find((e) => e.id === 1)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Detalles</th>
              )}
              {activeCols.find((e) => e.id === 2)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Gravedad</th>
              )}
              {activeCols.find((e) => e.id === 3)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Trabajador</th>
              )}
              {activeCols.find((e) => e.id === 4)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Motivo</th>
              )}
              {activeCols.find((e) => e.id === 5)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Comentario</th>
              )}
              {activeCols.find((e) => e.id === 6)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Descuento</th>
              )}
              {activeCols.find((e) => e.id === 7)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Fecha</th>
              )}
              {activeCols.find((e) => e.id === 8)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Subsanado</th>
              )}
              {activeCols.find((e) => e.id === 9)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Fecha subsanación</th>
              )}
              {activeCols.find((e) => e.id === 10)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Comentario subsanación</th>
              )}
              {activeCols.find((e) => e.id === 11)?.show && (
                <th className="p-4 border-b border-gray-300 truncate font-semibold">Acciones</th>
              )}
            </tr>
          </thead>
          <tbody>
            
          <ModalDetails
            show={showModal}
            handleClose={closeModal}
            incidencia={selectedRegistro}
          />

            {currentRegistros.map((registro, index) => {
              return (
                <tr
                  key={registro.id}
                  className="hover:bg-gray-100 transition-colors duration-100"
                >
                  {activeCols.find((e) => e.id === 1)?.show && (
                    <td scope="row" className="p-4 p-4 border-b border-gray-300 max-w-xs truncate">
                      <div className="flex items-center justify-center">
                          <a
                              onClick={() => openModalDetails(registro)}
                              className="p-2 text-blue-500 hover:bg-blue-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-blue-700 bg-opacity-10 flex items-center"
                              >
                              <FaEye />
                          </a>
                      </div>
                    </td>
                  )}
                  {activeCols.find((e) => e.id === 2)?.show && (
                    <td
                      className={`p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate font-semibold
                        ${registro.gravedad === "Leve" ? "text-yellow-300" : ""}
                        ${registro.gravedad === "Moderada" ? "text-yellow-500" : ""}
                        ${registro.gravedad === "Grave" ? "text-red-500" : ""}
                        ${registro.gravedad === "Muy grave" ? "text-red-800" : ""}`}
                    >
                      {registro.gravedad}
                    </td>
                  )}
                  {activeCols.find((e) => e.id === 3)?.show && (
                    <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate font-semibold">
                      {registro.trabajador?.name}
                    </td>
                  )}
                  {activeCols.find((e) => e.id === 4)?.show && (
                    <td className="p-4 border-b border-gray-300 max-w-xs truncate">
                      {registro.titulo}
                    </td>
                  )}
                  {activeCols.find((e) => e.id === 5)?.show && (
                    <td className="p-4 border-b border-gray-300 max-w-xs truncate">
                      {registro.comentario}
                    </td>
                  )}
                  {activeCols.find((e) => e.id === 6)?.show && (
                    <td className="p-4 border-b border-gray-300 max-w-xs truncate">
                      {registro.descuentoPorDefinir === true ? (
                        "Por definir"
                      ) : (
                        `S/. ${registro.descuento}`
                      )}
                    </td>
                  )}
                  {activeCols.find((e) => e.id === 7)?.show && (
                    <td className="p-4 p-4 border-b border-gray-300 max-w-xs truncate">
                      {registro.fecha
                        ? moment.utc(registro.fecha).format("DD/MM/YYYY")
                        : ""}
                    </td>
                  )}
                  {activeCols.find((e) => e.id === 8)?.show && (
                    <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                      {registro.subsanado}
                    </td>
                  )}
                  {activeCols.find((e) => e.id === 9)?.show && (
                    <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                      {registro.fechaSubsanacion
                        ? moment.utc(registro.fechaSubsanacion).format("DD/MM/YYYY")
                        : "" || "N/A"}
                    </td>
                  )}
                  {activeCols.find((e) => e.id === 10)?.show && (
                    <td className="p-4 pl-8 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                      {registro.comentarioSubsanacion || "N/A"}
                    </td>
                  )}
                  {activeCols.find((e) => e.id === 11)?.show && (
                  <td className="p-4 flex border-b justify-center border-gray-300 truncate">
                    <Link
                      to={`/home/incidencias/editar-incidencia/${registro.id}`}
                      className="mx-2 my-1 flex items-center text-white bg-green-500 hover:bg-green-600 px-4 py-2 rounded-lg shadow-md transition duration-300 ease-in-out"
                    >
                      <FaEdit className="mr-2" />
                      Editar
                    </Link>

                    <button
                      onClick={() => handleDelete(registro)}
                      className="mx-2 my-1 flex items-center text-white bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg shadow-md transition duration-300 ease-in-out"
                    >
                      <FaTrashAlt className="mr-2" />
                      Eliminar
                    </button>

                    <button
                      onClick={() => handleDuplicate(registro.id)}
                      className="mx-2 my-1 flex items-center text-white bg-purple-600 hover:bg-purple-700 px-4 py-2 rounded-lg shadow-md transition duration-300 ease-in-out"
                    >
                      <FaClone className="mr-2" />
                      Duplicar
                    </button>
                  </td>
                  )}

                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mb-4">
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="flex items-center justify-center mt-4"
          pageClassName="cursor-pointer mx-2"
          previousClassName="cursor-pointer mx-2"
          nextClassName="cursor-pointer mx-2"
          activeClassName="bg-[#ff6400] text-white rounded-lg px-3 py-1"
          disabledClassName="text-gray-500 cursor-not-allowed mx-2"
          previousLinkClassName="pagination-link"
          nextLinkClassName="pagination-link"
          pageLinkClassName="pagination-link"
          activeLinkClassName="pagination-link-active"
        />
      </div>
    </div>
  );
}

export default Incidencias;
