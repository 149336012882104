// Import React FilePond

import { FilePond, registerPlugin } from "react-filepond";

// Import the plugin code
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import FilePondPluginCrop from "filepond-plugin-image-crop";

import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import { useEffect, useRef, useState } from "react";

registerPlugin(
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginFilePoster,
  FilePondPluginImagePreview,
  FilePondPluginCrop
);
const FileUploadFilePond = ({
  title = "Subir Imagen",
  required = false,
  label = 'Arrastra & pega tu imagen o <span className="filepond--label-action">Selecciona</span>',
  sublabel,
  files,handleUpdateFile,
  initialUrl
}) => {
  
  console.log(initialUrl)
  const pond = useRef(null);
  useEffect(() => {
    // Initialize FilePond with the initial image
    if (pond.current && initialUrl && typeof(initialUrl)==="string") {
      try {
        pond.current.addFile(initialUrl);
      } catch (error) {
        
      }
    }
  }, []);
  return (
    <div className="App">
      <label
        htmlFor={title}
        className="block mb-2 text-base font-semibold text-primary-700   dark:text-primary-200 "
      >
        {title}
      </label>
      {sublabel && <div>{sublabel}</div>}
      <FilePond
      ref={pond}
        acceptedFileTypes={[
          "image/png",
          "image/jpeg",
          "image/svg",
          "image/jpg",
          "image/avif",
          "image/webp",
          "application/pdf",
          "application/doc",
          "application/xls",
          "application/docx",
        ]}
        id={title}
        required={required}
        files={files}
        maxFiles={1}
        //,
        // server={{url:`${BACKEND}/api/recursosImagenes`,headers:{"Content-Type": "multipart/form-data"}}}
        allowFileTypeValidation={true}
        labelFileTypeNotAllowed={"Tipo de archivo no permitido"}
        labelMaxFileSize={"El tamaño máximo es 5MB"}
        allowMultiple={false}
        // allowFileEncode={true}
        allowFileSizeValidation={true}
        labelMaxFileSizeExceeded={"El archivo es demasiado grande"}
        maxFileSize="5MB"
        onupdatefiles={handleUpdateFile}
        labelIdle={label}
      />
    </div>
  );
};

export default FileUploadFilePond;
