import { toast } from "react-toastify"
import { BACKEND } from "../config/configEnvs"
import { useEffect, useState } from "react"
import axios from "axios"
import { FaCogs, FaTasks, FaBullseye, FaProjectDiagram, FaPlus, FaBoxOpen, FaCalendarAlt, FaCheck, FaUser, FaBriefcase, FaTimes, FaSave, FaRegSave, FaTags, FaFileExcel, FaUpload, FaFile, FaSpinner } from "react-icons/fa"
import Modal from "../blockComponents/ui/Modal";
import TaskRow from "../blockComponents/procesos/TaskRow"
import ObjectiveRow from "../blockComponents/procesos/ObjectiveRow"
import PhaseRow from "../blockComponents/procesos/PhaseRow"
import { writeXLSX } from "xlsx"
import * as XLSX from "xlsx"

const Procesos = () => {

    const [templateSelected, setTemplateSelected] = useState("Dominios")

    const [tasksLoadingStatus, setTasksLoadingStatus] = useState(false)

    const [workers, setWorkers] = useState([])

    const [tasks, setTasks] = useState([])
    const [totalTasks, setTotalTasks] = useState(0)
    
    const [objectives, setObjectives] = useState([])
    const [totalObjectives, setTotalObjectives] = useState(0)

    const [phases, setPhases] = useState([])
    const [totalPhases, setTotalPhases] = useState(0)

    const [newTaskModal, setNewTaskModal] = useState(false)
    const [newObjectiveModal, setNewObjectiveModal] = useState(false)
    const [newPhaseModal, setNewPhaseModal] = useState(false)

    const [taskFormValues, setTaskFormValues] = useState({ // Valores del formulario de nueva tarea
        name: "",
        defaultResponsible: null,
        defaultObjective: "",
        defaultService: ""
    })

    const [objectiveFormValues, setObjectiveFormValues] = useState({ // Valores del formulario de nuevo objetivo
        name: "",
        defaultPhase: "",
        defaultService: ""
    })

    const [phaseFormValues, setPhaseFormValues] = useState({ // Valores del formulario de nueva fase
        name: "",
        defaultDuration: 0,
        defaultService: "",
        nomenclature: ""
    })

    const tabs = [ // Tabs del menú
        { id: 1, name: "Tareas", value: "Tareas", icon: FaTasks , show: true },
        { id: 2, name: "Objetivos", value: "Objetivos", icon: FaBullseye, show: true },
        { id: 3, name: "Fases", value: "Fases", icon: FaProjectDiagram, show: true },
        { id: 4, name: "Excel", value: "Excel", icon: FaFileExcel, show: true },
    ];

    const [activeTab, setActiveTab] = useState(tabs[0]) // Tab activo

    const successUpdate = async () => { // Actualiza todas las tablas cuando se realiza un cambio
        await getTasks()
        await getObjectives()
        await getPhases()
    }

    // Obtener la info cuando se carga el componente

    const getWorkers = async () => { // Obtiene los trabajadores
        try {
            const response = await axios.get(`${BACKEND}/api/trabajadores?limit=0`)
            setWorkers(response.data.docs)
        } catch (error) {
            toast.error("Error obteniendo los trabajadores: " + error)
        }
    }

    const getTasks = async () => { // Obtiene las tareas
        try {
            let url = `${BACKEND}/api/tareas?limit=0&where[defaultService][equals]=${templateSelected}`
            setTasksLoadingStatus(true)
            const response = await axios.get(url)
            setTotalTasks(response.data.totalDocs)
            setTasks(sortTasks(response.data.docs))
        } catch (error) {
            toast.error("Error obteniendo las tareas")
        } finally {
            setTasksLoadingStatus(false)
        }
    }

    const getObjectives = async () => { // Obtiene los objetivos
        try {
            const response = await axios.get(`${BACKEND}/api/objetivos?limit=0&where[defaultService][equals]=${templateSelected}`)
            setTotalObjectives(response.data.totalDocs)
            setObjectives(sortObjectives(response.data.docs))
        } catch (error) {
            toast.error("Error obteniendo los objetivos")
        }
    }

    const getPhases = async () => { // Obtiene las fases
        try {
            const response = await axios.get(`${BACKEND}/api/fases?limit=0`)
            setTotalPhases(response.data.totalDocs)
            setPhases(sortPhases(response.data.docs))
        } catch (error) {
            toast.error("Error obteniendo las fases")
        }
    }

    const uniqueDefaultServices = Array.from(
        new Set(phases.map((phase) => phase.defaultService))
    );

    // Manejador del cambio de estado

    const handleTaskChange = (e) => { // Cambio de tareas
        const { name, value } = e.target;
        const newValue = value;
        setTaskFormValues({
            ...taskFormValues,
            [name]: newValue,
        });
    };

    const handleObjectiveChange = (e) => { // Cambio de objetivos
        const { name, value } = e.target;
        const newValue = value;
        setObjectiveFormValues({
            ...objectiveFormValues,
            [name]: newValue,
        });
    };

    const handlePhaseChange = (e) => {
        const { name, value } = e.target;
        
        // Convertir a número si el campo es 'defaultDuration'
        const newValue = name === "defaultDuration" ? parseInt(value, 10) : value;
      
        setPhaseFormValues((prevValues) => ({
          ...prevValues,
          [name]: newValue
        }));
    };

    // Submit desde el formulario 

    const handleTaskSubmit = async (e) => { // Crea tareas nuevas
        e.preventDefault()
        try {
            const response = await axios.post(`${BACKEND}/api/tareas`, {
                name: taskFormValues.name,
                defaultObjective: taskFormValues.defaultObjective,
                defaultService: taskFormValues.defaultService,
                defaultResponsible: taskFormValues.defaultResponsible,
            })

            toast.success("Tarea creada exitosamente")
            setNewTaskModal(false)
            setTaskFormValues({
                name: "",
                defaultResponsible: null,
                defaultObjective: "",
                defaultService: ""
            })
            successUpdate()
        } catch (error) {
            toast.error("Error al crear la tarea: " + error)
        }
    }

    const handleObjectiveSubmit = async (e) => { // Crea objetivos nuevos
        e.preventDefault()
        try {
            const response = await axios.post(`${BACKEND}/api/objetivos`, {
                name: objectiveFormValues.name,
                defaultPhase: objectiveFormValues.defaultPhase,
                defaultService: objectiveFormValues.defaultService
            })
            toast.success("Objetivo creado exitosamente")
            setNewObjectiveModal(false)
            setObjectiveFormValues({
                name: "",
                defaultPhase: "",
                defaultService: ""
            })
            successUpdate()
        } catch (error) {
            toast.error("Error al crear el objetivo: " + error)
        }
    }

    const handlePhaseSubmit = async (e) => { // Crea fases nuevas
        e.preventDefault()
        try {
            const response = await axios.post(`${BACKEND}/api/fases`, {
                name: phaseFormValues.name,
                defaultDuration: phaseFormValues.defaultDuration,
                defaultService: phaseFormValues.defaultService
            })
            toast.success("Fase creada exitosamente")
            setNewPhaseModal(false)
            setPhaseFormValues({
                name: "",
                defaultDuration: 0,
                defaultService: ""
            })
            successUpdate()
        } catch (error) {
            toast.error("Error al crear la fase: " + error)
        }
    }

    // Filtrado y ordenamiento de los datos

    const extractNumberFromName = (str) => { // Extraer el número del nombre
        const match = str?.match(/^(TGAD|TLP|TSTV|TDOM|HOST|TCFC|TMCR|TMA|TSW|TMET|TWI|TAV|TSWI|TSMM|TSMMA|TUXI|TWC|TSET|MCBOT|TCAF|Objetivo|Fase)\s*(\d+(\.\d+)?)/);
        return match ? parseFloat(match[2], 10) : 0;
    };
    
    
    const extractPrefix = (str) => { // Extraer el nombre de la tarea/objetivo/fase
        const match = str.match(/(?:TGAD|TLP|TSTV|TDOM|HOST|TCFC|TMCR|TMA|TSW|TMET|TWI|TAV|TSWI|TSMM|TSMMA|TUXI|TWC|TSET|MCBOT|TCAF|Objetivo\s*|Fase\s*)/);
        return match ? match[0].trim() : ""; 
    };    
    
    const sortPhases = (phases) => { // Ordenar fases
        return phases.sort((a, b) => {
            if (a.defaultService > b.defaultService) return 1;
            if (a.defaultService < b.defaultService) return -1;

            const numberA = extractNumberFromName(a.name);
            const numberB = extractNumberFromName(b.name);
        
            return numberA - numberB;
        });
    };
    
    const sortObjectives = (objectives) => { // Ordenar objetivos
        return objectives.sort((a, b) => {
            if (a.defaultService > b.defaultService) return 1;
            if (a.defaultService < b.defaultService) return -1;
    
            const numberA = extractNumberFromName(a.name);
            const numberB = extractNumberFromName(b.name);
            
            return numberA - numberB;
        });
    };
    
    const sortTasks = (tasks) => { // Ordenar tareas
        return tasks.sort((a, b) => {
            const prefixA = extractPrefix(a.name);
            const prefixB = extractPrefix(b.name);
            const numberA = extractNumberFromName(a.name);
            const numberB = extractNumberFromName(b.name);
    
            if (prefixA > prefixB) return 1;
            if (prefixA < prefixB) return -1;
    
            return numberA - numberB;
        });
    };

    const phasesFiltered = templateSelected === "Todos" // Modelo de filtrado a mejorar
    ? sortPhases(phases)
    : sortPhases(phases.filter((phase) => phase.defaultService === templateSelected));

    // Carga de datos cuando se monta el componente

    useEffect(() => {
        getWorkers()
        getTasks()
        getObjectives()
        getPhases()
    }, [])

    useEffect(() => {
        getTasks()
        getObjectives()
        getPhases()
    }, [templateSelected])

    const templates = [...new Set(phases.map((item) => item.defaultService))] // Definimos las plantillas en funcion de las fases creadas y su "defaultService"

    // Funciones para subir un excel (?)

    const [file, setFile] = useState(null)
    const [data, setData] = useState([])

    const handleFileUpload = (e) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);
    
        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
        
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
        
            const sheetData = XLSX.utils.sheet_to_json(sheet);
            setData(sheetData);
        };
    
        reader.readAsBinaryString(uploadedFile);
    };

    const handleRemoveFile = () => {
        setFile(null);
        setData([]);  
    };

    const uniqueFases = data.reduce((acc, item) => {
        const existingFase = acc.find(f => f.name === item.Fases);
        if (!existingFase) {
            acc.push({ 
                name: item.Fases, 
                defaultService: item.Plantilla, 
                defaultDuration: item.DuracionFase
            });
        }
        return acc;
    }, []);;

    const uniqueObjectives = data.reduce((acc, item) => {
        const existingObjective = acc.find(f => f.name === item.Objetivos);
        if (!existingObjective) {
            acc.push({ 
                name: item.Objetivos, 
                defaultPhase: item.Fases, 
                defaultService: item.Plantilla
            });
        }
        return acc;
    }, []);;

    const uniqueTasks = data.reduce((acc, item) => {
        const existingTask = acc.find(f => f.name === item.Tareas);
        if (!existingTask) {
            acc.push({ 
                name: item.Tareas, 
                defaultResponsible: item.Responsable,
                defaultObjective: item.Objetivos, 
                defaultService: item.Plantilla,
                isProofNeeded: item.Pruebas,
                isMeeting: item.Reunion,
                isVisit: item.VisitaAV,
                label: item.Etiqueta,
                visibleToClient: item.VisibleCliente,
                score: item.Puntaje,
                defaultScoreAssignedTo: item.ResponsablePuntaje
            });
        }
        return acc;
    }, []);;

    const updateXLSXFile = async () => {
        try {
            await Promise.all(uniqueFases.map(async (phase) => {
                await axios.post(`${BACKEND}/api/fases`, {
                    name: phase.name,
                    defaultService: phase.defaultService,
                    defaultDuration: phase.defaultDuration
                })
            }))

            await Promise.all(uniqueObjectives.map(async (objective) => {
                await axios.post(`${BACKEND}/api/objetivos`, {
                    name: objective.name,
                    defaultPhase: objective.defaultPhase,
                    defaultService: objective.defaultService
                })
            }))

            await Promise.all(uniqueTasks.map(async (task) => {
                await axios.post(`${BACKEND}/api/tareas`, {
                    name: task.name,
                    defaultResponsible: task.defaultResponsible,
                    defaultObjective: task.defaultObjective,
                    defaultService: task.defaultService,
                    isProofNeeded: task.isProofNeeded,
                    isMeeting: task.isMeeting,
                    isVisit: task.isVisit,
                    label: task.label,
                    visibleToClient: task.visibleToClient,
                    score: task.score,
                    defaultScoreAssignedTo: task.defaultScoreAssignedTo
                })
            }))
            successUpdate()
            setFile(null)
            setData([])
            toast.success("Data subida correctamente")
        } catch (error) {
            console.log(error)
            toast.error("Error subiendo los datos")
        }
    }
    
    const deleteAudio = async (id) => {
        try {
            await axios.delete(`${BACKEND}/api/audiosTareas/${id}`);
        } catch (error) {
            console.log(error)
            toast.error("Error eliminando el audio");
        }
    };

    const veryfyTemplateInUse = async () => {
        try {
            const response = await axios.get(`${BACKEND}/api/tareasProyectos?where[task.defaultService][equals]=${templateSelected}`);
            if (response.data.docs.length > 0) {
                toast.warning("Esta plantilla está en uso, no es posible eliminar");
                return true;
            }
            return false;
        } catch (error) {
            toast.error("Error al verificar si la plantilla está en uso");
            return true;
        }
    };

    const handleRemoveTemplate = async () => {
        if (templateSelected === "Todos") {
            return;
        }

        const confirmDelete = window.confirm(`¿Seguro deseas eliminar esta plantilla? ${templateSelected}`);
        if (!confirmDelete) {
            return;
        }

        try {
            const isTemplateInUse = await veryfyTemplateInUse();
            if (isTemplateInUse) {
                return;
            }
    
            const tasksToDelete = tasks.map(async (task) => {
                if (task?.audios?.length > 0) {
                    const audioDeletionPromises = task.audios.map(audio => deleteAudio(audio.id));
                    await Promise.all(audioDeletionPromises);
                }
                return axios.delete(`${BACKEND}/api/tareas/${task.id}`);
            });
    
            const objectivesToDelete = objectives.map(objective => axios.delete(`${BACKEND}/api/objetivos/${objective.id}`));
            const phasesToDelete = phases.map(phase => axios.delete(`${BACKEND}/api/fases/${phase.id}`));
    
            await Promise.all([...tasksToDelete, ...objectivesToDelete, ...phasesToDelete]);
    
            toast.success("Plantilla eliminada exitosamente");
            successUpdate();
            setTemplateSelected("Todos");
        } catch (error) {
            console.error(error);
            toast.error("Error eliminando la plantilla: " + error.message);
        }
    };

    return (
        <div className="flex flex-col">

            <Modal // Modal para crear nuevas tareas
                activeModal={newTaskModal}
                title={"Nueva tarea"}
                onClose={() => setNewTaskModal(false)}
                centered={true}
            >
                <form onSubmit={handleTaskSubmit}>

                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2" htmlFor="name">
                            Nombre de la tarea
                            <span className="text-red-500"> *</span> 
                        </label>
                        <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md ">
                                <FaCheck />
                            </span>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="TWA: Descripción de la tarea"
                                value={taskFormValues.name}
                                onChange={handleTaskChange}
                                className="rounded-tr-lg rounded-br-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                                required
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2" htmlFor="defaultResponsible">
                            Responsable por defecto
                            <span className="text-red-500"> *</span> 
                        </label>
                        <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md">
                                <FaUser key={"1"}/>
                            </span>
                            <select
                                id="defaultResponsible"
                                name="defaultResponsible"
                                value={taskFormValues.defaultResponsible}
                                onChange={handleTaskChange}
                                className="rounded-tr-lg rounded-br-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                                required
                            >   
                                <option value="">Seleccionar trabajador</option>
                                {workers.map((worker) => (
                                    <option key={worker.id} value={worker.id}>{worker.name} - {worker.puesto}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2" htmlFor="defaultService">
                            Plantilla
                            <span className="text-red-500"> *</span> 
                        </label>
                        <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md">
                                <FaBriefcase key={"1"}/>
                            </span>
                            <select
                                id="defaultService"
                                name="defaultService"
                                value={taskFormValues.defaultService}
                                onChange={handleTaskChange}
                                className="rounded-tr-lg rounded-br-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                                required
                            >   
                                <option value="">Seleccionar plantilla</option>
                                {templates.map((template) => (
                                    <option value={template}>{template}</option>
                                ))}

                            </select>
                        </div>
                    </div>

                    <div className="mb-8">
                        <label className="block text-gray-700 mb-2" htmlFor="defaultObjective">
                            Objetivo 
                            <span className="text-red-500"> *</span> 
                        </label>
                        <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md">
                                <FaBullseye key={"1"}/>
                            </span>
                            <select
                                id="defaultObjective"
                                name="defaultObjective"
                                value={taskFormValues.defaultObjective}
                                onChange={handleTaskChange}
                                className="rounded-tr-lg rounded-br-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                                disabled={!taskFormValues.defaultService}
                                required
                            >   
                                <option value="">Seleccionar objetivo</option>
                                {objectives
                                    .filter((objective) => objective.defaultService === taskFormValues.defaultService)
                                    .map((objective) => (
                                        <option 
                                            key={objective.id} 
                                            value={objective.name}
                                        >
                                            {objective.name} - {objective.defaultService}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <div className="flex w-full gap-4">
                        <button
                            type="button"
                            onClick={() => setNewTaskModal(false)}
                            className="w-1/2 inline-flex items-center justify-center px-6 py-2 text-sm font-semibold text-white bg-gray-500 rounded-md shadow-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition-colors duration-300"
                        >
                            <FaTimes className="mr-2"/>
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="w-1/2 inline-flex items-center justify-center px-6 py-2 text-sm font-semibold text-white bg-orange-500 rounded-md shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition-colors duration-300"
                        >
                           <FaSave className="mr-2"/>
                            Guardar
                        </button>
                    </div>

                </form>
            </Modal>

            <Modal // Modal para crear nuevos objetivos
                activeModal={newObjectiveModal}
                title={"Nuevo objetivo"}
                onClose={() => setNewObjectiveModal(false)}
                centered={true}
            >
                <form onSubmit={handleObjectiveSubmit}>

                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2" htmlFor="name">
                            Nombre del objetivo
                            <span className="text-red-500"> *</span> 
                        </label>
                        <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md ">
                                <FaBullseye />
                            </span>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Objetivo X: Descripción del objetivo"
                                value={objectiveFormValues.name}
                                onChange={handleObjectiveChange}
                                className="rounded-tr-lg rounded-br-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                                required
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2" htmlFor="defaultService">
                            Plantilla
                            <span className="text-red-500"> *</span> 
                        </label>
                        <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md">
                                <FaBriefcase />
                            </span>
                            <select
                                id="defaultService"
                                name="defaultService"
                                value={objectiveFormValues.defaultService}
                                onChange={handleObjectiveChange}
                                className="rounded-tr-lg rounded-br-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                                required
                            >   
                                <option value="">Seleccionar plantilla</option>
                                {templates.map((template) => (
                                    <option value={template}>{template}</option>
                                ))}

                            </select>
                        </div>
                    </div>

                    <div className="mb-8">
                        <label className="block text-gray-700 mb-2" htmlFor="defaultPhase">
                            Fase
                            <span className="text-red-500"> *</span> 
                        </label>
                        <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md">
                                <FaProjectDiagram />
                            </span>
                            <select
                                id="defaultPhase"
                                name="defaultPhase"
                                value={objectiveFormValues.defaultPhase}
                                onChange={handleObjectiveChange}
                                className="rounded-tr-lg rounded-br-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                                required
                                disabled={!objectiveFormValues.defaultService}
                            >   
                                <option value="">Seleccionar fase</option>

                                {phases
                                .filter((phase) => phase.defaultService === objectiveFormValues.defaultService)
                                .map((phase) => (
                                    <option
                                        key={phase.id}
                                        value={phase.name}
                                    >
                                        {phase.name} - {phase.defaultService}
                                    </option>
                                ))}

                            </select>
                        </div>
                    </div>

                    <div className="flex w-full gap-4">
                        <button
                            type="button"
                            onClick={() => setNewObjectiveModal(false)}
                            className="w-1/2 inline-flex items-center justify-center px-6 py-2 text-sm font-semibold text-white bg-gray-500 rounded-md shadow-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition-colors duration-300"
                        >
                            <FaTimes className="mr-2"/>
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="w-1/2 inline-flex items-center justify-center px-6 py-2 text-sm font-semibold text-white bg-orange-500 rounded-md shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition-colors duration-300"
                        >
                            <FaSave className="mr-2"/>
                            Guardar
                        </button>
                    </div>

                </form>
            </Modal>

            <Modal // Modal para crear nuevas fases
                activeModal={newPhaseModal}
                title={"Nueva Fase"}
                onClose={() => setNewPhaseModal(false)}
                centered={true}
            >
                <form onSubmit={handlePhaseSubmit}>

                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2" htmlFor="name">
                            Nombre de la fase
                            <span className="text-red-500"> *</span> 
                        </label>
                        <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md ">
                                <FaProjectDiagram />
                            </span>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Fase X: Descripción de la fase"
                                value={phaseFormValues.name}
                                onChange={handlePhaseChange}
                                className="rounded-tr-lg rounded-br-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                                required
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2" htmlFor="defaultDuration">
                            Duración de la fase en días
                            <span className="text-red-500"> *</span> 
                        </label>
                        <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md">
                                <FaCalendarAlt />
                            </span>
                            <input
                                type="number"
                                id="defaultDuration"
                                name="defaultDuration"
                                placeholder="10"
                                value={phaseFormValues.defaultDuration}
                                onChange={handlePhaseChange}
                                className="rounded-tr-lg rounded-br-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                                required
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2" htmlFor="defaultService">
                            Plantilla
                            <span className="text-red-500"> *</span> 
                        </label>
                        <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md">
                                <FaBriefcase />
                            </span>
                            <input
                                type="text"
                                id="defaultService"
                                name="defaultService"
                                placeholder="Google - Meta - Web Ads - SMM"
                                value={phaseFormValues.defaultService}
                                onChange={handlePhaseChange}
                                className="rounded-tr-lg rounded-br-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                                required
                            />
                        </div>
                    </div>

                    {/* <div className="mb-8">
                        <label className="block text-gray-700 mb-2" htmlFor="defaultService">
                            Nomenclatura
                            <span className="text-red-500"> *</span> 
                        </label>
                        <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md">
                                <FaTags />
                            </span>
                            <input
                                type="text"
                                id="nomenclature"
                                name="nomenclature"
                                placeholder="TWA - TG - TSMM - TMA"
                                value={phaseFormValues.nomenclature}
                                onChange={handlePhaseChange}
                                className="rounded-tr-lg rounded-br-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                                required
                            />
                        </div>
                    </div> */}

                    <div className="flex w-full gap-4">
                        <button
                            type="button"
                            onClick={() => setNewPhaseModal(false)}
                            className="w-1/2 inline-flex items-center justify-center px-6 py-2 text-sm font-semibold text-white bg-gray-500 rounded-md shadow-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition-colors duration-300"
                        >
                            <FaTimes className="mr-2"/>
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="w-1/2 inline-flex items-center justify-center px-6 py-2 text-sm font-semibold text-white bg-orange-500 rounded-md shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition-colors duration-300"
                        >
                            <FaSave className="mr-2"/>
                            Guardar
                        </button>
                    </div>

                </form>
            </Modal>

            {/* --Header-- */}
            <div className="flex flex-row justify-between px-8 py-2 bg-zinc-800 mb-4">

                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="flex items-center gap-4 p-4">
                        <FaCogs className="text-white text-2xl" />
                        <h2 className="text-2xl font-bold text-white">Procesos</h2>
                    </div>
                </div>

                <div className="flex items-center mx-2 my-2 gap-4">
                    <label className="mr-2 text-white">Tareas: <span className="font-bold">{totalTasks}</span></label>
                    <label className="mr-2 text-white">Objetivos: <span className="font-bold">{totalObjectives}</span></label>
                    <label className="mr-2 text-white">Fases: <span className="font-bold">{phasesFiltered.length}</span></label>
                </div>

                <div className="flex items-center mx-2 my-2 gap-4">

                    <button 
                        className={`flex flex-row items-center justify-center gap-2 px-4 py-2 rounded-md text-xs font-bold mr-4 ${
                            templateSelected === "Todos" 
                                ? "bg-gray-400 text-gray-300 cursor-not-allowed" 
                                : "bg-orange-500 text-white hover:bg-orange-600"
                        }`}
                        onClick={() => handleRemoveTemplate()}
                        disabled={templateSelected === "Todos"}
                    >
                        <FaTimes />
                        Eliminar plantilla
                    </button>

                    <label className="mr-2 text-white font-medium">Plantilla: </label>
                    <select 
                        name="template" 
                        id="template"
                        value={templateSelected}
                        onChange={(e) => setTemplateSelected(e.target.value)}
                        className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                        >
                        {uniqueDefaultServices.map((service, index) => (
                            <option key={index} value={service}>{service}</option>
                        ))}
                    </select>

                </div>

            </div>

            {/* --Tabs-- */}
            <div className="flex flex-row items-center justify-between mx-4">

                <ul className="flex flex-row justify-start text-sm font-medium text-center border-b border-gray-300">
                    {tabs
                        .filter((tab) => tab.show === true)
                        .map((e) => (
                        <li key={e.id}>
                            <button
                            onClick={() => setActiveTab(e)}
                            type="button"
                            className={`${
                                activeTab.id === e.id
                                ? "bg-orange-500 text-white border-orange-500"
                                : "bg-orange-100 text-orange-700 border-transparent"
                            } px-4 py-2 rounded-t-md border-t border-l border-r cursor-pointer flex flex-row items-center gap-2 transition-all duration-300 ease-in-out hover:bg-orange-200 hover:text-orange-800`}
                            >
                                {`${e.name}`}
                                <e.icon className="text-xl" />
                            </button>
                        </li>
                    ))}
                </ul>

                {activeTab.id === 1 && (
                    <button 
                        className="flex flex-row items-center justify-center gap-2 mb-2 mr-4 px-4 py-2 bg-orange-500 rounded-md text-white font-bold text-xs"
                        onClick={() => setNewTaskModal(true)}
                    >
                        <FaCheck />
                        Crear tarea
                    </button>
                )}

                {activeTab.id === 2 && (
                    <button 
                        className="flex flex-row items-center justify-center gap-2 mb-2 mr-4 px-4 py-2 bg-orange-500 rounded-md text-white font-bold text-xs"
                        onClick={() => setNewObjectiveModal(true)}
                    >
                        <FaBullseye />
                        Crear objetivo
                    </button>
                )}

                {activeTab.id === 3 && (
                    <button 
                        className="flex flex-row items-center justify-center gap-2 mb-2 mr-4 px-4 py-2 bg-orange-500 rounded-md text-white font-bold text-xs"
                        onClick={() => setNewPhaseModal(true)}
                    >
                        <FaProjectDiagram />
                        Crear fase
                    </button>
                )}

                {activeTab.id === 4 && (
                    <div>
                        {file ? (
                            <div className="flex flex-row">
                                <button 
                                    className="flex flex-row items-center justify-center gap-2 mb-2 mr-4 px-4 py-2 bg-orange-500 rounded-md text-white font-bold text-xs"
                                    onClick={updateXLSXFile}
                                >
                                    <FaUpload />
                                    Subir archivo
                                </button>
                                <button 
                                    className="flex flex-row items-center justify-center gap-2 mb-2 mr-4 px-4 py-2 bg-gray-500 rounded-md text-white font-bold text-xs"
                                    onClick={handleRemoveFile}
                                >
                                    <FaTimes />
                                    Eliminar archivo
                                </button>
                            </div>
                        ) : (
                            <div className="flex items-center">
                                <input 
                                    type="file" 
                                    accept=".xlsx,.xls" 
                                    className="hidden" // Oculta el input
                                    id="file-upload" // Asigna un ID al input
                                    onChange={handleFileUpload} 
                                />
                                <label 
                                    htmlFor="file-upload" // Asocia el label con el input
                                    className="flex flex-row items-center hover:cursor-pointer justify-center gap-2 mb-2 mr-4 px-4 py-2 bg-orange-500 rounded-md text-white font-bold text-xs"
                                >
                                    <FaFile />
                                    Selecciona un archivo
                                </label>
                            </div>
                        )}
                    </div>
                )}

            </div>

            {/* --Body-- */}
            {activeTab.id === tabs[0].id && (
                tasksLoadingStatus ? (
                    <div className="w-full h-full flex items-center py-32 justify-center h-[80vh]">
                        <div className="text-gray-500 text-lg flex items-center">
                            <span className="mr-2">Cargando</span>
                            <FaSpinner className="animate-spin text-gray-500" />
                        </div>
                    </div>
                ) : (
                    <div className="mx-4 border rounded-b-md overflow-x-auto rounded-tl-none rounded-t-md">
                        <table className="w-full border-collapse text-sm">
                            <thead className="bg-gray-200 text-black">
                                <tr>
                                    <th className="p-4 border-b border-gray-300 truncate font-semibold">Acciones</th>
                                    <th className="p-4 border-b border-gray-300 truncate font-semibold">Tarea</th>
                                    <th className="p-4 border-b border-gray-300 truncate font-semibold">Etiqueta</th>
                                    <th className="p-4 border-b border-gray-300 truncate font-semibold">Responsable por defecto</th>
                                    <th className="p-4 border-b border-gray-300 truncate font-semibold">Plantilla</th>
                                    <th className="p-4 border-b border-gray-300 truncate font-semibold">Objetivo por defecto</th>
                                    <th className="p-4 border-b border-gray-300 truncate font-semibold">Puntaje</th>
                                    <th className="p-4 border-b border-gray-300 font-semibold">Puntaje asignado</th>
                                    <th className="p-4 border-b border-gray-300 font-semibold">Links</th>
                                    <th className="p-4 border-b border-gray-300 font-semibold">Audios</th>
                                    <th className="p-4 border-b border-gray-300 font-semibold">Pruebas</th>
                                    <th className="p-4 border-b border-gray-300 font-semibold">Reunión</th>
                                    <th className="p-4 border-b border-gray-300 font-semibold">Visita AV</th>
                                    <th className="p-4 border-b border-gray-300 font-semibold">Visible Cliente</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((task) => (
                                    <TaskRow
                                        key={task.id}
                                        task={task}
                                        workers={workers}
                                        templates={templates}
                                        objectives={objectives}
                                        successUpdate={successUpdate}
                                    /> 
                                ))}
                            </tbody>
                        </table>
                    </div>
                )
            )}

            {activeTab.id === tabs[1].id && (
                <div className="mx-4 border rounded-b-md overflow-x-auto rounded-tl-none rounded-t-md">
                    <table className="w-full border-collapse text-sm">
                        <thead className="bg-gray-200 text-black">
                            <tr>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Acciones</th>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Objetivo</th>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Plantilla</th>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Fase por defecto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {objectives.map((objective) => (
                                <ObjectiveRow
                                    key={objective.id}
                                    objective={objective}
                                    templates={templates}
                                    phases={phases}
                                    successUpdate={successUpdate}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {activeTab.id === tabs[2].id && (
                <div className="mx-4 border rounded-b-md overflow-x-auto rounded-tl-none rounded-t-md">
                    <table className="w-full border-collapse text-sm">
                        <thead className="bg-gray-200 text-black">
                            <tr>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Acciones</th>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Fases</th>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Duracion</th>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Plantilla</th>
                            </tr>
                        </thead>
                        <tbody>
                            {phasesFiltered.map((phase) => (
                                <PhaseRow
                                    key={phase.id}
                                    phase={phase}
                                    templates={templates}
                                    successUpdate={successUpdate}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {activeTab.id === tabs[3].id && (
                <div className="mx-4 border rounded-b-md overflow-x-auto rounded-tl-none rounded-t-md">
                    <table className="w-full border-collapse text-sm">
                        <thead className="bg-gray-200 text-black">
                            <tr>
                            <th className="p-4 border-b border-gray-300 truncate font-semibold">Tarea</th>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Etiqueta</th>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Responsable por defecto</th>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Plantilla</th>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Objetivo</th>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Fase</th>
                                <th className="p-4 border-b border-gray-300 font-semibold">Duración fase</th>
                                <th className="p-4 border-b border-gray-300 truncate font-semibold">Puntaje</th>
                                <th className="p-4 border-b border-gray-300 font-semibold">Responsable puntaje</th>
                                <th className="p-4 border-b border-gray-300 font-semibold">Pruebas</th>
                                <th className="p-4 border-b border-gray-300 font-semibold">Reunión</th>
                                <th className="p-4 border-b border-gray-300 font-semibold">Visita AV</th>
                                <th className="p-4 border-b border-gray-300 font-semibold">Visible Cliente</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 ? (
                                data.map((item, index) => (
                                    <tr key={index}>

                                        <td className="border border-gray-300 px-4 py-2">{item.Tareas}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{item.Etiqueta}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{item.Responsable}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{item.Plantilla}</td>
                                        <td className="border border-gray-300 px-4 py-2">{item.Objetivos}</td>
                                        <td className="border border-gray-300 px-4 py-2">{item.Fases}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{item.DuracionFase}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{item.Puntaje}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{item.ResponsablePuntaje}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{item.Pruebas ? "Si" : "No"}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{item.Reunion ? "Si" : "No"}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{item.VisitaAV ? "Si" : "No"}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{item.VisibleCliente ? "Si" : "No"}</td>
                                    </tr>
                                ))
                            ) : (
                            <tr>
                                <td className="border border-gray-300 px-4 py-2 text-gray-500 h-[400px] text-center" colSpan="13">
                                    No hay data cargada
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}


        </div>
      );
      
}

export default Procesos