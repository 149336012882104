import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";
import { FaCloudUploadAlt, FaEdit, FaExternalLinkAlt, FaPlus, FaPlusCircle, FaTrash, FaTrashAlt, FaUserCircle, FaUsers } from "react-icons/fa";
import { toast } from "react-toastify";

const NuevoTrabajador = () => {
  const [selectedTab, setSelectedTab] = useState(1)
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  // Datos para cargar al montar el componente
  const [areas, setAreas] = useState([]);
  const [subareas, setSubareas] = useState([]);
  const [puestos, setPuestos] = useState([]);

  // Primer tab - Datos del trabajador
  const [dni, setDni] = useState("")
  const [dniValid, setDniValid] = useState(false)
  const [birthday, setBirthday] = useState("");
  const [verificacionHabilitada, setVerificacionHabilitada] = useState(true)
  const [foto, setFoto] = useState(null)
  // Se genera una URL temporal para mostrar la imagen en el front sin que se suba
  const [temporalUrl, setTemporalUrl] = useState("")

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [area, setArea] = useState("");
  const [subarea, setSubarea] = useState("");
  const [puesto, setPuesto] = useState("");
  
  const [sexo, setSexo] = useState("");
  const [pais, setPais] = useState("Perú")
  const [ciudad, setCiudad] = useState("Lima")
  const [direccion, setDireccion] = useState("");

  const [banco, setBanco] = useState("");
  const [sueldo, setSueldo] = useState(0)
  const [cuentaBancaria, setCuentaBancaria] = useState("");
  const [cuentaInterbancaria, setCuentaInterbancaria] = useState("");

  // Segundo tab - Horarios
  const [partTime, setPartTime] = useState(false)
  const [horarios, setHorarios] = useState([
    { dia: "lunes", horaEntrada: "8:00", horaSalida: "17:00" },
    { dia: "martes", horaEntrada: "8:00", horaSalida: "17:00" },
    { dia: "miercoles", horaEntrada: "8:00", horaSalida: "17:00" },
    { dia: "jueves", horaEntrada: "8:00", horaSalida: "17:00" },
    { dia: "viernes", horaEntrada: "8:00", horaSalida: "17:00" },
    { dia: "sabado", horaEntrada: "8:00", horaSalida: "12:00" },
  ]);
  const horas = [
    "8:00",
    "8:30",
    "9:00",
    "9:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
  ];

  // Tercer tab - Datos Personales
  const [telefonoEmergencia, setTelefonoEmergencia] = useState("");
  const [correoPersonal, setCorreoPersonal] = useState("");

  const [hijos, setHijos] = useState(0);
  const [mascotas, setMascotas] = useState(0);

  const [gradoEstudios, setGradoEstudios] = useState("");
  const [carreraEspecialidad, setCarreraEspecialidad] = useState("");

  const [vinculoLaboral, setVinculoLaboral] = useState("")
  const [discapacidad, setDiscapacidad] = useState("");

  const [hobbies, setHobbies] = useState("");
  const [linkFacebook, setLinkFacebook] = useState("");

  const [linkInstagram, setLinkInstagram] = useState("");
  const [linkLinkedin, setLinkLinkedin] = useState("");

  const [antecedentes, setAntecedentes] = useState("No");
  const [antecedentesMedia, setAntecedentesMedia] = useState([])


  // =============== Sección de horario ======================== //

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const calculateDailyHours = (horaEntrada, horaSalida) => {

    if (!horaEntrada || !horaSalida) return 0;

    const entrada = timeToMinutes(horaEntrada);
    const salida = timeToMinutes(horaSalida);
  
    const pausaInicio = timeToMinutes("13:00");
    const pausaFin = timeToMinutes("14:00");
  
    let horasTrabajadas = (salida - entrada) / 60; // Convertir a horas
  
    if (entrada < pausaFin && salida > pausaInicio) {
      const tiempoDePausa = (Math.min(salida, pausaFin) - Math.max(entrada, pausaInicio)) / 60;
      horasTrabajadas -= tiempoDePausa;
    }
  
    return horasTrabajadas;
  };

  const calcularHorasTotales = (horarios) => {
    return horarios.reduce((total, { horaEntrada, horaSalida }) => {
      return total + calculateDailyHours(horaEntrada, horaSalida);
    }, 0);
  };

  const handleHoraEntradaChange = (e, dia) => {
    const horaEntradaStr = e.target.value;
    const updatedHorarios = horarios.map((horario) =>
      horario.dia === dia
        ? { ...horario, horaEntrada: horaEntradaStr }
        : horario
    );
    setHorarios(updatedHorarios);
  };

  const handleHoraSalidaChange = (e, dia) => {
    const horaSalidaStr = e.target.value;
    const updatedHorarios = horarios.map((horario) =>
      horario.dia === dia ? { ...horario, horaSalida: horaSalidaStr } : horario
    );
    setHorarios(updatedHorarios);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  
  const handlerRadioButtonSchedules = (e) => {
    if (e === "partTime") {
      setPartTime(true)
      setHorarios([
        { dia: "lunes", horaEntrada: "8:00", horaSalida: "12:00" },
        { dia: "martes", horaEntrada: "8:00", horaSalida: "12:00" },
        { dia: "miercoles", horaEntrada: "8:00", horaSalida: "12:00" },
        { dia: "jueves", horaEntrada: "8:00", horaSalida: "12:00" },
        { dia: "viernes", horaEntrada: "8:00", horaSalida: "12:00" },
        { dia: "sabado", horaEntrada: "8:00", horaSalida: "12:00" },
      ])
     } else {
      setPartTime(false)
      setHorarios([
        { dia: "lunes", horaEntrada: "8:00", horaSalida: "17:00" },
        { dia: "martes", horaEntrada: "8:00", horaSalida: "17:00" },
        { dia: "miercoles", horaEntrada: "8:00", horaSalida: "17:00" },
        { dia: "jueves", horaEntrada: "8:00", horaSalida: "17:00" },
        { dia: "viernes", horaEntrada: "8:00", horaSalida: "17:00" },
        { dia: "sabado", horaEntrada: "8:00", horaSalida: "12:00" },
      ])
     }
  }
  
  const horasTotales = calcularHorasTotales(horarios);

  // =============== Sección de DNI ======================== //

  const fecthDni = async () => {
    if (dni.length < 8 || dni.length > 8) {
      toast.warning("Ingrese un DNI válido")
    } else {
      try {
        const response = await axios.post(`https://api.migo.pe/api/v1/dni`, {
          dni: dni,
          token: "lKEismVZMQbkTvEIJKcGUUpZsAtiAlukYqZdkaM2FiXXsidomBK5aQ88ir2q",
        });
        setDniValid(true)
        const nombreCompleto = response.data.nombre;
        const partes = nombreCompleto.trim().split(' ');
        const nombre = partes.slice(-2).join(' ');

        const capitalizeInitials = (str) => {
          return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        };

        const nombreFormateado = capitalizeInitials(nombre);
    
        setName(nombreFormateado)
        toast.success("DNI validado exitosamente: " + nombreCompleto)
      } catch (error) {
        toast.error("Error: " + error.response.data.message)
      }
    }
  }

  const handleDniChange = (e) => {
    if (dniValid) {
      setDniValid(false)
      setName("")
    } else {
      setDni(e.target.value)
    }
  }


  // =============== Sección de Areas, Subareas y Puestos ======================== //

  const fetchAreas = async () => {
    try {
      const response = await axios.get(`${BACKEND}/api/areas`);
      const data = response.data.docs;
      setAreas(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAreaChange = (e) => {
    const areaSeleccionada = e.target.value;
    setArea(areaSeleccionada);

    const areaEncontrada = areas.find((area) => area.name === areaSeleccionada);
    if (areaEncontrada) {
      setSubarea("");
      setSubareas(areaEncontrada.subarea);
      setPuesto("");
      setPuestos([]);
    } else {
      setSubarea("");
      setSubareas([]);
      setPuesto("");
      setPuestos([]);
    }
  };

  const handleSubareaChange = (e) => {
    const subareaSeleccionada = e.target.value;
    setSubarea(subareaSeleccionada);

    const subareaEncontrada = subareas.find(
      (subarea) => subarea.name === subareaSeleccionada
    );
    if (subareaEncontrada) {
      setPuesto("");
      setPuestos(subareaEncontrada.puesto);
    } else {
      setPuesto("");
      setPuestos([]);
    }
  };

  const handlePuestoChange = (e) => {
    setPuesto(e.target.value);
  }; 

  // =============== Sección de Imágenes ======================== //

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", foto);

      const response = await axios.post(
        `${BACKEND}/api/trabajadoresfotos`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const mediaId = response.data.doc.id;
      toast.success(`La imagen se subio correctamente, ID: ${mediaId}`)
      return mediaId;
    } catch (error) {
      toast.error("No se pudo subir la imagen")
      return null;
    }
  };

  const handleFotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const temporalUrl = URL.createObjectURL(file);
      setTemporalUrl(temporalUrl);
      setFoto(file);
    }
    
  };

  const handleDeletePicture = () => {
    setFoto(null)
    setTemporalUrl("")
  }

  // Sección de media para antecedentes //

  const handlerUploadMedia = (e) => {  
    if (e.target.files) {
      const maxFileSize = 5000000;
      const maxFiles = 5;
      const filesArray = Array.from(e.target.files)

      const validFiles = filesArray.filter((file) => file.size <= maxFileSize)

      if (filesArray.length > maxFiles) {
        toast.warning(`Solo puedes seleccionar un máximo de ${maxFiles} archivos0`)
      }

      if (validFiles.length < filesArray.length) {
        toast.warning('Algunos archivos no fueron seleccionados porque superan el tamaño máximo de 5MB.');
      }

      setAntecedentesMedia((prevAntecedentes) => {
        const totalFiles = prevAntecedentes.length + validFiles.length;

        if (totalFiles > maxFiles) {
          toast.warning(`Solo puedes tener un máximo de ${maxFiles} archivos`);
          return prevAntecedentes;
        }

        return [...prevAntecedentes, ...validFiles];
      });

    }
  }

  const handleAntecedentesMediaUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file)

      const response = await axios.post(`${BACKEND}/api/antecedentesTrabajadoresMedia`,
        formData,
        {
          heaeders: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      if (response.data && response.data.doc && response.data.doc.id) {
        return response.data.doc.id;
      } else {
        toast.error("No se obtuvo el ID del archivo en la respuesta del servidor");
      }
    } catch (error) {
      toast.error("No se pudo subir la imagen")
      console.log(error)
    }
  }

  const handleDeleteAntecedentes = () => {
    setAntecedentes("No")
    setAntecedentesMedia([])
  }

  const handleDeleteFile = (fileToDelete) => {
    const updatedFiles = antecedentesMedia.filter(file => file !== fileToDelete)
    setAntecedentesMedia(updatedFiles)
  };

  // =============== Seccion submit ======================== //

  const handleSubmit = async (e) => {
    e.preventDefault();

    let idPicture = null

    try {
      if (foto) {
        idPicture = await handleImageUpload()
      }

    let antecedentesMediasIds = []

    if (antecedentesMedia) {
      for (const file of antecedentesMedia) {
        const id = await handleAntecedentesMediaUpload(file)
        if (id) {
          antecedentesMediasIds.push(id)
        }
      }
     }

      const response = await axios.post(`${BACKEND}/api/trabajadores`, {
        name,
        email,
        password,
        area,
        subarea,
        puesto,
        birthday,
        horarios,
        horasTotales,
        partTime,
        sueldo,
        foto: idPicture, 
        verificacionHabilitada,
        dni,
        banco,
        cuentaBancaria,
        cuentaInterbancaria,
        telefonoEmergencia,
        hijos,
        direccion,
        pais,
        ciudad,
        correoPersonal,
        sexo,
        linkFacebook,
        linkInstagram,
        linkLinkedin,
        mascotas,
        vinculoLaboral,
        gradoEstudios,
        carreraEspecialidad,
        hobbies,
        discapacidad,
        antecedentes,
        antecedentesMedia: antecedentesMediasIds
      });

      console.log(response)
      toast.success(`Trabajador ${response.data.doc.name} añadido exitosamente`)
      navigate("/home/trabajadores");
    } catch (error) {
      toast.error("Error: " + error.response.data.errors[0].message)
      console.log(error.response.data.errors[0].message)
    }
  };
  
  useEffect(() => {
    fetchAreas()
  }, [])


  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-col px-8 py-2 bg-zinc-800 mb-4">
        
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="flex items-center gap-4 p-4">
            <FaUsers className="text-white text-2xl" />
            <h2 className="text-2xl font-bold text-white">Nuevo trabajador</h2>
          </div>
        </div>

      </div>

        <form className="pt-3 p-4 px-4" onSubmit={handleSubmit}>
          <div className="mb-1 z-10">
            <span 
              className={`${selectedTab === 1 ? "bg-orange-500 hover:bg-orange-500 text-white" : "bg-orange-100"} px-4 py-2 rounded-t-md border-t border-l border-r border-gray-300 cursor-pointer hover:bg-orange-300 transition duration-100`}
              onClick={() => setSelectedTab(1)}
            >
              Datos del trabajador
            </span>
            <span 
              className={`${selectedTab === 2 ? "bg-orange-500 hover:bg-orange-500 text-white" : "bg-orange-100"} px-4 py-2 rounded-t-md border-t border-l border-r border-gray-300 cursor-pointer hover:bg-orange-300 transition duration-100`}
              onClick={() => setSelectedTab(2)}
            >
              Horarios
            </span>

            <span 
              className={`${selectedTab === 3 ? "bg-orange-500 hover:bg-orange-500 text-white" : "bg-orange-100"} px-4 py-2 rounded-t-md border-t border-l border-r border-gray-300 cursor-pointer hover:bg-orange-300 transition duration-100`}
              onClick={() => setSelectedTab(3)}
            >
              Datos personales
            </span>
          </div>


        {selectedTab === 1 && (
        <div className="border border-gray-400 p-4 rounded-md rounded-tl-none">


          <div className="grid grid-cols-3 gap-6 mb-2 pb-2">

            <div className="flex flex-col h-full justify-center">

              <div>
                <label htmlFor="dni" className="block mb-2">DNI</label>
              </div>
              
              <div className="flex items-center">
                <input
                  type="text"
                  id="dni"
                  value={dni}
                  onChange={(e) => handleDniChange(e)}
                  className="flex-grow border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                />
                <button
                  type="button"
                  className={`ml-2 px-4 py-2 rounded-md transition duration-200 
                    ${dniValid ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-orange-500 text-white hover:bg-orange-600'}
                  `}
                  disabled={dniValid}
                  onClick={() => fecthDni()}
                >
                  {dniValid ? 'Validado' : 'Validar'}
                </button>
              </div>


            </div>

            <div className="flex flex-col h-full justify-center">
              <div>
                <label htmlFor="birthday" className="block mb-2">
                  Fecha de nacimiento <span className="text-red-500">*</span>
                </label>
              </div>

              <div className="flex justify-center">
                  <input
                    type="date"
                    id="birthday"
                    value={birthday}
                    onChange={(e) => setBirthday(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                  />
              </div>
            </div>

            <div className="flex items-center gap-6 h-full pt-6 px-2">

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="verificacionHabilitada"
                  className="h-5 mr-2 w-5 text-[#ff6400] border-gray-300 rounded-md hover:cursor-pointer"
                  checked={verificacionHabilitada}
                  onChange={(e) => setVerificacionHabilitada(e.target.checked)}
                  />
                  
                <label htmlFor="verificacionHabilitada" className="text-gray-700">
                  Habilitar Trabajador
                </label>
              </div>

              <button
                type="button"
                className="flex items-center px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition duration-100 mr-2"
                onClick={handleButtonClick}
              >
                <FaCloudUploadAlt  className="mr-2" />
                Subir Foto
                <input
                  type="file"
                  id="foto"
                  ref={fileInputRef}
                  onChange={handleFotoChange}
                  className="hidden"
                />
              </button>

              <div className="flex items-center mr-2">
                {foto ? (
                  <div className="w-10 h-10 rounded-full overflow-hidden border border-gray-300">
                    <img
                      src={temporalUrl}
                      alt="Foto de perfil"
                      className="w-full h-full object-cover"
                    />
                  </div>
                ) : (
                  <FaUserCircle className="w-10 h-10 text-gray-500" />
                )}
              </div>

              <button
                type="button"
                className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                onClick={() => handleDeletePicture()}
              >
                <FaTrash />
              </button>
            </div>

          </div>


          <div className="flex w-full justify-between gap-6 mt-4 mb-2">

            <div className="flex-1 mb-4">
              <label htmlFor="name" className="block mb-2">
                Nombre <span className=" text-red-500">*</span><span className=" text-gray-500 text-sm ">{" "}Solo se guardarán los 2 primeros nombres</span>
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                required
              />
            </div>

            <div className="flex-1 mb-4">
            <label htmlFor="email" className="block mb-2">
                Correo electrónico <span className=" text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                required
              />
            </div>

            <div className="flex-1 mb-4">
              <label htmlFor="password" className="block mb-2">
                Contraseña <span className=" text-red-500">*</span>
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                required
              />
            </div>

          </div>


          <div className="flex w-full justify-between gap-6 mb-2">

            <div className="flex-1 mb-4">
              <label htmlFor="area" className="block mb-2">
                Area <span className=" text-red-500">*</span>
              </label>
              <select
                type="text"
                id="area"
                value={area}
                onChange={handleAreaChange}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                required
              >
                <option value="">Seleccionar area</option>
                {areas.map((area) => (
                  <option key={area.id} value={area.name}>
                    {area.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex-1 mb-4">
              <label htmlFor="subarea" className="block mb-2">
                Subarea <span className=" text-red-500">*</span>
              </label>
              <select
                type="subarea"
                id="subarea"
                value={subarea}
                onChange={handleSubareaChange}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                required
              >
                <option value="">Seleccionar subarea</option>
                {subareas.map((subarea) => (
                  <option key={subarea.id} value={subarea.name}>
                    {subarea.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex-1 mb-4">
              <label htmlFor="puesto" className="block mb-2">
                Puesto <span className=" text-red-500">*</span>
              </label>
              <select
                type="puesto"
                id="puesto"
                value={puesto}
                onChange={handlePuestoChange}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                required
              >
                <option value="">Seleccionar puesto</option>
                {puestos.map((puesto) => (
                  <option key={puesto.id} value={puesto.name}>
                    {puesto.name}
                  </option>
                ))}
              </select>
            </div>
          
          </div>


          <div className="flex w-full justify-between gap-6 mb-2">

            <div className="flex-1 mb-4">
              <label htmlFor="sexo" className="block mb-2">
                Género <span className=" text-red-500">*</span>
              </label>
              <select
                type="select"
                id="sexo"
                value={sexo}
                onChange={(e) => setSexo(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                required
              >
                <option value="">Seleccionar género</option>
                <option key="Masculino" value="masculino">Masculino</option>
                <option key="Femenino" value="femenino">Femenino</option>
              </select>
            </div>

            <div className="flex-1 mb-4">

              <div className="flex flex-row justify-between">
                <label htmlFor="pais" className="flex start mb-2">
                  País
                </label>
                <label htmlFor="ciudad" className="flex end mb-2">
                  Ciudad
                </label>
              </div>

              <div className="flex">
                <input
                  type="text"
                  id="pais"
                  value={pais}
                  onChange={(e) => setPais(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-l focus:outline-none focus:border-blue-500"
                  />

                <input
                  type="text"
                  id="ciudad"
                  value={ciudad}
                  onChange={(e) => setCiudad(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-r focus:outline-none focus:border-blue-500"
                />
            </div>

          </div>

            <div className="flex-1 mb-4">
              <label htmlFor="direccion" className="block mb-2">
                Direccion <span className=" text-red-500">*</span>
              </label>
              <input
                type="text"
                id="direccion"
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                required
              />
            </div>

          </div>


          <div className="flex w-full justify-between gap-6">

            <div className="flex-1 mb-4">
              <label htmlFor="banco" className="block mb-2">
                Banco
              </label>
              <select
                type="select"
                id="banco"
                value={banco}
                onChange={(e) => setBanco(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              >
                <option value="">Seleccionar banco</option>
                <option key="BBVA" value="BBVA"> BBVA</option>
                <option key="Scotiabank" value="Scotiabank"> Scotiabank</option>
                <option key="BCP" value="BCP"> BCP</option>
                <option key="Interbank" value="Interbank"> Interbank</option>
                <option key="Otro" value="Otro"> Otro</option>
              </select>
            </div>

            <div className="flex-1 mb-4">
              <label htmlFor="sueldo" className="block mb-2">
              Sueldo
              </label>
              <input
                type="number"
                id="sueldo"
                value={sueldo}
                onChange={(e) => setSueldo(Number(e.target.value))}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              >

              </input>
            </div>

            <div className="flex-1 mb-4">

              <div className="flex flex-row justify-between">
                <label htmlFor="cuentaBancaria" className="flex start mb-2">
                  N° cuenta bancaria
                </label>
                <label htmlFor="cuentaInterbancaria" className="flex end mb-2">
                  N° cuenta interbancaria
                </label>
              </div>

              <div className="flex">

                <input
                  type="text"
                  id="cuentaBancaria"
                  value={cuentaBancaria}
                  onChange={(e) => setCuentaBancaria(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-l focus:outline-none focus:border-blue-500"
                  />

                <input
                  type="text"
                  id="cuentaInterbancaria"
                  value={cuentaInterbancaria}
                  onChange={(e) => setCuentaInterbancaria(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-r focus:outline-none focus:border-blue-500"
                  
                />

              </div>

            </div>

          </div>  


        </div>

        )}

        {selectedTab === 2 && (
          <div className="border border-gray-400 p-4 rounded-md rounded-tl-none">
            
            <div className="flex justify-between mb-6">
              <div className="flex flex-row gap-4">
                <label htmlFor="fullTime" className="block">
                  <input
                    type="radio"
                    name="fullTime"
                    id="fullTime"
                    value="fullTime"
                    checked={partTime === false}
                    onChange={(e) => handlerRadioButtonSchedules(e.target.value)}
                    className="mr-2"
                  />
                  FullTime
                </label>

                <label htmlFor="partTime" className="block">
                  <input
                    type="radio"
                    name="partTime"
                    id="partTime"
                    value="partTime"
                    checked={partTime === true}
                    onChange={(e) => handlerRadioButtonSchedules(e.target.value)}
                    className="mr-2"
                  />
                  PartTime
                </label>
              </div>

              <div className="flex flex-row gap-16">
                <span className=" text-gray-500 text-sm ">{"Horas totales a la semana: "}{horasTotales || "Seleccione un horario válido"}</span>

                <span className=" text-gray-500 text-sm ">{" "}Formatee los datos según el horario del trabajador</span>
              </div>
            </div>

            <div className="w-full">
              {[["lunes", "martes"], ["miercoles", "jueves"], ["viernes", "sabado"]].map(
                (pair, index) => (
                  <div key={index} className="grid grid-cols-2 gap-6 mb-4">
                    {pair.map((dia) => {
                      const horario = horarios.find((h) => h.dia === dia);
                      return (
                        <div key={horario.dia}>
                          <label htmlFor={horario.dia} className="block mb-2 font-semibold">
                            {capitalizeFirstLetter(horario.dia)}
                          </label>
                          <div className="grid grid-cols-2 gap-6">
                            <div>
                              <label
                                htmlFor={`horaEntrada-${horario.dia}`}
                                className="block mb-2"
                              >
                                Entrada
                              </label>
                              <select
                                id={`horaEntrada-${horario.dia}`}
                                value={horario.horaEntrada}
                                onChange={(e) => handleHoraEntradaChange(e, horario.dia)}
                                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                              >
                                <option value="">Seleccionar hora</option>
                                {horas.map((hora) => (
                                  <option key={hora} value={hora}>
                                    {hora}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor={`horaSalida-${horario.dia}`}
                                className="block mb-2"
                              >
                                Salida
                              </label>
                              <select
                                id={`horaSalida-${horario.dia}`}
                                value={horario.horaSalida}
                                onChange={(e) => handleHoraSalidaChange(e, horario.dia)}
                                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                              >
                                <option value="">Seleccionar hora</option>
                                {horas.map((hora) => (
                                  <option key={hora} value={hora}>
                                    {hora}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )
              )}
            </div>

          </div>
        
        )}
            
        {selectedTab === 3 && (
        <div className="border flex gap-2 border-gray-300 p-4 rounded-md rounded-tl-none">

          <div className="flex flex-col w-2/3 justify-between gap-6 p-4">

          
            <div className="flex w-full justify-between gap-6">

              <div className="flex-1 mb-4">
                <label htmlFor="telefonoEmergencia" className="block mb-2">
                  Telefono de emergencia
                </label>
                <input
                  type="text"
                  id="telefonoEmergencia"
                  value={telefonoEmergencia}
                  onChange={(e) => setTelefonoEmergencia(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                />
              </div>

              <div className="flex-1 mb-4">
                <label htmlFor="correoPersonal" className="block mb-2">
                  Correo personal
                </label>
                <input
                  type="text"
                  id="correoPersonal"
                  value={correoPersonal}
                  onChange={(e) => setCorreoPersonal(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                >
                </input>
              </div>

            </div>


            <div className="flex w-full justify-between gap-6">

              <div className="flex-1 mb-4">
                  <label htmlFor="hijos" className="block mb-2">
                    Hijos 
                  </label>
                  <select
                    type="number"
                    id="hijos"
                    value={hijos}
                    onChange={(e) => setHijos(Number(e.target.value))}
                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                  >
                    <option key="0" value={0}> 0</option>
                    <option key="1" value={1}> 1</option>
                    <option key="2" value={2}> 2</option>
                    <option key="3" value={3}> 3</option>
                    <option key="4" value={4}> 4</option>
                  </select>
                </div>

              <div className="flex-1 mb-4">
                <label htmlFor="mascotas" className="block mb-2">
                  Mascotas
                </label>
                <select
                  type="number"
                  id="mascotas"
                  value={mascotas}
                  onChange={(e) => setMascotas(Number(e.target.value))}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                >
                  <option key="0" value="0"> 0</option>
                  <option key="1" value="1"> 1</option>
                  <option key="2" value="2"> 2</option>
                  <option key="3" value="3"> 3</option>
                  <option key="4" value="4"> 4</option>
                </select>
              </div>

            </div>


            <div className="flex w-full justify-between gap-6">

              <div className="flex-1 mb-4">
                <label htmlFor="gradoEstudios" className="block mb-2">
                  Grado estudios 
                </label>
                <select
                  type="select"
                  id="gradoEstudios"
                  value={gradoEstudios}
                  onChange={(e) => setGradoEstudios(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"  
                >
                  <option key="Secundario" value="Secundario"> Secundario</option>
                  <option key="Técnica" value="Técnica">  Técnica</option>
                  <option key="Universitaria" value="Universitaria">  Universitaria</option>
                  <option key="Maestría" value="Maestría"> Maestría</option>
                </select>
              </div>

              <div className="flex-1 mb-4">
                <label htmlFor="carreraEspecialidad" className="block mb-2">
                  Carrera o especialidad 
                </label>
                <input
                  type="text"
                  id="carreraEspecialidad"
                  value={carreraEspecialidad}
                  onChange={(e) => setCarreraEspecialidad(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                />
              </div>

            </div>


            <div className="flex w-full justify-between gap-6">

              <div className="flex-1 mb-4">
                <label htmlFor="vinculoLaboral" className="block mb-2">
                  Vinculo Laboral
                </label>
                <select
                  type="select"
                  id="vinculoLaboral"
                  value={vinculoLaboral}
                  onChange={(e) => setVinculoLaboral(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                >
                  <option key="Planilla" value="Planilla"> Planilla</option>
                  <option key="Locacion Servicio" value="Locacion Servicio"> Locacion Servicio</option>
                  <option key="Inactivo" value="Inactivo"> Inactivo</option>
                </select>
              </div>

              <div className="flex-1 mb-4">
                <label htmlFor="discapacidad" className="block mb-2">
                  Discapacidad
                </label>
                <select
                  type="select"
                  id="discapacidad"
                  value={discapacidad}
                  onChange={(e) => setDiscapacidad(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                >
                  <option key="No" value="No"> No</option>
                  <option key="Si" value="Si"> Si</option>
                </select>
              </div>
            </div>

            

            <div className="flex w-full justify-between gap-6">

              <div className="flex-1 mb-4">
                <label htmlFor="hobbies" className="block mb-2">
                  Hobbies 
                </label>
                <input
                  type="text"
                  id="hobbies"
                  value={hobbies}
                  onChange={(e) => setHobbies(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                />
              </div>

              <div className="flex-1 mb-4">
                <label htmlFor="linkFacebook" className="block mb-2">
                  Link Facebook
                </label>
                <input
                  type="text"
                  id="linkFacebook"
                  value={linkFacebook}
                  onChange={(e) => setLinkFacebook(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                >
                </input>
              </div>
            </div>

            <div className="flex w-full justify-between gap-6">

              <div className="flex-1 mb-4">
                <label htmlFor="linkInstagram" className="block mb-2">
                  Link Instagram
                </label>
                <input
                  type="text"
                  id="linkInstagram"
                  value={linkInstagram}
                  onChange={(e) => setLinkInstagram(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                >
                </input>
              </div>

              <div className="flex-1 mb-4">
                <label htmlFor="linkLinkedin" className="block mb-2">
                  Link Linkedin 
                </label>
                <input
                  type="text"
                  id="linkLinkedin"
                  value={linkLinkedin}
                  onChange={(e) => setLinkLinkedin(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                >
                </input>
              </div>

            </div>

          </div>

          <div className="flex flex-col w-1/3 justify-between gap-4 p-6">

            <div className="flex flex-col w-full border border-gray-300 rounded-lg pt-2">

              <div>
                <label htmlFor="antecedentes" className="block mb-2 mx-2">
                  Antecedentes penales 
                </label>
              </div>

              <div className="flex w-full justify-center items-center my-4">
                <div className="flex border border-gray-300 rounded-lg overflow-hidden">
                  <div
                    onClick={() => setAntecedentes("Si")}
                    className={`px-4 py-2 cursor-pointer transition-colors duration-300 ease-in-out ${
                      antecedentes === "Si" ? "bg-orange-500 text-white" : "bg-white text-black"
                    }`}
                  >
                    Con antecedentes
                  </div>
                  <div
                    onClick={() => handleDeleteAntecedentes("No")}
                    className={`px-4 py-2 cursor-pointer transition-colors duration-300 ease-in-out ${
                      antecedentes === "No" ? "bg-orange-500 text-white" : "bg-white text-black"
                    }`}
                  >
                    Sin antecedentes
                  </div>
                </div>
              </div>


              <div className="flex flex-col w-full items-center">
                <label htmlFor="antecedentesMedia" className="flex items-center cursor-pointer text-gray-500 hover:text-blue-400 p-2 rounded-md focus:outline-none">
                  
                <div
                  className={`flex flex-row justify-center items-center gap-4 p-2 rounded-md cursor-pointer ${
                    antecedentes === "Si"
                      ? 'bg-blue-200 text-blue-700 hover:bg-blue-300'
                      : 'bg-gray-300 text-gray-600 cursor-not-allowed'
                  }`}
                  onClick={() => {
                    if (antecedentes !== "Si") {
                      document.getElementById('antecedentesMedia').click();
                    }
                  }}
                >
                  <FaPlusCircle className="text-md" />
                  <span className="text-md">Añadir archivo</span>
                  <input
                    type="file"
                    id="antecedentesMedia"
                    accept="image/*,video/*,audio/*,application/pdf"
                    multiple
                    disabled={antecedentes === "No"}
                    onChange={(e) => handlerUploadMedia(e)}
                    className="hidden"
                  />
                </div>

                  
                  <input
                    type="file"
                    id="antecedentesMedia"
                    accept="image/*,video/*,audio/*,application/pdf"
                    multiple
                    disabled={antecedentes === "No"}
                    onChange={(e) => handlerUploadMedia(e)}
                    className={`hidden`}
                  />
                </label>
              </div>


            </div>

            <div className="flex flex-col items-center h-[500px] gap-4 overflow-y-auto rounded-lg border border-gray-300 p-2">
            {antecedentesMedia.length > 0 ? (
                antecedentesMedia.map((file, index) => {
                  const url = URL.createObjectURL(file.blob ? file.blob : file);
                  return (
                    <div
                      key={index}
                      className="p-4 border hover:bg-gray-100 border-gray-300 rounded-lg shadow-md flex w-full flex-col items-center"
                    >   
                        <div className="flex justify-between mb-4 items-center w-full">
                          <span className="text-xs text-gray-500">{file.name}</span>
                          <div className="flex gap-2">
                            <button
                              type="button"
                              onClick={() => window.open(url, '_blank')}
                              className="p-2 text-blue-500 hover:bg-blue-600 hover:bg-opacity-25 rounded-md bg-blue-700 bg-opacity-10 flex items-center"
                            >
                              <FaExternalLinkAlt />
                            </button>
                            <button
                              type="button"
                              onClick={() => handleDeleteFile(file)}
                              className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 rounded-md bg-red-700 bg-opacity-10 flex items-center"
                            >
                              <FaTrashAlt />
                            </button>
                          </div>
                        </div>


                      {file.type.includes('image') ? (
                        <div className="flex flex-col items-center justify-center h-full">
                          <img
                            src={url}
                            alt={`Antecedente ${index + 1}`}
                            className="w-24 h-24 object-cover rounded-lg mb-2"
                          />
                          
                        </div>
                      ) : file.type === 'application/pdf' ? (

                        <div className="flex flex-col items-center justify-center h-full">
                          <embed
                            src={url}
                            type="application/pdf"
                            className="w-24 h-24 border border-gray-300 rounded-lg mb-2"
                          />
                        </div>
                      ) : (
                        <div className="flex flex-col items-center justify-center h-full">
                          <span className="text-xs text-gray-500">Tipo no soportado</span>
                        </div>
                      )}

                          
                    </div>
                  );
                })
              ) : (
                <div className="flex items-center justify-center w-full h-full p-4">
                  No hay archivos subidos.
                </div>
              )}
            </div>

          </div>

        </div>
        )}

          <div className="flex justify-end gap-8 mt-4">
            <Link
              to="/home/trabajadores"
              type="button"
              className="px-4 py-2 bg-gray-200 hover:bg-gray-300 transition duration-100 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="flex items-center px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition duration-100 mr-2"
              >
              <FaPlus className="mr-2 text-lg" />
              Añadir Trabajador
            </button>
          </div>
        </form>
    </div>
  );
};

export default NuevoTrabajador;

{/* <div className="text-gray-500 flex p-2 rounded">No hay archivos subidos.</div> */}
