import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import moment from "moment";
import "moment-timezone";
import { BACKEND } from "../config/configEnvs";

import { FaBook, FaFileExcel } from "react-icons/fa";
// Imports para exportar en Excel
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function RegistrosAsistencia() {
  const [registros, setRegistros] = useState([]);
  const [filtroMotivo, setFiltroMotivo] = useState("");
  const [filtroNombre, setFiltroNombre] = useState("");
  const [filtroEstado, setFiltroEstado] = useState("");
  const [trabajadores, setTrabajadores] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const registrosPerPage = 50;
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");

  const [graceTime, setGraceTime] = useState(5)

  const diasMapeados = {
    "monday": "lunes",
    "tuesday": "martes",
    "wednesday": "miercoles",
    "thursday": "jueves",
    "friday": "viernes",
    "saturday": "sabado",
    "sunday": "domingo"
  };

  const fetchRegistros = async () => {
    try {
      const response = await axios.get(`${BACKEND}/api/asistencias`, {
        params: {
          limit: 1000,
        },
      });
      const data = response.data.docs;
      setRegistros(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTrabajadores = async () => {
    try {
      const response = await axios.get(`${BACKEND}/api/trabajadores`, {
        params: {
          limit: 1000,
        },
      });
      const data = response.data.docs;
      setTrabajadores(data);
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (dateString) => {
    const formattedDate = moment(dateString)
      .tz("America/Lima") // Ajusta al horario de Perú
      .format("YYYY-MM-DD HH:mm:ss"); // Formato deseado
    return formattedDate;
  };

  useEffect(() => {
    fetchRegistros();
    fetchTrabajadores();
  }, []);

  const handleMotivoFiltroChange = (event) => {
    setFiltroMotivo(event.target.value);
    setPageNumber(0);
  };

  const handleNombreFiltroChange = (event) => {
    setFiltroNombre(event.target.value);
    setPageNumber(0);
  };

  const handleEstadoFiltroChange = (event) => {
    setFiltroEstado(event.target.value);
    setPageNumber(0);
  };

  const handleFechaInicioChange = (event) => {
    setFechaInicio(event.target.value);
    setPageNumber(0);
  };

  const handleFechaFinChange = (event) => {
    setFechaFin(event.target.value);
    setPageNumber(0);
  };

  const registrosFiltrados = registros.filter((registro) => {
    return !(
      (filtroMotivo !== "" && registro.motivo !== filtroMotivo) ||
      (filtroNombre !== "" && registro.usuario && registro.usuario.name !== filtroNombre) ||
      (filtroEstado !== "" && ((filtroEstado === "tardanza" && !isTardanza(registro)) || (filtroEstado === "horasExtra" && !isHorasExtra(registro)))) ||
      (fechaInicio !== "" && moment(registro.fecha).isBefore(fechaInicio)) ||
      (fechaFin !== "" && moment(registro.fecha).isAfter(fechaFin))
    );
  });



  function isTardanza(registro) {

    function obtenerDiaDeLaSemana(fecha) {
      const diaConTilde = moment(fecha).format("dddd").toLowerCase();
      return diasMapeados[diaConTilde] || diaConTilde;
    }

    const horaRegistro = moment(registro.fecha).tz("America/Lima");

    const diaDeLaSemana = obtenerDiaDeLaSemana(registro.fecha);

    const horarioUsuario = registro.usuario?.horarios?.find(horario => horario.dia === diaDeLaSemana)

    if (!horarioUsuario) {
      return false;
    }
    
    // Hora de entrada estándar del trabajador
    const horaEntradaEstandar = moment(registro.fecha)
      .tz("America/Lima")
      .set({
        hour: parseInt(horarioUsuario.horaEntrada.split(":")[0]),
        minute: parseInt(horarioUsuario.horaEntrada.split(":")[1]),
        second: 0
      });

    // Tiempo límite con tolerancia
    const tiempoLimite = moment(horaEntradaEstandar).add(graceTime, "minutes");

    const esTardanza = registro.motivo === "Entrada Trabajo" && horaRegistro.isAfter(tiempoLimite);

    return esTardanza;
  }

  function isHorasExtra(registro) {

    function obtenerDiaDeLaSemana(fecha) {
      const diaConTilde = moment(fecha).format("dddd").toLowerCase();
      return diasMapeados[diaConTilde] || diaConTilde;
    }

    const horaRegistro = moment(registro.fecha).tz("America/Lima")

    const diaDeLaSemana = obtenerDiaDeLaSemana(registro.fecha);

    const horarioUsuario = registro.usuario?.horarios?.find(horario => horario.dia === diaDeLaSemana)

    if (!horarioUsuario) {
      return false
    }

    const horaSalidaEstandar = moment(registro.fecha)
    .tz("America/Lima")
    .set({
      hour: parseInt(horarioUsuario.horaSalida.split(":")[0]),
      minute: parseInt(horarioUsuario.horaSalida.split(":")[1]),
      second: 0
    })

    const tiempoExtra = moment(horaSalidaEstandar).add(10, "minutes");// Tiempo límite con tolerancia

    const isHorasExtra = registro.motivo === "Salida Trabajo" && horaRegistro.isAfter(tiempoExtra)

    return isHorasExtra
  }

  // Pagination logic
  const pageCount = Math.ceil(registrosFiltrados.length / registrosPerPage);
  const offset = pageNumber * registrosPerPage;
  const currentRegistros = registrosFiltrados.slice(
    offset,
    offset + registrosPerPage
  );

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  if (registros.length === 0) {
    return <div>No hay registros disponibles.</div>;
  }

  function getMotivoColor(motivo) {
    switch (motivo) {
      case "Entrada Trabajo":
        return "bg-green-500";
      case "Salida Trabajo":
        return "bg-red-500";
      case "Salida Almuerzo":
        return "bg-purple-500";
      case "Entrada Almuerzo":
        return "bg-blue-500";
      default:
        return "";
    }
  }

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    const dataForExport = registrosFiltrados.map((registro) => {
      const nombre = registro.usuario.name;
      const { motivo, fecha, direccion_ip, sistema_operativo } = registro;
      const formattedFecha = moment(fecha).format("YYYY-MM-DD HH:mm:ss");

      let tardanza = "";
      if (isTardanza(registro)) {
        tardanza = "Tardanza";
      }

      let horasExtra = "";
      if (isHorasExtra(registro)) {
        horasExtra = "Horas Extra";
      }
      return {
        usuario: nombre,
        motivo,
        fecha: formattedFecha,
        tardanza,
        horasExtra,
        direccion_ip,
        sistema_operativo,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(dataForExport);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Registros");

    const columnWidths = [
      { wch: 15 },
      { wch: 18 },
      { wch: 20 },
      { wch: 10 },
      { wch: 12 },
      { wch: 15 },
      { wch: 16 },
    ];

    worksheet["!cols"] = columnWidths;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Registro_de_Asistencia.xlsx`);
  };

  return (
    <div className="w-full flex flex-col">

      <div className="flex flex-col px-2 py-2 overflow-x-auto bg-zinc-800">
        <div className="flex flex-col md:flex-row items-center gap-4 justify-between">

          <div className="flex items-center gap-4 p-4">
            <FaBook className="text-white text-2xl" />
            <h2 className="text-2xl font-bold text-white">Historial de Registros de Asistencia</h2>
          </div>

          <div>
            <button
              onClick={exportToExcel}
              className="flex items-center justify-center max-w-max bg-[#1D6F42] hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
            >
              Exportar a Excel
              <FaFileExcel className="h-5 w-5 ml-2" />
            </button>
          </div>
          
        </div>
      </div>

      <div className="flex flex-row flex-wrap px-8 mb-4 overflow-x-auto justify-center bg-gray-200 items-center">

            <div className="flex items-center mx-4 my-2">
              <p htmlFor="filtroNombre" className="mr-2">
                Nombre:
              </p>
              <select
                id="filtroNombre"
                name="filtroNombre"
                value={filtroNombre}
                onChange={handleNombreFiltroChange}
                className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                <option value="">Todos</option>
                {trabajadores.map((trabajador) => (
                  <option key={trabajador.id} value={trabajador.name}>
                    {trabajador.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex items-center mx-4 my-2">
              <p htmlFor="filtroMotivo" className="mr-2">
                Motivo:
              </p>
              <select
                id="filtroMotivo"
                name="filtroMotivo"
                value={filtroMotivo}
                onChange={handleMotivoFiltroChange}
                className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                <option value="">Todos</option>
                <option value="Entrada Trabajo">Entrada Trabajo</option>
                <option value="Salida Almuerzo">Salida Almuerzo</option>
                <option value="Entrada Almuerzo">Entrada Almuerzo</option>
                <option value="Salida Trabajo">Salida Trabajo</option>
              </select>
            </div>

            <div className="flex items-center mx-4 my-2">
              <p htmlFor="filtroEstado" className="mr-2">
                Estado:
              </p>
              <select
                id="filtroEstado"
                name="filtroEstado"
                value={filtroEstado}
                onChange={handleEstadoFiltroChange}
                className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                <option value="">Todos</option>
                <option value="tardanza">Tardanzas</option>
                <option value="horasExtra">Tiempo Extra</option>
              </select>
            </div>

            <div className="flex items-center mx-4 my-2">
              <p htmlFor="filtroFechaInicio" className="mr-2">
                Fecha inicio:
              </p>
              <input
                type="date"
                id="filtroFechaInicio"
                name="filtroFechaInicio"
                value={fechaInicio}
                onChange={handleFechaInicioChange}
                className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              />
            </div>

            <div className="flex items-center mx-4 my-2">
              <p htmlFor="filtroFechaFin" className="mr-2">
                Fecha fin:
              </p>
              <input
                type="date"
                id="filtroFechaFin"
                name="filtroFechaFin"
                value={fechaFin}
                onChange={handleFechaFinChange}
                className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              />
            </div>
   
      </div>


      {/* Pantallas pequeñas */}
      <div className="flex md:hidden">
        <table className="w-full max-w-max mx-auto border-collapse">
          <thead>
            <tr>
              <th className="border-b-2 border-gray-800 p-2">Registros</th>
            </tr>
          </thead>
          <tbody>
            {currentRegistros.map((registro, index) => {
              return (
                <tr
                  key={registro.id}
                  className="hover:bg-gray-100 transition-colors duration-100"
                >
                  <td className="border px-6 flex items-center justify-center border-gray-300 p-2">
                    <div>
                      <div className="flex items-center mb-2">
                        <p className="w-28 pr-2 font-semibold">Nombre:</p>
                        <p className="pl-2">{registro.usuario?.name}</p>
                      </div>
                      <div className="flex items-center mb-2">
                        <p className="w-28 pr-2 font-semibold">Motivo:</p>
                        <p className="pl-2">
                          <span
                            className={`inline-block h-4 w-4 rounded-full mr-2 ${getMotivoColor(
                              registro.motivo
                            )}`}
                          ></span>
                          {registro.motivo}
                        </p>
                      </div>
                      <div className="flex items-center mb-2">
                        <p className="w-28 pr-2 font-semibold">Fecha:</p>
                        <p className="pl-2">{formatDate(registro.fecha)}</p>
                      </div>

                      <div className="flex justify-center items-center">
                        <p>
                          {isTardanza(registro) && (
                            <span className="inline-block bg-red-500 text-white rounded px-2 ml-2">
                              Tardanza
                            </span>
                          )}
                          {isHorasExtra(registro) && (
                            <span className="inline-block bg-blue-500 text-white rounded px-2 ml-2">
                              Tiempo Extra
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      
      {/* Pantallas grandes */}
      <div className="hidden md:flex">
        <table className="w-full mx-auto border-collapse text-sm">

          <thead className="bg-gray-200 text-black">
            <tr>
              <th className="p-2">Nombre</th>
              <th className="p-2">Motivo</th>
              <th className="p-2">Fecha y hora</th>
              <th className="p-2">Dirección IP</th>
              <th className="p-2">OS</th>
              <th className="p-2">Navegador</th>
            </tr>
          </thead>

          <tbody>
            {currentRegistros.map((registro, index) => {
              return (
                <tr
                  key={registro.id}
                  className="hover:bg-gray-100 transition-colors duration-100"
                >
                  <td className="border border-gray-300 p-2">
                    {registro.usuario?.name}
                  </td>
                  <td className="border border-gray-300 p-2">
                    <span
                      className={`inline-block h-4 w-4 rounded-full mr-2 ${getMotivoColor(
                        registro.motivo
                      )}`}
                    ></span>
                    {registro.motivo}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {formatDate(registro.fecha)}
                    {isTardanza(registro) && (
                      <span className="inline-block bg-red-500 text-white rounded px-2 ml-2">
                        Tardanza
                      </span>
                    )}
                    {isHorasExtra(registro) && (
                      <span className="inline-block bg-blue-500 text-white rounded px-2 ml-2">
                        Tiempo Extra
                      </span>
                    )}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {registro.direccion_ip}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {registro.sistema_operativo}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {registro.navegador}
                  </td>
                </tr>
              );
            })}
          </tbody>

        </table>
      </div>

      <div className="flex justify-center mt-4">
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="flex items-center justify-center mt-4"
          pageClassName="cursor-pointer mx-2"
          previousClassName="cursor-pointer mx-2"
          nextClassName="cursor-pointer mx-2"
          activeClassName="bg-[#ff6400] text-white rounded-lg px-3 py-1"
          disabledClassName="text-gray-500 cursor-not-allowed mx-2"
          previousLinkClassName="pagination-link"
          nextLinkClassName="pagination-link"
          pageLinkClassName="pagination-link"
          activeLinkClassName="pagination-link-active"
        />
      </div>
    </div>
  );
}

export default RegistrosAsistencia;
