import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import "moment-timezone";
import { BACKEND } from "../config/configEnvs";
import {
  FaPlus,
  FaEye,
  FaCommentDollar,
  FaTrash,
  FaExternalLinkAlt,
  FaChevronLeft,
  FaChevronRight,
  FaEdit,
  FaEyeSlash
} from "react-icons/fa";
import { toast } from "react-toastify";
import Modal from "../blockComponents/ui/Modal";

function PagosTrabajadores() {

  const [record, setRecord] = useState([]);

  const [isWorkerSelected, setIsWorkerSelected] = useState(false)
  const [selectedWorkerId, setSelectedWorkerId] = useState(null);

  const [isMonthSelected, setIsMonthSelected] = useState(true)
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  
  const [isYearSelected, setIsYearSelected] = useState(true)
  const [year, setYear] = useState(new Date().getFullYear());

  const [openImageViwer, setOpenImageViewer] = useState(false) // Modal de imagen
  const [selectedImages, setSelectedImages] = useState([])

  const [workers, setWorkers] = useState([]);

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1) // Paginador
  const [totalDocs, setTotalDocs] = useState(0)

  const limit = 10;

  // Funcion para obtener el mes ya que viene del back en formato numérico

    const getMonthName = (monthNumber) => {
        const months = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];
        return months[monthNumber - 1] || "";
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

  // Handlers de modal

  const handleOpenImageModal = (register) => {
    setSelectedImages(register.images);
    setOpenImageViewer(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageViewer(false);
    setSelectedImages([]); // Limpia el registro seleccionado
  };

  // Funciones al montar el componente

  const fetchPayments = async () => {
    try {
        let url = `${BACKEND}/api/pagosTrabajadores?page=${currentPage}&`; 

        if (isWorkerSelected) {
            url += `where[worker][equals]=${selectedWorkerId}&`; 
        }
        if (isMonthSelected) {
            url += `where[month][equals]=${month}&`; 
        }
        if (isYearSelected) {
            url += `where[year][equals]=${year}&`;
        }

        if (url.endsWith('&')) {
            url = url.slice(0, -1);
        }

        const response = await axios.get(url);
        setRecord(response.data.docs);
        setTotalPages(response.data.totalPages)
        setTotalDocs(response.data.totalDocs)
    } catch (error) {
        toast.error("Error obteniendo los registros: " + error);
    }
};


  const fetchWorkers = async () => {
    try {
        const response = await axios.get(`${BACKEND}/api/getUsersIds/getWorkerUsers`)
        setWorkers(response.data)
    } catch (error) {
        toast.error("Error obteniendo los trabajadores: " + error)
    }
  }

  const handleDelete = async (register) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este registro?"
    );
  
    if (confirmed) {
        try {

            if (register.images && register.images.length) {
            await Promise.all(
                register.images.map(async (image) => {
                try {
                    await axios.delete(`${BACKEND}/api/imagenesPagosTrabajadores/${image.id}`);
                } catch (deleteError) {
                    toast.warning(`Error al eliminar imagen con ID ${image.id}:`, deleteError);
                }
                })
            );
            }
    
            const response = await axios.delete(`${BACKEND}/api/pagosTrabajadores/${register.id}`);
            fetchPayments();
            toast.success("Registro eliminade exitosamente");

        } catch (error) {
            console.error("Error al eliminar el registro: ", error);
            toast.error("Error al eliminar el registro");
        }
    }
  };

  useEffect(() => {
    fetchPayments();
  }, [selectedWorkerId, month, year, currentPage]);

  useEffect(() => {
    fetchWorkers()
  }, []);
  

  return (
    <div className="w-full flex flex-col">

        <Modal
            onClose={() => handleCloseImageModal()}
            activeModal={openImageViwer}
            title={`Imagen Pago`}
            centered
        >
            <div className="max-h-[600px] overflow-y-auto">
                {selectedImages?.map((image, index) => (
                    <div className="gap-4 p-4">
                        <div key={image.id} className="relative">
                        {["image/jpeg", "image/jpg", "image/png"].includes(image.mimeType) && (
                        <>
                            <img
                            src={image.url}
                            alt={`Imagen de venta`}
                            className="w-full h-auto object-cover rounded-md shadow-md"
                            />
                            <a
                            href={image.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="absolute top-2 right-2 bg-white bg-opacity-75 text-black p-1 rounded-full"
                            >
                            <FaExternalLinkAlt />
                            </a>
                        </>
                        )}
                        {image.mimeType === "application/pdf" && (
                        <>
                            <embed
                            src={image.url}
                            type="application/pdf"
                            className="w-full h-[40rem] rounded-md shadow-md"
                            />
                        </>
                        )}
                        </div>
                    </div>
                ))}
            </div>
        </Modal>

        <div className="flex flex-col px-8 py-2 overflow-x-auto bg-zinc-800 mb-4">
            <div className="flex flex-col md:flex-row items-center gap-4 justify-between">

            <div className="flex items-center gap-4 p-4">
                <FaCommentDollar className="text-white text-2xl" />
                <h2 className="text-2xl font-bold text-white">Pagos Trabajadores</h2>
            </div>

            <div className="flex flex-col md:flex-row gap-4 px-4">

                <div className="flex items-center mx-2 my-2">
                    <label htmlFor="nameFilter" className="mr-2 text-white font-medium">
                        Nombre:
                    </label>

                    <select
                        id="workerId"
                        name="workerId"
                        value={selectedWorkerId}
                        onChange={(e) => {
                            setSelectedWorkerId(e.target.value === '' ? null : e.target.value);
                            setIsWorkerSelected(e.target.value === '' ? false : true)
                        }}
                        className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                        >
                        <option value="">Todos</option>
                        {workers.map((worker) => (
                            <option key={worker.id} value={worker.id}>
                            {worker.name}
                            </option>
                        ))}
                    </select>
                </div>


                <div className="flex items-center mx-2 my-2">
                    <label htmlFor="filtroFechaInicio" className="mr-2 text-white font-medium">
                        Mes:
                    </label>
                    <select
                        id="filtroFechaInicio"
                        name="filtroFechaInicio"
                        value={month}
                        onChange={(e) => {
                            const selectedMonth = e.target.value;
                            setMonth(e.target.value)
                            setIsMonthSelected(selectedMonth !== "")
                        }}
                        className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                    >
                        <option value="">Todos</option>
                        <option value="1">Enero</option>
                        <option value="2">Febrero</option>
                        <option value="3">Marzo</option>
                        <option value="4">Abril</option>
                        <option value="5">Mayo</option>
                        <option value="6">Junio</option>
                        <option value="7">Julio</option>
                        <option value="8">Agosto</option>
                        <option value="9">Septiembre</option>
                        <option value="10">Octubre</option>
                        <option value="11">Noviembre</option>
                        <option value="12">Diciembre</option>
                    </select>
                </div>


                <div className="flex items-center mx-2 my-2">
                    <label htmlFor="filtroFechaFin" className="mr-2 text-white font-medium">
                        Año:
                    </label>
                    <select
                        type="date"
                        id="filtroFechaFin"
                        name="filtroFechaFin"
                        value={year}
                        onChange={(e) => {
                            const selectedYear = e.target.value;
                            setYear(e.target.value)
                            setIsYearSelected(selectedYear !== "")
                        }}
                        className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                    >
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                    </select>
                </div>
            </div>

            <Link
                to="/home/pagos-trabajadores/nuevo-pago"
                className="inline-flex items-center px-6 py-2 text-sm font-semibold text-white bg-orange-500 rounded-md shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition-colors duration-300"
            >
                <FaPlus className="mr-2" />
                Nuevo Pago
            </Link>

            </div>
        </div>

        <div className="overflow-x-auto">
        
            <table className="w-full border-collapse text-sm">
                <thead className="bg-gray-200 text-black">
                    <tr>
                        <th className="px-4 py-2 border-b border-gray-300 truncate font-bold">Acciones</th>
                        <th className="px-4 py-2 border-b border-gray-300 truncate font-semibold">Imagen</th>
                        <th className="px-4 py-2 border-b border-gray-300 truncate font-semibold">Trabajador</th>
                        <th className="px-4 py-2 border-b border-gray-300 truncate font-semibold">Fecha Pago</th>
                        <th className="px-4 py-2 border-b border-gray-300 truncate font-semibold">Mes</th>
                        <th className="px-4 py-2 border-b border-gray-300 truncate font-semibold">Año</th>
                        <th className="px-4 py-2 border-b border-gray-300 truncate font-semibold">Monto</th>
                        <th className="px-4 py-2 border-b border-gray-300 truncate font-semibold">Pagado</th>
                        <th className="px-4 py-2 border-b border-gray-300 truncate font-semibold">Es adelanto?</th>
                        <th className="px-4 py-2 border-b border-gray-300 truncate font-semibold">Comentario</th>
                    </tr>
                </thead>

                <tbody>
                    {record.length > 0 ? (
                        record.map((register, index) => {
                            return (
                                <tr
                                key={register.id}
                                className="hover:bg-gray-100 transition-colors duration-100"
                                >
                                    <td>
                                        <div className="flex flex-row items-center justify-center gap-4">
                                            <Link
                                                to={`/home/pagos-trabajadores/editar-pago/${register.id}`}
                                                className="p-2 text-green-500 hover:bg-green-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-green-700 bg-opacity-10 flex items-center"
                                            >
                                                <FaEdit />
                                            </Link>
                                            <button
                                                onClick={() => handleDelete(register)}
                                                className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                                                >
                                                <FaTrash />
                                            </button>
                                        </div>

                                    </td>
                                    
                                    <td scope="row" className="p-4 p-4 border-b border-gray-300 max-w-xs truncate">
                                        <div className="flex items-center justify-center">
                                            {register?.images?.length > 0 ? (
                                                <button
                                                    onClick={() => handleOpenImageModal(register)}
                                                    className="p-2 text-blue-500 hover:bg-blue-600 hover:bg-opacity-25 transition-all duration-100 hover:cursor-pointer rounded-md bg-blue-700 bg-opacity-10 flex items-center"
                                                >
                                                    <FaEye />
                                                </button>
                                            ) : (
                                                <button
                                                    className="p-2 text-gray-500 rounded-md bg-gray-700 bg-opacity-10 flex items-center"
                                                >
                                                    <FaEyeSlash />
                                                </button>
                                            )}

                                        </div>
                                    </td>
                                    
                                    <td className="p-4 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                                        <span className="flex items-center justify-center">
                                            {register.worker?.name}
                                        </span>
                                    </td>

                                    <td className="p-4 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                                    <span className="flex items-center justify-center">
                                        {register.datePayment}
                                        </span>
                                    </td>

                                    <td className="p-4 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                                        <span className="flex items-center justify-center">
                                            {getMonthName(register.month)}
                                        </span>
                                    </td>

                                    <td className="p-4 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                                        <span className="flex items-center justify-center">
                                            {register.year}
                                        </span>
                                    </td>

                                    <td className="p-4 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                                        <span className="flex items-center justify-center">
                                            {register.amount ? `S/. ${register.amount}` : "S/. 0"}
                                        </span>

                                    </td>
                                    
                                    <td className="p-4 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                                        <span className="flex items-center justify-center">
                                            {register.payed ? "Si" : "No"}
                                        </span>
                                    </td>

                                    <td className="p-4 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                                        <span className="flex items-center justify-center">
                                            {register.isPayAdvance ? "Si" : "No"}
                                        </span>
                                    </td>

                                    <td className="p-4 justify-center p-4 border-b border-gray-300 max-w-xs truncate">
                                        <span className="flex items-center justify-center">
                                            {register.additionalNotes}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan="9" className="text-center">
                                <div className=" flex bg-gray-100 text-zinc-500 h-[400px] justify-center items-center">
                                    No hay registros
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <nav aria-label="Page navigation example" className="flex justify-center mt-8">
                    <ul className="flex items-center -space-x-px h-10 text-base">
                        <li>
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                <FaChevronLeft />
                            </button>
                        </li>

                        {[...Array(totalPages).keys()].filter(page => Math.abs(page + 1 - currentPage) <= 2 || page === 0 || page === totalPages - 1).map(page => (
                            <li key={page + 1}>
                                <button
                                    onClick={() => handlePageChange(page + 1)}
                                    className={`flex items-center justify-center px-4 h-10 leading-tight ${
                                        currentPage === page + 1
                                        ? "text-white border-blue-300 bg-blue-600 hover:bg-blue-700"
                                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                                    }`}
                                >
                                    {page + 1}
                                </button>
                            </li>
                        ))}

                        <li>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                <FaChevronRight />
                            </button>
                        </li>

                    </ul>
                </nav>
                <div className="flex justify-center mt-4">
                    <span className="text-sm text-gray-700 dark:text-gray-500">
                        Mostrando
                        <span className="font-semibold text-gray-900 dark:text-gray-300 ">
                            {" "}
                            {record.length}
                        </span>{" "}
                        de
                        <span className="font-semibold text-gray-900 dark:text-gray-300 ">
                            {" "}
                            {totalDocs}
                        </span>{" "}
                        registros
                    </span>
                </div>
        </div>
    </div>
  );
}

export default PagosTrabajadores;
