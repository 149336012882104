import React from "react";

const SelectInput = ({ value, setValue, placeholder, title, options,required=true,disabled=false }) => {
  return (
    <div className="my-3">
      <label
        htmlFor={title}
        className="block mb-2 text-sm font-medium text-gray-900 "
      >
        {title}
      </label>
      <select
        id={title}
        required={required}
        disabled={disabled}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
      >
          <option selected>Selecciona una opción</option>

        {options.map((e) => (
          <option key={e.id} value={e.value}>{e.label}</option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
