import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BACKEND } from "../config/configEnvs";

function Links() {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/links`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setLinks(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchLinks();
  }, []);

  const handleDelete = async (linkId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este link?"
    );

    if (confirmed) {
      try {
        await axios.delete(`${BACKEND}/api/links/${linkId}`);
        const updatedLinks = links.filter((link) => link._id !== linkId);
        setLinks(updatedLinks);
      } catch (error) {
        console.error("Error al eliminar el link:", error);
      }
    }
  };

  return (
    <div className="w-full flex flex-col">
      <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
        Links
      </h2>
      <div className="flex justify-between items-center">
        <div className="mb-4 mx-4 md:mx-8">
          <Link
            to="/home/links/nuevo-link"
            className="block px-4 py-2 text-sm  hover:bg-[#ff6400] bg-zinc-600 text-white"
            role="menuitem"
          >
            Añadir Link
          </Link>
        </div>
      </div>

      <div className="hidden md:flex px-8">
        <table className="w-full max-w-4xl mx-auto border-collapse">
          <thead className="bg-zinc-600 text-white">
            <tr>
              <th className="p-2">Plataforma</th>
              <th className="p-2">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {links.map((link, index) => {
              return (
                <tr
                  key={link.id}
                  className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
                >
                  <td className="border border-gray-300 p-2">{link.name}</td>
                  <td className="border font-semibold border-gray-300 p-2">
                    <Link
                      to={`/home/links/editar-link/${link.id}`}
                      className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                    >
                      Editar
                    </Link>
                    <button
                      onClick={() => handleDelete(link.id)}
                      className="mx-2 my-1 text-red-600  rounded-lg"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Links;
