import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BACKEND } from "../config/configEnvs";

function ClientesUser() {
  const [clientesUser, setClientesUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchClientesUser = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/clientesuser`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setClientesUser(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchClientesUser();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDelete = async (clienteUserId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este usuario cliente?"
    );

    if (confirmed) {
      try {
        await axios.delete(`${BACKEND}/api/clientesUser/${clienteUserId}`);
        const updatedClientesUser = clientesUser.filter(
          (clienteUser) => clienteUser.id !== clienteUserId
        );
        setClientesUser(updatedClientesUser);
      } catch (error) {
        console.error("Error al eliminar el usuario cliente:", error);
      }
    }
  };

  const filteredRegistros = clientesUser.filter((clienteUser) => {
    const searchTermLower = searchTerm.toLowerCase();
    return clienteUser.razonSocial?.toLowerCase().includes(searchTermLower);
  });

  return (
    <div className="w-full flex flex-col">
      <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
        Lista de Usuarios Clientes
      </h2>
      <div className="flex justify-between items-center">
        <div className="mb-4 mx-4 md:mx-8">
          <Link
            to="/home/clientes-user/nuevo-cliente-user"
            className="block px-4 py-2 text-sm  hover:bg-[#ff6400] bg-zinc-600 text-white"
            role="menuitem"
          >
            Añadir Usuario Cliente
          </Link>
        </div>

        <div className="mb-4 mx-4 md:mx-8">
          <input
            type="text"
            placeholder="Buscar usuario cliente"
            value={searchTerm}
            onChange={handleSearch}
            className="border border-gray-300 px-4 py-2 rounded-md"
          />
        </div>
      </div>

      <div className="flex md:hidden px-4 md:px-8">
        <table className="w-full max-w-max mx-auto border-collapse">
          <thead>
            <tr>
              <th className="border-b-2 border-gray-800 p-2">Registros</th>
            </tr>
          </thead>
          <tbody>
            {filteredRegistros.map((clienteUser, index) => {
              return (
                <tr
                  key={clienteUser.id}
                  className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
                >
                  <td className="border px-6 flex items-center justify-start border-gray-300 p-2">
                    <div>
                      <div className="flex items-center mb-2">
                        <p className="w-20 font-semibold">Nombre:</p>
                        <p className="">{clienteUser.razonSocial}</p>
                      </div>
                      <div className="flex items-center mb-2">
                        <p className="w-20 font-semibold">Email:</p>
                      </div>
                      <div className="flex items-center mb-2">
                        <p className="text-sm">{clienteUser.email}</p>
                      </div>
                      <div className="flex items-center mb-2">
                        <p className="w-20 font-semibold">RUC:</p>
                      </div>
                      <div className="flex items-center mb-2">
                        <p className="text-sm">{clienteUser.ruc}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="hidden md:flex px-8">
        <table className="w-full max-w-4xl mx-auto border-collapse">
          <thead className="bg-zinc-600 text-white">
            <tr>
              <th className="p-2">Nombre</th>
              <th className="p-2">Email</th>
              <th className="p-2">RUC</th>
              <th className="p-2">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {filteredRegistros.map((clienteUser, index) => {
              return (
                <tr
                  key={clienteUser.id}
                  className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
                >
                  <td className="border border-gray-300 p-2">
                    {clienteUser.razonSocial}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {clienteUser.email}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {clienteUser.ruc}
                  </td>
                  <td className="border font-semibold border-gray-300 p-2">
                    <Link
                      to={`/home/clientes-user/editar-cliente-user/${clienteUser.id}`}
                      className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                    >
                      Editar
                    </Link>
                    <button
                      onClick={() => handleDelete(clienteUser.id)}
                      className="mx-2 my-1 text-red-600  rounded-lg"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ClientesUser;
