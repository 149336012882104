import axios from "axios"
import { toast } from "react-toastify"
import { BACKEND } from "../../config/configEnvs"
import { useState } from "react"
import { FaSave, FaTrash } from "react-icons/fa"

const PhaseRow = ({phase, templates, successUpdate}) => {

    const [phaseName, setPhaseName] = useState(phase?.name)

    const updateNamePhase = async () => {
        try {
            axios.put(`${BACKEND}/api/fases/${phase.id}`, {
                name: phaseName
            })
            successUpdate()
            toast.success("Fase actualizada correctamente")
        } catch (error) {
            toast.error("Error actualizando la fase: " + error)
        }
    }

    const updateDefaultDurationPhase = async (defaultDurationSelected) => {
        try {
            await axios.put(`${BACKEND}/api/fases/${phase.id}`, {
                defaultDuration: defaultDurationSelected
            })
            successUpdate()
            toast.success("Fase actualizada correctaente")
        } catch (error) {
            toast.error("Error actualizando la fase: " + error)
        }
    }

    const updateDefaultServicePhase = async (defaultServiceSelected) => {
        try {
            await axios.put(`${BACKEND}/api/fases/${phase.id}`, {
                defaultService: defaultServiceSelected
            })
            successUpdate()
            toast.success("Fase actualizada correctamente")
        } catch (error) {
            toast.error("Error actualizando la fase: " + error)
        }
    }

    const deletePhase = async () => {
        try {
            const dependantPhases = await axios.get(`${BACKEND}/api/fasesProyectos?where[phase][equals]=${phase.id}`)
            if (dependantPhases.data.docs.length > 0) {
                toast.warning("Hay una o mas fases creadas que dependen de esta plantilla, no es posible eliminar. Si deseas que esta fase no aparezca en los nuevos procesos selecciona el campo " + "Planilla" + " del objetivo en null (Seleccionar plantilla)")
            } else {
                await axios.delete(`${BACKEND}/api/fases/${phase.id}`)
                successUpdate()
                toast.success("Fase eliminada correctamente")
            }
        } catch (error) {
            toast.error("Error al eliminar la fase: " + error)
        } 
    }

    return (
        <tr
            key={phase?.id}
            className="hover:bg-gray-100 transition-colors duration-100"
        >

            <td className="justify-center border border-gray-300 w-[7%] truncate">
                <div className="flex flex-row gap-4 items-center justify-center ">

                    <div className="flex items-center justify-center">
                        <a
                            onClick={() => deletePhase()}
                            className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                            >
                            <FaTrash />
                        </a>
                    </div>

                    <div className="flex items-center justify-center">
                        <a
                            onClick={() => updateNamePhase()}
                            className="p-2 text-green-500 hover:bg-green-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-green-700 bg-opacity-10 flex items-center"
                            >
                            <FaSave />
                        </a>
                    </div>

                </div>
            </td>

            <td className="justify-center border border-gray-300 w-[40%] truncate">
                <input 
                    type="text" 
                    value={phaseName}
                    onChange={(e) => setPhaseName(e.target.value)}
                    className="w-full border-white rounded-full"
                />
            </td>

            <td className="justify-center border border-gray-300 w-[40%] truncate">
                <select
                    value={phase?.defaultDuration} 
                    onChange={(e) => {
                    const newValue = parseInt(e.target.value);
                    updateDefaultDurationPhase(newValue);
                    }}
                    className="w-full border-white rounded-full"
                >
                    {Array.from({ length: 30 }, (_, i) => i + 1).map((day) => (
                    <option key={day} value={day}>
                        {day} días
                    </option>
                    ))}
                </select>
            </td>

            <td className="justify-center border border-gray-300 w-[40%] truncate">
                <select
                    id="defaultService"
                    name="defaultService"
                    className="w-full border-white rounded-full"
                    value={phase?.defaultService}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        updateDefaultServicePhase(newValue)
                    }}
                >
                    <option value="">Seleccionar plantilla</option>

                    {templates.map((template) => (
                        <option value={template}>{template}</option>
                    ))}

                    {/* {templates
                        .filter(template => template !== "") // Filtra templates vacíos
                        .map((template, index) => ( // Agrega un key único
                            <option key={index} value={template}>
                                {template}
                            </option>
                        ))
                    } */}
                    
                </select>
            </td>
        </tr>
    )
}

export default PhaseRow