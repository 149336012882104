import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../config/configEnvs";
import PreguntaFormulario from "../blockComponents/formulariosServicios/PreguntaFormulario";
import EditarPreguntaFormularioDrawer from "../blockComponents/drawers/EditarPreguntaFormularioDrawer";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import NuevaPreguntaFormularioDialog from "../blockComponents/dialogs/NuevaPreguntaFormularioDialog";
const FormularioDetails = () => {
  const { id } = useParams();
  const [preguntasList, setPreguntasList] = useState([]);
  const [serviciosList, setServiciosList] = useState([]);
  const [formularioData, setFormularioData] = useState(null);
  const [openPregunta, setOpenPregunta] = useState({ open: false, data: null });
  const [openAgregarPregunta, setOpenAgregarPregunta] = useState(false);

  const obtenerPreguntas = async () => {
    try {
      const respuestaPreguntas = await axios.get(
        `${BACKEND}/api/formularioServicioPregunta?where[formulario][equals]=${id}&limit=1000&sort=createdAt&where[active][not_equals]=false`
      );
      setPreguntasList(respuestaPreguntas.data?.docs);
    } catch (error) {
      console.log("Error al buscar preguntas", error);
    }
  };

  const obtenerDatosFormulario = async () => {
    try {
      const respuestaPreguntas = await axios.get(
        `${BACKEND}/api/formularioServicio/${id}`
      );
      setFormularioData(respuestaPreguntas.data);
    } catch (error) {
      console.log("Error al buscar formulario", error);
    }
  };

  const getServiciosList = async () => {
    try {
      const responsePlanes = await axios.get(
        `${BACKEND}/api/servicios?limit=1000`
      );
      setServiciosList(responsePlanes.data.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarPreguntaFormulario = async (idPregunta) => {
    if (!window.confirm("¿Seguro de eliminar esta pregunta?")) {
      return;
    }

    try {
      await axios.put(
        `${BACKEND}/api/formularioServicioPregunta/${idPregunta}`,{active:false}
      );
      setPreguntasList(preguntasList.filter((e) => e.id !== idPregunta));
      toast.success("Eliminado");
    } catch (error) {
      console.log("Error al buscar preguntas", error);
      toast.error("Error al eliminar");
    }
  };

  useEffect(() => {
    obtenerPreguntas();
    obtenerDatosFormulario();
    getServiciosList();
  }, []);

  return (
    <div className=" max-w-5xl mx-auto">
      <NuevaPreguntaFormularioDialog
        serviciosList={serviciosList}
        open={openAgregarPregunta}
        idFormulario={id}
        refetchPreguntas={() => obtenerPreguntas()}
        onClose={() => setOpenAgregarPregunta(false)}
        title={"Agregar Pregunta"}
      />

      <div className="fixed end-6 bottom-6 group">
        <button
          onClick={() => setOpenAgregarPregunta(true)}
          type="button"
          className="flex items-center justify-center text-white bg-blue-700 rounded-full w-14 h-14 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800"
        >
          <FaPlus className="w-5 h-5 transition-transform group-hover:rotate-45" />
          <span className="sr-only">Open actions menu</span>
        </button>
      </div>

      <EditarPreguntaFormularioDrawer
        title={"Editar Pregunta"}
        open={openPregunta.open}
        data={openPregunta.data}
        refetchPreguntas={() => obtenerPreguntas()}
        onClose={() => setOpenPregunta((prev) => ({ ...prev, open: false }))}
      />
      {formularioData && (
        <div className=" p-2 md:p-6">
          <h1 className=" pb-4 font-semibold  text-slate-700 text-xl  md:text-3xl">
            {formularioData?.servicio.name}
          </h1>
          <div className="flex start">
            <span className=" text-center shadow-md text-green-600 p-2 rounded-md text-sm font-semibold bg-green-50">
              {formularioData.tipo === "entrada" && "Formulario de entrada"}{" "}
              {formularioData.tipo === "satisfaccion" &&
                "Formulario de satisfaccion"}{" "}
            </span>
          </div>
        </div>
      )}

      <div className="py-10">
        <div className=" flex flex-col md:grid md:grid-cols-2 gap-2">
          {preguntasList.map((e) => (
            <PreguntaFormulario
              eliminarPregunta={() => eliminarPreguntaFormulario(e.id)}
              openPregunta={() => setOpenPregunta({ open: true, data: e })}
              key={e.id}
              data={e}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FormularioDetails;
