import { ResponsivePie } from '@nivo/pie';

// Filtra los datos para eliminar los elementos con valor 0
const filterData = (data) => {
  return data.filter((item) => item.value > 0);
};

const PieChartStatistics = ({ data }) => {
  // Filtra los datos antes de pasarlos al gráfico
  const filteredData = filterData(data);

  return (
    <div style={{ height: '500px' }}>
      {filteredData.length > 0 ? (
        <ResponsivePie
          data={filteredData}
          margin={{ top: 80, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          colors={{ scheme: 'nivo' }}
          arcLabel={(e) => `${e.value}`}
          arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 3]] }}
        />
      ) : (
        <div style={{ textAlign: 'center', paddingTop: '150px', color: '#666' }}>
          No hay datos disponibles
        </div>
      )}
    </div>
  );
};

export default PieChartStatistics;

          // legends={[
          //   {
          //     anchor: 'right',
          //     direction: 'column',
          //     justify: false,
          //     translateX: 0,
          //     translateY: 56,
          //     itemsSpacing: 8,
          //     itemWidth: 100,
          //     itemHeight: 18,
          //     itemTextColor: '#999',
          //     itemOpacity: 0.75,
          //     symbolSize: 20,
          //     symbolShape: 'circle',
          //   },
          // ]}
