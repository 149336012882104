import React, { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND } from "../config/configEnvs";
import TablePlanillaCurrentMonth from "../blockComponents/planilla/currentMonth/TablePlanillaCurrentMonth";
import RegistrosPlanilla from "../blockComponents/planilla/registrosPlanilla/RegistrosPlanilla";
import { MdCheck, MdSave } from "react-icons/md";
import TableCostosFijos from "../blockComponents/costos/currentMonth/tableCostosFijos/TableCostosFijos";
import TableCostosVariables from "../blockComponents/costos/currentMonth/tableCostosVariables/TableCostosVariables";
import { toast } from "react-toastify";
import { endOfMonth, startOfMonth } from "date-fns";
import RegistrosCostos from "../blockComponents/costos/registrosCostos/RegistrosCostos";
const Costos = () => {
  const [costosFijosList, setCostosFijosList] = useState([]);
  const [costosVariablesList, setCostosVariablesList] = useState([]);
  const [costosMesList, setCostosMesList] = useState([]);

  const [trabajadoresList, setTrabajadoresList] = useState([]);
  const [isFetchedPlanilla, setIsFetchedPlanilla] = useState(false);

  const [planillaMesList, setPlanillaMesList] = useState([]);

  const [planillaMetadata, setPlanillaMetadata] = useState({
    totalPages: 0,
    page: 0,
    hasPrevPage: false,
    hasNextPage: false,
  });

  const getCostosFijos = async () => {
    try {
      const response = await axios.get(
        `${BACKEND}/api/costosFijos?limit=1000&sort=createdAt`
      );
      setCostosFijosList(response.data.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const getCostosVariables = async () => {
    try {
      const response = await axios.get(
        `${BACKEND}/api/costosVariables?limit=1000&sort=createdAt`
      );
      setCostosVariablesList(response.data.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const getCostosMes = async () => {
    try {
      const response = await axios.get(
        `${BACKEND}/api/costosMes?sort=-fechaInicio&limit=1000`
      );
      setCostosMesList(response.data.docs);
      // setPlanillaMetadata({
      //   totalPages: response.data.totalPages,
      //   page: response.data.page,
      //   hasNextPage: response.data.hasNextPage,
      //   hasPrevPage: response.data.hasPrevPage,
      // });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchedPlanilla(true);
    }
  };

  const createCostosMes = async () => {
    try {
      await axios.post(`${BACKEND}/api/costosMes`, {
        fechaInicio: startOfMonth(new Date()),
        fechaFin: endOfMonth(new Date()),
        costos: costosFijosList
          .map((data) => ({
            servicio: data.nombre,
            precio: data.costoMensual,
          }))
          .concat(
            costosVariablesList.map((data2) => ({
              servicio: data2.nombre,
              precio: data2.costoMensual,
            }))
          ),
        precioCostosFijos: costosFijosList.reduce(
          (acc, value) => value.costoMensual + acc,
          0
        ),
        precioCostosVariables: costosVariablesList.reduce(
          (acc, value) => value.costoMensual + acc,
          0
        ),
        precioPlanilla: planillaMesList.some(
          (obj5) =>
            obj5.fechaInicio < new Date().toISOString() &&
            obj5.fechaFin > new Date().toISOString()
        )
          ? planillaMesList
              .find(
                (obj6) =>
                  obj6.fechaInicio < new Date().toISOString() &&
                  obj6.fechaFin > new Date().toISOString()
              )
              ?.sueldos.reduce((acc2, value2) => value2.total + acc2, 0)
          : 0,
        totalPrecio:
          costosFijosList
            .map((data) => ({
              servicio: data.nombre,
              precio: data.costoMensual,
            }))
            .concat(
              costosVariablesList.map((data2) => ({
                servicio: data2.nombre,
                precio: data2.costoMensual,
              }))
            )
            .reduce((acc, value) => value.precio + acc, 0) +
          (planillaMesList.some(
            (obj5) =>
              obj5.fechaInicio < new Date().toISOString() &&
              obj5.fechaFin > new Date().toISOString()
          )
            ? planillaMesList
                .find(
                  (obj6) =>
                    obj6.fechaInicio < new Date().toISOString() &&
                    obj6.fechaFin > new Date().toISOString()
                )
                ?.sueldos.reduce((acc2, value2) => value2.total + acc2, 0)
            : 0),
      });

      toast.success("Costo mensual creado exitosamente");
      getCostosMes();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchedPlanilla(true);
    }
  };

  const getTrabajadoresList = async () => {
    try {
      const responseTrabajadores = await axios.get(
        `${BACKEND}/api/trabajadores?limit=1000`
      );
      setTrabajadoresList(responseTrabajadores.data.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const getPlanillaMes = async () => {
    try {
      const response = await axios.get(
        `${BACKEND}/api/planillaMes?sort=-fechaInicio`
      );
      setPlanillaMesList(response.data.docs);
      setPlanillaMetadata({
        totalPages: response.data.totalPages,
        page: response.data.page,
        hasNextPage: response.data.hasNextPage,
        hasPrevPage: response.data.hasPrevPage,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchedPlanilla(true);
    }
  };

  useEffect(() => {
    getCostosFijos();
    getCostosVariables();
    getCostosMes();
    getTrabajadoresList();
    getPlanillaMes();
  }, []);

  const tabs = [
    { id: 1, name: "Estimacion mensual", value: "Estimacion mensual" },
    { id: 2, name: "Registros", value: "Registros" },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <div>
      <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
        Costos
      </h2>

      <div>
        <ul className="flex flex-wrap justify-center text-sm font-medium text-center text-gray-500 border-b border-gray-200 ">
          {tabs.map((e) => (
            <li key={e.id} className="me-2">
              <button
                onClick={() => {
                  setActiveTab(e);
                }}
                type="button"
                className={` inline-flex gap-2 items-center justify-center p-4 ${
                  activeTab.id === e.id
                    ? "text-blue-600  bg-gray-200 "
                    : " text-gray-700 hover:bg-gray-50 "
                }   rounded-t-lg active `}
              >
                {`${e.name}`}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {isFetchedPlanilla && activeTab.id === tabs[0].id && (
        <div>
          <TableCostosFijos
            costosFijosList={costosFijosList}
            getCostosFijos={getCostosFijos}
          />

          <TableCostosVariables
            costosVariablesList={costosVariablesList}
            getCostosVariables={getCostosVariables}
          />

          {planillaMesList.some(
            (obj) =>
              obj.fechaInicio < new Date().toISOString() &&
              obj.fechaFin > new Date().toISOString()
          ) ? (
            <div className="max-w-4xl flex mx-auto p-4 text-gray-600 text-xs">
              <div className=" w-auto rounded-md p-2 bg-green-500 bg-opacity-10 text-green-600">
                Costo Planilla:
                <span className=" text-green-600 font-bold text-base">
                  {" "}
                  S/.{" "}
                  {planillaMesList
                    .find(
                      (obj2) =>
                        obj2.fechaInicio < new Date().toISOString() &&
                        obj2.fechaFin > new Date().toISOString()
                    )
                    ?.sueldos.reduce((acc2, value2) => value2.total + acc2, 0)}
                </span>
              </div>
            </div>
          ) : (
            <div className="max-w-4xl flex mx-auto p-4 text-gray-600 text-xs">
              <div className=" w-auto rounded-md p-2 bg-green-500 bg-opacity-10 text-green-600">
                Costo Planilla: Planilla para este mes aún no fue guardado
              </div>
            </div>
          )}

          <div className="max-w-4xl flex mx-auto px-4 text-gray-600 text-xs">
            <div className=" w-auto rounded-md p-2 bg-green-500 bg-opacity-10 text-green-600">
              Costo total:{" "}
              <span className="text-green-600 font-bold text-base">
                S/{" "}
                {costosFijosList
                  .map((data) => ({
                    servicio: data.nombre,
                    precio: data.costoMensual,
                  }))
                  .concat(
                    costosVariablesList.map((data2) => ({
                      servicio: data2.nombre,
                      precio: data2.costoMensual,
                    }))
                  )
                  .reduce((acc, value) => value.precio + acc, 0) +
                  (planillaMesList.some(
                    (obj5) =>
                      obj5.fechaInicio < new Date().toISOString() &&
                      obj5.fechaFin > new Date().toISOString()
                  )
                    ? planillaMesList
                        .find(
                          (obj6) =>
                            obj6.fechaInicio < new Date().toISOString() &&
                            obj6.fechaFin > new Date().toISOString()
                        )
                        ?.sueldos.reduce(
                          (acc2, value2) => value2.total + acc2,
                          0
                        )
                    : 0)}
              </span>
            </div>
          </div>

          {costosMesList.some(
            (obj) =>
              obj.fechaInicio < new Date().toISOString() &&
              obj.fechaFin > new Date().toISOString()
          ) ? (
            <div className=" py-6 max-w-md mx-auto ">
              <div className=" flex justify-center gap-2 items-center bg-green-600 bg-opacity-10 font-semibold p-4 text-green-500 ">
                <div className=" bg-white rounded-full p-2 ">
                  <MdCheck />
                </div>
                <span> Estimación para este mes guardado</span>
              </div>
            </div>
          ) : (
            <div className=" flex justify-center pt-10   text-sm">
              <button
                type="button"
                onClick={createCostosMes}
                className=" flex justify-center font-semibold items-center gap-2 p-3 rounded-md bg-blue-600 hover:bg-blue-700 text-white"
              >
                <MdSave />
                Guardar estimación mensual
              </button>
            </div>
          )}
        </div>
      )}

      {activeTab.id === tabs[1].id && (
        <div>
          <RegistrosCostos
            costosMesList={costosMesList}
          />
        </div>
      )}
    </div>
  );
};

export default Costos;
