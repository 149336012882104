import React, { useState } from "react";
import Modal from "../ui/Modal";
import InputText from "../ui/InputText";
import InputNumber from "../ui/InputNumber";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";
const NuevoCostoFijo = ({ open, onClose, refetch }) => {
  const [formValues, setFormValues] = useState({ nombre: "", costoMensual: 100 });

  const registerNewCostoFijo = async (ev) => {
    ev.preventDefault();
    try {
      await axios.post(`${BACKEND}/api/costosFijos`, formValues);
      onClose();
      refetch();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal activeModal={open} title={"Nuevo costo fijo"} onClose={onClose}>
      <div>
        <form onSubmit={registerNewCostoFijo}>
          <InputText
            required={true}
            value={formValues.nombre}
            title={"Nombre"}
            setValue={(e) => setFormValues((prev) => ({ ...prev, nombre: e }))}
          />

          <InputNumber
            required={true}
            value={formValues.costoMensual}
            title={"Costo mensual"}
            setValue={(e) =>
              setFormValues((prev) => ({ ...prev, costoMensual: Number(e) }))
            }
          />
          <div className=" flex justify-center mt-10">
            <button type="submit" className=" bg-blue-600 hover:bg-blue-700 text-white font-semibold text-sm px-3 py-2 rounded-md">
              Registrar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NuevoCostoFijo;
