import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BACKEND } from "../config/configEnvs";

function Areas() {
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/areas`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setAreas(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAreas();
  }, []);

  const handleDelete = async (areaId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar esta area?"
    );

    if (confirmed) {
      try {
        await axios.delete(`${BACKEND}/api/areas/${areaId}`);
        const updatedAreas = areas.filter((area) => area._id !== areaId);
        setAreas(updatedAreas);
      } catch (error) {
        console.error("Error al eliminar el link:", error);
      }
    }
  };

  return (
    <div className="w-full flex flex-col">
      <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
        Areas
      </h2>
      <div className="flex justify-start items-center px-8 mb-4 text-white text-sm">
        <div className="mr-4">
          <Link
            to="/home/areas/nuevo-area"
            className="block px-4 py-2 hover:bg-[#ff6400] bg-zinc-600"
            role="menuitem"
          >
            Añadir Area
          </Link>
        </div>
        <div>
          <Link
            to="/home/reglas"
            className="block px-4 py-2 hover:bg-[#ff6400] bg-zinc-600"
            role="menuitem"
          >
            Reglas MC
          </Link>
        </div>
      </div>

      <div className="hidden md:flex px-8">
        <table className="w-full max-w-4xl mx-auto border-collapse">
          <thead className="bg-zinc-600 text-white">
            <tr>
              <th className="p-2">Nombre</th>
              <th className="p-2">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {areas.map((area, index) => {
              return (
                <tr
                  key={area.id}
                  className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
                >
                  <td className="border border-gray-300 p-2">{area.name}</td>
                  <td className="border font-semibold border-gray-300 p-2">
                    <Link
                      to={`/home/areas/editar-area/${area.id}`}
                      className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                    >
                      Editar
                    </Link>
                    <button
                      onClick={() => handleDelete(area.id)}
                      className="mx-2 my-1 text-red-600  rounded-lg"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Areas;
