import React, { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";
import {
  FaCalendar,
  FaChevronLeft,
  FaChevronRight,
  FaLink,
  FaUserAlt,
} from "react-icons/fa";
import { TbTarget } from "react-icons/tb";

import { format } from "date-fns";
import { es } from "date-fns/locale";

const KanBanListColumn = ({ title, colour, estado, filterForms }) => {
  const [tareasList, setTareasList] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [page, setPage] = useState(1);
  const [serviciosMetaData, setServiciosMetaData] = useState({
    totalPages: 0,
    page: 0,
    hasPrevPage: false,
    hasNextPage: false,
    totalDocs: 0,
  });
  const getTareas = async () => {
    try {
      let customQueryFilter = "";
      if (filterForms.trabajador) {
        customQueryFilter += `&where[responsable][equals]=${filterForms.trabajador}`;
        setPage(1);
      }

      if (
        filterForms.fecha.endDate &&
        filterForms.fecha.startDate &&
        filterForms.fechaType
      ) {
        customQueryFilter += `&where[createdAt][${
          filterForms.fechaType
        }]=${new Date(filterForms.fecha.endDate).toISOString()} `;
        setPage(1);
      }

      if (estado === "Incumplido") {
        const response = await axios.get(
          `${BACKEND}/api/etapaTareaServicioCotizaciones?&page=${page}&limit=20&where[estado][in]=Pendiente,En proceso&where[fechaEntega][less_than]=${new Date().toISOString()}${customQueryFilter}`
        );
        setTareasList(response.data.docs);
        setTotalDocs(response.data.totalDocs);

        setServiciosMetaData({
          totalPages: response.data.totalPages,
          page: response.data.page,
          hasNextPage: response.data.hasNextPage,
          hasPrevPage: response.data.hasPrevPage,
          totalDocs: response.data.totalDocs,
        });
      } else {
        const response = await axios.get(
          `${BACKEND}/api/etapaTareaServicioCotizaciones?&page=${page}&limit=20&where[estado][equals]=${estado}${customQueryFilter}`
        );
        setTareasList(response.data.docs);
        setTotalDocs(response.data.totalDocs);

        setServiciosMetaData({
          totalPages: response.data.totalPages,
          page: response.data.page,
          hasNextPage: response.data.hasNextPage,
          hasPrevPage: response.data.hasPrevPage,
          totalDocs: response.data.totalDocs,
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    getTareas();
  }, [page, filterForms]);

  const pageRange = [];
  const startPage = Math.max(1, page - 2);
  const endPage = Math.min(
    page + 2,
    serviciosMetaData.totalPages ? serviciosMetaData.totalPages : 5
  );
  for (let i = startPage; i <= endPage; i++) {
    pageRange.push(i);
  }

  return (
    <div className="bg-white rounded px-2 py-2 h-full overflow-hidden">
      {/* board category header */}

      {tareasList && tareasList?.length > 0 && (
        <div className="flex flex-col items-center py-2">
          <nav aria-label="Page navigation example">
            <ul className="flex items-center -space-x-px h-4 text-base">
              <li>
                <button
                  onClick={() => setPage(page - 1)}
                  disabled={page === 1}
                  className="flex items-center justify-center px-4 h-4 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 "
                >
                  <span className="sr-only">Previous</span>
                  <FaChevronLeft className=" w-3 h-3 text-gray-700 dark:text-gray-400" />
                </button>
              </li>

              {pageRange.map((pageNumber) => (
                <li key={pageNumber}>
                  <button
                    style={{
                      backgroundColor: page === pageNumber ? colour : "white",
                    }}
                    onClick={() => setPage(pageNumber)}
                    className={`flex items-center justify-center px-4  h-4 leading-tight ${
                      page === pageNumber
                        ? " border-blue-300 text-gray-700 "
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
                    }`}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => setPage(page + 1)}
                  disabled={!serviciosMetaData.hasNextPage}
                  className="flex items-center justify-center px-4 h-4 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 "
                >
                  <span className="sr-only">Next</span>
                  <FaChevronRight className=" w-3 h-3 text-gray-700 dark:text-gray-400" />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
      <div className="flex flex-row justify-between items-center mb-2 mx-1">
        <div className="flex items-center">
          <h2
            style={{ backgroundColor: colour }}
            className={`bg-[${colour}] text-sm w-max px-1 rounded mr-2 text-gray-700`}
          >
            {title}
          </h2>
          <p className="text-gray-400 text-sm">{totalDocs}</p>
        </div>
      </div>
      {/* board card */}
      <div className="overflow-y-auto max-h-full  pb-14">
        {tareasList.map((e) => (
          <div
            key={e.id}
            className="p-2 rounded shadow-sm border-gray-100 border-2"
          >
            <h3 className="text-sm mb-3 font-semibold text-gray-700">{e.name}</h3>
            <p
              style={{ backgroundColor: colour }}
              className={`bg-[${colour}] text-xs w-max   p-1 rounded mr-2 text-gray-700`}
            >
              {title}
            </p>
            {e?.link && (
              <div className="mt-4">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" flex justify-end text-xs  items-center gap-2 text-orange-600 hover:underline"
                  href={
                    e.link.startsWith("http") ? e.link : `https://${e.link}  `
                  }
                >
                  {" "}
                  <FaLink className=" h-4 w-4" /> Link
                </a>
              </div>
            )}
            <div className="flex flex-row items-center mt-2">
              <div className=" flex justify-center items-center rounded-full w-6 h-6 mr-3">
                {e.responsable?.foto ? (
                  <img
                    src={e.responsable.foto.url}
                    alt="trabajador"
                    className=" w-full h-full rounded-full object-cover"
                  />
                ) : (
                  <FaUserAlt />
                )}
              </div>
              <p className="text-xs text-gray-800">{e.responsable?.name}</p>
            </div>

            <div className="flex mt-4 justify-start items-center gap-2">
              <div className=" text-gray-500 text-xs flex justify-start gap-2 items-center">
                <div style={{backgroundColor:colour}} className=" p-1 rounded-full  ">
                  <TbTarget />
                </div>
                <span >Fecha de entrega: </span>
              </div>
              <p className="text-xs text-gray-500  capitalize">
                { e?.fechaEntega && format(new Date(e.fechaEntega), "  hh:mm a, dd MMMM yyyy", {
                  locale: es,
                })}
              </p>
            </div>
            <div className="flex mt-2 justify-start items-center gap-2">
              <div className=" text-gray-500 text-xs flex justify-start gap-2 items-center">
                <div className=" p-1 rounded-full  bg-gray-100">
                  <FaCalendar />
                </div>
                <span>Fecha creación: </span>
              </div>
              <p className="text-xs text-gray-500  capitalize">
                {format(new Date(e.createdAt), "  hh:mm a, dd MMMM yyyy", {
                  locale: es,
                })}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KanBanListColumn;
