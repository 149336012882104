import axios from "axios"
import { toast } from "react-toastify"
import { BACKEND } from "../../config/configEnvs"
import { useState } from "react"
import { FaSave, FaTrash } from "react-icons/fa"

const ObjectiveRow = ({objective, templates, phases, successUpdate}) => {

    const [objectiveName, setObjectiveName] = useState(objective?.name)

    const updateNameObjective = async () => {
        try {
            await axios.put(`${BACKEND}/api/objetivos/${objective.id}`, {
                name: objectiveName
            })
            successUpdate()
            toast.success("Objetivo actualizado correctamente")
        } catch (error) {
            toast.error("Error actualizando el objetivo: " + error)
        }
    }

    const updateDefaultPhaseObjective = async (defaultPhaseSelected) => {
        try {
            await axios.put(`${BACKEND}/api/objetivos/${objective.id}`, {
                defaultPhase: defaultPhaseSelected
            })
            successUpdate()
            toast.success("Objetivo actualizado correctamente")
        } catch (error) {
            toast.error("Error actualizando el objetivo: " + error)
        }
    }

    const updateDefaultServiceObjective = async (defaultServiceSelected) => {
        try {
            await axios.put(`${BACKEND}/api/objetivos/${objective.id}`, {
                defaultService: defaultServiceSelected
            })
            successUpdate()
            toast.success("Objetivo actualizado correctamente")
        } catch (error) {
            toast.error("Error actualizando el servicio: " + error)
        }
    }

    const deleteObjective = async () => {
        try {
            const dependantObjectives = await axios.get(`${BACKEND}/api/objetivosProyectos?where[objective][equals]=${objective.id}`)
            if (dependantObjectives.data.docs.length > 0) {
                toast.warning("Hay uno o más objetivos creados que dependen de esta plantilla, no es posible eliminar. Si deseas que este objetivo no aparezca en los nuevos procesos selecciona el campo " + "Planilla" + " del objetivo en null (Seleccionar plantilla)")
            } else {
                await axios.delete(`${BACKEND}/api/objetivos/${objective.id}`)
                successUpdate()
                toast.success("Objetivo eliminado correctamente")
            }
        } catch (error) {
            toast.error("Error al eliminar el objetivo: " + error)
        } 
    }

    return (
        <tr
            key={objective?.id}
            className="hover:bg-gray-100 transition-colors duration-100"
        >
            <td className="justify-center border border-gray-300 w-[7%] truncate">
                <div className="flex flex-row gap-4 items-center justify-center ">

                    <div className="flex items-center justify-center">
                        <a
                            onClick={() => deleteObjective()}
                            className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                            >
                            <FaTrash />
                        </a>
                    </div>

                    <div className="flex items-center justify-center">
                        <a
                            onClick={() => updateNameObjective()}
                            className="p-2 text-green-500 hover:bg-green-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-green-700 bg-opacity-10 flex items-center"
                            >
                            <FaSave />
                        </a>
                    </div>

                </div>
            </td>

            <td className="justify-center border border-gray-300 max-w-xs truncate">
                <div className="flex items-center">
                    <input
                        type="text"
                        value={objectiveName} // Usar el estado local para el nombre de la tarea
                        onChange={(e) => setObjectiveName(e.target.value)} // Actualiza el estado local
                        className="w-full border-white rounded-full"
                    />
                </div>
            </td>

            <td className="justify-center border border-gray-300 max-w-xs truncate">
                <select
                    id="defaultService"
                    name="defaultService"
                    className="w-full border-white rounded-full"
                    value={objective?.defaultService}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        updateDefaultServiceObjective(newValue)
                    }}
                >
                    <option value="">Seleccionar plantilla</option>
                    {templates.map((template) => (
                        <option value={template}>{template}</option>
                    ))}
                </select>
            </td>

            <td className="justify-center border border-gray-300 max-w-xs truncate">
                <select
                    id="defaultPhase"
                    name="defaultPhase"
                    className="w-full border-white rounded-full"
                    value={objective?.defaultPhase}
                    disabled={!objective.defaultService}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        updateDefaultPhaseObjective(newValue)
                    }}
                >
                    <option value="">Seleccionar objetivo</option>
                    {phases
                    .filter((phase) => phase.defaultService === objective.defaultService)
                    .map((phase) => (
                        <option
                            key={phase.id}
                            value={phase.name}
                        >
                            {phase.name} - {phase.defaultService}
                        </option>
                    ))}

                </select>
            </td>

        </tr>
    )
}

export default ObjectiveRow