import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import "moment-timezone";
import { FaChartBar } from "react-icons/fa";
import { BACKEND } from "../config/configEnvs";
import { toast } from "react-toastify";
import PieChartStatistics from "../blockComponents/charts/pieCharts/PieChartStatistics";


function EstadisticasTrabajador() {

  //const [record, setRecord] = useState([]);
  const [isWorkerSelected, setIsWorkerSelected] = useState(false)
  const [selectedWorkerId, setSelectedWorkerId] = useState(null);
  const [workersIds, setWorkersIds] = useState([]);

  const [isLoading, setIsLoading] = useState(false) // Estado que guarda si se está en estado de carga o no
  const [toastId, setToastId] = useState(null); // Estado para manejar el toast de carga
  const [isSuccess, setIsSucces] = useState(false)

  const [selectedTab, setSelectedTab] = useState(2)

  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  const [workers, setWorkers] = useState([])

  const [incidences, setIncidences] = useState([])
  const [countIncidences, setCountIncidences] = useState({})
  const [amountIncidences, setAmountIncidences] = useState([])

  const [discounts, setDiscounts] = useState(0)

  const formatMinutesToHours = (totalMinutes) => {
    // Convertir minutos a horas y minutos
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60);
  
    // Formatear las horas y minutos como una cadena
    return `${hours}h ${minutes}m`;
  };

  function getSeverityColor(severity) {
    switch (severity) {
      case 'Muy grave':
        return 'text-red-800'; // Color para alta gravedad
      case 'Grave':
        return 'text-red-500'; // Color para gravedad media
      case 'Moderada':
        return 'text-yellow-500'; // Color para baja gravedad
      case 'Leve':
        return 'text-yellow-300'; // Color para baja gravedad
      default:
        return 'text-gray-600'; // Color predeterminado
    }
  }

  const fetchWorkers = async () => {
    try {
      const response = await axios.get(`${BACKEND}/api/getUsersIds/getWorkerUsers`)
      setWorkersIds(response.data)
    } catch (error) {
      toast.error("Error obteniendo los trabajadores: " + error)
    } 
  }

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const workerQuery = selectedWorkerId ? `&idWorker=${selectedWorkerId}` : '';
      const response = await axios.get(`${BACKEND}/api/metaDataUsers/getWorkersData?month=${month}&year=${year}${workerQuery}`)
      
      setIncidences(response.data.incidences)
      setCountIncidences(response.data.countIncidences)
      setAmountIncidences(response.data.amountIncidences)

      setDiscounts(response.data.totalDiscounts)

      setWorkers(response.data.workers)
    
      setIsSucces(true)
    } catch (error) {
      toast.error("Error obteniendo la data: " + error)
    } finally {
      setIsLoading(false)
    }
  }

  // Crear opciones de meses
  const months = [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' }
  ];

  const monthObject = months.find(monthOption => monthOption.value === month)

  useEffect(() => { // Use effect que trae a los trabajadores para el selector
    fetchWorkers()
  }, [])
  
  useEffect(() => { // Si se cambia el mes, el año o el trabajador se envían los datos para traerlos
    fetchData()
  }, [month, year, selectedWorkerId]);

  useEffect(() => { // Use effect para el toast de carga
    if (isLoading) {
      if (!toastId) {
        const id = toast.loading("Cargando...");
        setToastId(id);
      }
    } else if (isSuccess && toastId) {
      toast.update(toastId, {
        render: "Data actualizada.",
        type: toast.TYPE.SUCCESS,
        isLoading: false, // Detiene la animación de carga
        autoClose: 3000, // Cierra automáticamente después de 3 segundos
      });
      setToastId(null); // Resetea el ID para futuros usos
    } else if (toastId) {
      toast.dismiss(toastId);
      setToastId(null);
    }
  }, [isLoading, isSuccess, toastId]);


  function getTotalDaysInMonth(year, month) {
    return moment(`${year}-${month + 1}`, "YYYY-M").daysInMonth();
  }

  const totalDaysInMonth = getTotalDaysInMonth(year, month);  

  const data = []; // Data para los gráficos generales

  const pieChartData = []; // Data para los gráficos individuales por trabajador


  const totalLateDays = workers.reduce((sum, worker) => {
    return sum + (worker.totalLateDays || 0);
  }, 0);

  const totalIncidences = workers.reduce((sum, worker) => {
    return sum + worker.incidences.length;
  }, 0);


  const totalMinutesWorked = workers.reduce((sum, worker) => {
    return sum + (worker.totalMinutesWorked || 0);
  }, 0);

  const totalLateMinutes = workers.reduce((sum, worker) => {
    return sum + (worker.totalMinutesLate || 0);
  }, 0);

  const totalExtraMinutes = workers.reduce((sum, worker) => {
    return sum + (worker.totalExtraMinutes || 0);
  }, 0); 
  

  const lateDaysPerWorker = workers.map(worker => ({
    id: worker.workerName,
    label: worker.workerName,
    value: worker.totalLateDays || 0
  }));

  const incidencesPerWorker = workers.map(worker => ({
    id: worker.workerName,
    label: worker.workerName,
    value: worker.incidences.length
  }))

  const minutesPerWorker = workers.map(worker => {
    // Asegúrate de que totalMinutesWorked sea un número y no nulo o indefinido
    const minutesWorked = worker.totalMinutesWorked ?? 0;
  
    // Convertir minutos a horas en formato decimal
    const hoursWorked = minutesWorked / 60;
  
    // Devuelve el valor como un número decimal
    return {
      id: worker.workerName,
      label: worker.workerName,
      value: hoursWorked.toFixed(2) // Formatea como decimal con dos decimales
    };
  });


  // Datos generales

  if (selectedTab === 2) {
    data.push(...minutesPerWorker);
  }
  if (selectedTab === 3) {
    data.push(...incidencesPerWorker);
  }
  if (selectedTab === 4) {
    data.push(...lateDaysPerWorker);
  }


  // Datos por trabajador

  if (selectedTab === 2) {
    pieChartData.push(
      {
        id: "Minutos Tardanza",
        name: "Minutos Tardanza",
        value: parseFloat(totalLateMinutes.toFixed(2))
      },
      {
        id: "Minutos Totales",
        name: "Minutos Totales",
        value: parseFloat(totalMinutesWorked.toFixed(2))
      },
      {
        id: "Tiempo Extra",
        name: "Tiempo Extra",
        value: parseFloat(totalExtraMinutes.toFixed(2))
      },
    )
  }
  if (selectedTab === 3) {
    pieChartData.push(...Object.keys(countIncidences).map(severity => ({
      id: severity,
      name: severity,
      value: countIncidences[severity]
    })));
  }
  if (selectedTab === 4) {
    pieChartData.push(
      {
        id: "Días con Tardanza",
        name: "Días con Tardanza",
        value: totalLateDays
      },
      {
        id: "Días Puntuales",
        name: "Días Puntuales",
        value: totalDaysInMonth - totalLateDays
      },
    )
  }

  const chartData = !isWorkerSelected
  ? data
  : (selectedTab === 2 || selectedTab === 3 || selectedTab === 4)
    ? pieChartData
    : null;

  

  return (
    <div className="w-full flex flex-col">

      <div className="flex flex-col px-2 py-2 overflow-x-auto bg-zinc-800">
        <div className="flex items-center gap-4 p-4">
          <FaChartBar className="text-white text-2xl" />
          <h2 className="text-2xl font-bold text-white">Estadísticas trabajadores</h2>
        </div>
      </div>

      <div className="flex flex-row flex-wrap px-8 mb-4 justify-center bg-gray-200 items-center">
          
          <div className="flex items-center mx-4 my-2">
            <p htmlFor="filtroNombre" className="mr-2 w-28 md:w-auto">
              Elegir trabajador:
            </p>

            <select
              id="workerId"
              name="workerId"
              value={selectedWorkerId}
              onChange={(e) => {
                // Si se selecciona "Todos", restablece workerId a null
                setSelectedWorkerId(e.target.value === '' ? null : e.target.value);
                setIsWorkerSelected(e.target.value === '' ? false : true)
              }}
              className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
            >
              <option value="">Todos</option>
              {workersIds.map((worker) => (
                <option key={worker.id} value={worker.id}>
                  {worker.name}
                </option>
              ))}
            </select>

          </div>

          <div className="flex items-center mx-4 my-2">
            <p htmlFor="filtroFechaInicio" className="mr-2 w-28">
              Mes:
            </p>
            <select
            id="filtroFechaInicio"
            name="filtroFechaInicio"
            value={month}
            onChange={(e) => setMonth(Number(e.target.value))}
            className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
          >
            {months.map((monthOption) => (
              <option
                key={monthOption.value}
                value={monthOption.value}
              >
                {monthOption.label}
              </option>
            ))}
          </select>
          </div>

          <div className="flex items-center mx-4 my-2">
            <p htmlFor="filtroFechaFin" className="mr-2 w-28">
              Año:
            </p>
            <select
              type="date"
              id="filtroFechaFin"
              name="filtroFechaFin"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                <option value="2023">2023</option>
                <option value="2024">2024</option>
              </select>
          </div>

      </div>

      <div className="mb-1 ml-auto z-10 mx-4 mt-4 bg-red-200">
        <span 
          className={`${selectedTab === 1 ? "bg-orange-500 hover:bg-orange-500 text-white" : "bg-orange-100"} px-4 py-2 rounded-t-md border-t border-l border-r border-gray-300 cursor-pointer hover:bg-orange-300 transition duration-100`}
          //onClick={() => setSelectedTab(1)}
        >
          Productividad
        </span>
        <span 
          className={`${selectedTab === 2 ? "bg-orange-500 hover:bg-orange-500 text-white" : "bg-orange-100"} px-4 py-2 rounded-t-md border-t border-l border-r border-gray-300 cursor-pointer hover:bg-orange-300 transition duration-100`}
          onClick={() => setSelectedTab(2)}
        >
          Datos generales
        </span>
        <span 
          className={`${selectedTab === 3 ? "bg-orange-500 hover:bg-orange-500 text-white" : "bg-orange-100"} px-4 py-2 rounded-t-md border-t border-l border-r border-gray-300 cursor-pointer hover:bg-orange-300 transition duration-100`}
          onClick={() => setSelectedTab(3)}
        >
          Incidencias
        </span>
        <span 
          className={`${selectedTab === 4 ? "bg-orange-500 hover:bg-orange-500 text-white" : "bg-orange-100"} px-4 py-2 rounded-t-md border-t border-l border-r border-gray-300 cursor-pointer hover:bg-orange-300 transition duration-100`}
          onClick={() => setSelectedTab(4)}
        >
          Tardanzas
        </span>
      </div>

        <div className="flex flex-row space-x-4 border border-gray-400 p-4 mx-4 rounded-md rounded-tl-none">
          <div className="flex flex-col w-1/3 gap-4">

            <div className="bg-gray-100 px-4 py-3 hover:bg-gray-200 transition-colors duration-100 rounded shadow cursor-pointer">
              Horas de trabajo: {formatMinutesToHours(totalMinutesWorked)}
            </div>

            <div className="bg-gray-100 px-4 py-3 hover:bg-gray-200 transition-colors duration-100 rounded shadow cursor-pointer">
              Tiempo extra: {formatMinutesToHours(totalExtraMinutes)}
            </div>

            <div className="bg-gray-100 px-4 py-3 hover:bg-gray-200 transition-colors duration-100 rounded shadow cursor-pointer">
              Tiempo de tardanza: {formatMinutesToHours(totalLateMinutes)}
            </div>

            <div className="bg-gray-100 px-4 py-3 hover:bg-gray-200 transition-colors duration-100 rounded shadow cursor-pointer">
              Tardanzas: {totalLateDays}
            </div>

            <div className="bg-gray-100 px-4 py-3 hover:bg-gray-200 transition-colors duration-100 rounded shadow cursor-pointer">
              Incidencias: {amountIncidences}
              {totalIncidences > 0 && (
                <ul className="mt-2 text-sm text-gray-600">
                  {Object.entries(countIncidences).map(([category, count]) => (
                    <li 
                      key={category} 
                      className={`flex justify-between py-1 px-2 rounded ${getSeverityColor(category)}`}
                    >
                      <span>{category}</span>
                      <span>{count}</span>
                    </li>
                  ))}
                </ul>
              )}

            </div>

            <div className="bg-gray-100 px-4 py-3 hover:bg-gray-200 transition-colors duration-100 rounded shadow cursor-pointer">
              Descuentos por incidencias: S/. {" " + discounts}
            </div>

          </div>

          <div className="w-2/3 border">

            <div className="h-[500px]">
              <label className="w-full flex mt-4 justify-center items-center">
                {selectedTab === 2 && "Horas trabajadas "}
                {selectedTab === 3 && "Incidencias "}
                {selectedTab === 4 && "Tardanzas "}
                para el mes de {monthObject ? monthObject.label + " - " + year: 'Seleccionar mes'}
              </label>

              {chartData && (
                <PieChartStatistics data={chartData} />
              )}

            </div>

          </div>

        </div>

            
    </div>
  );
}

export default EstadisticasTrabajador;
