import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import HomeRoutes from "../components/HomeRoutes";
import { logout } from "../features/authSlice";
import {
  FaUsers,
  FaChartPie,
  FaCalendarAlt,
  FaRestroom,
  FaPenSquare,
  FaSitemap,
  FaFolderOpen,
  FaBriefcase,
  FaMoneyBill,
  FaHandshake,
  FaBuilding,
  FaChalkboardTeacher,
  FaCalculator,
  FaChartLine,
  FaLink,
  FaHistory,
  FaExclamationCircle,
  FaBook,
  FaBullhorn,
  FaCubes,
  FaWpforms,
  FaDollarSign,
  FaCommentDollar,
  FaCogs,
} from "react-icons/fa";
import { BiSolidVideoRecording } from "react-icons/bi";
import { TbReportMoney } from "react-icons/tb";

import { RiKeyboardLine } from "react-icons/ri";
import { MdBalance } from "react-icons/md";

import { MdLogout, MdPerson } from "react-icons/md";
import "flowbite/dist/flowbite.min.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Home = () => {
  const [redirect, setRedirect] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const handleLogout = async () => {
    dispatch(logout());
    setRedirect(true);
  };

  if (redirect) {
    return <Navigate to={"/"} />;
  }

  if (user == null) {
    return <Navigate to={"/"} />;
  }

  return (
    <div className="min-h-screen">
      <ToastContainer />

      <nav className="fixed top-0 z-50 w-full bg-white border-b-2 border-zinc-400">
        <div className="px-3 py-2 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 text-sm  rounded-lg sm:hidden  focus:outline-none focus:ring-2  text-zinc-400 hover:bg-zinc-700 focus:ring-zinc-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <a
                href="https://monstruocreativo.com"
                className="flex ml-3 md:-ml-3 md:-my-2 py-1 md:py-2 md:w-64 border-zinc-200 md:border-r-2"
              >
                <img
                  src="/images/logo-color.png"
                  className="h-10 md:h-12 mx-auto"
                  alt="Monstruo Creativo Logo"
                />
              </a>
            </div>
            <div className="flex items-center md:mr-4">
              <span className="text-[#ff6400] font-semibold">{user.name}</span>
              <div className="flex items-center ml-3">
                <div className="flex">
                  <button
                    type="button"
                    className="flex text-sm bg-zinc-800 rounded-full focus:ring-4  focus:ring-zinc-600"
                    aria-expanded="false"
                    data-dropdown-toggle="dropdown-user"
                  >
                    <span className="sr-only">Open user menu</span>
                    {user.foto?.url ? (
                      <img
                        className="w-8 h-8 rounded-full"
                        src={user.foto.url}
                        alt={user.foto?.alt}
                      />
                    ) : (
                      <MdPerson className="text-black h-6 w-6" />
                    )}
                    {/* {console.log(user.foto?.url)} */}
                  </button>
                </div>
                <div
                  className="z-50 hidden my-4 text-base list-none divide-y  rounded shadow bg-zinc-700 divide-zinc-600"
                  id="dropdown-user"
                >
                  <div className="px-4 py-3" role="none">
                    <p className="text-sm text-white" role="none">
                      {user.name}
                    </p>
                    <p
                      className="text-sm font-medium  truncate text-zinc-300"
                      role="none"
                    >
                      {user.email}
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    <li>
                      <a
                        href="/home/editar-perfil"
                        className="block px-4 py-2 text-sm  text-zinc-300 hover:bg-zinc-600 hover:text-white"
                        role="menuitem"
                      >
                        Editar Perfil
                      </a>
                    </li>
                    <li>
                      <a
                        href="/home/dashboard"
                        className="block px-4 py-2 text-sm  text-zinc-300 hover:bg-zinc-600 hover:text-white"
                        role="menuitem"
                      >
                        Dashboard
                      </a>
                    </li>
                    <li>
                      <button
                        onClick={handleLogout}
                        className="block px-4 py-2 text-sm  text-zinc-300 hover:bg-zinc-600 hover:text-white"
                        role="menuitem"
                      >
                        Sign out
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full  border-r  sm:translate-x-0 bg-zinc-800 border-zinc-700"
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-zinc-800">
          <ul className="space-y-2 font-medium">
            <li>
              <a
                href="/home/dashboard"
                className="flex items-center p-2 rounded-lg text-white hover:bg-zinc-700"
              >
                <FaChartLine className="w-6 h-6 transition duration-75 text-zinc-400  group-hover:text-white"></FaChartLine>
                <span className="flex-1 ml-3 whitespace-nowrap">Dashboard</span>
              </a>
            </li>
            <li>
              <a
                href="/home/procesos"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaCogs className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Procesos
                </span>
              </a>
            </li>
            <li>
              <a
                href="/home/trabajadores"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaUsers className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Trabajadores
                </span>
              </a>
            </li>
            <li>
              <a
                href="/home/estadisticas-trabajador"
                className="flex items-center p-2  rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaChartPie className="flex-shrink-0 w-5 h-5 transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Estadísticas Trabajadores
                </span>
              </a>
            </li>
            <li>
              <a
                href="/home/pagos-trabajadores"
                className="flex items-center p-2  rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaCommentDollar className="flex-shrink-0 w-5 h-5 transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Pagos Trabajadores
                </span>
              </a>
            </li>
            <li>
              <a
                href="/home/verificacion-trabajadores"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <BiSolidVideoRecording  className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Verificación Trabajador
                </span>
              </a>
            </li>
            <li>
              <a
                href="/home/planilla"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <RiKeyboardLine className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Planilla
                </span>
              </a>
            </li>

            <li>
              <a
                href="/home/costos"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <MdBalance  className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Costos
                </span>
              </a>
            </li>
            <li>
              <a
                href="/home/areas"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaSitemap className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">Areas</span>
              </a>
            </li>
            <li>
              <a
                href="/home/anuncios"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaBullhorn className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">Anuncios</span>
              </a>
            </li>
            <li>
              <a
                href="/home/clientes-user"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaSitemap className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Clientes (Usuarios)
                </span>
              </a>
            </li>
            <li>
              <a
                href="/home/links"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaLink className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">Links</span>
              </a>
            </li>
            <li>
              <a
                href="/home/recursos"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaFolderOpen className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">Recursos</span>
              </a>
            </li>
            {/*<li>
              <a
                href="/home/proyectos"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaBriefcase className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">Proyectos</span>
              </a>
                    </li>*/}
            <li>
              <a
                href="/home/ventas"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaMoneyBill className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">Ventas</span>
              </a>
            </li>

            <li>
              <a
                href="/home/comisiones-generales"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <TbReportMoney className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">Comisiones generales</span>
              </a>
            </li>
            <li>
              <a
                href="/home/clientes"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaHandshake className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">Clientes</span>
              </a>
            </li>
            <li>
              <a
                href="/home/learning"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaChalkboardTeacher className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">Team</span>
              </a>
            </li>
            {/*<li>
              <a
                href="/home/rrhh"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaBuilding className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">RRHH</span>
              </a>
            </li>
          
            <li>
              <a
                href="/home/contabilidad"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaCalculator className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Contabilidad
                </span>
              </a>
                    </li>
            <li>
              <a
                href="/home/tutoriales"
                className="flex items-center p-2 rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaBook className="flex-shrink-0 w-5 h-5  transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Tutoriales
                </span>
              </a>
            </li>*/}
            <li>
              <a
                href="/home/registros-asistencia"
                className="flex items-center p-2  rounded-lg text-white  hover:bg-zinc-700"
              >
                <FaCalendarAlt className="flex-shrink-0 w-5 h-5 transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Registros Asistencia
                </span>
              </a>
            </li>
            <li>
              <a
                href="/home/registros-sshh"
                className="flex items-center p-2  rounded-lg text-white hover:bg-zinc-700"
              >
                <FaRestroom className="flex-shrink-0 5 h-5 transition duration-75 text-zinc-400  group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Registros SSHH
                </span>
              </a>
            </li>
            <li>
              <a
                href="/home/registros-tareas"
                className="flex items-center p-2  rounded-lg text-white hover:bg-zinc-700"
              >
                <FaPenSquare className="flex-shrink-0 w-5 h-5 transition duration-75 text-zinc-400 group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Registros de Tareas
                </span>
              </a>
            </li>
            <li>
              <a
                href="/home/tareas-admin"
                className="flex items-center p-2  rounded-lg text-white hover:bg-zinc-700"
              >
                <FaPenSquare className="flex-shrink-0 w-5 h-5 transition duration-75 text-zinc-400 group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Tareas (ADMIN)
                </span>
              </a>
            </li>
            <li>
              <a
                href="/home/registro-busqueda-clientes"
                className="flex items-center p-2  rounded-lg text-white hover:bg-zinc-700"
              >
                <FaHistory className="flex-shrink-0 w-5 h-5 transition duration-75 text-zinc-400 group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Buscador de Clientes
                </span>
              </a>
            </li>
            <li>
              <a
                href="/home/incidencias"
                className="flex items-center p-2  rounded-lg text-white hover:bg-zinc-700"
              >
                <FaExclamationCircle className="flex-shrink-0 w-5 h-5 transition duration-75 text-zinc-400 group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Incidencias
                </span>
              </a>
            </li>

            <li>
              <Link
                to="/home/servicios"
                className="flex items-center p-2  rounded-lg text-white hover:bg-zinc-700"
              >
                <FaCubes className="flex-shrink-0 w-5 h-5 transition duration-75 text-zinc-400 group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">Servicios</span>
              </Link>
            </li>

            <li>
              <Link
                to="/home/formularios"
                className="flex items-center p-2  rounded-lg text-white hover:bg-zinc-700"
              >
                <FaWpforms className="flex-shrink-0 w-5 h-5 transition duration-75 text-zinc-400 group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Formularios
                </span>
              </Link>
            </li>

            <li>
              <Link
                to="/home/cumpleaños-clientes"
                className="flex items-center p-2  rounded-lg text-white hover:bg-zinc-700"
              >
                <FaCalendarAlt className="flex-shrink-0 w-5 h-5 transition duration-75 text-zinc-400 group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">Cumpleaños Clientes</span>
              </Link>
            </li>
            
          </ul>
          <ul className="pt-4 mt-4 space-y-2 font-medium border-t  border-zinc-700">
            <li>
              <button
                onClick={handleLogout}
                className="flex w-full items-center p-2  rounded-lg text-white  hover:bg-zinc-700"
              >
                <MdLogout className="w-6 h-6  transition duration-75 text-zinc-400  group-hover:text-white"></MdLogout>
                <span className="ml-3">Logout</span>
              </button>
            </li>
          </ul>
        </div>
      </aside>

      <main className="mx-auto mt-16 sm:ml-64">
        <HomeRoutes />
      </main>
    </div>
  );
};

export default Home;
