import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const EditarCliente = () => {
  // Valores
  const { id } = useParams();
  const [name, setName] = useState("");
  const [img, setImg] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [popup, setPopup] = useState(false);
  const [elementos, setElementos] = useState([]);
  const [link, setLink] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCliente = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/clientes/${id}`);
        const cliente = response.data;
        setName(cliente.name || "");
        setImg(cliente.img || "");
        setDescripcion(cliente.descripcion || "");
        setPopup(cliente.popup || false);
        setElementos(cliente.elemento || []);
        setLink(cliente.link || "");
      } catch (error) {
        console.error("Error al obtener el cliente:", error);
      }
    };

    if (id) {
      fetchCliente();
    }
  }, [id]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleImgChange = (e) => {
    setImg(e.target.value);
  };

  const handleDescripcionChange = (e) => {
    setDescripcion(e.target.value);
  };

  const handlePopupChange = () => {
    setPopup(!popup);
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${BACKEND}/api/clientes/${id}`, {
        name,
        img,
        descripcion,
        popup,
        link,
      });

      const clienteActualizado = response.data;
      console.log("Cliente actualizado:", clienteActualizado);
      navigate("/home/clientes");
    } catch (error) {
      console.error("Error al actualizar el cliente:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">Editar Cliente</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">
              Titulo <span className=" text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="img" className="block mb-2">
              Imagen
            </label>
            <input
              type="text"
              id="img"
              value={img}
              onChange={handleImgChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="descripcion" className="block mb-2">
              Descripcion
            </label>
            <input
              type="text"
              id="descripcion"
              value={descripcion}
              onChange={handleDescripcionChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="popup" className="block mb-2">
              Popup
            </label>
            <input
              type="checkbox"
              id="popup"
              checked={popup}
              onChange={handlePopupChange}
              className="h-5 w-5 text-[#ff6400] focus:ring-[#ff6400] border-gray-300 rounded-md"
            />
          </div>
          {popup && (
            <div className="hidden md:flex md:flex-col mb-4">
              <label htmlFor="descripcion" className="block mb-2">
                Elementos
                <Link
                  to={`/home/clientes/editar-cliente/${id}/nuevo-elemento`}
                  className="ml-4 px-3 py-1 text-sm  hover:bg-[#ff6400] bg-zinc-600 text-white"
                >
                  Añadir
                </Link>
              </label>
              <table className="w-full max-w-4xl mx-auto border-collapse">
                <thead className="bg-zinc-600 text-white">
                  <tr>
                    <th className="p-2">Título</th>
                    <th className="p-2">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {elementos.map((elemento, index) => {
                    return (
                      <tr
                        key={elemento.id}
                        className={`${
                          index % 2 === 0 ? "bg-zinc-100" : "bg-white"
                        }`}
                      >
                        <td className="border border-gray-300 p-2">
                          {elemento.title}
                        </td>
                        <td className="border font-semibold border-gray-300 p-2">
                          <Link
                            to={`/home/clientes/editar-cliente/${id}/editar-elemento/${elemento.id}`}
                            className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                          >
                            Editar
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {!popup && (
            <div className="mb-4">
              <label htmlFor="link" className="block mb-2">
                Link
              </label>
              <input
                type="text"
                id="link"
                value={link}
                onChange={handleLinkChange}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
            </div>
          )}
          <div className="flex justify-end">
            <Link
              to="/home/clientes"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Actualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarCliente;
