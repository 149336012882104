import React, { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";
import {toast} from "react-toastify"
const VerificacionTextoDialog = ({ open, setOpen }) => {
  const [verificationObject, setVerificationObject] = useState(null);
  const [verificationText, setVerificationText] = useState("");
  const getVerificacationText = async () => {
    try {
      const response = await axios.get(
        `${BACKEND}/api/trabajadoresVerificacionTexto?limit=1`
      );
      if (response.data.docs.length > 0) {
        setVerificationObject(response.data.docs[0]);
        setVerificationText(response.data.docs[0].text);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveText = async (e) => {

    if(!verificationObject)return ;

    e.preventDefault();
    try {
      const response = await axios.put(
        `${BACKEND}/api/trabajadoresVerificacionTexto/${verificationObject.id}`,
        { text: verificationText }
      );

      setOpen(false)
      toast.success("Guardado")
      
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVerificacationText();
  }, []);

  return (
    <Modal
      activeModal={open}
      title="Texto instrucciones de verificación"
      onClose={() => setOpen(false)}
    >
      {verificationObject && (
        <div>
          <form onSubmit={saveText}>
            <div className=" text-gray-800 text-xs p-2 border-t border-l border-r rounded-t-lg bg-gray-50">
              Yo, <strong>{" {Nombre trabajador}"}</strong>
            </div>
            <div className="w-full mb-4  border-l border-r border-b border-gray-200  rounded-b-lg bg-gray-50">
              <div className="px-4 py-2 bg-white rounded-t-lg ">
                <label htmlFor="comment" className="sr-only">
                  Tus instrucciones
                </label>
                <textarea
                  id="comment"
                  rows="10"
                  value={verificationText}
                  onChange={(e) => setVerificationText(e.target.value)}
                  className="w-full px-0 text-sm text-gray-900 bg-white border-0  focus:ring-0 "
                  placeholder="Escribe un texto"
                  required
                ></textarea>
              </div>
              <div className="flex items-center justify-between px-3 py-2 border-t ">
                <button
                  type="submit"
                  className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200  hover:bg-blue-800"
                >
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};

export default VerificacionTextoDialog;
