import React, { useEffect, useState } from "react";
import ItemRowPlanillaCurrentMonth from "./ItemRowPlanillaCurrentMonth";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { es } from "date-fns/locale";
import { MdSave } from "react-icons/md";
import axios from "axios";
import {toast} from "react-toastify";
import { BACKEND } from "../../../config/configEnvs";
const TablePlanillaCurrentMonth = ({ trabajadores,getPlanillaMes }) => {
  const [dataList, setDataList] = useState([]);
const[isPlanillaSaving,setIsPlanillaSaving]=useState(false)
  useEffect(() => {
    if (trabajadores.length > 0) {
      setDataList(
        trabajadores.map((e) => ({ trabajador: e, descuento: 0, extras: 0 }))
      );
    }
  }, [trabajadores]);

  const savePlanillaMensual = async () => {
    setIsPlanillaSaving(true)
   try {
    await axios.post(`${BACKEND}/api/planillaMes`, {
        fechaInicio: startOfMonth(new Date()),
        fechaFin: endOfMonth(new Date()),
        sueldos: dataList.map((data) => ({
          trabajador: data.trabajador.id,
          descuento: data.descuento,
          extra: data.extras,
          total:data?.trabajador?.sueldo ? data.trabajador.sueldo + data.extras - data.descuento : data.extras - data.descuento
        })),
      });
      toast.success("Planilla mensual guardada")
      getPlanillaMes()
    
   } catch (error) {
    toast.error("Hubo un erorr al guardar la planilla mensual")

   }
    finally{
        setIsPlanillaSaving(false)
    }
   
   
  };

  return (
    <section class="  p-3 sm:p-5">
      <div class="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div class="bg-white  relative shadow-md sm:rounded-lg overflow-hidden">
          <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div class="w-full  flex  justify-between md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center  md:space-x-3 flex-shrink-0">
              <div>
                <span className=" font-bold text-xl text-gray-600 capitalize">
                  {" "}
                  Planilla de {format(new Date(), "MMMM, yyyy", { locale: es })}
                </span>
              </div>
            </div>
          </div>
          <div class="overflow-x-auto">
            <table class="w-full text-sm text-left text-gray-500 ">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                <tr>
                  <th scope="col" class="px-4 py-3">
                    N°
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Nombre
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Puesto
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Sueldo
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Descuento
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Extras
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataList.map((e, index) => (
                  <ItemRowPlanillaCurrentMonth
                    setDataList={setDataList}
                    key={e.trabajador.id}
                    index={index}
                    data={e}
                  />
                ))}
              </tbody>
              <tfoot className=" w-full"></tfoot>
            </table>
            <div className=" p-4 flex w-full justify-between gap-4  flex-wrap">
              <div className="flex justify-start gap-8 ">
                <div className=" flex flex-col gap-2">
                  <span className=" text-gray-500  text-xs">Costo Total </span>
                  <span className=" text-gray-800 font-semibold text-base">
                    S/{" "}
                    {dataList.reduce(
                      (acc, value) =>
                        (value.trabajador?.sueldo
                          ? value.trabajador.sueldo +
                            value.extras -
                            value.descuento
                          : 0) + acc,
                      0
                    )}{" "}
                  </span>
                </div>
                <div className=" flex flex-col gap-2">
                  <span className=" text-gray-500  text-xs">
                    Total trabajadores{" "}
                  </span>
                  <span className=" text-gray-800 font-semibold text-base text-center">
                    {dataList.length}{" "}
                  </span>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  disabled={isPlanillaSaving}
                  onClick={savePlanillaMensual}
                  class="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2  focus:outline-none "
                >
                  <MdSave className=" mr-2" />
                  Guardar estimación mensual
                </button>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TablePlanillaCurrentMonth;
