import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const EditarLink = () => {
  // Valores
  const { id } = useParams();
  const [name, setName] = useState("");
  const [elementos, setElementos] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLink = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/links/${id}`);
        const link = response.data;
        setName(link.name || "");
        setElementos(link.elemento || []);
      } catch (error) {
        console.error("Error al obtener el link:", error);
      }
    };

    if (id) {
      fetchLink();
    }
  }, [id]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDelete = async (elementoId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este elemento?"
    );

    if (confirmed) {
      try {
        const updatedElementos = elementos.filter(
          (elemento) => elemento.id !== elementoId
        );
        setElementos(updatedElementos);
        await axios.put(`${BACKEND}/api/links/${id}`, {
          name,
          elemento: updatedElementos, // Actualiza el enlace con la nueva lista de elementos
        });

        console.log("Elemento eliminado con éxito.");
      } catch (error) {
        console.error("Error al eliminar el elemento:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`${BACKEND}/api/links/${id}`, {
        name,
      });

      const linkActualizado = { ...name };
      console.log("Link actualizado:", linkActualizado);
      navigate("/home/links");
    } catch (error) {
      console.error("Error al actualizar el link:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-lg">
        <h2 className="text-2xl mb-4">Editar Link</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">
              Titulo <span className=" text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="hidden md:flex md:flex-col mb-4">
            <label htmlFor="descripcion" className="block mb-2">
              Elementos
              <Link
                to={`/home/links/editar-link/${id}/nuevo-elemento`}
                className="ml-4 px-3 py-1 text-sm  hover:bg-[#ff6400] bg-zinc-600 text-white"
              >
                Añadir
              </Link>
            </label>
            <table className="w-full max-w-4xl mx-auto border-collapse">
              <thead className="bg-zinc-600 text-white">
                <tr>
                  <th className="p-2">Título</th>
                  <th className="p-2">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {elementos.map((elemento, index) => {
                  return (
                    <tr
                      key={elemento.id}
                      className={`${
                        index % 2 === 0 ? "bg-zinc-100" : "bg-white"
                      }`}
                    >
                      <td className="border border-gray-300 p-2">
                        {elemento.name}
                      </td>
                      <td className="border font-semibold border-gray-300 p-2">
                        <Link
                          to={`/home/links/editar-link/${id}/editar-elemento/${elemento.id}`}
                          className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                        >
                          Editar
                        </Link>
                        <Link
                          to={`/home/links/editar-link/${id}/acceso-elemento/${elemento.id}`}
                          className="mx-2 my-1 text-blue-500  rounded-lg mr-2"
                        >
                          Acceso
                        </Link>
                        <button
                          onClick={() => handleDelete(elemento.id)}
                          className="mx-2 my-1 text-red-600 rounded-lg"
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end">
            <Link
              to="/home/links"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Actualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarLink;
