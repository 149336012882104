import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";
import useFetchRequestGet from "../../hooks/useFetch";

const NuevoClienteUser = () => {
  // Login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // Valores
  const [pais, setPais] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [ruc, setRuc] = useState("");
  const [nombreComercial, setNombreComercial] = useState("");
  const [fechaAniversario, setFechaAniversario] = useState("");
  const [direccion, setDireccion] = useState("");
  const [nEmpleados, setNEmpleados] = useState("");
  const [rubro, setRubro] = useState("");
  const [ingresosAnuales, setIngresosAnuales] = useState("");
  const [nombreGerente, setNombreGerente] = useState("");
  const [cumpleaniosGerente, setCumpleaniosGerente] = useState("");
  const [telefonoGerente, setTelefonoGerente] = useState("");
  const [emailGerencia, setEmailGerencia] = useState("");
  const [encargadoProyecto, setEncargadoProyecto] = useState("");
  const [telefono, setTelefono] = useState("");
  const [fechaCumpleanios, setFechaCumpleanios] = useState("");
  const [puestoTrabajo, setPuestoTrabajo] = useState("");
  const [nicho, setNicho] = useState("");
  const [currentNicho, setCurrentNicho] = useState(null);
  const [ currentRubro, setCurrentRubro ] = useState("");


  const { dataList: listRubros } = useFetchRequestGet({
    endpoint: "clientesRubros",
    page: 1,
    limit: 20,
  });

  const { dataList: listNichos } = useFetchRequestGet({
    endpoint: "clientesNichos",
    page: 1,
    limit: 50,
    query:currentRubro ? `&where[rubro][equals]=${currentRubro}` : ""
  });

  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePaisChange = (e) => {
    setPais(e.target.value);
  };

  const handleRazonSocialChange = (e) => {
    setRazonSocial(e.target.value);
  };

  const handleRucChange = (e) => {
    setRuc(e.target.value);
  };

  const handleNombreComercialChange = (e) => {
    setNombreComercial(e.target.value);
  };

  const handleFechaAniversarioChange = (e) => {
    setFechaAniversario(e.target.value);
  };

  const handleDireccionChange = (e) => {
    setDireccion(e.target.value);
  };

  const handleNEmpleadosChange = (e) => {
    setNEmpleados(e.target.value);
  };

  const handleRubroChange = (e) => {
    setRubro(e.target.value);
  };

  const handleIngresosAnualesChange = (e) => {
    setIngresosAnuales(e.target.value);
  };

  const handleNombreGerenteChange = (e) => {
    setNombreGerente(e.target.value);
  };

  const handleCumpleaniosGerenteChange = (e) => {
    setCumpleaniosGerente(e.target.value);
  };

  const handleTelefonoGerenteChange = (e) => {
    setTelefonoGerente(e.target.value);
  };

  const handleEmailGerenciaChange = (e) => {
    setEmailGerencia(e.target.value);
  };

  const handleEncargadoProyectoChange = (e) => {
    setEncargadoProyecto(e.target.value);
  };

  const handleTelefonoChange = (e) => {
    setTelefono(e.target.value);
  };

  const handleFechaCumpleaniosChange = (e) => {
    setFechaCumpleanios(e.target.value);
  };

  const handlePuestoTrabajoChange = (e) => {
    setPuestoTrabajo(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BACKEND}/api/clientesuser`, {
        email,
        password,
        pais,
        razonSocial,
        ruc,
        nombreComercial,
        fechaAniversario,
        direccion,
        nEmpleados,
        rubro,
        ingresosAnuales,
        nombreGerente,
        cumpleaniosGerente,
        telefonoGerente,
        emailGerencia,
        encargadoProyecto,
        telefono,
        fechaCumpleanios,
        puestoTrabajo,
        nicho:nicho ? nicho : null
      });

      const nuevoTrabajador = response.data;
      console.log("Trabajador añadido:", nuevoTrabajador);
      navigate("/home/clientes-user");
    } catch (error) {
      console.error("Error al añadir el usuario cliente:", error);
      setErrorMessage("Error al añadir el usuario cliente");
    }
  };

  return (
    <div className="flex items-center justify-center">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">Añadir Usuario Cliente</h2>
        {errorMessage && (
          <div className="bg-red-200 text-red-800 py-2 px-4 mb-4 rounded">
            {errorMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="razonSocial" className="block mb-2">
              Razón Social <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="razonSocial"
              value={razonSocial}
              onChange={handleRazonSocialChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">
              Correo Electrónico <span className=" text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block mb-2">
              Contraseña <span className=" text-red-500">*</span>
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="rubro" className="block mb-2">
              Rubro <span className="text-red-500">*</span>
            </label>
            <select
              type="text"
              id="rubro"
              value={currentRubro}
              onChange={(e) => setCurrentRubro(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            >
              <option value={""}>Selecciona un rubro</option>
              {listRubros.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="rubro" className="block mb-2">
              Nicho <span className="text-red-500">*</span>
            </label>
            <select
              type="text"
              id="rubro"
              value={nicho}
              onChange={(e) => { setCurrentRubro(listNichos.find((nic)=>nic.id==e.target.value)?.rubro.id); setNicho(e.target.value)}}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            >
              <option value={""}>Selecciona un nicho</option>
              {listNichos.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="pais" className="block mb-2">
              País <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="pais"
              value={pais}
              onChange={handlePaisChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="ruc" className="block mb-2">
              RUC <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="ruc"
              value={ruc}
              onChange={handleRucChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nombreComercial" className="block mb-2">
              Nombre Comercial
            </label>
            <input
              type="text"
              id="nombreComercial"
              value={nombreComercial}
              onChange={handleNombreComercialChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="fechaAniversario" className="block mb-2">
              Fecha Aniversario
            </label>
            <input
              type="date"
              id="fechaAniversario"
              value={fechaAniversario}
              onChange={handleFechaAniversarioChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="direccion" className="block mb-2">
              Direccion
            </label>
            <input
              type="text"
              id="direccion"
              value={direccion}
              onChange={handleDireccionChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nEmpleados" className="block mb-2">
              Número Empleados
            </label>
            <input
              type="text"
              id="nEmpleados"
              value={nEmpleados}
              onChange={handleNEmpleadosChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
        
          <div className="mb-4">
            <label htmlFor="ingresosAnuales" className="block mb-2">
              Ingresos Anuales
            </label>
            <input
              type="text"
              id="ingresosAnuales"
              value={ingresosAnuales}
              onChange={handleIngresosAnualesChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nombreGerente" className="block mb-2">
              Nombre Gerente
            </label>
            <input
              type="text"
              id="nombreGerente"
              value={nombreGerente}
              onChange={handleNombreGerenteChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="cumpleaniosGerente" className="block mb-2">
              Cumpleaños Gerente
            </label>


            <input
              type="date"
              id="cumpleaniosGerente"
              value={cumpleaniosGerente}
              onChange={handleCumpleaniosGerenteChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
           
          </div>
          <div className="mb-4">
            <label htmlFor="telefonoGerente" className="block mb-2">
              Teléfono Gerente
            </label>
            <input
              type="text"
              id="telefonoGerente"
              value={telefonoGerente}
              onChange={handleTelefonoGerenteChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="emailGerencia" className="block mb-2">
              Email Gerencia
            </label>
            <input
              type="email"
              id="emailGerencia"
              value={emailGerencia}
              onChange={handleEmailGerenciaChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="encargadoProyecto" className="block mb-2">
              Encargado Proyecto
            </label>
            <input
              type="text"
              id="encargadoProyecto"
              value={encargadoProyecto}
              
              onChange={handleEncargadoProyectoChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="telefono" className="block mb-2">
              Teléfono encargado de proyecto
            </label>
            <input
              type="text"
              id="telefono"
              value={telefono}
              onChange={handleTelefonoChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="fechaCumpleanios" className="block mb-2">
              Fecha Cumpleaños
            </label>


            <input
              type="date"
              id="fechaCumpleanios"
              value={fechaCumpleanios}
              required={false}
              onChange={handleFechaCumpleaniosChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            
          </div>
          <div className="mb-4">
            <label htmlFor="puestoTrabajo" className="block mb-2">
              Puesto Trabajo <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="puestoTrabajo"
              required={false}
              value={puestoTrabajo}
              onChange={handlePuestoTrabajoChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="flex justify-end">
            <Link
              to="/home/clientes-user"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Añadir
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NuevoClienteUser;
