import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const EditarArea = () => {
  // Valores
  const { id } = useParams();
  const [name, setName] = useState("");
  const [subareas, setSubareas] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchArea = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/areas/${id}`);
        const area = response.data;
        setName(area.name || "");
        setSubareas(area.subarea || []);
      } catch (error) {
        console.error("Error al obtener el area:", error);
      }
    };

    if (id) {
      fetchArea();
    }
  }, [id]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${BACKEND}/api/areas/${id}`, {
        name,
        subarea: subareas,
      });

      const areaActualizado = response.data;
      console.log("Area actualizado:", areaActualizado);
      navigate("/home/areas");
    } catch (error) {
      console.error("Error al actualizar el area:", error);
    }
  };

  const handleDelete = async (subareaId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar esta subarea?"
    );

    if (confirmed) {
      try {
        const updatedElementos = subareas.filter(
          (subarea) => subarea.id !== subareaId
        );
        setSubareas(updatedElementos);
        await axios.put(`${BACKEND}/api/areas/${id}`, {
          name,
          subarea: updatedElementos,
        });

        console.log("Subarea eliminada con éxito.");
      } catch (error) {
        console.error("Error al eliminar la subarea:", error);
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">Editar Area</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">
              Nombre <span className=" text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="hidden md:flex md:flex-col mb-4">
            <label htmlFor="descripcion" className="block mb-2">
              Subareas
              <Link
                to={`/home/areas/editar-area/${id}/nuevo-subarea`}
                className="ml-4 px-3 py-1 text-sm  hover:bg-[#ff6400] bg-zinc-600 text-white"
              >
                Añadir
              </Link>
            </label>
            <table className="w-full max-w-4xl mx-auto border-collapse">
              <thead className="bg-zinc-600 text-white">
                <tr>
                  <th className="p-2">Subarea</th>
                  <th className="p-2">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {subareas.map((subarea, index) => {
                  return (
                    <tr
                      key={subarea.id}
                      className={`${
                        index % 2 === 0 ? "bg-zinc-100" : "bg-white"
                      }`}
                    >
                      <td className="border border-gray-300 p-2">
                        {subarea.name}
                      </td>
                      <td className="border font-semibold border-gray-300 p-2">
                        <Link
                          to={`/home/areas/editar-area/${id}/editar-subarea/${subarea.id}`}
                          className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                        >
                          Editar
                        </Link>
                        <button
                          onClick={() => handleDelete(subarea.id)}
                          className="mx-2 my-1 text-red-600 rounded-lg"
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end">
            <Link
              to="/home/areas"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Actualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarArea;
