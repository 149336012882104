import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const NuevoElementoTutorial = () => {
  // Valores
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");

  const navigate = useNavigate();

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(
        `${BACKEND}/api/tutoriales/${id}`
      );
      const tutorial = response.data;
      if (!tutorial.elemento) {
        tutorial.elemento = [];
      }
      const nuevoElemento = {
        title,
        link,
        id: Date.now().toString(),
      };
      tutorial.elemento.push(nuevoElemento);
      await axios.put(`${BACKEND}/api/tutoriales/${id}`, tutorial);
      console.log("Elemento añadido:", nuevoElemento);
      navigate(`/home/tutoriales/editar-tutorial/${id}`);
    } catch (error) {
      console.error("Error al añadir el elemento:", error);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">Añadir Tutorial</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="title" className="block mb-2">
              Título <span className=" text-red-500">*</span>
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={handleTitleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="link" className="block mb-2">
              Link
            </label>
            <input
              type="text"
              id="link"
              value={link}
              onChange={handleLinkChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="flex justify-end">
            <Link
              to={`/home/tutoriales/editar-tutorial/${id}`}
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Añadir
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NuevoElementoTutorial;
