import React, { useState } from "react";
import DrawerLeft from "../ui/DrawerLeft";
import InputText from "../ui/InputText";
import SelectInput from "../ui/SelectInput";
import axios from "axios";
import { FaPlus, FaTrash } from "react-icons/fa";
import { BACKEND } from "../../config/configEnvs";
import { toast } from "react-toastify";
const NuevoFormularioDrawer = ({
  open,
  onClose,
  title,
  refetch,
  serviciosList,
}) => {
  const [formValues, setFormValues] = useState({
    servicio: "",
    tipo: "",
  });

  console.log(formValues)



  const registrarFormulario = async () => {
  
    if (formValues.servicio === "" || formValues.tipo === "" || formValues.servicio==="Selecciona una opción") {
      toast.warning("Completa los campos", {
        position: "top-left",
        style: { zIndex: 9999 },
      });
      return;
    }
    try {
      await axios.post(`${BACKEND}/api/formularioServicio`, {
        servicio:formValues.servicio,
        tipo:formValues.tipo
      });
      setFormValues({ servicio: "", tipo: "" });
    
      onClose();
      toast.success("Registrado", { position: "top-center" });
      refetch();
    } catch (error) {
      toast.error("Error al registrar", {
        position: "top-center",
        style: { zIndex: 9999 },
      });
    }
  };

  return (
    <DrawerLeft onClose={onClose} open={open} maxWidth={700} title={title}>
      <div>
        <SelectInput
          title={"Servicio"}
          setValue={(e) => setFormValues((prev) => ({ ...prev, servicio: e }))}
          value={formValues.servicio}
          options={serviciosList.map((e) => ({
            id: e.id,
            value: e.id,
            label: e.name,
          }))}
        />
      </div>

      <div className=" my-4">
          <h3 class="block mb-2 text-sm font-medium text-gray-900 ">Tipo</h3>
          <div className=" grid grid-cols-2 gap-4">
            <div className="flex items-center ps-4 border border-gray-200 rounded ">
              <input
                id="bordered-radio-1"
                type="radio"
                value={"entrada"}
                checked={formValues.tipo === "entrada"}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, tipo: e.target.value }))
                }
                name="bordered-radio"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2 "
              />
              <label
                htmlFor="bordered-radio-1"
                className="w-full py-4 ms-2 text-sm font-medium text-gray-900 "
              >
                Formulario de entrada
              </label>
            </div>
            <div className="flex items-center ps-4 border border-gray-200 rounded ">
              <input
                id="bordered-radio-2"
                type="radio"
                value={"satisfaccion"}
                checked={formValues.tipo === "satisfaccion"}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, tipo: e.target.value }))
                }
                name="bordered-radio"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2 "
              />
              <label
                htmlFor="bordered-radio-2"
                className="w-full py-4 ms-2 text-sm font-medium text-gray-900 "
              >
                Formulario de satisfaccion
              </label>
            </div>
          </div>
        </div>

     
      <div className=" mt-8 h-[1px] w-full bg-gray-200"></div>
    
      

      <div className=" flex justify-center py-6">
        <button
          type="button"
          onClick={registrarFormulario}
          className=" w-full md:max-w-xs rounded-md font-semibold p-2 text-white bg-[#FF6400] hover:bg-orange-600 text-sm  "
        >
          Registrar
        </button>
      </div>
    </DrawerLeft>
  );
};

export default NuevoFormularioDrawer;
