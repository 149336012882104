import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BACKEND } from "../config/configEnvs";

function Reglas() {
  const [valores, setValores] = useState([]);
  const [reglas, setReglas] = useState([]);
  const [sanciones, setSanciones] = useState([]);

  useEffect(() => {
    const fetchValores = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/valores`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setValores(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchValores();
  }, []);

  useEffect(() => {
    const fetchReglas = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/reglasgenerales`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setReglas(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchReglas();
  }, []);

  useEffect(() => {
    const fetchSanciones = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/sanciones`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setSanciones(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSanciones();
  }, []);

  const handleDeleteValor = async (valorId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este valor?"
    );

    if (confirmed) {
      try {
        await axios.delete(`${BACKEND}/api/valores/${valorId}`);
        const updatedValores = valores.filter((valor) => valor._id !== valorId);
        setValores(updatedValores);
      } catch (error) {
        console.error("Error al eliminar el valor:", error);
      }
    }
  };

  const handleDeleteRegla = async (reglaId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar esta regla?"
    );

    if (confirmed) {
      try {
        await axios.delete(`${BACKEND}/api/reglasgenerales/${reglaId}`);
        const updatedReglas = reglas.filter((regla) => regla._id !== reglaId);
        setReglas(updatedReglas);
      } catch (error) {
        console.error("Error al eliminar la regla:", error);
      }
    }
  };

  const handleDeleteSancion = async (sancionId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar esta sancion?"
    );

    if (confirmed) {
      try {
        await axios.delete(`${BACKEND}/api/sanciones/${sancionId}`);
        const updatedSanciones = sanciones.filter(
          (sancion) => sancion._id !== sancionId
        );
        setSanciones(updatedSanciones);
      } catch (error) {
        console.error("Error al eliminar la sancion:", error);
      }
    }
  };

  return (
    <div className="w-full flex flex-col">
      <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
        Reglas Monstruo Creativo
      </h2>
      <div className="mb-4">
        <div className="flex justify-between items-center">
          <div className="mb-4 mx-4 md:mx-8">
            <Link
              to="/home/reglas/nuevo-valor"
              className="block px-4 py-2 text-sm  hover:bg-[#ff6400] bg-zinc-600 text-white"
              role="menuitem"
            >
              Añadir Valor
            </Link>
          </div>
        </div>
        <div className="hidden md:flex px-8">
          <table className="w-full max-w-4xl mx-auto border-collapse">
            <thead className="bg-zinc-600 text-white">
              <tr>
                <th className="p-2">Valor</th>
                <th className="p-2">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {valores.map((valor, index) => {
                return (
                  <tr
                    key={valor.id}
                    className={`${
                      index % 2 === 0 ? "bg-zinc-100" : "bg-white"
                    }`}
                  >
                    <td className="border border-gray-300 p-2">{valor.name}</td>
                    <td className="border font-semibold border-gray-300 p-2">
                      <Link
                        to={`/home/reglas/editar-valor/${valor.id}`}
                        className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                      >
                        Editar
                      </Link>
                      <button
                        onClick={() => handleDeleteValor(valor.id)}
                        className="mx-2 my-1 text-red-600  rounded-lg"
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mb-4">
        <div className="flex justify-between items-center">
          <div className="mb-4 mx-4 md:mx-8">
            <Link
              to="/home/reglas/nuevo-regla"
              className="block px-4 py-2 text-sm  hover:bg-[#ff6400] bg-zinc-600 text-white"
              role="menuitem"
            >
              Añadir Regla
            </Link>
          </div>
        </div>
        <div className="hidden md:flex px-8">
          <table className="w-full max-w-4xl mx-auto border-collapse">
            <thead className="bg-zinc-600 text-white">
              <tr>
                <th className="p-2">Regla</th>
                <th className="p-2">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {reglas.map((regla, index) => {
                return (
                  <tr
                    key={regla.id}
                    className={`${
                      index % 2 === 0 ? "bg-zinc-100" : "bg-white"
                    }`}
                  >
                    <td className="border border-gray-300 p-2">{regla.name}</td>
                    <td className="border font-semibold border-gray-300 p-2">
                      <Link
                        to={`/home/reglas/editar-regla/${regla.id}`}
                        className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                      >
                        Editar
                      </Link>
                      <button
                        onClick={() => handleDeleteRegla(regla.id)}
                        className="mx-2 my-1 text-red-600  rounded-lg"
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mb-4">
        <div className="flex justify-between items-center">
          <div className="mb-4 mx-4 md:mx-8">
            <Link
              to="/home/reglas/nuevo-sancion"
              className="block px-4 py-2 text-sm  hover:bg-[#ff6400] bg-zinc-600 text-white"
              role="menuitem"
            >
              Añadir Sanción
            </Link>
          </div>
        </div>
        <div className="hidden md:flex px-8">
          <table className="w-full max-w-4xl mx-auto border-collapse">
            <thead className="bg-zinc-600 text-white">
              <tr>
                <th className="p-2">Sanción</th>
                <th className="p-2">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {sanciones.map((sancion, index) => {
                return (
                  <tr
                    key={sancion.id}
                    className={`${
                      index % 2 === 0 ? "bg-zinc-100" : "bg-white"
                    }`}
                  >
                    <td className="border border-gray-300 p-2">
                      {sancion.name}
                    </td>
                    <td className="border font-semibold border-gray-300 p-2">
                      <Link
                        to={`/home/reglas/editar-regla/${sancion.id}`}
                        className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                      >
                        Editar
                      </Link>
                      <button
                        onClick={() => handleDeleteSancion(sancion.id)}
                        className="mx-2 my-1 text-red-600  rounded-lg"
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Reglas;
