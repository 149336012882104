import React, { useEffect, useState } from "react";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { es } from "date-fns/locale";
import ItemRowCostosRegistro from "./ItemRowCostosRegistro";

const TableCostosRegistro = ({ costoMensual }) => {
  const [dataList, setDataList] = useState([]);
  const [costos, setCostos] = useState({
    precioCostosFijos: 0,
    precioCostosVariables: 0,
    precioPlanilla: 0,
    totalPrecio: 0,
  });

  useEffect(() => {
    setDataList(costoMensual?.costos);
  }, [costoMensual]);

  useEffect(() => {
    if (costoMensual.precioCostosFijos) {
      setCostos((prev) => ({
        ...prev,
        precioCostosFijos: costoMensual.precioCostosFijos,
      }));
    }else{
      setCostos((prev) => ({
        ...prev,
        precioCostosFijos: 0,
      }));
    }

    if (costoMensual.precioCostosVariables) {
      setCostos((prev) => ({
        ...prev,
        precioCostosVariables: costoMensual.precioCostosVariables,
      }));
    }else{
      setCostos((prev) => ({
        ...prev,
        precioCostosVariables: 0,
      }));
    }


    if (costoMensual.precioPlanilla) {
      setCostos((prev) => ({
        ...prev,
        precioPlanilla: costoMensual.precioPlanilla,
      }));
    }else{
      setCostos((prev) => ({
        ...prev,
        precioPlanilla: 0,
      }));
    }

    if (costoMensual.totalPrecio) {
      setCostos((prev) => ({
        ...prev,
        totalPrecio: costoMensual.totalPrecio,
      }));
    }else{
      setCostos((prev) => ({
        ...prev,
        totalPrecio: 0,
      }));
    }
  }, [costoMensual]);

  return (
    <section class="  p-3 sm:p-5">
      <div class="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div class="bg-white  relative shadow-md sm:rounded-lg overflow-hidden">
          <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div class="w-full  flex  justify-between md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center  md:space-x-3 flex-shrink-0">
              <div>
                <span className=" font-bold text-xl text-gray-600 capitalize">
                  {" "}
                  Costo de {format(new Date(), "MMMM, yyyy", { locale: es })}
                </span>
              </div>
            </div>
          </div>
          <div class="overflow-x-auto">
            <table class="w-full text-sm text-left text-gray-500 ">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                <tr>
                  <th scope="col" class="px-4 py-3">
                    Nombre
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Precio
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataList.map((e, index) => (
                  <ItemRowCostosRegistro
                  key={e.id}
                    index={index}
                    data={e}
                  />
                ))}
              </tbody>
              <tfoot className=" w-full"></tfoot>
            </table>
            <div className=" p-4  w-full ">
              <div className="flex justify-between flex-wrap gap-8 ">
                <div className=" flex flex-col gap-2">
                  <span className=" text-gray-500  text-xs">Costos fijos </span>
                  <span className=" text-gray-800 font-semibold text-base">
                    S/{" "}
                    {costos.precioCostosFijos}{" "}
                  </span>
                </div>
                <div className=" flex flex-col gap-2">
                  <span className=" text-gray-500  text-xs">Costos variables </span>
                  <span className=" text-gray-800 font-semibold text-base">
                    S/{" "}
                    {costos.precioCostosVariables}{" "}
                  </span>
                </div>

                <div className=" flex flex-col gap-2">
                  <span className=" text-gray-500  text-xs">Costos planilla </span>
                  <span className=" text-gray-800 font-semibold text-base">
                    S/{" "}
                    {costos.precioPlanilla}{" "}
                  </span>
                </div>

                <div className=" flex flex-col gap-2">
                  <span className=" text-gray-500  text-xs">Costo total </span>
                  <span className=" text-gray-800 font-semibold text-base">
                    S/{" "}
                    {costos.totalPrecio}{" "}
                  </span>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TableCostosRegistro;
