import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";
import { FaCheckCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
const EditarAnuncio = () => {
  // Valores
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [link, setLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [selecteFileForPut, setSelectedFileForPut] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAnuncio();
  }, []);

  const fetchAnuncio = async () => {
    try {
      const response = await axios.get(`${BACKEND}/api/anuncios/${id}`);
      const anuncio = response.data;
      setTitle(anuncio.title || "");
      setDescripcion(anuncio.descripcion || "");
      setLink(anuncio.link || "");
      setSelectedFileForPut(anuncio?.imagen ? anuncio?.imagen : null);
    } catch (error) {
      console.error("Error al obtener el anuncio:", error);
    }
  };
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file.size > 5 * 1000000) {
      window.alert("Archivo superior a 5mb");
      return;
    }

    await uploadImage(file);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescripcionChange = (e) => {
    setDescripcion(e.target.value);
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${BACKEND}/api/anuncios/${id}`, {
        title,
        descripcion,
        link,
      });

      const anuncioActualizado = response.data;
      console.log("Anuncio actualizado:", anuncioActualizado);
      navigate("/home/anuncios");
    } catch (error) {
      console.error("Error al actualizar el anuncio:", error);
      setErrorMessage("Error al añadir el anuncio");
    }
  };

  const removeImagePreview = async (e) => {
    e.preventDefault();
    if (!selecteFileForPut) return;

    if (!window.confirm("¿Eliminar esta imágen?")) {
      return;
    }

    try {
      await axios.delete(
        `${BACKEND}/api/anunciosImagenes/${selecteFileForPut.id}`
      );

      await axios
        .put(`${BACKEND}/api/anuncios/${id}`, { imagen: null })
        .then(() => fetchAnuncio())
        .then(() => toast.success("Imagen eliminada"));
    } catch (error) {
      setErrorMessage("Error al eliminar la imagen");
    }
  };

  const uploadImage = async (fileInput) => {
    try {
      // First request
      const formData = new FormData();
      formData.append("file", fileInput);

      const dataImage = await axios.post(
        `${BACKEND}/api/anunciosImagenes`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setSelectedFileForPut(dataImage.data.doc);
      await axios.put(`${BACKEND}/api/anuncios/${id}`, {
        imagen: dataImage.data.doc.id,
      });
      toast.success("Imagen subida");
    } catch (error) {
      // Handle errors from either the first or second request
      console.log("Error in uploadImage:", error);
      toast.error("Error al subir esta imagen", {
        style: { zIndex: 9999 },
        position: "top-center",
      });
      return null;
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">Editar Anuncio</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="title" className="block mb-2">
              Titulo <span className=" text-red-500">*</span>
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={handleTitleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="description" className="block mb-2">
              Descripción
            </label>
            <textarea
              id="descripcion"
              value={descripcion}
              rows={15}
              onChange={handleDescripcionChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>

          {selecteFileForPut ? (
            <div>
              <div>
                <div className=" flex items-center flex-col justify-center mb-4">
                  <img
                    alt="upload"
                    src={selecteFileForPut.url} // Use createObjectURL for local files
                    style={{ maxWidth: "100%", maxHeight: "300px" }}
                    className=" rounded-md object-contain"
                  />
                  <div className="mt-4">
                    <button
                      className=" text-xs p-2 rounded-full text-red-600 bg-red-600 bg-opacity-10 hover:bg-opacity-20"
                      type="button"
                      onClick={removeImagePreview}
                    >
                      Borrar Imagen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {" "}
              {/**FILE INPUT */}
              <div className="flex flex-col gap-4 items-center justify-center w-full mb-8">
                {selectedFile && selectedFileType.startsWith("image/") && (
                  <div>
                    <img
                      alt="upload"
                      src={URL.createObjectURL(selectedFile)} // Use createObjectURL for local files
                      style={{ maxWidth: "100%", maxHeight: "300px" }}
                      className=" rounded-md"
                    />
                  </div>
                )}
                {selectedFile && (
                  <div className=" w-full flex justify-center items-center gap-4">
                    <div className=" flex justify-center text-justify gap-2  items-center bg-green-50 text-green-700 rounded-md p-2">
                      <p className=" max-w-xs truncate">{selectedFile.name}</p>
                      <FaCheckCircle />
                    </div>
                    <button
                      onClick={() => {
                        setSelectedFileType("");
                        setSelectedFile(null);
                      }}
                      className="p-2 bg-red-50 rounded-md flex justify-center text-red-700 hover:text-red-800"
                    >
                      <MdClose />
                    </button>
                  </div>
                )}
                {!selectedFile && (
                  <label
                    htmlFor="dropzone-file"
                    onDrop={handleFileUpload}
                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">
                          Click para subir tu archivo
                        </span>
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG , WEBP, PDF, DOC, DOCX, XLS (MAX. 5MB)
                      </p>
                    </div>
                    <input
                      onChange={handleFileUpload}
                      id="dropzone-file"
                      accept=".pdf, .doc, .docx, .xls, image/*"
                      type="file"
                      className="hidden"
                    />
                  </label>
                )}
              </div>
            </div>
          )}

          {errorMessage && (
            <div className="bg-red-200 text-red-800 py-2 px-4 mb-4 rounded">
              {errorMessage}
            </div>
          )}
          <div className="flex justify-end">
            <Link
              to="/home/anuncios"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Actualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarAnuncio;
