import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const NuevoSubarea = () => {
  // Valores
  const { id } = useParams();
  const [name, setName] = useState("");

  const navigate = useNavigate();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(`${BACKEND}/api/areas/${id}`);
      const area = response.data;
      if (!area.subarea) {
        area.subarea = [];
      }
      const nuevoArea = {
        name,
        id: Date.now().toString(),
      };
      area.subarea.push(nuevoArea);
      await axios.put(`${BACKEND}/api/areas/${id}`, area);
      console.log("Area añadido:", nuevoArea);
      navigate(`/home/areas/editar-area/${id}`);
    } catch (error) {
      console.error("Error al añadir el area:", error);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">Añadir Subarea</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">
              Nombre
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="flex justify-end">
            <Link
              to={`/home/areas/editar-area/${id}`}
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Añadir
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NuevoSubarea;
