import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const EditarPuesto = () => {
  // Valores
  const { id, subareaId, puestoId } = useParams();
  const [name, setName] = useState("");
  const [mision, setMision] = useState("");
  // Jefe
  const [jefe, setJefe] = useState("");
  const [jefesList, setJefesList] = useState([]);
  // Supervisa a
  const [supervisa, setSupervisa] = useState("");
  const [supervisaList, setSupervisaList] = useState([]);
  // Responsabilidad
  const [responsabilidad, setResponsabilidad] = useState("");
  const [responsabilidadesList, setResponsabilidadesList] = useState([]);
  // Habilidades
  const [habilidad, setHabilidad] = useState("");
  const [habilidadesList, setHabilidadesList] = useState([]);
  // Funcion
  const [funcion, setFuncion] = useState("");
  const [funcionesList, setFuncionesList] = useState([]);
  // Formacion
  const [formacion, setFormacion] = useState("");
  const [formacionList, setFormacionList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/areas/${id}`);
        const subarea = response.data.subarea;
        const subareaData = subarea.find((p) => p.id === subareaId);
        const puesto = subareaData.puesto;
        const puestoData = puesto.find((p) => p.id === puestoId);
        if (puestoData) {
          setName(puestoData.name || "");
          setMision(puestoData.mision || "");
          setJefesList(puestoData.jefe || []);
          setSupervisaList(puestoData.supervisa || []);
          setResponsabilidadesList(puestoData.responsabilidades || []);
          setHabilidadesList(puestoData.habilidades || []);
          setFuncionesList(puestoData.funciones || []);
          setFormacionList(puestoData.formacion || []);
        }
      } catch (error) {
        console.error("Error al obtener el area:", error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, subareaId, puestoId]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  // Jefe

  const handleJefeChange = (e) => {
    setJefe(e.target.value);
  };

  const handleAddJefe = () => {
    if (jefe.trim() !== "") {
      setJefesList([...jefesList, { name: jefe }]);
      setJefe("");
    }
  };

  const handleRemoveJefe = (index) => {
    const newJefesList = [...jefesList];
    newJefesList.splice(index, 1);
    setJefesList(newJefesList);
  };

  // Supervisa

  const handleSupervisaChange = (e) => {
    setSupervisa(e.target.value);
  };

  const handleAddSupervisa = () => {
    if (supervisa.trim() !== "") {
      setSupervisaList([...supervisaList, { name: supervisa }]);
      setSupervisa("");
    }
  };

  const handleRemoveSupervisa = (index) => {
    const newSupervisaList = [...supervisaList];
    newSupervisaList.splice(index, 1);
    setSupervisaList(newSupervisaList);
  };

  // Mision

  const handleMisionChange = (e) => {
    setMision(e.target.value);
  };

  // Responsabilidades

  const handleResponsabilidadesChange = (e) => {
    setResponsabilidad(e.target.value);
  };

  const handleAddResponsabilidad = () => {
    if (responsabilidad.trim() !== "") {
      setResponsabilidadesList([
        ...responsabilidadesList,
        { name: responsabilidad },
      ]);
      setResponsabilidad("");
    }
  };

  const handleRemoveResponsabilidad = (index) => {
    const newResponsabilidadesList = [...responsabilidadesList];
    newResponsabilidadesList.splice(index, 1);
    setResponsabilidadesList(newResponsabilidadesList);
  };

  // Habilidades

  const handleHabilidadesChange = (e) => {
    setHabilidad(e.target.value);
  };

  const handleAddHabilidad = () => {
    if (habilidad.trim() !== "") {
      setHabilidadesList([...habilidadesList, { name: habilidad }]);
      setHabilidad("");
    }
  };

  const handleRemoveHabilidad = (index) => {
    const newHabilidadesList = [...habilidadesList];
    newHabilidadesList.splice(index, 1);
    setHabilidadesList(newHabilidadesList);
  };

  // Funciones

  const handleFuncionesChange = (e) => {
    setFuncion(e.target.value);
  };

  const handleAddFuncion = () => {
    if (funcion.trim() !== "") {
      setFuncionesList([...funcionesList, { name: funcion }]);
      setFuncion("");
    }
  };

  const handleRemoveFuncion = (index) => {
    const newFuncionesList = [...funcionesList];
    newFuncionesList.splice(index, 1);
    setFuncionesList(newFuncionesList);
  };

  // Formacion

  const handleFormacionChange = (e) => {
    setFormacion(e.target.value);
  };

  const handleAddFormacion = () => {
    if (formacion.trim() !== "") {
      setFormacionList([...formacionList, { name: formacion }]);
      setFormacion("");
    }
  };

  const handleRemoveFormacion = (index) => {
    const newFormacionList = [...formacionList];
    newFormacionList.splice(index, 1);
    setFormacionList(newFormacionList);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${BACKEND}/api/areas/${id}`);
      const area = response.data;
      const subareaData = area.subarea.find(
        (subarea) => subarea.id === subareaId
      );
      const updatedPuesto = subareaData.puesto.map((puesto) => {
        if (puesto.id === puestoId) {
          return {
            ...puesto,
            name,
            jefe: jefesList,
            supervisa: supervisaList,
            mision,
            responsabilidades: responsabilidadesList,
            habilidades: habilidadesList,
            funciones: funcionesList,
            formacion: formacionList,
          };
        }
        return puesto;
      });
      const updatedSubareaData = area.subarea.map((subarea) => {
        if (subarea.id === subareaId) {
          return {
            ...subarea,
            puesto: updatedPuesto,
          };
        }
        return subarea;
      });
      const updatedArea = { ...area, subarea: updatedSubareaData };

      // Send the updated data to your API endpoint using a PUT request
      await axios.put(`${BACKEND}/api/areas/${id}`, updatedArea);

      console.log("Puesto actualizado:", updatedPuesto);
      navigate(`/home/areas/editar-area/${id}/editar-subarea/${subareaId}`);
    } catch (error) {
      console.error("Error al actualizar el puesto:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-2xl">
        <h2 className="text-2xl mb-4">Editar Puesto</h2>
        <form onSubmit={handleSubmit}>
          {/* Nombre de Puesto */}
          <div className="mb-4">
            <label htmlFor="area" className="block mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>

          {/* Jefe */}
          <div className="mb-4">
            <label htmlFor="jefe" className="block mb-2">
              Jefe
            </label>
            <div className="flex flex-wrap">
              {jefesList.map((jefe, index) => (
                <div
                  key={jefe.id}
                  className="bg-gray-200 px-2 py-1 rounded-full mr-2 mb-2"
                >
                  {jefe.name}
                  <button
                    type="button"
                    className="ml-2 text-red-600"
                    onClick={() => handleRemoveJefe(index)}
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
            <input
              type="text"
              id="jefe"
              value={jefe}
              onChange={handleJefeChange}
              className="w-full mt-2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            <button
              type="button"
              onClick={handleAddJefe}
              className="mt-2 px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Agregar Jefe
            </button>
          </div>

          {/* Supervisa a */}
          <div className="mb-4">
            <label htmlFor="supervisa" className="block mb-2">
              Supervisa a
            </label>
            <div className="flex flex-wrap">
              {supervisaList.map((supervisa, index) => (
                <div
                  key={supervisa.id}
                  className="bg-gray-200 px-2 py-1 rounded-full mr-2 mb-2"
                >
                  {supervisa.name}
                  <button
                    type="button"
                    className="ml-2 text-red-600"
                    onClick={() => handleRemoveSupervisa(index)}
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
            <input
              type="text"
              id="supervisa"
              value={supervisa}
              onChange={handleSupervisaChange}
              className="w-full mt-2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            <button
              type="button"
              onClick={handleAddSupervisa}
              className="mt-2 px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Agregar Supervisa
            </button>
          </div>

          {/* Mision */}
          <div className="mb-4">
            <label htmlFor="mision" className="block mb-2">
              Mision
            </label>
            <input
              type="text"
              id="mision"
              value={mision}
              onChange={handleMisionChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>

          {/* Responsabilidades */}
          <div className="mb-4">
            <label htmlFor="responsabilidad" className="block mb-2">
              Responsabilidades
            </label>
            <table className="w-full max-w-4xl mx-auto border-collapse">
              <tbody>
                {responsabilidadesList.map((responsabilidad, index) => (
                  <tr
                    key={responsabilidad.id}
                    className="bg-gray-200 px-2 py-1 rounded-full mr-2 mb-2"
                  >
                    <td className="border text-xs border-gray-300 p-2">
                      {responsabilidad.name}
                    </td>
                    <td className="border font-semibold border-gray-300 p-2">
                      <button
                        type="button"
                        className="ml-2 text-red-600"
                        onClick={() => handleRemoveResponsabilidad(index)}
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <input
              type="text"
              id="responsabilidad"
              value={responsabilidad}
              onChange={handleResponsabilidadesChange}
              className="w-full mt-2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            <button
              type="button"
              onClick={handleAddResponsabilidad}
              className="mt-2 px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Agregar Responsabilidad
            </button>
          </div>

          {/* Habilidades */}
          <div className="mb-4">
            <label htmlFor="habilidad" className="block mb-2">
              Habilidades
            </label>
            <table className="w-full max-w-4xl mx-auto border-collapse">
              <tbody>
                {habilidadesList.map((habilidad, index) => (
                  <tr
                    key={habilidad.id}
                    className="bg-gray-200 px-2 py-1 rounded-full mr-2 mb-2"
                  >
                    <td className="border text-xs border-gray-300 p-2">
                      {habilidad.name}
                    </td>
                    <td className="border font-semibold border-gray-300 p-2">
                      <button
                        type="button"
                        className="ml-2 text-red-600"
                        onClick={() => handleRemoveHabilidad(index)}
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <input
              type="text"
              id="habilidad"
              value={habilidad}
              onChange={handleHabilidadesChange}
              className="w-full mt-2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            <button
              type="button"
              onClick={handleAddHabilidad}
              className="mt-2 px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Agregar Habilidad
            </button>
          </div>

          {/* Funciones */}
          <div className="mb-4">
            <label htmlFor="funcion" className="block mb-2">
              Funciones
            </label>
            <table className="w-full max-w-4xl mx-auto border-collapse">
              <tbody>
                {funcionesList.map((funcion, index) => (
                  <tr
                    key={funcion.id}
                    className="bg-gray-200 px-2 py-1 rounded-full mr-2 mb-2"
                  >
                    <td className="border text-xs border-gray-300 p-2">
                      {funcion.name}
                    </td>
                    <td className="border font-semibold border-gray-300 p-2">
                      <button
                        type="button"
                        className="ml-2 text-red-600"
                        onClick={() => handleRemoveFuncion(index)}
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <input
              type="text"
              id="funcion"
              value={funcion}
              onChange={handleFuncionesChange}
              className="w-full mt-2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            <button
              type="button"
              onClick={handleAddFuncion}
              className="mt-2 px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Agregar Funcion
            </button>
          </div>

          {/* Formacion */}
          <div className="mb-4">
            <label htmlFor="formacion" className="block mb-2">
              Formacion
            </label>
            <table className="w-full max-w-4xl mx-auto border-collapse">
              <tbody>
                {formacionList.map((formacion, index) => (
                  <tr
                    key={formacion.id}
                    className="bg-gray-200 px-2 py-1 rounded-full mr-2 mb-2"
                  >
                    <td className="border text-xs border-gray-300 p-2">
                      {formacion.name}
                    </td>
                    <td className="border font-semibold border-gray-300 p-2">
                      <button
                        type="button"
                        className="ml-2 text-red-600"
                        onClick={() => handleRemoveFormacion(index)}
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <input
              type="text"
              id="formacion"
              value={formacion}
              onChange={handleFormacionChange}
              className="w-full mt-2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            <button
              type="button"
              onClick={handleAddFormacion}
              className="mt-2 px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Agregar Formacion
            </button>
          </div>

          {/* Botones */}
          <div className="flex justify-end">
            <Link
              to={`/home/areas/editar-area/${id}/editar-subarea/${subareaId}`}
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Actualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarPuesto;
