import React from "react";
import { FaEye, FaTrash } from "react-icons/fa";

const PreguntaFormulario = ({ data, openPregunta, eliminarPregunta }) => {
  return (
    data && (
      <div
        className={` cursor-pointer ${
          data.tipo === "respuesta"  ? "hover:bg-blue-50" : " hover:bg-green-50"
        } bg-white  transition duration-300  p-3 rounded-md shadow-md flex flex-col justify-between z-10`}
      >
        <div>
        <div className=" pb-4">
          <span
            className={` bg-white ${
              data.tipo === "respuesta" ? "text-blue-600 " : " text-green-500"
            } rounded-md  p-1  text-xs font-semibold shadow-md`}
          >
            {data.tipo === "respuesta" && "Respuesta única"}{" "}
            {data.tipo === "alternativas" && "Alternativas"}
            {data.tipo === "seleccionMultiple" && "Seleccion múltiple"}

          </span>
        </div>
        <div>
          <h2 className=" text-gray-700 font-semibold text-base">
            {data.pregunta}
          </h2>
        </div>

        {data.tipo==="alternativas" && <div className=" py-4 flex gap-2 items-center flex-wrap "> 
        {data?.alternativas?.map((e)=><div className=" text-xs  bg-green-50 p-1 rounded-sm text-green-700 shadow-md" key={e.id}>{e.respuesta}</div>)}
        </div>}
        </div>

        <div className=" w-full md:grid md:grid-cols-2 flex flex-col gap-2 mt-4 z-50">
          <button
            type="button"
            onClick={openPregunta}
            className={`w-full flex justify-center text-sm z-20 ${
              data.tipo === "respuesta"  ? " bg-blue-700 text-white hover:bg-blue-800" : " bg-green-500 text-white hover:bg-green-600"
            } font-semibold p-2 rounded-md items-center gap-2 `}
          >
            Detalles
            <FaEye />
          </button>
          <button
            type="button"
            onClick={eliminarPregunta}
            className="w-full flex justify-center text-sm z-20 text-gray-800 border hover:bg-white  border-gray-500 font-semibold p-2 rounded-md items-center gap-2 "
          >
            Eliminar
            <FaTrash />
          </button>
        </div>
      </div>
    )
  );
};

export default PreguntaFormulario;
