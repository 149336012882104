import { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND } from "../config/configEnvs";

const useFetchRequestGet = ({ query, page = 1, endpoint, limit = 15 }) => {
  const [fetchStatus, setFetchStatus] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });
  const [dataList, setDataList] = useState([]);
  const [metaData, setMetaData] = useState({
    totalPages: 0,
    page: 0,
    hasPrevPage: false,
    hasNextPage: false,
    totalDocs: 0,
  });
  const fetchRequest = async () => {
    setFetchStatus({ isLoading: true, isSuccess: false, isError: false });
    try {
      const response = await axios.get(
        `${BACKEND}/api/${endpoint}?limit=${Number(limit)}&page=${page}${query ? query : ""}`
      );


     if(response.data.docs){
        setDataList(response.data.docs)
        setMetaData({
          totalPages: response.data.totalPages,
          page: response.data.page,
          hasNextPage: response.data.hasNextPage,
          hasPrevPage: response.data.hasPrevPage,
          totalDocs: response.data.totalDocs,
        });
     }
      setFetchStatus((prev) => ({ ...prev, isSuccess: true }));
    } catch (err) {
        setFetchStatus((prev) => ({ ...prev, isError: true }));

      console.error("Error:", err);
    } finally {
        setFetchStatus((prev) => ({ ...prev, isLoading: true }));
    }
  };

  useEffect(() => {
    fetchRequest();
  }, [query]);

  return {dataList,metaData,fetchStatus,refetch:fetchRequest}
};

export default useFetchRequestGet;
