import React, { useEffect, useState } from "react";
import KanBanListColumn from "./KanBanListColumn";
import axios from "axios";
import Datepicker from "react-tailwindcss-datepicker";

import { BACKEND } from "../../config/configEnvs";
const KanBanContainer = () => {
  const [trabajadoresList, setTrabajadoresList] = useState([]);

  const [filterForms, setFilterForms] = useState({
    trabajador: null,

    fecha: {
      startDate: null,
      endDate: null,
    },
    fechaType: "",
  });
  const getTrabajadores = async () => {
    try {
      const response = await axios.get(
        `${BACKEND}/api/trabajadores?limit=1000`
      );
      setTrabajadoresList(response.data.docs);
    } catch (error) {}
  };

  useEffect(() => {
    getTrabajadores();
  }, []);

  return (
    <div>
      <div className=" h-[200vh] md:h-screen p-0 md:p-2">
        <div className="flex gap-4 justify-around flex-wrap pb-2">
          <div>
            <select
              id="vendedor"
              style={{ maxWidth: "200px" }}
              value={filterForms.trabajador}
              onChange={(e) => {
                setFilterForms((prev) => ({
                  ...prev,
                  trabajador: e.target.value,
                }));
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 "
            >
              <option value={""}> Todos</option>
              {trabajadoresList.map((e) => (
                <option value={e.id} key={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </div>

          <div className=" grid grid-cols-2 gap-2">
            <div className=" flex justify-center">
              <div>
                <select
                  value={filterForms.fechaType}
                  onChange={(e) =>
                    setFilterForms((prev) => ({
                      ...prev,
                      fechaType: e.target.value,
                    }))
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 "
                >
                  <option value={"less_than"}>Menor a </option>
                  <option value={"equals"}>Igual a</option>
                  <option value={"greater_than"}>Mayor a </option>
                </select>
              </div>
            </div>
            <div>
              <div>
                <Datepicker
                  popoverDirection={"up"}
                  toggleClassName="absolute bg-blue-300     rounded-r-lg text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
                  inputClassName={
                    "   rounded-md bg-gray-50  w-full  z-[50] border border-gray-300  py-2  pl-4 pr-20 text-gray-700 "
                  }
                  inputId="fechaInicio"
                  containerClassName={"z-[0]   relative"}
                  useRange={false}
                  asSingle={true}
                  classNames={" z-[9999]"}
                  placeholder={"Fecha"}
                  value={filterForms.fecha}
                  onChange={(e) =>
                    setFilterForms((prev) => ({ ...prev, fecha: e }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5 h-full">
          {/* <!-- To-do --> */}
          <KanBanListColumn
            title={"Pendientes"}
            estado={"Pendiente"}
            colour={"#fde6b2"}
            filterForms={filterForms}
          />

          <KanBanListColumn
            title={"En proceso"}
            estado={"En proceso"}
            colour={"#dee9fa"}
            filterForms={filterForms}
          />

          <KanBanListColumn
            title={"Finalizado"}
            estado={"Finalizado"}
            colour={"#def7ec"}
            filterForms={filterForms}
          />

          <KanBanListColumn
            title={"Incumplido"}
            estado={"Incumplido"}
            colour={"#fde8e8"}
            filterForms={filterForms}
          />
        </div>
      </div>
    </div>
  );
};

export default KanBanContainer;
