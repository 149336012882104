import axios from "axios"
import { toast } from "react-toastify"
import { BACKEND } from "../../config/configEnvs"
import { FaAudioDescription, FaCheck, FaExternalLinkAlt, FaLink, FaMicrophone, FaPlay, FaPlus, FaRegSquare, FaSave, FaStop, FaTrash, FaUpload } from "react-icons/fa"
import { useEffect, useRef, useState } from "react"
import Modal from "../ui/Modal"

const TaskRow = ({task, workers, templates, objectives, successUpdate}) => {

    const [taskName, setTaskName] = useState(task?.name)

    const [taskLabel, setTaskLabel] = useState(task?.label || null)

    const [audiosModal, setAudiosModal] = useState(false)
    const [linksModal, setLinksModal] = useState(false)

    // Estado inicial para el estado de visualización del cliente
    const [initialStatusVisibleToClient, setInitialStatusVisibleToClient] = useState(task.visibleToClient)
    const [visibleToClient, setVisibleToClient] = useState(task.visibleToClient)

    // Estado inicial para las pruebas de la tarea
    const [initialStatusProofNeeded, setInitialStatusProofNeeded] = useState(task.isProofNeeded)
    const [isProofNeeded, setIsProofNeeded] = useState(task.isProofNeeded)

    // Estado para el checkbox de visita de audiovisual
    const [initialStatusIsVisit, setInitialStatusIsVisit] = useState(task.isVisit)
    const [isVisit, setIsVisit] = useState(task.isVisit)

    // Estado para ell checkbox de reunión
    const [initialStatusIsMeeting, setInitialStatusIsMeeting] = useState(task.isMeeting)
    const [isMeeting, setIsMeeting] = useState(task.isMeeting)

    // Estado para los links
    const [links, setLinks] = useState(task.links ? task.links : []); // Estado para los links
    const [audios, setAudios] = useState(task.audios ? task.audios : [])
    const [newLink, setNewLink] = useState(""); // Estado para el nuevo link

    const addNewLink = () => {
        setLinks(prevLinks => [...prevLinks, { link: newLink }]);
        setNewLink("");
    };

    const handleLinkChange = (index, event) => {
        const newLinks = [...links];
        newLinks[index] = { link: event.target.value }; 
        setLinks(newLinks);
    };

    const saveLink = async (index) => {
        const updatedLink = links[index].link;
        const isNewLink = !links[index].id; // Verifica si es un nuevo link
    
        if (isNewLink) {
            try {
                // Envía la solicitud de actualización a la API
                const response = await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                    links: links // Envía el array completo de links
                });
                console.log(response);
    
                // Si se subió correctamente, actualiza el estado local
                setLinks(prevLinks => {
                    const newLinks = [...prevLinks];
                    newLinks[index].id = response.data.id; // Agrega el ID generado si es un nuevo link
                    return newLinks;
                });
    
                toast.success("Link subido correctamente");
                successUpdate();
            } catch (error) {
                toast.error("Error guardando el nuevo link: " + error.message);
            }
        } else {
            try {
                // Actualiza el link existente
                const updatedLinks = task.links.map(link => 
                    link.id === links[index].id ? { ...link, link: updatedLink } : link
                );
    
                // Envía la solicitud de actualización a la API
                await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                    links: updatedLinks
                });
    
                toast.success("Link actualizado correctamente");
                successUpdate();
    
                // Actualiza el estado local
                setLinks(prevLinks => {
                    const newLinks = [...prevLinks];
                    newLinks[index] = { ...newLinks[index], link: updatedLink };
                    return newLinks;
                });
            } catch (error) {
                toast.error("Error subiendo el link: " + error.message);
            }
        }
    };

    const removeLinkField = async (index) => {
        const linkToRemove = links[index];
        const isUploadedLink = linkToRemove.id;

        const updatedLinks = task.links.filter((link) => link.id !== linkToRemove.id); 
    
        if (isUploadedLink) {
            try {
                await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                    links: updatedLinks
                });
                successUpdate()
            } catch (error) {
                toast.error("Error eliminando el link: " + error.message);
                return;
            }
        }
        setLinks((prevLinks) => prevLinks.filter((_, i) => i !== index));
    };
    
    const handleProovesChange = (e) => {
        setIsProofNeeded(e.target.checked);
    };

    const handleVisibleToClientChange = (e) => {
        setVisibleToClient (e.target.checked)
    }

    const handleMeetingChange = (e) => {
        setIsMeeting(e.target.checked)
    }

    const handleVisitChange = (e) => {
        setIsVisit(e.target.checked)
    }

    const updateIsVisibleToClientCheckbox = async () => {
        if (isVisit !== initialStatusIsVisit) {
            try {
                await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                    visibleToClient: visibleToClient
                })
                successUpdate()
                toast.success("Tarea actualizada correctamente")
                setInitialStatusVisibleToClient(visibleToClient)
            } catch (error) {
                console.log(error)
                toast.error("Error al actualizar la tarea: " + error)
            }
        }
    }

    const updateIsProoveNeededCheckbox = async () => {
        if (isProofNeeded !== initialStatusProofNeeded) {
            try {
                await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                    isProofNeeded: isProofNeeded
                })
                successUpdate()
                toast.success("Tarea actualizada correctamente")
                setInitialStatusProofNeeded(isProofNeeded)
            } catch (error) {
                console.log(error)
                toast.error("Error al actualizar la tarea: " + error)
            }
        }
    }

    const updateIsmeetingCheckbox = async () => {
        if (isMeeting !== initialStatusIsMeeting) {
            try {
                await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                    isMeeting: isMeeting
                })
                successUpdate()
                toast.success("Tarea actualizada correctamente")
                setInitialStatusIsMeeting(isMeeting)
            } catch (error) {
                console.log(error)
                toast.error("Error al actualizar la tarea: " + error)
            }
        }
    }

    const updateIsVisitCheckbox = async () => {
        if (isVisit != initialStatusIsVisit) {
            try {
                await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                    isVisit: isVisit
                })
                successUpdate()
                toast.success("Tarea actualizada correctamente")
                setInitialStatusIsVisit(isVisit)
            } catch (error) {
                console.log(error)
                toast.error("Error al actualizar la tarea: " + error)
            }
        }
    }

    const updateTask = async () => {
        try {
            await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                name: taskName,
                label: taskLabel
            })
            successUpdate()
        } catch (error) {
            toast.error("Error actualizando la tarea: " + error)
        }
    }

    const updateDefaultResponsibleTask = async (defaultResponsibleSelected) => { // Actualiza al responsable de la tarea
        try {
            await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                defaultResponsible: defaultResponsibleSelected
            })
            successUpdate()
            toast.success("Tarea actualizada correctamente")
        } catch (error) {
            toast.error("Error actualizando la tarea: " + error)
        }
    }

    const updateDefaultScoreAssignedTo = async (defaultScoreAssignedTo) => {
        try {
            await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                defaultScoreAssignedTo: defaultScoreAssignedTo
            })
            successUpdate()
            toast.success("Tarea actualizada correctamente")
        } catch (error) {
            toast.error("Error actualizando la tarea: " + error)
        }
    }

    const updateScore = async (score) => {
        try {
            await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                score: score
            })
            successUpdate()
            toast.success("Tarea actualizada correctamente")
        } catch (error) {
            toast.error("Error actualizando la tarea: " + error)
        }
    }

    const updateDefaultObjectiveTask = async (defaultObjectiveSelected) => { // Actualiza el objetivo de la tarea
        try {
            await axios.put(`${BACKEND}/api/tareas/${task.id}`,  {
                defaultObjective: defaultObjectiveSelected
            })
            successUpdate()
            toast.success("Tarea actualizada correctamente")
        } catch (error) {
            toast.error("Error actualizando la tarea: " + error)
        }
    }

    const updateDefaultServiceTask = async (defaultServiceSelected) => { // Actualiza el servicio de la tarea
        try {
            await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                defaultService: defaultServiceSelected
            })
            successUpdate()
            toast.success("Tarea actualizada correctamente")
        } catch (error) {
            toast.error("Error actualizando la tarea: " + error)
        }
    }

    const deleteTask = async () => {
        try {
            const dependantTasks = await axios.get(`${BACKEND}/api/tareasProyectos?where[task][equals]=${task.id}`)
            if (dependantTasks.data.docs.length > 0) {
                toast.warning("Hay una o más tareas creadas que dependen de esta plantilla, no es posible eliminar. Si deseas que esta tarea no aparezca en los nuevos procesos selecciona el campo " + "Planilla" + " de la tarea en null")
            } else {
                await axios.delete(`${BACKEND}/api/tareas/${task.id}`)
                successUpdate()
                toast.success("Tarea eliminada correctamente")
            }
        } catch (error) {
            toast.error("Error al eliminar la tarea: " + error )
        } 
    }

    // Estado para las grabaciones de audio
    const [isRecording, setIsRecording] = useState(false);
    const [uploadStatus, setUploadStatus] = useState("")

    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    const startRecording = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream) => {
                    const mediaRecorder = new MediaRecorder(stream);
                    mediaRecorderRef.current = mediaRecorder;
                    mediaRecorder.start();
            
                    setIsRecording(true);  // Cambia el estado para indicar que está grabando
            
                    mediaRecorder.ondataavailable = (event) => {
                        audioChunksRef.current.push(event.data);
                    };
            
                    mediaRecorder.onstop = async () => {
                        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                        const fileName = generateFileName();
                        
                        const audioFile = new File([audioBlob], fileName, { type: 'audio/wav' });
                        setIsRecording(false);
            
                        if (audioFile.size <= 5000000 && audios.length < 5) {
                            const formData = new FormData();
                            formData.append("file", audioFile);
            
                            try {
                                const response = await axios.post(
                                    `${BACKEND}/api/audiosTareas`,
                                    formData,
                                    {
                                        headers: {
                                            "Content-Type": "multipart/form-data",
                                        },
                                    }
                                );

                                if (response.data && response.data.doc.id) {
                                    const audiosId = audios.map((audio) => audio.id )
                                    const updatedTask = await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                                        audios: [...audiosId, response.data.doc.id]
                                    });
                                    
                                    setAudios(updatedTask.data.doc.audios);
                                    successUpdate()
                                }
                            } catch (error) {
                                toast.error("Error subiendo el audio: " + error.message);
                            }
                        } else {
                            toast.warning('El archivo de audio excede el tamaño máximo de 5MB o se alcanzó el límite de 5 archivos.');
                        }
            
                        audioChunksRef.current = [];
                    };
                })
                .catch((error) => {
                    toast.error('Error: ' + error);
                });
        } else {
            toast.error('Tu navegador no soporta la grabación de audio.');
        }
    };
    
    // Función para detener la grabación
    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();  // Detiene la grabación
            setIsRecording(false);  // Cambia el estado para indicar que se dejó de grabar
        }
    };
    
    // Función para generar un nombre de archivo único
    const generateFileName = () => {
        return `audio_${new Date().getTime()}.wav`;
    };

    const deleteAudio = async (id) => {
        const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar este audio?");
        if (!confirmDelete) return;
    
        try {

            const deletedAudio = await axios.delete(`${BACKEND}/api/audiosTareas/${id}`);
    
            if (deletedAudio.data.id) {
                const updatedAudios = audios
                .filter(audio => audio.id !== id)
                .map(audio => audio.id); 
                
                const updatedTasks = await axios.put(`${BACKEND}/api/tareas/${task.id}`, {
                    audios: updatedAudios
                });
                successUpdate()
                setAudios(updatedTasks.data.doc.audios)
            }
        } catch (error) {
            console.log(error)
            toast.error("Error eliminando el audio");
        }
    };

    useEffect(() => {
        updateIsVisibleToClientCheckbox()
    }, [visibleToClient])

    useEffect (() => {
        updateIsProoveNeededCheckbox()
    }, [isProofNeeded])

    useEffect( () => {
        updateIsmeetingCheckbox()
    }, [isMeeting])

    useEffect( () => {
        updateIsVisitCheckbox()
    }, [isVisit])
    
    return (
        <tr
            key={task?.id}
            className="hover:bg-gray-100 transition-colors duration-100"
        >

            {/* Modal de links */}
            <Modal
                activeModal={linksModal}
                title={`Links: ${task.name}`}
                onClose={() => setLinksModal(false)}
                centered={true}
            >
                {/* Mostrar los links subidos */}
                {links?.length > 0 ? (
                    <ul className="list-disc">
                        {links.map((link, index) => (
                            <div key={index} className="flex border rounded-lg mb-3">
                                <button
                                    type="button"
                                    onClick={() => saveLink(index)}
                                    className="inline-flex items-center px-3 text-sm text-green-600 bg-green-100 border border-gray-100 hover:bg-green-200 transition-all duration-100  rounded-l-md"
                                >
                                    <FaSave />
                                </button>
                                <input
                                    type="text"
                                    value={link.link}
                                    placeholder="Ingrese un link..."
                                    onChange={(event) => handleLinkChange(index, event)}
                                    className="flex w-full justify-center items-center border border-gray-100 px-2 py-2"
                                />
                                <button
                                    type="button"
                                    onClick={() => removeLinkField(index)}
                                    className="inline-flex items-center px-3 text-sm text-red-600 bg-red-100 border border-gray-100 hover:bg-red-200 transition-all duration-100 rounded-r-md"
                                >
                                    <FaTrash />
                                </button>
                            </div>
                        ))}
                    </ul>
                ) : (
                    <div className="flex justify-center items-center h-[50px] border rounded-md">
                        No hay links subidos
                    </div>
                )}

                {/* Sección para subir nuevos links */}
                <div className="mt-4">
                    {links.length < 5 ? (
                        <button
                            onClick={addNewLink}  // Función para subir un nuevo link
                            className="flex w-full justify-center items-center gap-2 bg-white hover:bg-blue-200 transition-all duration-100 text-white px-4 py-2 rounded-lg"
                        >
                            <span className="text-blue-700">Crear nuevo link</span>
                            <FaPlus className="text-blue-700"/>
                        </button>
                    ) : (
                        <div className="flex w-full justify-center items-center text-red-500">
                            Ya has subido el máximo de 5 links
                        </div>
                    )}
                </div>
            </Modal>

            {/* Modal de audios */}
            <Modal
                activeModal={audiosModal}
                title={`Audios: ${task.name}`}
                onClose={() => setAudiosModal(false)}
                centered={true}
            >
                {audios.length > 0 ? (
                    <ul className="list-disc">
                        {audios.map((audio, index) => {
                            const id = audio.id;
                            const url = audio.url;
                            const name = audio.filename;
                            const mymeType = audio.mimeType;
                            const fileType = audio.mimeType?.split('/')[0];

                            return (
                                <div key={index} className="border p-3 w-full rounded-md shadow hover:bg-gray-50 transition-all duration-100 mb-4">
                                    <div>
                                        {fileType === 'video' && (
                                            <div className="items-centerspace-y-6">
                                                <div className="flex items-center justify-between">
                                                    <span className="mb-2">{name}</span>
                                                    <div className="flex space-x-2">
                                                        <button
                                                            type="button"
                                                            onClick={() => window.open(url, '_blank')}
                                                            className="p-2 text-blue-500 hover:bg-blue-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-blue-700 bg-opacity-10 flex items-center"
                                                        >
                                                            <FaExternalLinkAlt />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => deleteAudio(id)}
                                                            className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                                                        >
                                                            <FaTrash />
                                                        </button>
                                                    </div>
                                                </div>
                                                <video controls className="w-full h-auto rounded">
                                                    <source src={url} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        )}

                                        {fileType === 'audio' && (
                                            <div className="items-center justify-center space-y-6">
                                                <div className="flex items-center justify-between">
                                                    <span className="mb-2">{name}</span>
                                                    <div className="flex space-x-2">
                                                        <button
                                                            type="button"
                                                            onClick={() => window.open(url, '_blank')}
                                                            className="p-2 text-blue-500 hover:bg-blue-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-blue-700 bg-opacity-10 flex items-center"
                                                        >
                                                            <FaExternalLinkAlt />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => deleteAudio(id)}
                                                            className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                                                        >
                                                            <FaTrash />
                                                        </button>
                                                    </div>
                                                </div>
                                                <audio controls className="w-full max-h-12">
                                                    <source src={url} type={mymeType} />
                                                    Your browser does not support the audio element.
                                                </audio>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </ul>
                )   :   (
                    <div className="flex justify-center items-center h-[50px] border rounded-md">
                        No hay audios subidos
                    </div>
                )}

                <div className="mt-4">
                    {audios.length < 5 ? (
                        <button
                            onClick={isRecording ? stopRecording : startRecording}
                            className={`flex w-full justify-center items-center gap-2 transition-all duration-100 text-white px-4 py-2 rounded-lg 
                            ${isRecording ? 'bg-red-500 hover:bg-red-600' : 'bg-white hover:bg-green-200'}`}
                        >
                            {isRecording ? (
                                <>
                                    <span className="text-white">Detener grabación</span>
                                    <FaStop className="text-white" />
                                </>
                            ) : (
                                <>
                                    <span className="text-green-700">Grabar nuevo audio</span>
                                    <FaMicrophone className="text-green-700" />
                                </>
                            )}
                        </button>
                    ) : (
                        <div className="flex w-full justify-center items-center text-red-500">
                            Ya has subido el máximo de 5 audios
                        </div>
                    )}
                </div>
            </Modal>

            
            <td className="justify-center border border-gray-300 w-[5%] truncate">
                <div className="flex flex-row gap-4 items-center justify-center ">

                    <div className="flex items-center justify-center">
                        <a
                            onClick={() => deleteTask()}
                            className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                            >
                            <FaTrash />
                        </a>
                    </div>

                    <div className="flex items-center justify-center">
                        <a
                            onClick={() => updateTask()}
                            className="p-2 text-green-500 hover:bg-green-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-green-700 bg-opacity-10 flex items-center"
                            >
                            <FaSave />
                        </a>
                    </div>

                </div>
            </td>

            <td className="justify-center border border-gray-300 w-[30%] truncate">
                <input 
                    type="text" 
                    value={taskName}
                    onChange={(e) => setTaskName(e.target.value)}
                    className="w-full border-white rounded-full"
                />
            </td>

            <td className="justify-center border border-gray-300 w-[15%] truncate">
                <input 
                    type="text" 
                    value={taskLabel}
                    onChange={(e) => setTaskLabel(e.target.value)}
                    className="w-full border-white rounded-full"
                />
            </td>

            <td className="justify-center border border-gray-300 w-[15%] truncate">
                <select
                    id="defaultResponsible"
                    name="defaultResponsible"
                    className="w-full border-white rounded-full"
                    value={task?.defaultResponsible?.id ? task?.defaultResponsible?.id : ""} 
                    onChange={(e) => {
                        const selectedWorkerId = e.target.value;
                        updateDefaultResponsibleTask(selectedWorkerId);
                    }}
                >
                    <option 
                        key={""} 
                        value={""}
                    >
                        Seleccionar Trabajador
                    </option>
                    {workers.map((worker) => (
                        <option 
                            key={worker.id} 
                            value={worker.id}
                        >
                            {worker.name} - {worker.puesto}
                        </option>
                    ))}
                </select>
            </td>

            <td className="justify-center border border-gray-300 w-[8%] truncate">
                <select
                    id="defaultService"
                    name="defaultService"
                    className="w-full border-white rounded-full"
                    value={task?.defaultService}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        updateDefaultServiceTask(newValue)
                    }}
                >
                    <option value="">Seleccionar plantilla</option>
                    {templates.map((template) => (
                        <option value={template}>{template}</option>
                    ))}
                </select>
            </td>

            <td className="justify-center border border-gray-300 w-[10%] truncate">
                <select
                    id="defaultObjective"
                    name="defaultObjective"
                    className="w-full border-white rounded-full"
                    value={task?.defaultObjective}  
                    disabled={!task.defaultService}
                    onChange={(e) => {
                        const selectedObjective = e.target.value; 
                        updateDefaultObjectiveTask(selectedObjective); 
                    }}
                >
                    <option value="">Seleccionar objetivo</option>
                    {objectives
                        .filter((objective) => objective.defaultService === task.defaultService)
                        .map((objective) => (
                            <option 
                                key={objective.id} 
                                value={objective.name}
                            >
                                {objective.name} - {objective.defaultService}
                            </option>
                        ))
                    }
                </select>
            </td>

            <td className="justify-center border border-gray-300 w-[5%] truncate">
                <select
                    id="score"
                    name="score"
                    className="w-full border-white rounded-full"
                    value={task?.score ? task?.score : 0} 
                    onChange={(e) => {
                        updateScore(e.target.value);
                    }}
                >
                    <option key={"5"} value={5} > 5 </option>
                    <option key={"4"} value={4} > 4 </option>
                    <option key={"3"} value={3} > 3 </option>
                    <option key={"2"} value={2} > 2 </option>
                    <option key={"1"} value={1} > 1 </option>
                    <option key={"0"} value={0} > 0 </option>
                    <option key={"-1"} value={-1} > -1 </option>
                    <option key={"-2"} value={-2} > -2 </option>
                    <option key={"-3"} value={-3} > -3 </option>
                    <option key={"-4"} value={-4} > -4 </option>
                    <option key={"-5"} value={-5} > -5 </option>

                </select>
            </td> 

            <td className="justify-center border border-gray-300 w-[15%] truncate">
                <select
                    id="scoreAssignedTo"
                    name="scoreAssignedTo"
                    className="w-full border-white rounded-full"
                    value={task?.defaultScoreAssignedTo?.id ? task?.defaultScoreAssignedTo?.id : ""} 
                    onChange={(e) => {
                        const selectedWorkerId = e.target.value;
                        updateDefaultScoreAssignedTo(selectedWorkerId);
                    }}
                >
                    <option 
                        key={""} 
                        value={""}
                    >
                        Seleccionar Trabajador
                    </option>
                    {workers.map((worker) => (
                        <option 
                            key={worker.id} 
                            value={worker.id}
                        >
                            {worker.name} - {worker.puesto}
                        </option>
                    ))}
                </select>
            </td>

            <td className="justify-center border border-gray-300 w-[5%] truncate">
                {links.length > 0 ? (
                    <div className="flex items-center justify-center">
                        <button
                            className="flex items-center justify-center px-2 py-2 rounded-lg bg-blue-500 transition-all duration-300 mx-auto"
                            style={{ width: '25px', height: '25px' }}
                            onClick={() => setLinksModal(true)}  // Función para manejar la acción del botón
                        >
                            <FaLink className="text-white" />
                        </button>
                    </div>
                ) : (
                    <div className="flex items-center justify-center">
                        <button
                            className="flex items-center justify-center px-2 py-2 rounded-lg bg-blue-500 opacity-60 transition-all duration-300 mx-auto"
                            style={{ width: '25px', height: '25px' }}
                            onClick={() => (setLinksModal(true))}  // Función para manejar la acción del botón
                        >
                            <FaUpload className="text-white" />
                        </button>
                    </div>
                )}
            </td>

            <td className="justify-center border border-gray-300 w-[5%] truncate">
                {audios.length > 0 ? (
                    <div className="flex items-center justify-center">
                        <button
                            className="flex items-center justify-center px-2 py-2 rounded-lg bg-green-500 transition-all duration-300 mx-auto"
                            style={{ width: '25px', height: '25px' }}
                            onClick={() => setAudiosModal(true)}  // Función para manejar la acción del botón
                        >
                            <FaPlay className="text-white" />
                        </button>
                    </div>
                ) : (
                    <button
                        className="flex items-center justify-center px-2 py-2 rounded-lg bg-green-500 opacity-60 transition-all duration-300 mx-auto"
                        style={{ width: '25px', height: '25px' }}
                        onClick={() => setAudiosModal(true)}  // Función para manejar la acción del botón
                        >
                        <FaUpload className="text-white" />
                    </button>
                )}
            </td>

            <td className="justify-center border border-gray-300 w-[5%] truncate">
                <label className="flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={isProofNeeded}
                        onChange={handleProovesChange}
                        className="hidden"
                    />
                    <div
                        className={`flex items-center justify-center px-2 py-2 rounded-lg transition-all duration-300 mx-auto ${
                            isProofNeeded ? 'bg-orange-500' : 'bg-gray-200'
                        }`}
                        style={{ width: '25px', height: '25px' }}
                    >
                        {isProofNeeded ? <FaCheck className="text-white" /> : <FaRegSquare className="text-gray-700" />}
                    </div>
                </label>
            </td>

            <td className="justify-center border border-gray-300 w-[5%] truncate">
                <label className="flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={isMeeting}
                        onChange={handleMeetingChange}
                        className="hidden"
                    />
                    <div
                        className={`flex items-center justify-center px-2 py-2 rounded-lg transition-all duration-300 mx-auto ${
                            isMeeting ? 'bg-orange-500' : 'bg-gray-200'
                        }`}
                        style={{ width: '25px', height: '25px' }}
                    >
                        {isMeeting ? <FaCheck className="text-white" /> : <FaRegSquare className="text-gray-700" />}
                    </div>
                </label>
            </td>

            <td className="justify-center border border-gray-300 w-[5%] truncate">
                <label className="flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={isVisit}
                        onChange={handleVisitChange}
                        className="hidden"
                    />
                    <div
                        className={`flex items-center justify-center px-2 py-2 rounded-lg transition-all duration-300 mx-auto ${
                            isVisit ? 'bg-orange-500' : 'bg-gray-200'
                        }`}
                        style={{ width: '25px', height: '25px' }}
                    >
                        {isVisit ? <FaCheck className="text-white" /> : <FaRegSquare className="text-gray-700" />}
                    </div>
                </label>
            </td>

            <td className="justify-center border border-gray-300 w-[5%] truncate">
                <label className="flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={visibleToClient}
                        onChange={handleVisibleToClientChange}
                        className="hidden"
                    />
                    <div
                        className={`flex items-center justify-center px-2 py-2 rounded-lg transition-all duration-300 mx-auto ${
                            visibleToClient ? 'bg-orange-500' : 'bg-gray-200'
                        }`}
                        style={{ width: '25px', height: '25px' }}
                    >
                        {visibleToClient ? <FaCheck className="text-white" /> : <FaRegSquare className="text-gray-700" />}
                    </div>
                </label>
            </td>     
        </tr>
    )
}

export default TaskRow