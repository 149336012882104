import React, { useEffect, useState } from "react";
import NuevoServicioDrawer from "../blockComponents/drawers/NuevoServicioDrawer";
import { FaChevronLeft, FaChevronRight, FaCogs, FaEye, FaPlus, FaWpforms } from "react-icons/fa";
import axios from "axios";
import { BACKEND } from "../config/configEnvs";
import EditarServicioDrawer from "../blockComponents/drawers/EditarServicioDrawer";
import { Link } from "react-router-dom";
import NuevoFormularioDrawer from "../blockComponents/drawers/NuevoFormularioDrawer";
import {toast} from "react-toastify"
const Formularios = () => {
  const [planesList, setPlanesList] = useState([]);
  const [serviciosList, setServiciosList] = useState([]);
  const [openServicio, setOpenServicio] = useState(false);
  const [openNuevoFormulario, setOpenNuevoFormulario] = useState(false);

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [totalDocs, setTotalDocs] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const [servicioActual, setServicioActual] = useState("");
  const [formulariosList, setFormulariosList] = useState([]);
  const [openEditarServicio, setOpenEditarServicio] = useState({
    open: false,
    data: null,
  });

  const getPlanes = async () => {
    try {
      const responsePlanes = await axios.get(`${BACKEND}/api/planes`);
      setPlanesList(responsePlanes.data.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const getServiciosList = async () => {
    try {
      const responsePlanes = await axios.get(
        `${BACKEND}/api/servicios?limit=1000`
      );
      setServiciosList(responsePlanes.data.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const getFormularios = async () => {
    try {
      const responseFormularios = await axios.get(
        `${BACKEND}/api/formularioServicio?limit=${limit}&page=${page}${
          servicioActual !== "" && `&where[servicio][equals]=${servicioActual}`
        }`
      );
      setTotalDocs(responseFormularios.data.totalDocs)
      setTotalPages(responseFormularios.data.totalPages)
      setFormulariosList(responseFormularios.data.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarFormulario = async (idFormulario) => {
    if(!window.confirm("¿Seguro de eliminar este formulario?")){
      return
    }
    try {
      const responseDeleteFormulario = await axios.delete(
        `${BACKEND}/api/formularioServicio/${idFormulario}`
      );
      toast.success("Eliminado ")
      await getFormularios();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPlanes();
    getServiciosList();
  }, []);

  useEffect(() => {
    getFormularios();
  }, [servicioActual, page, limit]);
  
  return (
    <div>
      <NuevoFormularioDrawer
        open={openNuevoFormulario}
        onClose={() => setOpenNuevoFormulario(false)}
        title={"Nuevo Formulario"}
        refetch={() => getFormularios()}
        serviciosList={serviciosList}
      />
      <EditarServicioDrawer
        open={openEditarServicio.open}
        data={openEditarServicio.data}
        onClose={() =>
          setOpenEditarServicio((prev) => ({ ...prev, open: false }))
        }
        refetch={() => getServiciosList()}
        planesList={planesList}
        title={"Editar Servicio"}
      />

      <div className="flex flex-col">

        <div className="flex flex-row items-center justify-between px-8 py-2 bg-zinc-800">

          <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="flex items-center gap-4 p-4">
                  <FaWpforms className="text-white text-2xl" />
                  <h2 className="text-2xl font-bold text-white">Formularios</h2>
              </div>
          </div>

          <div className="flex items-center mx-2 my-2 gap-4">
            <select
              onChange={(e) => setServicioActual(e.target.value)}
              className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
              <option value={""} selected>
                Filtrar por servicio
              </option>
              {serviciosList.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center mx-2 my-2 gap-4">
            <label htmlFor="limit" className="text-white">Límite</label>
            <select
              onChange={(e) => setLimit(e.target.value)}
              className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                <option key={1} value={10}>10</option>
                <option key={2} value={20}>20</option>
                <option key={3} value={30}>30</option>
                <option key={4} value={40}>40</option>
                <option key={5} value={50}>50</option>
            </select>
          </div>

          <button
            type="button"
            onClick={() => setOpenNuevoFormulario(true)}
            className="flex justify-center items-center px-6 py-2 text-sm font-semibold text-white bg-orange-500 rounded-md shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition-colors duration-300"
          >
            <FaPlus className="mr-2" />
            <span>Agregar nuevo formulario</span>
          </button>

        </div>

      </div>

      <div className="flex flex-col overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Servicio
              </th>
              <th scope="col" className="px-6 py-3">
                Tipo
              </th>
              <th scope="col" className="px-6 py-3">
                Acción
              </th>
            </tr>
          </thead>
          <tbody>
            {formulariosList.map((e) => (
              <tr key={e.id} className="odd:bg-white  even:bg-gray-50">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  {e?.servicio?.name}
                </th>
                <td className="px-6 py-4">
                  <p className=" line-clamp-2 max-w-sm">
                    {e.tipo === "entrada" && "Formulario de Entrada"}
                    {e.tipo === "satisfaccion" && "Formulario de Satisfaccion"}
                  </p>
                </td>
                <td className="px-6 py-4">
                  <div className=" flex justify-center gap-2 items-center">
                    <Link
                      to={`/home/formularios/${e.id}`}
                      className=" text-sm font-semibold flex justify-center gap-2 border hover:bg-orange-100 border-orange-100 items-center bg-orange-50 p-2 rounded-md text-orange-600"
                    >
                      Detalles <FaEye />
                    </Link>
                    <button
                      type="button"
                      onClick={()=>eliminarFormulario(e.id)}
                      className=" font-semibold bg-red-50 text-red-500 p-2 rounded-md hover:bg-red-100"
                    >
                      Eliminar
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex flex-col items-center justify-center py-8">
          <span className="text-sm text-gray-700 dark:text-gray-500">
            Mostrando
            <span className="font-semibold text-gray-900 dark:text-gray-300 ">
              {" "}
              {formulariosList.length}
            </span>{" "}
            de
            <span className="font-semibold text-gray-900 dark:text-gray-300 ">
              {" "}
              {totalDocs}
            </span>{" "}
            formularios
          </span>

          <nav aria-label="Page navigation example">
            <ul className="flex items-center -space-x-px h-10 text-base">
              <li>
                <button
                  onClick={() => setPage(page - 1)}
                  disabled={page === 1}
                  className="flex items-center justify-center px-4 h-10 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 "
                >
                  <span className="sr-only">Previous</span>
                  <FaChevronLeft className=" w-3 h-3 text-gray-700 dark:text-gray-400" />
                </button>
              </li>

              {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                <li key={pageNumber}>
                  <button
                    onClick={() => setPage(pageNumber)}
                    className={`flex items-center justify-center px-4 h-10 leading-tight ${
                      page === pageNumber
                        ? "text-white border-blue-300 bg-blue-600 hover:bg-blue-700"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    }`}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => setPage(page + 1)}
                  disabled={page >= totalPages}
                  className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 "
                >
                  <span className="sr-only">Next</span>
                  <FaChevronRight className=" w-3 h-3 text-gray-700 dark:text-gray-400" />
                </button>
              </li>
              
            </ul>
          </nav>
      </div>
    </div>
  );
};

export default Formularios;
