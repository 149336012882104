import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";
import Datepicker from "react-tailwindcss-datepicker";
import { toast } from "react-toastify";
import { formatDateString } from "../../helpers/datesHelper";
import useFetchRequestGet from "../../hooks/useFetch";
const EditarClienteUser = () => {
  // Login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // Valores
  const { id } = useParams();
  const [pais, setPais] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [ currentRubro, setCurrentRubro ] = useState("");

  const [ruc, setRuc] = useState("");
  const [dni, setDni] = useState("");

  const [nombreComercial, setNombreComercial] = useState("");
  const [fechaAniversario, setFechaAniversario] = useState({
    startDate: null,
    endDate: null,
  });
  const [direccion, setDireccion] = useState("");
  const [nEmpleados, setNEmpleados] = useState("");
  const [rubro, setRubro] = useState("");
  const [ingresosAnuales, setIngresosAnuales] = useState("");
  const [nombreGerente, setNombreGerente] = useState("");
  const [cumpleaniosGerente, setCumpleaniosGerente] = useState({
    startDate: null,
    endDate: null,
  });
  const [telefonoGerente, setTelefonoGerente] = useState("");
  const [emailGerencia, setEmailGerencia] = useState("");
  const [encargadoProyecto, setEncargadoProyecto] = useState("");
  const [telefono, setTelefono] = useState("");
  const [fechaCumpleanios, setFechaCumpleanios] = useState({
    startDate: null,
    endDate: null,
  });
  const [puestoTrabajo, setPuestoTrabajo] = useState("");
  const [nicho, setNicho] = useState("");
  const [currentNicho, setCurrentNicho] = useState(null);

  const { dataList: listRubros } = useFetchRequestGet({
    endpoint: "clientesRubros",
    page: 1,
    limit: 20,
  });

  const { dataList: listNichos } = useFetchRequestGet({
    endpoint: "clientesNichos",
    page: 1,
    limit: 50,
    query:currentRubro ? `&where[rubro][equals]=${currentRubro}` : ""
  });

  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async (url) => {
      try {
        const response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const fetchClienteUser = async () => {
      try {
        const clienteUser = await fetchData(
          `${BACKEND}/api/clientesuser/${id}`
        );
        if(clienteUser.nicho){

          setNicho(clienteUser.nicho.id)
          setCurrentNicho(clienteUser.nicho)
          setCurrentRubro(clienteUser.nicho.rubro.id)
        }
        const stateUpdates = {};

        const dataMapping = {
          email: setEmail,
          password: setPassword,
          pais: setPais,
          razonSocial: setRazonSocial,
          ruc: setRuc,
          dni:setDni,
          nombreComercial: setNombreComercial,
          //  fechaAniversario: setFechaAniversario,
          direccion: setDireccion,
          nEmpleados: setNEmpleados,
          rubro: setRubro,
          ingresosAnuales: setIngresosAnuales,
          nombreGerente: setNombreGerente,
          //   cumpleaniosGerente: setCumpleaniosGerente,
          telefonoGerente: setTelefonoGerente,
          emailGerencia: setEmailGerencia,
          encargadoProyecto: setEncargadoProyecto,
          telefono: setTelefono,
          // fechaCumpleanios: setFechaCumpleanios,
          puestoTrabajo: setPuestoTrabajo,
        };

        for (const key in dataMapping) {
          if (clienteUser.hasOwnProperty(key)) {
            stateUpdates[key] = clienteUser[key] || "";
            dataMapping[key](clienteUser[key] || "");
          }
        }

        if (clienteUser.fechaAniversario) {
          setFechaAniversario({
            startDate: formatDateString(clienteUser.fechaAniversario),
            endDate: formatDateString(clienteUser.fechaAniversario),
          });
        }

        if (clienteUser.cumpleaniosGerente) {
          setCumpleaniosGerente({
            startDate: formatDateString(clienteUser.cumpleaniosGerente),
            endDate: formatDateString(clienteUser.cumpleaniosGerente),
          });
        }
        if (clienteUser.fechaCumpleanios) {
          setFechaCumpleanios({
            startDate: formatDateString(clienteUser.fechaCumpleanios),
            endDate: formatDateString(clienteUser.fechaCumpleanios),
          });
        }
      } catch (error) {
        console.error("Error al obtener el usuario cliente:", error);
      }
    };

    if (id) {
      fetchClienteUser();
    }
  }, [id]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePaisChange = (e) => {
    setPais(e.target.value);
  };

  const handleRazonSocialChange = (e) => {
    setRazonSocial(e.target.value);
  };

  const handleRucChange = (e) => {
    setRuc(e.target.value);
  };

  const handleDNIChange = (e) => {
    setDni(e.target.value);
  };

  const handleNombreComercialChange = (e) => {
    setNombreComercial(e.target.value);
  };

  const handleFechaAniversarioChange = (e) => {
    setFechaAniversario(e.target.value);
  };

  const handleDireccionChange = (e) => {
    setDireccion(e.target.value);
  };

  const handleNEmpleadosChange = (e) => {
    setNEmpleados(e.target.value);
  };

  const handleRubroChange = (e) => {
    setRubro(e.target.value);
  };

  const handleIngresosAnualesChange = (e) => {
    setIngresosAnuales(e.target.value);
  };

  const handleNombreGerenteChange = (e) => {
    setNombreGerente(e.target.value);
  };

  const handleCumpleaniosGerenteChange = (e) => {
    setCumpleaniosGerente(e.target.value);
  };

  const handleTelefonoGerenteChange = (e) => {
    setTelefonoGerente(e.target.value);
  };

  const handleEmailGerenciaChange = (e) => {
    setEmailGerencia(e.target.value);
  };

  const handleEncargadoProyectoChange = (e) => {
    setEncargadoProyecto(e.target.value);
  };

  const handleTelefonoChange = (e) => {
    setTelefono(e.target.value);
  };

  const handleFechaCumpleaniosChange = (e) => {
    setFechaCumpleanios(e.target.value);
  };

  const handlePuestoTrabajoChange = (e) => {
    setPuestoTrabajo(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${BACKEND}/api/clientesuser/${id}`, {
        email,
        pais,
        razonSocial,
        dni,
        ruc,
        nombreComercial,
        fechaAniversario: fechaAniversario.startDate,
        direccion,
        nEmpleados,
        rubro,
        ingresosAnuales,
        nombreGerente,
        cumpleaniosGerente: cumpleaniosGerente.startDate,
        telefonoGerente,
        emailGerencia,
        encargadoProyecto,
        telefono,
        fechaCumpleanios: fechaCumpleanios.startDate,
        puestoTrabajo,
        nicho:nicho ? nicho : null
      });

      toast.success("Actualizado");
    } catch (error) {
      toast.error("Error al actualizar");
    }
  };

  console.log(nicho);
  return (
    <div className="flex items-center justify-center">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">Añadir Usuario Cliente</h2>
        {errorMessage && (
          <div className="bg-red-200 text-red-800 py-2 px-4 mb-4 rounded">
            {errorMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="razonSocial" className="block mb-2">
              Razón Social <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="razonSocial"
              value={razonSocial}
              onChange={handleRazonSocialChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              
            />
          </div>

          <div className="mb-4">
            <label htmlFor="rubro" className="block mb-2">
              Rubro <span className="text-red-500">*</span>
            </label>
            <select
              type="text"
              id="rubro"
              value={currentRubro}
              onChange={(e) => setCurrentRubro(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            >
              <option value={""}>Selecciona un rubro</option>
              {listRubros.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="rubro" className="block mb-2">
              Nicho <span className="text-red-500">*</span>
            </label>
            <select
              type="text"
              id="rubro"
              value={nicho}
              onChange={(e) => { setCurrentRubro(listNichos.find((nic)=>nic.id==e.target.value)?.rubro.id); setNicho(e.target.value)}}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            >
              <option value={""}>Selecciona un nicho</option>
              {listNichos.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">
              Correo Electrónico <span className=" text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          {/* <div className="mb-4">
            <label htmlFor="password" className="block mb-2">
              Contraseña <span className=" text-red-500">*</span>
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              
            />
          </div> */}
          <div className="mb-4">
            <label htmlFor="pais" className="block mb-2">
              País <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="pais"
              value={pais}
              onChange={handlePaisChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="ruc" className="block mb-2">
              RUC <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="ruc"
              value={ruc}
              onChange={handleRucChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="dni" className="block mb-2">
              DNI <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="dni"
              value={dni}
              onChange={handleDNIChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nombreComercial" className="block mb-2">
              Nombre Comercial <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="nombreComercial"
              value={nombreComercial}
              onChange={handleNombreComercialChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="fechaAniversario" className="block mb-2">
              Fecha aniversario
            </label>
            <Datepicker
              popoverDirection={"up"}
              toggleClassName="absolute bg-blue-300     rounded-r-lg text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
              inputClassName={
                "   rounded-md bg-gray-50  w-full  z-[50] border border-gray-300  py-2  pl-4 pr-20 text-gray-700 "
              }
              inputId="fechaAniversario"
              containerClassName={"z-[0]   relative"}
              useRange={false}
              asSingle={true}
              classNames={" z-[9999]"}
              placeholder={"Fecha aniversario"}
              value={fechaAniversario}
              onChange={setFechaAniversario}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="direccion" className="block mb-2">
              Direccion
            </label>
            <input
              type="text"
              id="direccion"
              value={direccion}
              onChange={handleDireccionChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nEmpleados" className="block mb-2">
              Número Empleados
            </label>
            <input
              type="text"
              id="nEmpleados"
              value={nEmpleados}
              onChange={handleNEmpleadosChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
       
          <div className="mb-4">
            <label htmlFor="ingresosAnuales" className="block mb-2">
              Ingresos Anuales
            </label>
            <input
              type="text"
              id="ingresosAnuales"
              value={ingresosAnuales}
              onChange={handleIngresosAnualesChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nombreGerente" className="block mb-2">
              Nombre Gerente
            </label>
            <input
              type="text"
              id="nombreGerente"
              value={nombreGerente}
              onChange={handleNombreGerenteChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="fechaGerente" className="block mb-2">
              Cumpleaños Gerente
            </label>
            <Datepicker
              popoverDirection={"up"}
              toggleClassName="absolute bg-blue-300     rounded-r-lg text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
              inputClassName={
                "   rounded-md bg-gray-50  w-full  z-[50] border border-gray-300  py-2  pl-4 pr-20 text-gray-700 "
              }
              inputId="fechaGerente"
              containerClassName={"z-[0]   relative"}
              useRange={false}
              asSingle={true}
              classNames={" z-[9999]"}
              placeholder={"Cumpleaños gerente"}
              value={cumpleaniosGerente}
              onChange={setCumpleaniosGerente}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="telefonoGerente" className="block mb-2">
              Teléfono Gerente <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="telefonoGerente"
              value={telefonoGerente}
              onChange={handleTelefonoGerenteChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="emailGerencia" className="block mb-2">
              Email Gerencia <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              id="emailGerencia"
              value={emailGerencia}
              onChange={handleEmailGerenciaChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="encargadoProyecto" className="block mb-2">
              Encargado Proyecto
            </label>
            <input
              type="text"
              id="encargadoProyecto"
              value={encargadoProyecto}
              onChange={handleEncargadoProyectoChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="telefono" className="block mb-2">
              Teléfono
            </label>
            <input
              type="text"
              id="telefono"
              value={telefono}
              onChange={handleTelefonoChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="fechaCumpleanios" className="block mb-2">
              Fecha Cumpleaños
            </label>
            <Datepicker
              popoverDirection={"up"}
              toggleClassName="absolute bg-blue-300     rounded-r-lg text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
              inputClassName={
                "   rounded-md bg-gray-50  w-full  z-[50] border border-gray-300  py-2  pl-4 pr-20 text-gray-700 "
              }
              inputId="fechaCumpleanios"
              containerClassName={"z-[0]   relative"}
              useRange={false}
              asSingle={true}
              classNames={" z-[9999]"}
              placeholder={"Cumpleaños "}
              value={fechaCumpleanios}
              onChange={setFechaCumpleanios}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="puestoTrabajo" className="block mb-2">
              Puesto Trabajo
            </label>
            <input
              type="text"
              id="puestoTrabajo"
              value={puestoTrabajo}
              onChange={handlePuestoTrabajoChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="flex justify-end">
            <Link
              to="/home/clientes-user"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Actualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarClienteUser;
