import React from 'react';
import { FaExternalLinkAlt, FaTimes } from 'react-icons/fa';
import moment from "moment";

const ModalDetails = ({ show, handleClose, incidencia }) => {
  if (!show) return null;

  const links = incidencia.links || [];
  const pruebas = incidencia.pruebas || [];

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl relative">
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
        >
          <FaTimes className="w-6 h-6" />
        </button>
        <h2 className="text-xl font-semibold mb-4">Detalles de la Incidencia</h2>
        <div className="flex flex-wrap -mx-4">
          {/* Detalles de la incidencia */}
          <div className="w-full md:w-1/2 px-4 mb-4 rounded-md">
            <div className="mb-4">
              <strong>Gravedad:</strong>                     
                <span
                  className={`font-semibold
                    ${incidencia.gravedad === "Leve" ? "text-yellow-300" : ""}
                    ${incidencia.gravedad === "Moderada" ? "text-yellow-500" : ""}
                    ${incidencia.gravedad === "Grave" ? "text-red-500" : ""}
                    ${incidencia.gravedad === "Muy grave" ? "text-red-800" : ""}`}
                >
                  {" " + incidencia.gravedad || "N/A"}
                </span>
            </div>
            <div className="mb-4">
              <strong>Autor:</strong> {incidencia.autor || "N/A"}
            </div>
            <div className="mb-4">
              <strong>Trabajador:</strong> {incidencia.trabajador.name}
            </div>
            <div className="mb-4">
              <strong>Motivo:</strong> {incidencia.titulo}
            </div>
            <div className="mb-4">
              <strong>Comentario:</strong> {incidencia.comentario || "N/A"}
            </div>
            <div className="mb-4">
              <strong>Fecha de creación:</strong>                     
                {incidencia.fecha
                  ? " " + moment.utc(incidencia.fecha).format("DD/MM/YYYY")
                  : ""
                }
            </div>
            
            <div className="mb-4">
              <strong>Descuento:</strong> {incidencia.descuentoPorDefinir ? "Por definir" : "S/. " + incidencia.descuento}
            </div>

            <div className="mb-4">
              <strong>Subsanado:</strong> {incidencia.subsanado}
            </div>

            {incidencia.fechaSubsanacion && (
              <div className="mb-4">
                <strong>Fecha de subsanación:</strong>
                {incidencia.fechaSubsanacion
                  ? " " + moment.utc(incidencia.fechaSubsanacion).format("DD/MM/YYYY")
                  : ""
                }
              </div>
            )}

            {incidencia.comentarioSubsanacion && (
              <div className="mb-4">
                <strong>Comentario subsanacion:</strong> {incidencia.comentarioSubsanacion}
              </div>
            )}
            <div className="mb-4">
              <strong>Links:</strong>
              <ul className="list-disc mt-4 ml-8">
                {links.length > 0 ? (
                  links.map((link, index) => (
                    <li key={index}>
                      <a href={link.link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                        {link.link}
                      </a>
                    </li>
                  ))
                ) : (
                  <span className="text-gray-600">No hay links disponibles</span>
                )}
              </ul>
            </div>
          </div>
          
          <div className="w-full md:w-1/2 px-4 mb-4 max-h-[calc(100vh-250px)] overflow-y-auto rounded-md">
            <strong>Archivos adjuntos:</strong>
            {pruebas.length > 0 ? (
              pruebas.map((prueba, index) => {
                const url = prueba.url;
                const name = prueba.filename;
                const mimeType = prueba.type;
                const fileType = prueba.mimeType?.split('/')[0] || '';

                return (
                  <div key={index} className="border p-3 rounded-md shadow mb-4 mt-4 bg-white hover:bg-gray-100 transition-all duration-300">
                    <div className="flex items-center justify-between mb-2">
                      <span className="font-medium">{name}</span>
                      <button
                        type="button"
                        onClick={() => window.open(url, '_blank')}
                        className="p-2 text-blue-500 hover:bg-blue-600 hover:bg-opacity-25 rounded-md bg-blue-700 bg-opacity-10 flex items-center"
                      >
                        <FaExternalLinkAlt />
                      </button>
                    </div>
                    {fileType === 'image' && (
                      <img src={url} alt={`Preview ${index}`} className="w-full h-auto rounded-md" />
                    )}
                    {fileType === 'video' && (
                      <video controls className="w-full h-auto rounded-md">
                        <source src={url} type={mimeType} />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {fileType === 'audio' && (
                      <audio controls className="w-full max-h-12 rounded-md">
                        <source src={url} type={mimeType} />
                        Your browser does not support the audio element.
                      </audio>
                    )}
                    {fileType !== 'image' && fileType !== 'video' && fileType !== 'audio' && (
                      <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                        {url}
                      </a>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="w-full h-32 flex items-center justify-center text-gray-600">
                No hay archivos adjuntos
              </div>
            )}
          </div>
        </div>
        <button
          onClick={handleClose}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default ModalDetails;
