import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import moment from "moment";
import "moment-timezone";
import { BACKEND } from "../config/configEnvs";
import { FaChevronLeft, FaChevronRight, FaHistory } from "react-icons/fa";
import { toast } from "react-toastify";

function RegistrosBusquedaCliente() {
  const [registros, setRegistros] = useState([]);
  const [workers, setWorkers] = useState([]);

  //Filtros
  const [filtroNombre, setFiltroNombre] = useState(null);

  const [selectedWorker, setSelectedWorker] = useState("Todos")

  const [startDate, setStartDate] = useState("");

  // Paginado desde el servidor
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [totalDocs, setTotalDocs] = useState(0)
  const [totalPages, setTotalPages] = useState(0)


  const fetchRegistros = async () => { // Obtener los registros
    try {
      let url = `${BACKEND}/api/busquedacliente?page=${page}&limit=${limit}`

      if (selectedWorker !== "Todos") {
        url += `&where[autor][equals]=${selectedWorker}`
      }

      if (startDate != "") {
        url += `&where[tiempo][greater_than]=${startDate}`
      }

      const response = await axios.get(url)
      setTotalDocs(response.data.totalDocs)
      setTotalPages(response.data.totalPages)
      setRegistros(response.data.docs)

    } catch (error) {
      toast.error("Error obteniendo los registros")
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRegistros()
  }, [selectedWorker, page, startDate])

  const fetchTrabajadores = async () => { // Obtener la lista de trabajadores
    try {
      const response = await axios.get(
        `${BACKEND}/api/trabajadores?limit=0`,
      );
      setWorkers(response.data.docs);
    } catch (error) {
      toast.error("Error obteniendo los trabajadores")
      console.log(error);
    }
  };

  //Funciones de arranque de componente

  useEffect(() => {
    fetchRegistros();
    fetchTrabajadores();
  }, []);

  const formatDate = (dateString) => { // Formatear la fecha a la hora Peru
    const formattedDate = moment(dateString)
      .tz("America/Lima") // Ajusta al horario de Perú
      .format("YYYY-MM-DD HH:mm:ss"); // Formato deseado
    return formattedDate;
  };

  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-row items-center justify-between px-8 py-2 bg-zinc-800 overflow-x-auto">

        <div className="flex flex-col md:flex-row items-center justify-between w-1/2">
          <div className="flex items-center gap-4 p-4">
            <FaHistory className="text-white text-2xl" />
            <h2 className="text-2xl font-bold text-white">Historial de búsqueda de clientes</h2>
          </div>
        </div>


        <div className="flex flex-row justify-center items-center gap-8">

              <div className="flex items-center gap-4">
                <p htmlFor="filtroNombre" className="text-white">
                  Nombre:
                </p>
                <select
                  id="filtroNombre"
                  name="filtroNombre"
                  value={filtroNombre}
                  onChange={(e) => {
                    setSelectedWorker(e.target.value)
                  }}
                  className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                  >
                  <option value="Todos">Todos</option>
                  {workers.map((worker) => (
                    <option key={worker.id} value={worker.id}>
                      {worker.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex items-center gap-4">
                <p htmlFor="startDate" className="text-white">
                  Filtrar a partir de:
                </p>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value)
                  }}
                  className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                />
              </div>

        </div>
        
      </div>

      <div className="mt-4 overflow-x-auto">
        <table className="w-full w-full mx-auto border-collapse text-sm">
          <thead className="bg-gray-200 text-black">
            <tr>
              <th className="py-4 font-semibold">Nombre</th>
              <th className="py-4 font-semibold">Tipo Documento</th>
              <th className="py-4 font-semibold">Número Documento</th>
              <th className="py-4 font-semibold">Nombre o Razón Social</th>
              <th className="py-4 font-semibold">Fecha y hora</th>
            </tr>
          </thead>
          <tbody>
            {registros.length > 0 ? (
              registros.map((registro, index) => {
                return (
                  <tr
                    key={registro.id}
                    className="bg-white hover:bg-gray-100 transition-all duration-100"
                  >
                    <td className="border-b border-gray-300 py-4 text-center">
                      {registro.autor?.name}
                    </td>
                    <td className="border-b border-gray-300 py-4  text-center">
                      {registro.tipoDocumento}
                    </td>
                    <td className="border-b border-gray-300 py-4 text-center">
                      {registro.numeroDocumento}
                    </td>
                    <td className="border-b border-gray-300 py-4 text-center">
                      {registro.nombreRazonSocial}
                    </td>
                    <td className="border-b border-gray-300 py-4  text-center">
                      {formatDate(registro.tiempo)}
                    </td>
                  </tr>
                );
                
              })
            ) : (
              <td className="border-b border-gray-300 py-4 text-center h-[400px]" colSpan={5}>
                No hay registros que coincidan con el filtro
              </td>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col items-center justify-center py-8">
          <span className="text-sm text-gray-700 dark:text-gray-500">
            Mostrando
            <span className="font-semibold text-gray-900 dark:text-gray-300 ">
              {" "}
              {registros.length}
            </span>{" "}
            de
            <span className="font-semibold text-gray-900 dark:text-gray-300 ">
              {" "}
              {totalDocs}
            </span>{" "}
            registros
          </span>

          <nav aria-label="Page navigation example">
            <ul className="flex items-center -space-x-px h-10 text-base">
              <li>
                <button
                  onClick={() => setPage(page - 1)}
                  disabled={page === 1}
                  className="flex items-center justify-center px-4 h-10 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 "
                >
                  <span className="sr-only">Previous</span>
                  <FaChevronLeft className=" w-3 h-3 text-gray-700 dark:text-gray-400" />
                </button>
              </li>

              {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                <li key={pageNumber}>
                  <button
                    onClick={() => setPage(pageNumber)}
                    className={`flex items-center justify-center px-4 h-10 leading-tight ${
                      page === pageNumber
                        ? "text-white border-blue-300 bg-blue-600 hover:bg-blue-700"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    }`}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => setPage(page + 1)}
                  disabled={page >= totalPages}
                  className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 "
                >
                  <span className="sr-only">Next</span>
                  <FaChevronRight className=" w-3 h-3 text-gray-700 dark:text-gray-400" />
                </button>
              </li>
              
            </ul>
          </nav>
      </div>
    </div>
  );
}

export default RegistrosBusquedaCliente;
