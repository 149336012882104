import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BACKEND } from "../config/configEnvs";

function TrabajadoresMes() {
  const [trabajadores, setTrabajadores] = useState([]);

  useEffect(() => {
    const fetchTrabajadores = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/trabajadormes`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setTrabajadores(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchTrabajadores();
  }, []);

  const handleDelete = async (trabajadorId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este trabajador?"
    );

    if (confirmed) {
      try {
        await axios.delete(`${BACKEND}/api/trabajadormes/${trabajadorId}`);
        const updatedTrabajadores = trabajadores.filter(
          (trabajador) => trabajador._id !== trabajadorId
        );
        setTrabajadores(updatedTrabajadores);
      } catch (error) {
        console.error("Error al eliminar el trabajador:", error);
      }
    }
  };

  return (
    <div className="w-full flex flex-col">
      <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
        Lista de Trabajadores del Mes
      </h2>
      <div className="flex justify-between items-center">
        <div className="mb-4 mx-4 md:mx-8">
          <Link
            to="/home/anuncios/trabajador-mes/nuevo-trabajador-mes"
            className="block px-4 py-2 text-sm  hover:bg-[#ff6400] bg-zinc-600 text-white"
            role="menuitem"
          >
            Añadir Trabajador del Mes
          </Link>
        </div>
      </div>

      <div className="flex md:hidden px-4 md:px-8">
        <table className="w-full max-w-max mx-auto border-collapse">
          <thead>
            <tr>
              <th className="border-b-2 border-gray-800 p-2">Registros</th>
            </tr>
          </thead>
          <tbody>
            {trabajadores.map((trabajador, index) => {
              return (
                <tr
                  key={trabajador.id}
                  className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
                >
                  <td className="border px-6 flex items-center justify-start border-gray-300 p-2">
                    <div>
                      <div className="flex items-center mb-2">
                        <p className="w-20 font-semibold">Nombre:</p>
                        <p className="">{trabajador.name}</p>
                      </div>
                      <div className="flex items-center mb-2">
                        <p className="w-20 font-semibold">Mes:</p>
                        <p className="">{trabajador.mes}</p>
                      </div>
                      <div className="flex items-center mb-2">
                        <p className="w-20 font-semibold">Premio:</p>
                        <p className="">{trabajador.premio}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="hidden md:flex px-8">
        <table className="w-full max-w-4xl mx-auto border-collapse">
          <thead className="bg-zinc-600 text-white">
            <tr>
              <th className="p-2">Nombre</th>
              <th className="p-2">Mes</th>
              <th className="p-2">Premio</th>
              <th className="p-2">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {trabajadores.map((trabajador, index) => {
              return (
                <tr
                  key={trabajador.id}
                  className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
                >
                  <td className="border border-gray-300 p-2">
                    {trabajador.trabajador?.name}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {trabajador.mes}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {trabajador.premio}
                  </td>
                  <td className="border font-semibold border-gray-300 p-2">
                    <button
                      onClick={() => handleDelete(trabajador.id)}
                      className="mx-2 my-1 text-red-600  rounded-lg"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TrabajadoresMes;
