import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { addTareaSuccess } from "../../features/tareaSlice";
import { BACKEND } from "../../config/configEnvs";

const NuevaTarea = () => {
  const navigate = useNavigate();
  const [tarea, setTarea] = useState("");
  const [marca, setMarca] = useState("");
  const [fechaLimite, setFechaLimite] = useState("");
  const [trabajadores, setTrabajadores] = useState([]);
  const [trabajador, setTrabajador] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [first, setFirst] = useState(false);

  const user = useSelector((state) => state.auth.user);
  console.log(user);

  useEffect(() => {
    const fetchTrabajadores = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/trabajadores`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setTrabajadores(data);
        setFirst(true);
      } catch (error) {
        console.log(error);
      }
    };

    if (!first) {
      fetchTrabajadores();
    }
  });

  const handleTareaChange = (e) => {
    setTarea(e.target.value);
  };

  const handleMarcaChange = (e) => {
    setMarca(e.target.value);
  };

  const handleFechaLimiteChange = (event) => {
    setFechaLimite(event.target.value);
  };

  const handleTrabajadorChange = (e) => {
    const selectedTrabajador = JSON.parse(e.target.value);
    setTrabajador(selectedTrabajador);
  };

  const dispatch = useDispatch();

  const handleRegistroTarea = async () => {
    const currentTime = new Date().toISOString();

    try {
      const response = await axios.get(`${BACKEND}/api/tareas`);

      const tarea = response.data.docs[0];

      await axios
        .post(`${BACKEND}/api/tareasregistro`, {
          tarea: tarea.id,
          estado: "pendiente",
          fecha: currentTime,
        })
        .then(({ data }) => dispatch(addTareaSuccess(data.register)))
        .catch((err) => console.log(err));
    } catch (error) {
      console.error("Error al obtener la tarea:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BACKEND}/api/tareas`, {
        usuario: trabajador.id,
        tarea: tarea,
        fecha_limite: fechaLimite,
        marca: marca,
        estado: "pendiente",
      });

      const nuevoTarea = response.data;
      handleRegistroTarea();
      console.log("Tarea añadida:", nuevoTarea);
      navigate("/home/registros-tareas");
    } catch (error) {
      console.error("Error al añadir la tarea:", error);
      setErrorMessage("Error al añadir tarea");
    }
  };

  return (
    <div className="flex items-center justify-center mt-12">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-4">Añadir Tarea</h2>
        {errorMessage && (
          <div className="bg-red-200 text-red-800 py-2 px-4 mb-4 rounded">
            {errorMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4 flex items-center">
            <p htmlFor="filtroFechaLimite" className="block mr-4">
              Fecha límite:
            </p>
            <input
              type="date"
              id="filtroFechaLimite"
              name="filtroFechaLimite"
              value={fechaLimite}
              onChange={handleFechaLimiteChange}
              className="w-40"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="tarea" className="block mb-2">
              Nombre de Tarea
            </label>
            <input
              type="text"
              id="tarea"
              value={tarea}
              onChange={handleTareaChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="marca" className="block mb-2">
              Marca
            </label>
            <input
              type="text"
              id="marca"
              value={marca}
              onChange={handleMarcaChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="trabajador" className="block mb-2">
              Trabajador <span className=" text-red-500">*</span>
            </label>
            <select
              type="text"
              id="trabajador"
              value={JSON.stringify(trabajador)}
              onChange={handleTrabajadorChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            >
              <option value="">Seleccionar trabajador</option>
              {trabajadores.map((trabajador) => (
                <option key={trabajador.id} value={JSON.stringify(trabajador)}>
                  {trabajador.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-end">
            <Link
              to="/home/registros-tareas"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Añadir
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NuevaTarea;
