import { format } from "date-fns";
import { es } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import TablePlanillaRegistro from "./TablePlanillaRegistro";

const RegistrosPlanilla = ({ planillaMetadata, planillaMesList }) => {
  const [activePlanilla, setActivePlanilla] = useState(null);
  useEffect(()=>{
    if(planillaMesList.length>0){
        setActivePlanilla(planillaMesList[0])
    }
  },[planillaMesList])

  return (
    <div>
      <div className=" pt-4">
        <ul className="flex flex-wrap justify-center text-sm font-medium text-center text-gray-500 border-b border-gray-200 ">
          {planillaMesList.map((e) => (
            <li key={e.id} className="me-2">
              <button
                onClick={() => {
                  setActivePlanilla(e);
                }}
                type="button"
                className={` inline-flex gap-2 items-center justify-center capitalize p-4 ${
                  activePlanilla?.id === e.id
                    ? "text-blue-600  bg-gray-200 "
                    : " text-gray-700 hover:bg-gray-50 "
                }   rounded-t-lg active `}
              >
                {e?.fechaInicio &&
                  format(new Date(e?.fechaInicio), "MMMM - yyyy", {
                    locale: es,
                  })}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {activePlanilla && (
        <TablePlanillaRegistro
          planilla={activePlanilla}
        />
      )}
    </div>
  );
};

export default RegistrosPlanilla;
