import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RegistrosAsistencia from "./RegistrosAsistencia";
import RegistrosSSHH from "./RegistrosSSHH";
import Dashboard from "./Dashboard";
import Trabajadores from "./Trabajadores";
import EstadisticasTrabajador from "./EstadisticasTrabajador";
import NuevoTrabajador from "../forms/Trabajadores/NuevoTrabajador";
import EditarTrabajador from "../forms/Trabajadores/EditarTrabajador";
import RegistrosTareas from "./RegistrosTareas";
import Areas from "./Areas";
import Recursos from "./Recursos";
import NuevoRecurso from "../forms/Recursos/NuevoRecurso";
import EditarRecurso from "../forms/Recursos/EditarRecurso";
import Ventas from "./Ventas";
import NuevoVenta from "../forms/Ventas/NuevoVenta";
import EditarVenta from "../forms/Ventas/EditarVenta";
import Proyectos from "./Proyectos";
import NuevoProyecto from "../forms/Proyectos/NuevoProyecto";
import EditarProyecto from "../forms/Proyectos/EditarProyecto";
import NuevoElementoProyecto from "../forms/Proyectos/NuevoElementoProyecto";
import EditarElementoProyecto from "../forms/Proyectos/EditarElementoProyecto";
import NuevoElementoVenta from "../forms/Ventas/NuevoElementoVenta";
import EditarElementoVenta from "../forms/Ventas/EditarElementoVenta";
import Clientes from "./Clientes";
import NuevoCliente from "../forms/Clientes/NuevoCliente";
import EditarCliente from "../forms/Clientes/EditarCliente";
import NuevoElementoCliente from "../forms/Clientes/NuevoElementoCliente";
import EditarElementoCliente from "../forms/Clientes/EditarElementoCliente";
import RRHH from "./RRHH";
import NuevoRRHH from "../forms/RRHH/NuevoRRHH";
import EditarRRHH from "../forms/RRHH/EditarRRHH";
import NuevoElementoRRHH from "../forms/RRHH/NuevoElementoRRHH";
import EditarElementoRRHH from "../forms/RRHH/EditarElementoRRHH";
import Learning from "./Learning";
import NuevoLearning from "../forms/Learning/NuevoLearning";
import EditarLearning from "../forms/Learning/EditarLearning";
import NuevoElementoLearning from "../forms/Learning/NuevoElementoLearning";
import EditarElementoLearning from "../forms/Learning/EditarElementoLearning";
import Contabilidad from "./Contabilidad";
import NuevoContabilidad from "../forms/Contabilidad/NuevoContabilidad";
import EditarContabilidad from "../forms/Contabilidad/EditarContabilidad";
import NuevoElementoContabilidad from "../forms/Contabilidad/NuevoElementoContabilidad";
import EditarElementoContabilidad from "../forms/Contabilidad/EditarElementoContabilidad";
import NuevoArea from "../forms/Areas/NuevoArea";
import EditarArea from "../forms/Areas/EditarArea";
import NuevoPuesto from "../forms/Areas/NuevoPuesto";
import EditarPuesto from "../forms/Areas/EditarPuesto";
import NuevoSubarea from "../forms/Areas/NuevoSubarea";
import EditarSubarea from "../forms/Areas/EditarSubarea";
import Links from "./Links";
import NuevoLink from "../forms/Links/NuevoLink";
import EditarLink from "../forms/Links/EditarLink";
import EditarElementoLink from "../forms/Links/EditarElementoLink";
import NuevoElementoLink from "../forms/Links/NuevoElementoLink";
import ListaTareas from "./ListaTareas";
import NuevaTarea from "../forms/Tareas/NuevaTarea";
import ListaTareasAdmin from "./ListaTareasAdmin";
import NuevaTareaAdmin from "../forms/TareasAdmin/NuevaTareaAdmin";
import RegistrosTareasAdmin from "./RegistrosTareasAdmin";
import RegistrosBusquedaCliente from "./RegistrosBusquedaCliente";
import AccesoElementoLink from "../forms/Links/AccesoElementoLink";
import AccesoRecurso from "../forms/Recursos/AccesoRecurso";
import AccesoProyecto from "../forms/Proyectos/AccesoProyecto";
import AccesoVenta from "../forms/Ventas/AccesoVentas";
import AccesoCliente from "../forms/Clientes/AccesoCliente";
import AccesoRRHH from "../forms/RRHH/AccesoRRHH";
import AccesoLearning from "../forms/Learning/AccesoLearning";
import AccesoContabilidad from "../forms/Contabilidad/AccesoContabilidad";
import Incidencias from "./Incidencias";
import NuevaIncidencia from "../forms/Incidencias/NuevaIncidencia";
import EditarIncidencia from "../forms/Incidencias/EditarIncidencia";
import Tutoriales from "./Tutoriales";
import NuevoTutorial from "../forms/Tutoriales/NuevoTutorial";
import EditarTutorial from "../forms/Tutoriales/EditarTutorial";
import AccesoTutorial from "../forms/Tutoriales/AccesoTutorial";
import NuevoElementoTutorial from "../forms/Tutoriales/NuevoElementoTutorial";
import EditarElementoTutorial from "../forms/Tutoriales/EditarElementoTutorial";
import Anuncios from "./Anuncios";
import NuevoAnuncio from "../forms/Anuncios/NuevoAnuncio";
import EditarAnuncio from "../forms/Anuncios/EditarAnuncio";
import EditarPerfil from "../forms/EditarPerfil";
import Reglas from "./Reglas";
import NuevoValor from "../forms/Reglas/NuevoValor";
import EditarValor from "../forms/Reglas/EditarValor";
import NuevoRegla from "../forms/Reglas/NuevoRegla";
import EditarRegla from "../forms/Reglas/EditarRegla";
import NuevoSancion from "../forms/Reglas/NuevoSancion";
import EditarSancion from "../forms/Reglas/EditarSancion";
import TrabajadoresMes from "./TrabajadorMes";
import NuevoTrabajadorMes from "../forms/TrabajadorMes/NuevoTrabajadorMes";
import ClientesUser from "./ClientesUser";
import NuevoClienteUser from "../forms/ClientesUser/NuevoClienteUser";
import EditarClienteUser from "../forms/ClientesUser/EditarClienteUser";
import Servicios from "./Servicios";
import Formularios from "./Formularios";
import FormularioDetails from "./FormularioDetails";
import Planilla from "./Planilla";
import Costos from "./Costos";
import VerificacionTrabajadores from "./VerificacionTrabajadores";
import ComisionesGenerales from "./ComisionesGenerales";
import CalendarioCumpleañosClientes from "../components/CalendarioCumpleañosClientes"
import PagosTrabajadores from "./PagosTrabajadores";
import NuevoPago from "../forms/PagosTrabajaddores/NuevoPago";
import Procesos from "./Procesos";
import EditarPago from "../forms/PagosTrabajaddores/EditarPago";

const HomeRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home/dashboard" replace />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/editar-perfil" element={<EditarPerfil />} />

      {/* Trabajadores  verificacion-trabajadores */}
      <Route path="/trabajadores" element={<Trabajadores />} />
      <Route path="/verificacion-trabajadores" element={<VerificacionTrabajadores />} />

      <Route path="/planilla" element={<Planilla />} />
      <Route path="/costos" element={<Costos />} />

      <Route
        path="/trabajadores/nuevo-trabajador"
        element={<NuevoTrabajador />}
      />
      <Route
        path="/trabajadores/editar-trabajador/:id"
        element={<EditarTrabajador />}
      />
      {/* Pagos Trabajadores */}
      <Route path="/pagos-trabajadores"element={<PagosTrabajadores />} />
      <Route path="/pagos-trabajadores/nuevo-pago"element={<NuevoPago />} />
      <Route path="/pagos-trabajadores/editar-pago/:registerId"element={<EditarPago />} />

      {/* Areas */}
      <Route path="/areas" element={<Areas />} />
      <Route path="/areas/nuevo-area" element={<NuevoArea />} />
      <Route path="/areas/editar-area/:id" element={<EditarArea />} />
      <Route
        path="/areas/editar-area/:id/nuevo-subarea"
        element={<NuevoSubarea />}
      />
      <Route
        path="/areas/editar-area/:id/editar-subarea/:subareaId"
        element={<EditarSubarea />}
      />
      <Route
        path="/areas/editar-area/:id/editar-subarea/:subareaId/nuevo-puesto"
        element={<NuevoPuesto />}
      />
      <Route
        path="/areas/editar-area/:id/editar-subarea/:subareaId/editar-puesto/:puestoId"
        element={<EditarPuesto />}
      />

      {/* reglas */}
      <Route path="/reglas" element={<Reglas />} />
      <Route path="/reglas/nuevo-valor" element={<NuevoValor />} />
      <Route path="/reglas/editar-valor/:id" element={<EditarValor />} />
      <Route path="/reglas/nuevo-regla" element={<NuevoRegla />} />
      <Route path="/reglas/editar-regla/:id" element={<EditarRegla />} />
      <Route path="/reglas/nuevo-sancion" element={<NuevoSancion />} />
      <Route path="/reglas/editar-sancion/:id" element={<EditarSancion />} />

      {/* Anuncios */}
      <Route path="/anuncios" element={<Anuncios />} />
      <Route path="/anuncios/nuevo-anuncio" element={<NuevoAnuncio />} />
      <Route path="/anuncios/editar-anuncio/:id" element={<EditarAnuncio />} />
      <Route path="/anuncios/trabajador-mes" element={<TrabajadoresMes />} />
      <Route
        path="/anuncios/trabajador-mes/nuevo-trabajador-mes"
        element={<NuevoTrabajadorMes />}
      />

      {/* Clientes User */}
      <Route path="/clientes-user" element={<ClientesUser />} />
      <Route
        path="/clientes-user/nuevo-cliente-user"
        element={<NuevoClienteUser />}
      />
      <Route
        path="/clientes-user/editar-cliente-user/:id"
        element={<EditarClienteUser />}
      />

      {/* Links */}
      <Route path="/links" element={<Links />} />
      <Route path="/links/nuevo-link" element={<NuevoLink />} />
      <Route path="/links/editar-link/:id" element={<EditarLink />} />
      <Route
        path="/links/editar-link/:id/nuevo-elemento"
        element={<NuevoElementoLink />}
      />
      <Route
        path="/links/editar-link/:id/editar-elemento/:elementoId"
        element={<EditarElementoLink />}
      />
      <Route
        path="/links/editar-link/:id/acceso-elemento/:elementoId"
        element={<AccesoElementoLink />}
      />

      {/* Recursos */}
      <Route path="/recursos" element={<Recursos />} />
      <Route path="/recursos/nuevo-recurso" element={<NuevoRecurso />} />
      <Route path="/recursos/editar-recurso/:id" element={<EditarRecurso />} />
      <Route path="/recursos/acceso-recurso/:id" element={<AccesoRecurso />} />

      {/* Proyectos */}
      <Route path="/proyectos" element={<Proyectos />} />
      <Route path="/proyectos/nuevo-proyecto" element={<NuevoProyecto />} />
      <Route
        path="/proyectos/editar-proyecto/:id"
        element={<EditarProyecto />}
      />
      <Route
        path="/proyectos/acceso-proyecto/:id"
        element={<AccesoProyecto />}
      />
      <Route
        path="/proyectos/editar-proyecto/:id/nuevo-elemento"
        element={<NuevoElementoProyecto />}
      />
      <Route
        path="/proyectos/editar-proyecto/:id/editar-elemento/:elementoId"
        element={<EditarElementoProyecto />}
      />

      {/* Comisiones */}

      <Route path="/comisiones-generales" element={<ComisionesGenerales />} />

      {/* Ventas */}
      <Route path="/ventas" element={<Ventas />} />
      <Route path="/ventas/nuevo-venta" element={<NuevoVenta />} />
      <Route path="/ventas/editar-venta/:id" element={<EditarVenta />} />
      <Route path="/ventas/acceso-venta/:id" element={<AccesoVenta />} />
      <Route
        path="/ventas/editar-venta/:id/nuevo-elemento"
        element={<NuevoElementoVenta />}
      />
      <Route
        path="/ventas/editar-venta/:id/editar-elemento/:elementoId"
        element={<EditarElementoVenta />}
      />

      {/* Clientes */}
      <Route path="/clientes" element={<Clientes />} />
      <Route path="/clientes/nuevo-cliente" element={<NuevoCliente />} />
      <Route path="/clientes/editar-cliente/:id" element={<EditarCliente />} />
      <Route path="/clientes/acceso-cliente/:id" element={<AccesoCliente />} />
      <Route
        path="/clientes/editar-cliente/:id/nuevo-elemento"
        element={<NuevoElementoCliente />}
      />
      <Route
        path="/clientes/editar-cliente/:id/editar-elemento/:elementoId"
        element={<EditarElementoCliente />}
      />

      {/* RRHH */}
      <Route path="/rrhh" element={<RRHH />} />
      <Route path="/rrhh/nuevo-rrhh" element={<NuevoRRHH />} />
      <Route path="/rrhh/editar-rrhh/:id" element={<EditarRRHH />} />
      <Route path="/rrhh/acceso-rrhh/:id" element={<AccesoRRHH />} />
      <Route
        path="/rrhh/editar-rrhh/:id/nuevo-elemento"
        element={<NuevoElementoRRHH />}
      />
      <Route
        path="/rrhh/editar-rrhh/:id/editar-elemento/:elementoId"
        element={<EditarElementoRRHH />}
      />

      {/* Learning */}
      <Route path="/learning" element={<Learning />} />
      <Route path="/learning/nuevo-learning" element={<NuevoLearning />} />
      <Route
        path="/learning/editar-learning/:id"
        element={<EditarLearning />}
      />
      <Route
        path="/learning/acceso-learning/:id"
        element={<AccesoLearning />}
      />
      <Route
        path="/learning/editar-learning/:id/nuevo-elemento"
        element={<NuevoElementoLearning />}
      />
      <Route
        path="/learning/editar-learning/:id/editar-elemento/:elementoId"
        element={<EditarElementoLearning />}
      />

      {/* Contabilidad */}
      <Route path="/contabilidad" element={<Contabilidad />} />
      <Route
        path="/contabilidad/nuevo-contabilidad"
        element={<NuevoContabilidad />}
      />
      <Route
        path="/contabilidad/editar-contabilidad/:id"
        element={<EditarContabilidad />}
      />
      <Route
        path="/contabilidad/acceso-contabilidad/:id"
        element={<AccesoContabilidad />}
      />
      <Route
        path="/contabilidad/editar-contabilidad/:id/nuevo-elemento"
        element={<NuevoElementoContabilidad />}
      />
      <Route
        path="/learning/editar-learning/:id/editar-elemento/:elementoId"
        element={<EditarElementoContabilidad />}
      />

      {/* Learning */}
      <Route path="/tutoriales" element={<Tutoriales />} />
      <Route path="/tutoriales/nuevo-tutorial" element={<NuevoTutorial />} />
      <Route
        path="/tutoriales/editar-tutorial/:id"
        element={<EditarTutorial />}
      />
      <Route
        path="/tutoriales/acceso-tutorial/:id"
        element={<AccesoTutorial />}
      />
      <Route
        path="/tutoriales/editar-tutorial/:id/nuevo-elemento"
        element={<NuevoElementoTutorial />}
      />
      <Route
        path="/tutoriales/editar-tutorial/:id/editar-elemento/:elementoId"
        element={<EditarElementoTutorial />}
      />

      {/* Estadísticas de Trabajador */}
      <Route
        path="/estadisticas-trabajador"
        element={<EstadisticasTrabajador />}
      />
      <Route path="/registros-asistencia" element={<RegistrosAsistencia />} />
      <Route path="/registros-sshh" element={<RegistrosSSHH />} />

      {/* Tareas Trabajadores */}
      <Route path="/registros-tareas" element={<ListaTareas />} />
      <Route path="/registros-tareas/nueva-tarea" element={<NuevaTarea />} />
      <Route
        path="/registros-tareas/lista-tareas/editar-tarea/:id"
        element={<NuevaTarea />}
      />
      <Route
        path="/registros-tareas/trazabilidad/:id"
        element={<RegistrosTareas />}
      />

      {/* Tareas ADMIN */}
      <Route path="/tareas-admin" element={<ListaTareasAdmin />} />
      <Route path="/tareas-admin/nueva-tarea" element={<NuevaTareaAdmin />} />
      <Route
        path="/tareas-admin/lista-tareas/editar-tarea/:id"
        element={<NuevaTareaAdmin />}
      />
      <Route
        path="/tareas-admin/trazabilidad/:id"
        element={<RegistrosTareasAdmin />}
      />

      {/* Incidencias */}
      <Route path="/incidencias" element={<Incidencias />} />
      <Route
        path="/incidencias/nueva-incidencia"
        element={<NuevaIncidencia />}
      />
      <Route
        path="/incidencias/editar-incidencia/:id"
        element={<EditarIncidencia />}
      />

      <Route
        path="/registro-busqueda-clientes"
        element={<RegistrosBusquedaCliente />}
      />

      <Route path="/cumpleaños-clientes" element={<CalendarioCumpleañosClientes />} />
      <Route path="/servicios" element={<Servicios />} />
      <Route path="/formularios" element={<Formularios />} />
      <Route path="/formularios/:id" element={<FormularioDetails/>} />

      <Route path="/procesos" element={<Procesos/>} />


    </Routes>
  );
};

export default HomeRoutes;
