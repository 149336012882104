import { createSlice } from "@reduxjs/toolkit";

const asistSlice = createSlice({
  name: "asist",
  initialState: {
    register: localStorage.getItem("registerStatus") ? localStorage.getItem("registerStatus") : [],
    error: null,
    loading: false,
  },
  reducers: {
    addAsistStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    addAsistSuccess: (state, action) => {
      state.loading = false;
      state.register = action.payload;
      localStorage.setItem("registerStatus", action.payload);
    },
    addAsistFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  addAsistStart,
  addAsistSuccess,
  addAsistFailure,
} = asistSlice.actions;

export default asistSlice.reducer;