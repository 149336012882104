import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import { BACKEND } from "../config/configEnvs";

function Anuncios() {
  const [anuncios, setAnuncios] = useState([]);

  const formatDate = (dateString) => {
    const formattedDate = moment(dateString)
      .tz("America/Lima") // Ajusta al horario de Perú
      .format("YYYY-MM-DD HH:mm:ss"); // Formato deseado
    return formattedDate;
  };

  useEffect(() => {
    const fetchAnuncios = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/anuncios`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setAnuncios(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAnuncios();
  }, []);

  const handleDelete = async (anuncioId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar esta anuncio?"
    );

    if (confirmed) {
      try {
        await axios.delete(`${BACKEND}/api/anuncios/${anuncioId}`);
        const updatedAnuncios = anuncios.filter(
          (anuncio) => anuncio._id !== anuncioId
        );
        setAnuncios(updatedAnuncios);
      } catch (error) {
        console.error("Error al eliminar el anuncio:", error);
      }
    }
  };

  return (
    <div className="w-full flex flex-col">
      <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
        Anuncios
      </h2>
      <div className="flex justify-between items-center">
        <div className="mb-4 mx-4 md:mx-8">
          <Link
            to="/home/anuncios/nuevo-anuncio"
            className="block px-4 py-2 text-sm  hover:bg-[#ff6400] bg-zinc-600 text-white"
            role="menuitem"
          >
            Añadir Anuncio
          </Link>
        </div>
        <div className="mb-4 mx-4 md:mx-8">
          <Link
            to="/home/anuncios/trabajador-mes"
            className="block px-4 py-2 text-sm hover:bg-[#ff6400] bg-zinc-600 text-white"
            role="menuitem"
          >
            Trabajador del Mes
          </Link>
        </div>
      </div>

      <div className="hidden md:flex px-8">
        <table className="w-full max-w-4xl mx-auto border-collapse">
          <thead className="bg-zinc-600 text-white">
            <tr>
              <th className="p-2">Anuncio</th>
              <th className="p-2">Fecha</th>
              <th className="p-2">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {anuncios.map((anuncio, index) => {
              return (
                <tr
                  key={anuncio.id}
                  className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
                >
                  <td className="border border-gray-300 p-2">
                    {anuncio.title}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {formatDate(anuncio.createdAt)}
                  </td>
                  <td className="border font-semibold border-gray-300 p-2">
                    <Link
                      to={`/home/anuncios/editar-anuncio/${anuncio.id}`}
                      className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                    >
                      Editar
                    </Link>
                    <button
                      onClick={() => handleDelete(anuncio.id)}
                      className="mx-2 my-1 text-red-600  rounded-lg"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Anuncios;
