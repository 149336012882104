import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import ItemTableCostosFijos from "./ItemTableCostosFijos";
import NuevoCostoFijo from "../../../dialogs/NuevoCostoFijo";

const TableCostosFijos = ({ costosFijosList, getCostosFijos }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <section className="  p-3 sm:p-5">
      <NuevoCostoFijo
        open={openModal}
        onClose={() => setOpenModal(false)}
        refetch={getCostosFijos}
      />

      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className=" text-gray-600 font-bold text-lg ">
              Costos Fijos
            </div>
            <button
              type="button"
              onClick={() => setOpenModal(true)}
              className="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2  focus:outline-none "
            >
              <FaPlus className=" mr-2" />
              Agregar costo
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Nombre
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Costo mensual
                  </th>

                  <th scope="col" className="px-4 py-3">
                    <span className="sr-only">Acciones</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {costosFijosList?.map((data) => (
                  <ItemTableCostosFijos
                    getCostosFijos={getCostosFijos}
                    key={data.id}
                    data={data}
                  />
                ))}
              </tbody>
            </table>
            <div className=" flex p-4">
              <div className=" flex flex-col gap-2">
                <span className=" text-gray-500  text-xs">Costo total</span>
                <span className=" text-gray-800 font-semibold text-base text-center">
                 S/ {costosFijosList.reduce(
                    (acc, value) => value.costoMensual + acc,
                    0
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TableCostosFijos;
