import React, { useState } from "react";
import DrawerLeft from "../ui/DrawerLeft";
import InputText from "../ui/InputText";
import SelectInput from "../ui/SelectInput";
import axios from "axios";
import { FaPlus, FaTrash } from "react-icons/fa";
import { BACKEND } from "../../config/configEnvs";
import { toast } from "react-toastify";
const NuevoServicioDrawer = ({
  open,
  onClose,
  title,
  planesList,
  refetch,
  trabajadoresList,
}) => {
  const [formValues, setFormValues] = useState({
    name: "",
    descripcion: "",
    encargado: null,
    subarea:"",
    cuentaComercialPublicitariaActive:false
  });

  const [preciosUnitarios, setPreciosunitarios] = useState([]);
  const [preciounitario, setPrecioUnitario] = useState({
    plan: "",
    precio: 0,
    puntos: 0,
    descripcion: ""
  });

  const handleAddPrecioUnitario = () => {
    setPreciosunitarios([...preciosUnitarios, preciounitario]);
    setPrecioUnitario({ plan: "", precio: 0, puntos: 0, descripcion: "" });
  };

  const handleDeletePreciounitario = (index) => {
    const updatedPairs = [...preciosUnitarios];
    updatedPairs.splice(index, 1);
    setPreciosunitarios(updatedPairs);
  };

  const handleInputChangePreciosUnitarios = (index, key, value) => {
    const updatedPairs = [...preciosUnitarios];
    updatedPairs[index][key] = value;
    setPreciosunitarios(updatedPairs);
  };

  const registrarServicio = async () => {
    if (
     
      preciosUnitarios.some(
        (e) => e.plan === "" || e.puntos === 0 || e.precio === 0
      )
    ) {
      toast.warning("Completa los planes o sus campos en blanco", {
        position: "top-left",
        style: { zIndex: 9999 },
      });
      return;
    }
    if (formValues.name === "") {
      toast.warning("Completa el nombre y descripción", {
        position: "top-left",
        style: { zIndex: 9999 },
      });
      return;
    }

    if (
      !formValues.encargado ||
      formValues.encargado === "Selecciona una opción"
    ) {
      toast.warning("Selecciona un encargado predeterminado", {
        position: "top-left",
        style: { zIndex: 9999 },
      });
      return;
    }
    try {
      await axios.post(`${BACKEND}/api/servicios`, {
        name: formValues.name,
        descripcion: formValues.descripcion,
        preciosUnitarios: preciosUnitarios,
        encargado: formValues.encargado,
        subarea:formValues.subarea,
        cuentaComercialPublicitariaActive:formValues.cuentaComercialPublicitariaActive
      });
      setFormValues({ name: "", descripcion: "" });
      setPreciosunitarios([]);
      onClose();
      toast.success("Registrado", { position: "top-center" });
      refetch();
    } catch (error) {
      toast.error("Error al registrar", {
        position: "top-center",
        style: { zIndex: 9999 },
      });
    }
  };

  return (
    <DrawerLeft onClose={onClose} open={open} maxWidth={700} title={title}>
      <InputText
        value={formValues.name}
        title={"Nombre"}
        setValue={(e) => setFormValues((prev) => ({ ...prev, name: e }))}
      />
      <SelectInput
        title={"Subarea"}
        value={formValues.subarea}
        setValue={(e) => setFormValues((prev) => ({ ...prev, subarea: e }))}
        options={[
          { id: 1, value: "Campañas", label: "Campañas" },
          { id: 2, value: "Social Media", label: "Social Media" },
          { id: 3, value: "CMS", label: "CMS" },
          { id: 4, value: "Code", label: "Code" },
          { id: 5, value: "Audiovisuales", label: "Audiovisuales" }
        ]}
      />
      <SelectInput
        title={"Encargado predeterminado"}
        value={formValues.encargado}
        setValue={(e) => setFormValues((prev) => ({ ...prev, encargado: e }))}
        options={trabajadoresList.map((e) => ({
          id: e.id,
          value: e.id,
          label: e.name + " - " + e?.puesto,
        }))}
      />

<div className=" pt-4">
        <div>
          <span className="block mb-2 text-sm font-medium text-gray-900 ">
            {" "}
            ¿ Habilitar cuenta publicitaria | cuenta agencia ?
          </span>
        </div>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            id={"cuentaComercialPublicitariaActive"}
            type="checkbox"
            value={formValues.cuentaComercialPublicitariaActive}
            checked={formValues.cuentaComercialPublicitariaActive}
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                cuentaComercialPublicitariaActive:
                  !formValues.cuentaComercialPublicitariaActive,
              }))
            }
            className="sr-only peer"
          />{" "}
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all  peer-checked:bg-blue-600"></div>
          <span className="ms-3 text-sm font-medium text-gray-900 ">
            {formValues.cuentaComercialPublicitariaActive
              ? "Habilitado"
              : "Deshabilitado"}
          </span>
        </label>
      </div>

      <div className=" mt-8 h-[1px] w-full bg-gray-200"></div>
      <div className=" mt-6">
        <div className=" flex justify-center ">
          <button
            type="button"
            className=" pb-1 text-sm dark:hover:text-blue-500 dark:hover:border-blue-500 dark:border-b  text-gray-700 hover:text-blue-600 hover:border-blue-600 hover:border-b  dark:text-blue-100  flex justify-start gap-2 items-center "
            onClick={handleAddPrecioUnitario}
          >
            Agregar Planes
            <FaPlus />
          </button>
        </div>
        <div className=" flex flex-col gap-4 pt-4">
          {preciosUnitarios.map((preciounitario, index) => (
            <div
              className="flex flex-col  gap-2 w-full shadow-md bg-gray-50  rounded-md p-2"
              key={index}
            >
              <div>
                <SelectInput
                  title={"Plan"}
                  setValue={(e) =>
                    handleInputChangePreciosUnitarios(index, "plan", e)
                  }
                  value={preciounitario.plan}
                  options={planesList.map((e) => ({
                    id: e.id,
                    value: e.id,
                    label: e.name,
                  }))}
                />
              </div>
              <div className=" grid grid-cols-2 gap-2">
                <div>
                  <label
                    htmlFor={"precio"}
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Precio
                  </label>

                  <input
                    type="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Precio"
                    value={preciounitario.precio}
                    onChange={(e) =>
                      handleInputChangePreciosUnitarios(
                        index,
                        "precio",
                        e.target.value
                      )
                    }
                  />
                </div>

                <div>
                  <label
                    htmlFor={"puntos"}
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Puntos
                  </label>
                  <input
                    type="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Puntos"
                    value={preciounitario.puntos}
                    onChange={(e) =>
                      handleInputChangePreciosUnitarios(
                        index,
                        "puntos",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
              <div>
                <div className=" py-3">
                  <label
                    htmlFor={"descripcion"}
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Descripción
                  </label>
                  <textarea
                    id="descripcion"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={preciounitario.descripcion}
                    onChange={(e) =>
                      handleInputChangePreciosUnitarios(
                        index,
                        "descripcion",
                        e.target.value
                      )
                    }
                  ></textarea>
                </div>
              </div>
              <div className=" flex justify-center  py-2">
                <button
                  className=" flex justify-center w-full md:max-w-xs items-center bg-red-50 text-red-800 p-2 rounded-lg border border-red-400 hover:bg-red-100"
                  type="button"
                  onClick={() => handleDeletePreciounitario(index)}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className=" mt-8 h-[1px] w-full bg-gray-200"></div>
      {/* <div className=" mt-6">
        <div className=" flex justify-center ">
          <button
            type="button"
            className=" pb-1 text-sm dark:hover:text-blue-500 dark:hover:border-blue-500 dark:border-b  text-gray-700 hover:text-blue-600 hover:border-blue-600 hover:border-b  dark:text-blue-100  flex justify-start gap-2 items-center "
            onClick={handleAddItemDescripcion}
          >
            Agregar Item Descripción
            <FaPlus />
          </button>
        </div>
        <div className=" flex flex-col gap-4 pt-4">
          {itemDescripciones.map((itemDesc, index) => (
            <div
              className="flex flex-col  gap-2 w-full shadow-md bg-gray-50  rounded-md p-2"
              key={index}
            >
              <div>
                <div>
                  <label
                    htmlFor={"descripcionItem"}
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Descripción
                  </label>

                  <input
                    type="text"
                    id="descripcionItem"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Descripción item"
                    value={itemDesc.item}
                    onChange={(e) =>
                      handleInputChangeItemDescripciones(
                        index,
                        "item",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>

              <div className=" flex justify-center  py-2">
                <button
                  className=" flex justify-center w-full md:max-w-xs items-center bg-red-50 text-red-800 p-2 rounded-lg border border-red-400 hover:bg-red-100"
                  type="button"
                  onClick={() => handleDeleteImteDescripcion(index)}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      <div className=" flex justify-center py-6">
        <button
          type="button"
          onClick={registrarServicio}
          className=" w-full md:max-w-xs rounded-md font-semibold p-2 text-white bg-[#FF6400] hover:bg-orange-600 text-sm  "
        >
          Registrar
        </button>
      </div>
    </DrawerLeft>
  );
};

export default NuevoServicioDrawer;
