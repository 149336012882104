import { startOfMonth } from "date-fns";
import React from "react";

const ItemRowPlanillaRegistro = ({ index, data }) => {

  return (
    <tr className="border-b dark:border-gray-700">
      <th
        scope="row"
        className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap "
      >
        {index + 1}
      </th>
      <td className="px-4 py-3">{data?.trabajador ? data.trabajador?.name : "Trabajador eliminado"}</td>
      <td className="px-4 py-3">{data?.trabajador?  data.trabajador?.puesto :"No aplica"}</td>
      <td className="px-4 py-3">{ data?.trabajador ? data.trabajador?.sueldo : "No aplica"}</td>
      <td className="px-4 py-3">{data?.descuento}</td>
      <td className="px-4 py-3">{data?.extras}</td>

     
    </tr>
  );
};

export default ItemRowPlanillaRegistro;
