import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BACKEND } from "../config/configEnvs";

function RRHH() {
  const [rrhh, setRRHH] = useState([]);

  useEffect(() => {
    const fetchRRHH = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/rrhh`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setRRHH(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchRRHH();
  }, []);

  const handleDelete = async (rrhhId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este rrhh?"
    );

    if (confirmed) {
      try {
        await axios.delete(`${BACKEND}/api/rrhh/${rrhhId}`);
        const updatedRRHH = rrhh.filter((rrhh) => rrhh._id !== rrhhId);
        setRRHH(updatedRRHH);
      } catch (error) {
        console.error("Error al eliminar el rrhh:", error);
      }
    }
  };

  return (
    <div className="w-full flex flex-col">
      <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
        RRHH
      </h2>
      <div className="flex justify-between items-center">
        <div className="mb-4 mx-4 md:mx-8">
          <Link
            to="/home/rrhh/nuevo-rrhh"
            className="block px-4 py-2 text-sm  hover:bg-[#ff6400] bg-zinc-600 text-white"
            role="menuitem"
          >
            Añadir RRHH
          </Link>
        </div>
      </div>

      <div className="hidden md:flex px-8">
        <table className="w-full max-w-4xl mx-auto border-collapse">
          <thead className="bg-zinc-600 text-white">
            <tr>
              <th className="p-2">Título</th>
              <th className="p-2">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {rrhh.map((rrhh, index) => {
              return (
                <tr
                  key={rrhh.id}
                  className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
                >
                  <td className="border border-gray-300 p-2">{rrhh.name}</td>
                  <td className="border font-semibold border-gray-300 p-2">
                    <Link
                      to={`/home/rrhh/editar-rrhh/${rrhh.id}`}
                      className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                    >
                      Editar
                    </Link>
                    <Link
                      to={`/home/rrhh/acceso-rrhh/${rrhh.id}`}
                      className="mx-2 my-1 text-blue-500  rounded-lg mr-2"
                    >
                      Acceso
                    </Link>
                    <button
                      onClick={() => handleDelete(rrhh.id)}
                      className="mx-2 my-1 text-red-600  rounded-lg"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RRHH;
