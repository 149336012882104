import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from '@fullcalendar/rrule'
import listPlugin from "@fullcalendar/list";
import multiMonthPlugin from "@fullcalendar/multimonth";
import axios from 'axios';
import Modal from 'react-modal';

import { useState } from "react";
import { useEffect } from "react";
import { BACKEND } from '../config/configEnvs';
import { RRule } from 'rrule';

function CalendarioCumpleañosClientes() {
    const [isMobile, setIsMobile] = useState(false);
    const [clients, setClients] = useState([]);
    const [cumpleaniosGerentes, setCumpleaniosGerentes] = useState([])
    const [cumpleaniosEncargados, setCumpleaniosEncargados] = useState([])
    const [fechaAniversario, setFechaAniversario] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [searchText, setSearchText] = useState([])
    const [stringSearch, setStringSearch] = useState(0)
    const [typeModal, setTypeModal] = useState(1)

    //console.log(selectedEvent)
    //console.log(searchText)
    //console.log(selectedEvent)
    //console.log(clients)
    //console.log(cumpleaniosGerentes)
    //console.log(cumpleaniosEncargados)
    //console.log(fechaAniversario)
    //console.log(searchText)

    const getClients = async () => {
        try {
            const response = await axios.get(
                `${BACKEND}/api/clientesuser?limit=10000`
            );
            setClients(response.data.docs)
        } catch (error) {
            console.log(error)
        }
    }

    function repetirFechaAnualmente(fecha) {
        if (!fecha) {
            return null; // o alguna otra forma de manejar fechas inválidas
        }
        const yearNow = new Date().getFullYear();
        const dateParts = fecha.split("T")[0].split("-");
        const month = dateParts[1];
        const day = dateParts[2];
        const nuevaFecha = new Date(`${yearNow}-${month}-${day}T00:00:00.000Z`);
        const nuevaFechaISO = nuevaFecha.toISOString();
        return nuevaFechaISO;
    }

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();

        setStringSearch(value.length)
    
        const filteredResults = clients.filter(client => {
    
            if (client.nombreComercial) {
                return client.nombreComercial.toLowerCase().includes(value);
            } else {
                return false;
            }
        });
        
        setSearchText(filteredResults)
    }


    useEffect(() => {

        const gerentes = []
        const encargados = []
        const aniversarios = []

        clients.forEach(client => {

            if (client.fechaCumpleanios) {

                var rrule = null

                if (isValidDate(client.fechaCumpleanios)) {
                    rrule = {
                        freq: RRule.YEARLY,
                        dtstart: new Date(client.fechaCumpleanios),
                    };                    
                } else {
                    rrule = {
                        freq: RRule.YEARLY,
                    }
                }
            
                encargados.push({
                    id: `${client.id}`, 
                    title: `Encargado ${client.encargadoProyecto} - ${client.nombreComercial}`, 
                    date: repetirFechaAnualmente(client.fechaCumpleanios),
                    color: "#5B9BF1",
                    textColor: "#000000",
                    email: `${client.emailGerencia}` || `${client.email}`,
                    className: "font-semibold text-md",
                    phoneAdmin: `${client.telefonoGerente}`,
                    phoneCompany: `${client.telefono}`,
                    rrule: rrule
                });
            }

            if (client.cumpleaniosGerente) {

                var rrule = null

                if (isValidDate(client.cumpleaniosGerente)) {
                    rrule = {
                        freq: RRule.YEARLY,
                        dtstart: new Date(client.cumpleaniosGerente),
                    };                    
                } else {
                    rrule = {
                        freq: RRule.YEARLY,
                    }
                }

                gerentes.push({ 
                    id: `${client.id}`, 
                    title: `Gerente ${client.nombreGerente} - ${client.nombreComercial}`, 
                    date: repetirFechaAnualmente(client.cumpleaniosGerente),
                    color: "#E76363",
                    textColor: "#000000",
                    email: `${client.emailGerencia}` || `${client.email}` ,
                    className: "font-semibold text-md",
                    phoneAdmin: `${client.telefonoGerente}`,
                    phoneCompany: `${client.telefono}`,
                    rrule: rrule
                });
            }

            if (client.fechaAniversario) {

                if (isValidDate(client.fechaAniversario)) {
                    rrule = {
                        freq: RRule.YEARLY,
                        dtstart: new Date(client.fechaAniversario),
                    };                    
                } else {
                    rrule = {
                        freq: RRule.YEARLY,
                    }
                }
                aniversarios.push({
                    id: `${client.id}`,  
                    title: `Aniversario ${client.nombreComercial}`, 
                    date: repetirFechaAnualmente(client.fechaAniversario),
                    color: "#6FC140",
                    textColor: "#000000",
                    email: `${client.emailGerencia}` || `${client.email}`,
                    className: "font-semibold text-md",
                    phoneAdmin: `${client.telefonoGerente}`,
                    phoneCompany: `${client.telefono}`,
                    rrule: rrule
                });
            }
        });

        setCumpleaniosGerentes(gerentes)
        setCumpleaniosEncargados(encargados)
        setFechaAniversario(aniversarios)

      }, [clients])

    useEffect(() => {

        getClients()
        const mediaQuery = window.matchMedia("(max-width: 900px)");
        setIsMobile(mediaQuery.matches);
        const handleMediaQueryChange = (event) => {
            setIsMobile(event.matches);
        };

        mediaQuery.addEventListener("change", handleMediaQueryChange);
        return () => {
            mediaQuery.removeEventListener("change", handleMediaQueryChange);
        };
    }, []);

    const openModal = (client, type) => {
        setTypeModal(type)
        setSelectedEvent(client);
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)
        setSelectedEvent(null)
    }

    const isValidDate = (date) => {
        return !isNaN(Date.parse(date));
    };

    function fixDate(fechaString) {
        const fecha = new Date(fechaString);
        fecha.setDate(fecha.getDate() + 1);
        return fecha;
    }
    
    return (
        <div className="calendar-container">

            <h2 className="text-slate-600 text-lg md:text-2xl font-bold mb-4">
                Cumpleaños de Clientes
            </h2>

            <div className="relative mb-4">
                <input 
                    type="text" 
                    id="searchInput" 
                    placeholder='Introducir empresa' 
                    className='mt-4'
                    onChange={handleSearchChange}
                />

                <div className="search-results z-50 absolute max-h-80 overflow-y-auto">
                    
                    {stringSearch > 1 ? (
                        searchText.length > 0 ? (
                            <ul className="bg-white border shadow-lg p-4 rounded-md">
                                {searchText.map(client => (
                                    <li className="my-2" key={client.id}>
                                        <a  onClick={() => openModal(client, 2)} className="cursor-pointer hover:bg-orange-100 transition duration-100 ease-in-out p-2 block rounded-md">{client.nombreComercial}</a>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="bg-white border shadow-lg p-4 rounded-md">No se encontraron coincidencias</p>
                        )
                    ) : (
                        false
                    )}
                </div>
            </div>

            <FullCalendar
                height="75vh"

                plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                    listPlugin,
                    multiMonthPlugin,
                    rrulePlugin
                ]}

                selectable={false}

                moreLinkText={"Ver todos"}
                locale={"es"}
                headerToolbar={{
                    start: isMobile ? "" : "title",
                    center: !isMobile && "multiMonthYear,dayGridMonth",
                    end: isMobile? "multiMonthYear,dayGridMonth": "prev,next",
                }}

                buttonText={{
                    today: "Hoy",
                    month: "Mes",
                    year: "Año",
                    timeGrid: "Semana",
                }}

                views={{
                    timeGridFourDay: {
                        type: "timeGrid",
                        duration: { days: 4 },
                        buttonText: "Semana",
                    },

                    timeGridWeek: {
                        duration: { days: isMobile ? 4 : 7 },
                        buttonText: "Semana",
                    },
                }}

                eventContent={function (arg) {
                    let containerEl = document.createElement("div");
                    containerEl.classList = `event-container cursor-pointer ${" hover:bg-gray-300"}`;
                    containerEl.style.transition = "background-color 0.2s ease";
    
                    let contentEl = document.createElement("p");
                    contentEl.classList = "event-content overflow-hidden";
    
                    contentEl.innerHTML = `<div class=" w-full flex justify-start   gap-2 items-center">
                    <div class=" flex-1  line-clamp-1">${arg.event.title}</div> 
                        <div class=" text-white rounded-md  ${arg.event.extendedProps.state ? "py-0.5  px-1" : ""} 
                            ${arg.event.extendedProps.state ? arg.event.extendedProps.state  : "" }
                        </div> 
                    </div>`;
    
                    containerEl.appendChild(contentEl);
    
                    containerEl.addEventListener("click", function (event) {
                        openModal(arg.event, 1);
                    });
    
                    return { domNodes: [containerEl] };
                }}

                defaultAllDay

                initialView="dayGridMonth"

                events={[...cumpleaniosGerentes, ...cumpleaniosEncargados, ...fechaAniversario]}
            />

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Event Details"
                ariaHideApp={false}
                style={{
                    zIndex: 9999,
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        zIndex: 9999,
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        border: 'none',
                        padding: '20px',
                        maxWidth: '400px',
                        transform: 'translate(-50%, -50%)',
                    },
                }}
            >
                {selectedEvent && typeModal === 1 ? (
                    <div className="modal-content">
                        <h2 className="text-xl font-bold mb-2">{selectedEvent.title}</h2>
                        <p className="text-sm mb-1">Fecha: {new Date(selectedEvent.start).toLocaleDateString()}</p>
                        <p className="text-sm mb-1">Email: {selectedEvent.extendedProps?.email || "N/A"}</p>
                        <p className="text-sm mb-1">Número telefónico Gerente: {selectedEvent.extendedProps?.phoneAdmin || "N/A"}</p>
                        <p className="text-sm mb-4">Número telefónico Compañia: {selectedEvent.extendedProps?.phoneCompany || "N/A"}</p>
                        <button className="bg-orange-500 hover:bg-orange-400 transition duration-200 ease-in-out text-white py-2 px-4 rounded-md text-sm"
                                onClick={closeModal}>
                            Cerrar
                        </button>
                    </div>
                ):
                    <div className="modal-content">
                        <h2 className="text-xl font-bold mb-2">{selectedEvent?.nombreComercial || "N/A"}</h2>
                        <p className="text-sm mb-1">Fecha Aniversario: {isValidDate(selectedEvent?.fechaAniversario) ? fixDate(selectedEvent.fechaAniversario).toLocaleDateString() : "N/A"}</p>
                        <p className="text-sm mb-1">Nombre Gerente: {selectedEvent?.nombreGerente || "N/A"}</p>
                        <p className="text-sm mb-1">Fecha Cumpleaños Gerente: {isValidDate(selectedEvent?.cumpleaniosGerente) ? fixDate(selectedEvent.cumpleaniosGerente).toLocaleDateString() : "N/A"}</p>
                        <p className="text-sm mb-1">Nombre Encargado: {selectedEvent?.encargadoProyecto || "N/A"}</p>
                        <p className="text-sm mb-1">Fecha Cumpleaños Encargado: {isValidDate(selectedEvent?.fechaCumpleanios) ? fixDate(selectedEvent.fechaCumpleanios).toLocaleDateString() : "N/A"}</p>
                        <p className="text-sm mb-1">Email: {selectedEvent?.email || "N/A"}</p>
                        <p className="text-sm mb-1">Número telefónico Gerente: {selectedEvent?.telefonoGerente || "N/A"}</p>
                        <p className="text-sm mb-4">Número telefónico Compañia: {selectedEvent?.telefono || "N/A"}</p>
                        <button className="bg-orange-500 hover:bg-orange-400 transition duration-200 ease-in-out text-white py-2 px-4 rounded-md text-sm"
                            onClick={closeModal}>
                            Cerrar
                        </button>
                    </div>
                }
            </Modal>
        </div>
    );
}

export default CalendarioCumpleañosClientes;

