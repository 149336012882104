import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BACKEND } from "../config/configEnvs";

function Proyectos() {
  const [proyectos, setProyectos] = useState([]);

  useEffect(() => {
    const fetchProyectos = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/proyectos`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setProyectos(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProyectos();
  }, []);

  const handleDelete = async (proyectoId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este proyecto?"
    );

    if (confirmed) {
      try {
        await axios.delete(`${BACKEND}/api/proyectos/${proyectoId}`);
        const updatedProyectos = proyectos.filter(
          (proyecto) => proyecto._id !== proyectoId
        );
        setProyectos(updatedProyectos);
      } catch (error) {
        console.error("Error al eliminar el proyecto:", error);
      }
    }
  };

  return (
    <div className="w-full flex flex-col">
      <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
        Proyectos
      </h2>
      <div className="flex justify-between items-center">
        <div className="mb-4 mx-4 md:mx-8">
          <Link
            to="/home/proyectos/nuevo-proyecto"
            className="block px-4 py-2 text-sm  hover:bg-[#ff6400] bg-zinc-600 text-white"
            role="menuitem"
          >
            Añadir Proyecto
          </Link>
        </div>
      </div>

      <div className="hidden md:flex px-8">
        <table className="w-full max-w-4xl mx-auto border-collapse">
          <thead className="bg-zinc-600 text-white">
            <tr>
              <th className="p-2">Título</th>
              <th className="p-2">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {proyectos.map((proyecto, index) => {
              return (
                <tr
                  key={proyecto.id}
                  className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
                >
                  <td className="border border-gray-300 p-2">
                    {proyecto.title}
                  </td>
                  <td className="border font-semibold border-gray-300 p-2">
                    <Link
                      to={`/home/proyectos/editar-proyecto/${proyecto.id}`}
                      className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                    >
                      Editar
                    </Link>
                    <Link
                      to={`/home/proyectos/acceso-proyecto/${proyecto.id}`}
                      className="mx-2 my-1 text-blue-500  rounded-lg mr-2"
                    >
                      Acceso
                    </Link>
                    <button
                      onClick={() => handleDelete(proyecto.id)}
                      className="mx-2 my-1 text-red-600  rounded-lg"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Proyectos;
