import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const EditarSubarea = () => {
  // Valores
  const { id, subareaId } = useParams();
  const [name, setName] = useState("");
  const [puestos, setPuestos] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/areas/${id}`);
        const subarea = response.data.subarea;
        const subareaData = subarea.find((p) => p.id === subareaId);
        if (subareaData) {
          setName(subareaData.name || "");
          setPuestos(subareaData.puesto || []);
        }
      } catch (error) {
        console.error("Error al obtener el area:", error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, subareaId]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${BACKEND}/api/areas/${id}`);
      const area = response.data;
      const subareaActualizado = area.subarea.map((subarea) => {
        if (subarea.id === subareaId) {
          return {
            ...subarea,
            name,
            puesto: puestos,
          };
        }
        return subarea;
      });

      await axios.put(`${BACKEND}/api/areas/${id}`, {
        ...area,
        subarea: subareaActualizado,
      });
      console.log("Subarea actualizado:", subareaActualizado);
      navigate(`/home/areas/editar-area/${id}`);
    } catch (error) {
      console.error("Error al actualizar el subarea:", error);
    }
  };

  const handleDelete = async (puestoId) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este puesto?"
    );

    if (confirmed) {
      try {
        const response = await axios.get(`${BACKEND}/api/areas/${id}`);
        const area = response.data;

        // Encuentra la subárea a editar
        const subareaData = area.subarea.find(
          (subarea) => subarea.id === subareaId
        );

        // Filtra los puestos para eliminar el que tenga el puestoId especificado
        const updatedPuestos = subareaData.puesto.filter(
          (puesto) => puesto.id !== puestoId
        );

        // Actualiza el array de puestos en la subárea
        subareaData.puesto = updatedPuestos;

        // Actualiza el área con la subárea modificada
        const updatedArea = {
          ...area,
          subarea: area.subarea.map((sub) =>
            sub.id === subareaId ? subareaData : sub
          ),
        };

        // Envía los datos actualizados al servidor para guardar los cambios
        await axios.put(`${BACKEND}/api/areas/${id}`, updatedArea);

        console.log("Puesto eliminado con éxito.");

        // Actualiza el estado local para reflejar el cambio
        setPuestos(updatedPuestos);
      } catch (error) {
        console.error("Error al eliminar el puesto:", error);
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-2xl">
        <h2 className="text-2xl mb-4">Editar Subarea</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="area" className="block mb-2">
              Nombre
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="hidden md:flex md:flex-col mb-4">
            <label htmlFor="descripcion" className="block mb-2">
              Subareas
              <Link
                to={`/home/areas/editar-area/${id}/editar-subarea/${subareaId}/nuevo-puesto`}
                className="ml-4 px-3 py-1 text-sm  hover:bg-[#ff6400] bg-zinc-600 text-white"
              >
                Añadir
              </Link>
            </label>
            <table className="w-full max-w-4xl mx-auto border-collapse">
              <thead className="bg-zinc-600 text-white">
                <tr>
                  <th className="p-2">Puestos</th>
                  <th className="p-2">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {puestos.map((puesto, index) => {
                  return (
                    <tr
                      key={puesto.id}
                      className={`${
                        index % 2 === 0 ? "bg-zinc-100" : "bg-white"
                      }`}
                    >
                      <td className="border border-gray-300 p-2">
                        {puesto.name}
                      </td>
                      <td className="border font-semibold border-gray-300 p-2">
                        <Link
                          to={`/home/areas/editar-area/${id}/editar-subarea/${subareaId}/editar-puesto/${puesto.id}`}
                          className="mx-2 my-1 text-green-500  rounded-lg mr-2"
                        >
                          Editar
                        </Link>
                        <button
                          onClick={() => handleDelete(puesto.id)}
                          className="mx-2 my-1 text-red-600 rounded-lg"
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end">
            <Link
              to={`/home/areas/editar-area/${id}`}
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Actualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarSubarea;
