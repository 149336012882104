import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { BACKEND } from "../../config/configEnvs";
import { toast } from "react-toastify";
import { FaBarcode, FaCommentDollar, FaEdit, FaExternalLinkAlt, FaMicrophoneAlt, FaPlay, FaPlus, FaPlusCircle, FaStop, FaTrash, FaTrashAlt } from "react-icons/fa";

const NuevoPago = () => {

  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  // Primer Tab

  const [workers, setWorkers] = useState([]);
  const [selectedWorker, setSelectedWorker] = useState("")

  const [month, setMonth] = useState(new Date().getMonth() + 1); // `getMonth()` retorna un valor entre 0 y 11
  const [year, setYear] = useState(new Date().getFullYear());
  const [datePayment, setDatePayment] = useState("")
  const [amount, setAmount] = useState(0)
  const [payed, setPayed] = useState(false)
  const [additionalNotes, setAdditionalNotes] = useState("")
  const [images, setImages] = useState([])

  // Handlers media

  const handleMediaUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
  
      const response = await axios.post(
        `${BACKEND}/api/imagenesPagosTrabajadores`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.data && response.data.doc && response.data.doc.id) {
        return response.data.doc.id;
      } else {
        throw new Error("El ID del archivo no se encontró en la respuesta.");
      }
    } catch (error) {
      toast.error("Error al subir el archivo", {
        style: { zIndex: 9999 },
        position: "top-right",
      });
      return null;
    }
  };

  const handleDeleteImagen = (indexToDelete) => {
    setImages((prevImages) => prevImages.filter((_, index) => index !== indexToDelete));
    toast.success("Imagen eliminada exitosamente");
  };

  // Funciones al montar el componente

  const fetchWorkers = async () => {
    try {
        const response = await axios.get(`${BACKEND}/api/getUsersIds/getWorkerUsers`)
        setWorkers(response.data)
    } catch (error) {
        toast.error("Error obteniendo los trabajadores: " + error)
    }
  }

  useEffect(() => {
    const now = new Date();
    setMonth(now.getMonth() + 1); // Ajustar el mes (de 0-11 a 1-12)
    setYear(now.getFullYear());
    fetchWorkers()
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let mediaIds = [];

    if (images) {
        for (const file of images) {
            const id = await handleMediaUpload(file);
            if (id) {
                mediaIds.push(id);
            }
        }
    }
  
    try {
        const response = await axios.post(`${BACKEND}/api/pagosTrabajadores`, {
            worker: selectedWorker,
            month,
            year,
            datePayment,
            payed,
            amount,
            additionalNotes,
            images: mediaIds
        });

        navigate("/home/pagos-trabajadores");
        toast.success("Pago añadido exitosamente")
    } catch (error) {
        toast.error("No se pudo crear el pago: " + error)
    }
  };
  
  
  return (
    <div className="flex items-center justify-center mt-12">
      <div className="bg-white rounded-lg w-full">

        <div className="flex flex-col px-8 py-2 bg-zinc-800 mb-4">
          <div className="flex items-center gap-4 p-4">
              <FaCommentDollar className="text-white text-2xl" />
              <h2 className="text-2xl font-bold text-white">Nuevo Pago</h2>
            </div>
        </div>

        <form className="p-4" onSubmit={handleSubmit}>


            <div className="flex flex-row space-x-4 border border-gray-400 p-4 rounded-md rounded-tl-none">
              <div className="flex-1">

                <div className="mb-4">
                  <label htmlFor="worker" className="block mb-2">
                    Trabajador <span className=" text-red-500">*</span>
                  </label>
                  <select
                    type="text"
                    id="worker"
                    value={selectedWorker}
                    onChange={(e) => setSelectedWorker(e.target.value)}
                    className="w-full border border-gray-300 rounded-lg"              required
                  >
                    <option value="">Seleccionar trabajador</option>
                    {workers.map((worker) => (
                      <option key={worker.id} value={worker.id}>
                        {worker.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4">
                  <label htmlFor="datePayment" className="block mb-2">
                  Fecha de pago: <span className=" text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    id="datePayment"
                    name="datePayment"
                    value={datePayment}
                    onChange={(e) => setDatePayment(e.target.value)}
                    className="w-full border border-gray-300 rounded-lg"
                    required
                  />
                </div>

                <div className="mb-4">
                    <label htmlFor="month" className="block mb-2">
                        Mes: <span className=" text-red-500">*</span>
                    </label>
                    <select
                        id="month"
                        name="month"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                        className="w-full border border-gray-300 rounded-lg"
                        required
                    >
                        <option value="1">Enero</option>
                        <option value="2">Febrero</option>
                        <option value="3">Marzo</option>
                        <option value="4">Abril</option>
                        <option value="5">Mayo</option>
                        <option value="6">Junio</option>
                        <option value="7">Julio</option>
                        <option value="8">Agosto</option>
                        <option value="9">Septiembre</option>
                        <option value="10">Octubre</option>
                        <option value="11">Noviembre</option>
                        <option value="12">Diciembre</option>
                    </select>
                </div>


                <div className="mb-4">
                    <label htmlFor="year" className="block mb-2">
                        Año: <span className=" text-red-500">*</span>
                    </label>
                    <select
                        type="number"
                        id="year"
                        name="year"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        className="w-full border border-gray-300 rounded-lg"
                        required
                    >
                        <option value="2024">2024</option>
                        <option value="2024">2025</option>

                    </select>
                </div>

                <div className="mb-4">
                    <label htmlFor="amount" className="block mb-2">
                        Monto
                    </label>
                    <label className="flex items-center cursor-pointer relative">
                        <input
                            type="number"
                            id="amount"
                            name="amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            className="w-full border border-gray-300 rounded-lg"
                            required
                        />
                    </label>
                </div>

                <div className="mb-4">
                    <label htmlFor="payed" className="block mb-2">
                        Pagado
                    </label>
                    <label className="flex items-center cursor-pointer relative">
                        <input
                            type="checkbox"
                            id="payed"
                            checked={payed}
                            onChange={(e) => setPayed(e.target.checked)}
                            className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                        </div>
                    </label>
                </div>



                <div className="mb-4">
                    <label htmlFor="additionalNotes" className="block mb-2">
                        Comentarios adicionales
                    </label>
                    <input
                        type="text"
                        id="additionalNotes"
                        value={additionalNotes}
                        onChange={(e) => setAdditionalNotes(e.target.value)}
                        className="w-full border border-gray-300 rounded-lg"
                    />
                </div>

                <div className="mb-4">

                  <div className="flex">
                    <label htmlFor="images" className="block mb-2">
                      <span className="">Imagenes</span> <span className="text-gray-500"> - max. 5 archivos de 5mb c/u</span>
                    </label>
                  </div>

                  <input
                    type="file"
                    id="images"
                    accept="image/*"
                    multiple
                    onChange={(e) => {
                      if (e.target.files) {
                        const maxFileSize = 5000000; // 5MB
                        const maxFiles = 5;
                        const filesArray = Array.from(e.target.files);

                        const validFiles = filesArray.filter((file) => file.size <= maxFileSize);
                        
                        if (filesArray.length > maxFiles) {
                          toast.warning(`Puedes seleccionar un máximo de ${maxFiles} archivos.`);
                          return; 
                        }
                        
                        if (validFiles.length < filesArray.length) {
                          toast.warning('Algunos archivos no fueron seleccionados porque superan el tamaño máximo de 5MB.');
                        }

                        setImages((prevPruebas) => {
                          const totalFiles = prevPruebas.length + validFiles.length;

                          if (totalFiles > maxFiles) {
                            toast.warning(`Solo puedes tener un máximo de ${maxFiles} archivos.`);
                            return prevPruebas;
                          }

                          return [...prevPruebas, ...validFiles];
                        });
                      }
                    }}
                    className="w-full border border-gray-300 rounded-lg"
                  />

                </div>         
              </div>
              
              <div className="w-1/3 p-4">
                <h1 className="mb-2">Imagen a subir: {" " + images.length}</h1>
                
                <div className="flex flex-col items-center h-[700px] gap-4 overflow-y-auto rounded-lg border border-gray-300 p-2">
                {images && images.length > 0 ? (
                  images.map((image, index) => {
                    const name = image.name || image.filename;
                    const mymeType = image.type;
                    const fileType = image.type.split('/')[0];
                    const url = URL.createObjectURL(image.blob ? image.blob : image);

                    return (
                      <div key={index} className="border p-3 rounded-md shadow-md mb-4 hover:bg-gray-100 transition-300">
                        <div>
                          
                          {fileType === 'image' && (
                            <div className="items-center space-y-6">
                              <div className="flex items-center justify-between">
                                <span className="mb-2">{name}</span>
                                <div className="flex space-x-2">
                                  <button
                                    type="button"
                                    onClick={() => window.open(url, '_blank')}
                                    className="p-2 text-blue-500 hover:bg-blue-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-blue-700 bg-opacity-10 flex items-center"
                                  >
                                    <FaExternalLinkAlt />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => handleDeleteImagen(index)}
                                    className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                                  >
                                    <FaTrashAlt />
                                  </button>
                                </div>
                              </div>
                              <img src={url} alt={`Preview ${"index"}`} className="w-full h-auto rounded" />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex items-center justify-center w-full h-full p-4">
                  No hay archivos subidos.
                </div>
                )}
                </div>
              </div>

            </div>

          <div className="flex justify-end space-x-4 mt-8">
            <Link
              to="/home/pagos-trabajadores"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 hover:bg-gray-300 transition duration-100 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="flex items-center px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition duration-100 mr-2"
            >
              <FaPlus className="mr-2 text-lg" />
              Regsitrar Pago
            </button>
          </div>
        </form>

      </div>
    </div>
  );
};

export default NuevoPago;
