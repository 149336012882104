import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";
import { toast } from "react-toastify";
import { FaEdit, FaExclamationCircle, FaExternalLinkAlt, FaMicrophoneAlt, FaPlay, FaPlus, FaStop, FaTrash, FaTrashAlt } from "react-icons/fa";

const EditarIncidencia = () => {

  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(1)

  // Primer tab
  const [trabajador, setTrabajador] = useState("");
  const [titulo, setTitulo] = useState("");
  const [descuento, setDescuento] = useState("");
  const [descuentoPorDefinir, setDescuentoPordefinir] = useState("")
  const [fecha, setFecha] = useState("");
  const [gravedad, setGravedad] = useState("")
  const [comentario, setComentario] = useState("");
  const [pruebas, setPruebas] = useState([]);
  const [trabajadores, setTrabajadores] = useState([]);
  const [links, setLinks] = useState([])
  const [existingPruebas, setExistingPruebas] = useState([])

  // Segundo Tab
  const [subsanado, setSubsanado] = useState("")
  const [fechaSubsanacion, setFechaSubsanacion] = useState("")
  const [comentarioSubsanacion, setComentarioSubsanacion] = useState("")

  // Estados de grabación de audio
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  // Handlers para la grabacion

  const generateFileName = () => {
    const date = new Date();
    const formattedDate = date.toISOString().replace(/[:.]/g, '-');
    return `Audio-${user.name}-${formattedDate}.wav`;
  };

  const startRecording = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorderRef.current = mediaRecorder;
          mediaRecorder.start();

          setIsRecording(true);

          mediaRecorder.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
          };

          mediaRecorder.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
            const fileName = generateFileName()
            
            const audioFile = new File([audioBlob], fileName, { type: 'audio/wav' })
            const audioUrl = URL.createObjectURL(audioBlob);

            if (audioFile.size <= 5000000 && pruebas.length < 5) {
              setPruebas((prevPruebas) => [...prevPruebas, audioFile]);
            } else {
              toast.warning('El archivo de audio excede el tamaño máximo de 5MB o se alcanzó el límite de 5 archivos.');
            }

            audioChunksRef.current = [];
          };
        })
        .catch((error) => {
          console.error('Error al acceder al micrófono:', error);
          toast.error('No se pudo acceder al micrófono.');
        });
    } else {
      toast.error('Tu navegador no soporta la grabación de audio.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  // Handlers

  const handleTrabajadorChange = (e) => {
    const selectedTrabajador = JSON.parse(e.target.value);
    setTrabajador(selectedTrabajador);
  };

  const handleLinkChange = (index, event) => {
    const newLinks = [...links];
    newLinks[index] = { link: event.target.value }; 
    setLinks(newLinks);
  };

  const handleSubsanadoChange = (e) => {
    const value = e.target.value;
    setSubsanado(value);
  
    if (value === "No") {
      setFechaSubsanacion("");
      setComentarioSubsanacion("");
    }
  };

  const handleDescuentoPorDefinir = (isChecked) => {
    setDescuentoPordefinir(isChecked)
    if (isChecked) {
      setDescuento(0)
    }
  }

  // Handlers links

  const addLinkField = () => {
    if (links.length < 5) {
      setLinks([...links, { link: "" }]);
    }
  };

  const removeLinkField = (index) => {
    const newLinks = links.filter((_, i) => i !== index); 
    setLinks(newLinks);
  };

  // Handlers media

  const handleMediaUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
  
      const response = await axios.post(
        `${BACKEND}/api/incidenciasMedia`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.data && response.data.doc && response.data.doc.id) {
        return response.data.doc.id;
      } else {
        throw new Error("El ID del archivo no se encontró en la respuesta.");
      }
    } catch (error) {
      console.error("Error al subir el archivo:", error.response ? error.response.data : error.message);
      toast.error("Error al subir el archivo", {
        style: { zIndex: 9999 },
        position: "top-right",
      });
      return null;
    }
  };
  
  const handleDeleteMedia = async (incidenciaId, mediaId) => {
    try {
      const confirmed = window.confirm(
        "¿Estás seguro de que deseas eliminar esta prueba?"
      );
      if (confirmed) {

        const fixedPruebas = existingPruebas
        .filter(prueba => prueba.id !== mediaId)
        .map(prueba => prueba.id);

        const response = await axios.put(`${BACKEND}/api/incidencias/${incidenciaId}`, {
          pruebas: fixedPruebas
        });

        const deletedImage = await axios.delete(`${BACKEND}/api/incidenciasMedia/${mediaId}`);

        fetchIncidencia()
        toast.success("Prueba eliminada exitosamente");
      }      
    } catch (error) {
      toast.error("La prueba no se pudo eliminar")
    }
  }

  const handleDeleteFromArray = (index) => {
    setPruebas(prevPruebas => prevPruebas.filter((_, i) => i !== index));
  };

  // Funciones al montar el componente

  const fetchTrabajadores = async () => {
    try {
      const response = await axios.get(`${BACKEND}/api/trabajadores`, {
        params: {
          limit: 1000,
        },
      });
      const data = response.data.docs;
      setTrabajadores(data);
    } catch (error) {
      toast.error("No se pudieron obtener los trabajadores");
    }
  };

  const fetchIncidencia = async () => {
    try {
      const response = await axios.get(`${BACKEND}/api/incidencias/${id}`);
      const incidencia = response.data;

      // Primer Tab
      const fechaFormateada = new Date(incidencia.fecha)
      .toISOString()
      .split("T")[0];
      setFecha(fechaFormateada);
      setGravedad(incidencia.gravedad || "Leve")
      setTitulo(incidencia.titulo || "");
      setTrabajador(incidencia.trabajador || "");
      setDescuento(incidencia.descuento || 0);
      setDescuentoPordefinir(incidencia.descuentoPorDefinir || false)
      setComentario(incidencia.comentario || "");
      setExistingPruebas(incidencia.pruebas || []);
      setLinks(incidencia.links || [])
 

      // Segundo Tab
      setSubsanado(incidencia.subsanado || "No")
      const fechaSubsanacionFormateada = incidencia.fechaSubsanacion
      ? new Date(incidencia.fechaSubsanacion).toISOString().split("T")[0]
      : "";
      setFechaSubsanacion(fechaSubsanacionFormateada);
      setComentarioSubsanacion(incidencia.comentarioSubsanacion || "")
    } catch (error) {
      toast.error("Error al cargar la incidencia")
    }
  };

  useEffect(() => {
    fetchTrabajadores();
    fetchIncidencia();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let mediaIds = [];
    if (pruebas) {
      for (const file of pruebas) {
        const id = await handleMediaUpload(file);
        if (id) {
          mediaIds.push(id);
        }
      }
    }
    const existintPruebasIds = existingPruebas.map(prueba => prueba.id);
    const allPruebasIds = existintPruebasIds.concat(mediaIds);

    try {
      const response = await axios.put(`${BACKEND}/api/incidencias/${id}`, {
         // Primer tab
        fecha,
        gravedad,
        titulo,
        trabajador: trabajador.id,
        descuento,
        descuentoPorDefinir,
        comentario,
        pruebas: allPruebasIds,
        links,

        // Segundo tab
        subsanado,
        fechaSubsanacion,
        comentarioSubsanacion,
      });

      toast.success("Incidencia actualizada exitosamente")
      navigate("/home/incidencias");
      setPruebas([])
    } catch (error) {
      console.error("Error al actualizar la incidencia:", error);
      toast.error("Error al actualizar la incidencia")
    }
  };

  return (
    <div className="flex items-center justify-center mt-12">
      <div className="bg-white rounded-lg w-full">

          <div className="flex flex-col px-8 py-2 bg-zinc-800 mb-4">
            <div className="flex items-center gap-4 p-4">
                <FaEdit className="text-white text-2xl" />
                <h2 className="text-2xl font-bold text-white">Editar Incidencia</h2>
              </div>
          </div>

        <form onSubmit={handleSubmit} className="p-4">

          <div className="mb-1 z-10">
            <span 
              className={`${selectedTab === 1 ? "bg-orange-500 hover:bg-orange-500 text-white" : "bg-orange-100"} px-4 py-2 rounded-t-md border-t border-l border-r border-gray-300 cursor-pointer hover:bg-orange-300 transition duration-100`}
              onClick={() => setSelectedTab(1)}
            >
              Editar incidencia
            </span>
            <span 
              className={`${selectedTab === 2 ? "bg-orange-500 hover:bg-orange-500 text-white" : "bg-orange-100"} px-4 py-2 rounded-t-md border-t border-l border-r border-gray-300 cursor-pointer hover:bg-orange-300 transition duration-100`}
              onClick={() => setSelectedTab(2)}
            >
              Subsanación
            </span>
          </div>
          
          {selectedTab === 1 &&
            <div className="flex flex-row space-x-4 border border-gray-400 p-4 rounded-md rounded-tl-none">

              <div className="flex-1">

                <div className="mb-4">
                  <label htmlFor="fecha" className="block mb-2">
                    Fecha:
                  </label>
                  <input
                    type="date"
                    id="fecha"
                    name="fecha"
                    value={fecha}
                    onChange={(e) => setFecha(e.target.value)}
                    className="w-full border border-gray-300 rounded-lg"
                    required
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="gravedad" className="block mb-2">
                    Gravedad:
                  </label>
                  <select
                    type="text"
                    id="gravedad"
                    name="gravedad"
                    value={gravedad}
                    onChange={(e) => setGravedad(e.target.value)}
                    className="w-full border border-gray-300 rounded-lg"
                    required
                  >
                    <option value="Leve">Leve</option>
                    <option value="Moderada">Moderada</option>
                    <option value="Grave">Grave</option>
                    <option value="Muy grave">Muy grave</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label htmlFor="titulo" className="block mb-2">
                    Título
                  </label>
                  <input
                    type="text"
                    id="titulo"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                    className="w-full border border-gray-300 rounded-lg"
                    required
                  />
                </div>

                <div className="mb-4">
                  <div className="flex justify-between items-center mb-2">
                    <label htmlFor="descuento" className="block mb-2">
                      Descuento
                    </label>
                    <div className="flex items-center gap-2">
                      <label htmlFor="descuentoPorDefinir" className="block">
                        Por definir
                      </label>
                      <input
                        className="rounded cursor-pointer" 
                        type="checkbox" 
                        name="descuentoPorDefinir" 
                        id="descuentoPorDefinir" 
                        checked={descuentoPorDefinir}
                        onChange={(e) => handleDescuentoPorDefinir(e.target.checked)}
                      />
                    </div>
                  </div>
                  <input
                    type="number"
                    id="descuento"
                    value={descuento}
                    onChange={(e) => setDescuento(e.target.value)}
                    className={`w-full border border-gray-300 rounded-lg ${descuentoPorDefinir ? "bg-gray-200 text-gray-500" : ""}`}
                    min={0}
                    disabled={descuentoPorDefinir}
                    required={!descuentoPorDefinir}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="comentario" className="block mb-2">
                    Comentario
                  </label>
                  <input
                    type="text"
                    id="comentario"
                    value={comentario}
                    onChange={(e) => setComentario(e.target.value)}
                    className="w-full border border-gray-300 rounded-lg"
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="trabajador" className="block mb-2">
                    Trabajador <span className=" text-red-500">*</span>
                  </label>
                  <select
                    type="text"
                    id="trabajador"
                    value={JSON.stringify(trabajador)}
                    onChange={handleTrabajadorChange}
                    className="w-full border border-gray-300 rounded-lg"
                    required
                  >
                    <option value="">Seleccionar trabajador</option>
                    {trabajadores.map((trabajador) => (
                      <option key={trabajador.id} value={JSON.stringify(trabajador)}>
                        {trabajador.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4">
                  <div className="flex">
                    <label htmlFor="pruebas" className="block mb-2">
                      <span className="font-bold">Pruebas</span> <span className="text-gray-500"> - max. 5 archivos de 5mb c/u</span>
                    </label>
                  </div>

                  <input
                    type="file"
                    id="pruebas"
                    accept="image/*,video/*,audio/*"
                    multiple
                    onChange={(e) => {
                      if (e.target.files) {
                        const maxFileSize = 5000000; // 5MB
                        const maxFiles = 5;
                        const validFiles = Array.from(e.target.files).filter((file) => file.size <= maxFileSize);
                        
                        const availablePruebas = maxFiles - (existingPruebas.length + pruebas.length)

                        if (validFiles.length > availablePruebas) {
                          toast.warning('Pueden existir un máximo de 5 archivos');
                          return
                        }

                        if (validFiles.length < e.target.files.length) {
                          toast.warning('Algunos archivos no fueron seleccionados porque superan el tamaño máximo de 5MB.');
                        }

                        setPruebas((prevPruebas) => {
                          const totalFiles = prevPruebas.length + validFiles.length;

                          if (totalFiles > maxFiles) {
                            toast.warning(`Solo puedes tener un máximo de ${maxFiles} archivos.`);
                            return prevPruebas;
                          }

                          return [...prevPruebas, ...validFiles];
                        });
                      }
                    }}
                    className="w-full border border-gray-300 rounded-lg"
                  />
                </div>

                <div className="mb-4">

                  <div className="flex">
                    <label htmlFor="audios" className="block mb-2">
                      <span className="font-bold">Audios</span> 
                      <span className="text-gray-500"> - max. 5 archivos de 5MB c/u</span>
                    </label>
                  </div>

                  <div className="flex gap-4">
                  <button
                    type="button"
                    onClick={startRecording}
                    disabled={isRecording || pruebas.length + existingPruebas.length >= 5}
                    className={`${isRecording || pruebas.length + existingPruebas.length>= 5 ? "px-4 py-2 bg-gray-400 cursor-not-allowed text-white rounded-lg flex items-center" : "flex items-center px-4 py-2 rounded-lg bg-green-500 text-white hover:bg-green-600"}`}
                  >
                    {isRecording ? (
                      <>
                        <FaMicrophoneAlt className="mr-2" />Grabando... 
                      </>
                    ) : (
                      <>
                        <FaPlay className="mr-2" /> Iniciar Grabación 
                      </>
                    )}
                  </button>

                    <button
                      type="button"
                      onClick={stopRecording}
                      disabled={!isRecording}
                      className="px-4 py-2 bg-red-500 text-white rounded-lg flex items-center"
                      >
                      <>
                        <FaStop className="mr-2" /> Detener Grabación
                      </>
                      
                    </button>
                  </div>

                </div>


                <div className="mb-4">
                  <label htmlFor="links" className="block mb-2">
                    Links
                  </label>
                  {links.map((link, index) => (
                    <>
                      <div key={index} className="flex border rounded-lg mb-3">
                        <input
                          type="text"
                          value={link.link}
                          placeholder="Ingrese un link..."
                          onChange={(event) => handleLinkChange(index, event)}
                          className="w-full border border-white rounded-l-lg px-2 py-2"
                        />
                        <button
                          type="button"
                          onClick={() => removeLinkField(index)}
                          className="inline-flex items-center px-3 text-sm text-red-600 bg-gray-200 border border-gray-300 hover:bg-red-200 rounded-r-md"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </>
                  ))}
                  {links.length < 5 && (
                    <button
                      type="button"
                      onClick={addLinkField}
                      className="w-full border border-gray-300 rounded-lg flex items-center justify-center p-2 mt-2 text-gray-700 hover:bg-blue-500 hover:bg-opacity-25"
                    >
                      <FaPlus className="mr-2" /> Añadir Link
                    </button>
                  )}
                </div>
                
              </div>



              <div className="w-1/3 p-4">

                {pruebas && pruebas.length > 0 && (
                  <>
                    <h1 className="mb-2">Pruebas por subir: {" " + pruebas.length}</h1>
                    <div className="flex flex-col items-center gap-4 overflow-y-auto rounded-lg border border-gray-300 p-2 mb-4">
                      
                        <>
                          
                          {pruebas.map((prueba, index) => {
                            const url = URL.createObjectURL(prueba);
                            const name = prueba.name;
                            const mymeType = prueba.type;
                            const fileType = prueba.type.split('/')[0];

                            return (
                              <div key={index} className="border p-3 rounded-md shadow hover:bg-gray-100 transition-300">
                                <div>
                                  {fileType === 'image' && (
                                    <div className="items-center space-y-6">
                                      <div className="flex items-center justify-between">
                                        <span className="mb-2">{name}</span>
                                        <div className="flex space-x-2">
                                          <button
                                            type="button"
                                            onClick={() => window.open(url, '_blank')}
                                            className="p-2 text-blue-500 hover:bg-blue-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-blue-700 bg-opacity-10 flex items-center"
                                          >
                                            <FaExternalLinkAlt />
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() => handleDeleteFromArray(index)}
                                            className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                                          >
                                            <FaTrashAlt />
                                          </button>
                                        </div>
                                      </div>
                                      <img src={url} alt={`Preview ${index}`} className="w-full h-auto rounded" />
                                    </div>
                                  )}

                                  {fileType === 'video' && (
                                    <div className="items-center space-y-6">
                                      <div className="flex items-center justify-between">
                                        <span className="mb-2">{name}</span>
                                        <div className="flex space-x-2">
                                          <button
                                            type="button"
                                            onClick={() => window.open(url, '_blank')}
                                            className="p-2 text-blue-500 hover:bg-blue-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-blue-700 bg-opacity-10 flex items-center"
                                          >
                                            <FaExternalLinkAlt />
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() => handleDeleteFromArray(index)}
                                            className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                                          >
                                            <FaTrashAlt />
                                          </button>
                                        </div>
                                      </div>
                                      <video controls className="w-full h-auto rounded">
                                        <source src={url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </div>
                                  )}

                                  {fileType === 'audio' && (
                                    <div className="items-center justify-center space-y-6">
                                      <div className="flex items-center justify-between">
                                        <span className="mb-2">{name}</span>
                                        <div className="flex space-x-2">
                                          <button
                                            type="button"
                                            onClick={() => window.open(url, '_blank')}
                                            className="p-2 text-blue-500 hover:bg-blue-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-blue-700 bg-opacity-10 flex items-center"
                                          >
                                            <FaExternalLinkAlt />
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() => handleDeleteFromArray(index)}
                                            className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                                          >
                                            <FaTrashAlt />
                                          </button>
                                        </div>
                                      </div>
                                      <audio controls className="w-full max-h-12">
                                        <source src={url} type={mymeType} />
                                        Your browser does not support the audio element.
                                      </audio>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </>
                    
                    </div>
                  </>
                )}

                <h1 className="mb-2">Pruebas subidas: {" " + existingPruebas.length}</h1>

                <div className="flex flex-col items-center overflow-y-auto rounded-lg gap-4 border border-gray-300 p-2">
                  {existingPruebas && existingPruebas.length > 0 ? (
                    existingPruebas.map((prueba, index) => { 
                      const incidenciaId = id;
                      const mediaId = prueba.id;
                      const url = prueba.url;
                      const name = prueba.filename;
                      const mymeType = prueba.mymeType;
                      const fileType = prueba.mimeType.split('/')[0];

                      return (
                        <div key={index} className="border p-3 rounded-md shadow hover:bg-gray-100 transition-300">
                          <div>
                            {fileType === 'image' && (
                              <div className="items-center space-y-6">
                                <div className="flex items-center justify-between">
                                  <span className="mb-2">{name}</span>
                                  <div className="flex space-x-2">
                                    <button
                                      type="button"
                                      onClick={() => window.open(url, '_blank')}
                                      className="p-2 text-blue-500 hover:bg-blue-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-blue-700 bg-opacity-10 flex items-center"
                                    >
                                      <FaExternalLinkAlt />
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => handleDeleteMedia(incidenciaId, mediaId)}
                                      className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                                    >
                                      <FaTrashAlt />
                                    </button>
                                  </div>
                                </div>
                                <img src={url} alt={`Preview ${index}`} className="w-full h-auto rounded" />
                              </div>
                            )}

                            {fileType === 'video' && (
                              <div className="items-center space-y-6">
                                <div className="flex items-center justify-between">
                                  <span className="mb-2">{name}</span>
                                  <div className="flex space-x-2">
                                    <button
                                      type="button"
                                      onClick={() => window.open(url, '_blank')}
                                      className="p-2 text-blue-500 hover:bg-blue-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-blue-700 bg-opacity-10 flex items-center"
                                    >
                                      <FaExternalLinkAlt />
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => handleDeleteMedia(incidenciaId, mediaId)}
                                      className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                                    >
                                      <FaTrashAlt />
                                    </button>
                                  </div>
                                </div>
                                <video controls className="w-full h-auto rounded">
                                  <source src={url} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            )}

                            {fileType === 'audio' && (
                              <div className="items-center justify-center space-y-6">
                                <div className="flex items-center justify-between">
                                  <span className="mb-2">{name}</span>
                                  <div className="flex space-x-2">
                                    <button
                                      type="button"
                                      onClick={() => window.open(url, '_blank')}
                                      className="p-2 text-blue-500 hover:bg-blue-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-blue-700 bg-opacity-10 flex items-center"
                                    >
                                      <FaExternalLinkAlt />
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => handleDeleteMedia(incidenciaId, mediaId)}
                                      className="p-2 text-red-500 hover:bg-red-600 hover:bg-opacity-25 hover:cursor-pointer rounded-md bg-red-700 bg-opacity-10 flex items-center"
                                    >
                                      <FaTrashAlt />
                                    </button>
                                  </div>
                                </div>
                                <audio controls className="w-full max-h-12">
                                  <source src={url} type={mymeType} />
                                  Your browser does not support the audio element.
                                </audio>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="flex items-center justify-center w-full h-full p-4">
                      No hay pruebas disponibles
                    </div>
                  )}
                </div>
              </div>
            </div>
          }

          {selectedTab === 2 &&
            <div className="flex flex-row space-x-4 border border-gray-400 p-4 rounded-md rounded-tl-none">
              <div className="flex-1">

                <div className="mb-4">
                  <label htmlFor="subsanado" className="block mb-2">
                  Subsanado:
                  </label>
                  <select
                    type="text"
                    id="subsanado"
                    name="subsanado"
                    value={subsanado}
                    onChange={handleSubsanadoChange}
                    className="w-full border border-gray-300 rounded-lg"
                  >
                    <option value="No">No</option>
                    <option value="Si">Si</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label htmlFor="fecha" className="block mb-2">
                  Fecha subsanación:
                  </label>
                  <input
                    type="date"
                    id="fechaSubsanacion"
                    name="fechaSubsanacion"
                    value={fechaSubsanacion}
                    onChange={(e) => setFechaSubsanacion(e.target.value)}
                    className={`w-full border border-gray-300 rounded-lg ${subsanado === "No" ? "bg-gray-200 text-gray-500" : ""}`}
                    disabled={subsanado === "No"}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="fecha" className="block mb-2">
                  Comentario:
                  </label>
                  <input
                    type="text"
                    id="comentarioSubsanacion"
                    name="comentarioSubsanacion"
                    value={comentarioSubsanacion}
                    onChange={(e) => setComentarioSubsanacion(e.target.value)}
                    className={`w-full border border-gray-300 rounded-lg ${subsanado === "No" ? "bg-gray-200 text-gray-500" : ""}`}
                    disabled={subsanado === "No"}
                  />
                </div>

              </div>
            </div>
          }

          <div className="flex justify-end space-x-4 mt-8">
            <Link
              to="/home/incidencias"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 hover:bg-gray-300 transition duration-100 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="flex items-center px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition duration-100 mr-2"
            >
              <FaEdit className="mr-2 text-lg"/>
              Actualizar Incidencia
            </button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default EditarIncidencia;



