import React, { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import { MultiSelect } from "react-multi-select-component";
import { Tab } from "@headlessui/react";
import useFetchRequestGet from "../../hooks/useFetch";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";
import { toast } from "react-toastify";
const categories = [
  {
    name: "Nuevo",
    id: 1,
  },
  {
    name: "Lista",
    id: 2,
  },
];

const FiltradoRapidoServiciosDialog = ({ open, onClose }) => {
  const { dataList: servicesList } = useFetchRequestGet({
    endpoint: "servicios",
    limit: 50,
    page: 1,
  });
  const { dataList: filterList, refetch: refetchFilters } = useFetchRequestGet({
    endpoint: "servicioFiltrado",
    limit: 50,
    page: 1,
  });
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [currentTab, setCurrentTab] = useState(1);
  const handleAddFilterSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios
        .post(`${BACKEND}/api/servicioFiltrado`, {
          name: filterName,
          servicios: selected.map((e) => ({ item: e.value })),
        })
        .then(() => refetchFilters());
      toast.success("Agregado");
      setFilterName("");
      setSelected([]);
    } catch (error) {
      toast.error("Ocurrió un error");
    }
  };
  return (
    <Modal
      activeModal={open}
      title={"Filtrado rápido  servicios"}
      onClose={onClose}
      centered={true}
    >
      <div className="flex w-full justify-center px-4">
        <div className="w-full max-w-md ">
          <Tab.Group>
            <Tab.List className=" gap-4 border-b border-b-gray-100 pb-1 flex justify-center gap-2">
              {categories.map(({ name, id }) => (
                <Tab
                  onClick={() => setCurrentTab(id)}
                  key={name}
                  className={` ${
                    currentTab === id ? "bg-orange-50" : ""
                  } rounded-md py-1 px-3 text-sm/6 font-semibold text-orange-700 `}
                >
                  {name}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-3">
              <Tab.Panel key={1} className="rounded-xl bg-white/5 p-3">
                <form onSubmit={handleAddFilterSubmit} className=" ">
                  <div className=" mb-6">
                    <label
                      htmlFor="items"
                      class="block  mb-1 text-sm font-medium text-gray-700 "
                    >
                      Servicios
                    </label>
                    <MultiSelect
                      options={servicesList.map((e) => ({
                        label: e.name,
                        value: e.id,
                      }))}
                      value={selected}
                      onChange={setSelected}
                      className="z-[9999]"
                      labelledBy="Selecciona los servicios"
                    />
                  </div>
                  <div className=" mt-4">
                    <label
                      htmlFor="nameFilter"
                      class="block mb-1 text-sm font-medium text-gray-700 "
                    >
                      Nombre
                    </label>

                    <input
                      type="text"
                      id="nameFilter"
                      className="mb-6  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      value={filterName}
                      onChange={(e) => setFilterName(e.target.value)}
                    />
                  </div>

                  <div className=" pt-10 flex justify-center pb-20 ">
                    <button
                      type="submit"
                      className=" w-full font-semibold max-w-xs bg-orange-500 bg-opacity-10 hover:bg-opacity-20 transition duration-300 text-orange-500 text-center p-2 rounded-md"
                    >
                      Crear filtro rápido
                    </button>
                  </div>
                </form>
              </Tab.Panel>
              <Tab.Panel key={2} className="rounded-xl bg-white/5 p-3">
                <div className="flex flex-col gap-4  divide-y divide-gray-200 pb-[200px] ">
                  {filterList.map((e) => (
                    <FilterComponent
                      filter={e}
                      servicesList={servicesList}
                      refetch={refetchFilters}
                    />
                  ))}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </Modal>
  );
};

export default FiltradoRapidoServiciosDialog;

const FilterComponent = ({ filter, servicesList, refetch }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterName, setFilterName] = useState("");
  useEffect(() => {
    if (filter.servicios) {
      setSelectedItems(
        filter.servicios.map((e) => ({ label: e.item.name, value: e.item.id }))
      );
    }
    setFilterName(filter.name);
  }, [filter]);

  const handleUpdate = async () => {

    try {
      await axios.put(`${BACKEND}/api/servicioFiltrado/${filter.id}`, {
        name: filterName,
        servicios: selectedItems.map((e) => ({ item: e.value })),
      });
      toast.success("Actualizado");
    } catch (error) {
      toast.error("Ocurrió un error");
    }
  };

  const handleDelete = async () => {
   if(! window.confirm("¿Seguro de eliminar este item?"))return
    try {
      await axios
        .delete(`${BACKEND}/api/servicioFiltrado/${filter.id}`)
        .then(() => refetch());
      toast.success("Eliminado");
    } catch (error) {
      toast.error("Ocurrió un error");
    }
  };

  return (
    <div className="  pb-2 pt-4 ">
      <label
        htmlFor="nameFilter"
        class="block mb-1 text-sm font-medium text-gray-700 "
      >
        Nombre
      </label>

      <input
        type="text"
        id="nameFilter"
        aria-label="disabled input"
        className="mb-6  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
        value={filterName}
        onChange={(e) => setFilterName(e.target.value)}
      />

      <MultiSelect
        options={servicesList.map((e) => ({
          label: e.name,
          value: e.id,
        }))}
        value={selectedItems}
        onChange={setSelectedItems}
        className="z-[9999]"
        labelledBy="Selecciona los servicios"
      />

      <div className=" flex justify-between gap-2 mt-2 ">
        <button
          onClick={handleUpdate}
          className=" flex-1  px-4 py-2 rounded-md font-semibold bg-orange-600 text-orange-700 bg-opacity-10 hover:bg-opacity-20 text-center flex justify-center "
          type="button"
        >
          Actualizar
        </button>
        <button
         onClick={handleDelete}
          className="  flex px-4 py-2 rounded-md font-semibold bg-red-600 text-red-600 bg-opacity-10 hover:bg-opacity-20 transition duration-300 text-center"
          type="button"
        >
          Eliminar
        </button>
      </div>
    </div>
  );
};
