import React, { useEffect, useState } from "react";
import { MdDelete, MdSave } from "react-icons/md";
import axios from "axios";
import { BACKEND } from "../../../../config/configEnvs";
import { toast } from "react-toastify";
const ItemTableCostosVariables = ({ data, getCostosVariables }) => {
  const [formValues, setFormValues] = useState({ nombre: "", costoMensual: 0 });

  useEffect(() => {
    setFormValues({ nombre: data.nombre, costoMensual: data.costoMensual });
  }, []);

  const saveItemCosto = async () => {
    try {
      await axios.put(`${BACKEND}/api/costosVariables/${data.id}`, formValues);

      toast.success("Guardado");
      getCostosVariables();
    } catch (error) {
      toast.error("Hubo un erorr al guardar la planilla mensual");
    }
  };

  const deleteItemCosto = async () => {
    if (!window.confirm("Estas seguro")) {
      return;
    }
    try {
      await axios.delete(`${BACKEND}/api/costosVariables/${data.id}`);

      toast.success("Eliminado");
      getCostosVariables();
    } catch (error) {
      toast.error("Hubo un erorr al guardar la planilla mensual");
    }
  };

  return (
    <tr class="border-b ">
      <th
        scope="row"
        className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap "
      >
        <input
          type="text"
          id="descuento"
          value={formValues.nombre}
          onChange={(e) =>
            setFormValues((prev) => ({ ...prev, nombre: e.target.value }))
          }
          // onChange={(e)=>updateObjectDescuento(data.trabajador.id,Number(e.target.value))}
          className="block  p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500"
        />
      </th>
      <td className="px-4 py-3">
        {" "}
        <input
          type="number"
          id="descuento"
          value={formValues.costoMensual}
          onChange={(e) =>
            setFormValues((prev) => ({
              ...prev,
              costoMensual: Number(e.target.value),
            }))
          }
          // onChange={(e)=>updateObjectDescuento(data.trabajador.id,Number(e.target.value))}
          className="block  p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500"
        />
      </td>

      <td className="px-4 py-3 flex items-center gap-4 justify-center">
        <button type="button" onClick={saveItemCosto}>
          <div className=" bg-green-600 bg-opacity-10 text-green-600 hover:bg-opacity-20 p-2 rounded-md">
            <MdSave />
          </div>
        </button>

        <button type=" button" onClick={deleteItemCosto}>
          <div className=" bg-red-600 bg-opacity-10 text-red-600 hover:bg-opacity-20 p-2 rounded-md">
            <MdDelete />
          </div>
        </button>
      </td>
    </tr>
  );
};

export default ItemTableCostosVariables;
