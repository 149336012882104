import React from "react";
import MetaGrupalComercialContainer from "../blockComponents/comisiones/MetaGrupalComercialContainer";
import MetaIndividualComercialContainer from "../blockComponents/comisiones/MetaIndividualComercialContainer";

const ComisionesGenerales = () => {
  return (
    <div className=" max-w-7xl mx-auto">
      <div className=" mb-10">
        <MetaIndividualComercialContainer />
      </div>
      <div>
        <MetaGrupalComercialContainer />
      </div>
    </div>
  );
};

export default ComisionesGenerales;
