import React, { useState } from "react";
import Modal from "../ui/Modal";
import InputText from "../ui/InputText";
import InputNumber from "../ui/InputNumber";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";
const NuevaMetaIndividual = ({ open, onClose, refetch }) => {
  const [formValues, setFormValues] = useState({
    name: "",
    meta: 0,
    metaPorcentaje: 0,
  });

  const register = async (ev) => {
    ev.preventDefault();
    try {
      await axios.post(`${BACKEND}/api/metaIndividualComercial`, formValues);
      onClose();
      refetch();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal activeModal={open} title={"Nueva meta individual"} onClose={onClose}>
      <div>
        <form onSubmit={register}>
          <InputText
            required={true}
            value={formValues.name}
            title={"Nombre"}
            setValue={(e) => setFormValues((prev) => ({ ...prev, name: e }))}
          />

          <InputNumber
            required={true}
            value={formValues.meta}
            title={"Meta"}
            setValue={(e) =>
              setFormValues((prev) => ({ ...prev, meta: Number(e) }))
            }
          />

          <InputNumber
            required={true}
            value={formValues.metaPorcentaje}
            title={"% Comision"}
            setValue={(e) =>
              setFormValues((prev) => ({ ...prev, metaPorcentaje: Number(e) }))
            }
          />
          <div className=" flex justify-center mt-10">
            <button
              type="submit"
              className=" bg-blue-600 hover:bg-blue-700 text-white font-semibold text-sm px-3 py-2 rounded-md"
            >
              Registrar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NuevaMetaIndividual;
