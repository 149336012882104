import React, { useEffect, useState } from "react";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { es } from "date-fns/locale";
import ItemRowPlanillaRegistro from "./ItemRowPlanillaRegistro";

const TablePlanillaRegistro = ({ planilla, }) => {
  const [dataList, setDataList] = useState([]);


  useEffect(() => {
    setDataList(
        planilla?.sueldos.map((e) => ({ trabajador: e.trabajador, descuento: e.descuento, extras: e?.extra,total:e?.total ? e.total : 0 }))
      );
  }, [planilla]);

  

  return (
    <section class="  p-3 sm:p-5">
      <div class="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div class="bg-white  relative shadow-md sm:rounded-lg overflow-hidden">
          <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div class="w-full  flex  justify-between md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center  md:space-x-3 flex-shrink-0">
              <div>
                <span className=" font-bold text-xl text-gray-600 capitalize">
                  {" "}
                  Planilla de {format(new Date(planilla.fechaInicio), "MMMM, yyyy", { locale: es })}
                </span>
              </div>
            </div>
          </div>
          <div class="overflow-x-auto">
            <table class="w-full text-sm text-left text-gray-500 ">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                <tr>
                  <th scope="col" class="px-4 py-3">
                    N°
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Nombre
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Puesto
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Sueldo
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Descuento
                  </th>
                  <th scope="col" class="px-4 py-3">
                    Extras
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataList.map((e, index) => (
                  <ItemRowPlanillaRegistro
                    key={e.trabajador.id}
                    index={index}
                    data={e}
                  />
                ))}
              </tbody>
              <tfoot className=" w-full"></tfoot>
            </table>
            <div className=" p-4 flex w-full justify-between gap-4  flex-wrap">
              <div className="flex justify-start gap-8 ">
                <div className=" flex flex-col gap-2">
                  <span className=" text-gray-500  text-xs">Costo Total </span>
                  <span className=" text-gray-800 font-semibold text-base">
                    S/{" "}
                    {dataList.reduce(
                      (acc, value) =>
                        (value.total) + acc,
                      0
                    )}{" "}
                  </span>
                </div>
                <div className=" flex flex-col gap-2">
                  <span className=" text-gray-500  text-xs">
                    Total trabajadores{" "}
                  </span>
                  <span className=" text-gray-800 font-semibold text-base text-center">
                    {dataList.length}{" "}
                  </span>
                </div>
              </div>

            </div>

            <div></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TablePlanillaRegistro;
