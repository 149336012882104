import { startOfMonth } from "date-fns";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND } from "../../../config/configEnvs";
const ItemRowPlanillaCurrentMonth = ({ index, data, setDataList }) => {
  console.log(startOfMonth(new Date()));
  const [incidenciasList, setIncidenciasList] = useState([]);

  const updateObjectDescuento = (targetId, newDescuentoValue) => {
    setDataList((prevData) =>
      prevData.map((item) =>
        item.trabajador.id === targetId
          ? { ...item, descuento: newDescuentoValue }
          : item
      )
    );
  };

  const updateObjectExtras = (targetId, newExtrasValue) => {
    setDataList((prevData) =>
      prevData.map((item) =>
        item.trabajador.id === targetId
          ? { ...item, extras: newExtrasValue }
          : item
      )
    );
  };

  const updateDescuentoValueFromDescuentosList = (array) => {
    if (array.length > 0) {
      const sum = array.reduce(
        (acc, value) => (value?.descuento ? value.descuento : 0) + acc,
        0
      );
      updateObjectDescuento(data.trabajador.id, sum);
    }
  };
  useEffect(() => {
    getDescuentoByUserIdAndCurrentMonth();
  }, []);

  const getDescuentoByUserIdAndCurrentMonth = async () => {
    const response = await axios.get(
      `${BACKEND}/api/incidencias?limit=1000&where[fecha][greater_than]=${startOfMonth(
        new Date()
      )}&where[trabajador][equals]=${data.trabajador.id}`
    );
    setIncidenciasList(response.data.docs);
    updateDescuentoValueFromDescuentosList(response.data.docs);
  };
  return (
    <tr className="border-b dark:border-gray-700">
      <th
        scope="row"
        className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap "
      >
        {index + 1}
      </th>
      <td className="px-4 py-3">{data.trabajador?.name}</td>
      <td className="px-4 py-3">{data.trabajador?.puesto}</td>
      <td className="px-4 py-3">{data.trabajador?.sueldo}</td>
      <td className="px-4 py-3">
            <input
          type="number"
          id="descuento"
          value={data.descuento}
          onChange={(e)=>updateObjectDescuento(data.trabajador.id,Number(e.target.value))}
          className="block  p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500"
        />
      </td>
      <td className="px-4 py-3">   <input
          type="number"
          id="descuento"
          value={data.extras}
          onChange={(e)=>updateObjectExtras(data.trabajador.id,Number(e.target.value))}
          className="block  p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500"
        /></td>
    </tr>
  );
};

export default ItemRowPlanillaCurrentMonth;
