import React, { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND } from "../config/configEnvs";
import VerificacionTextoDialog from "../blockComponents/dialogs/VerificacionTextoDialog";

const VerificacionTrabajadores = () => {
  const [verificationsList, setVerificationsList] = useState([]);
  const [openTextoVerificacion, setOpenTextoVerificacion] = useState(false);
  const getVerifications = async () => {
    try {
      const response = await axios.get(
        `${BACKEND}/api/trabajadoresVerificacion?limit=1000`
      );
      setVerificationsList(response.data.docs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVerifications();
  }, []);
  return (
    <div className="w-full ">
      <VerificacionTextoDialog
        open={openTextoVerificacion}
        setOpen={setOpenTextoVerificacion}
      />
      <div className="mx-auto w-full max-w-5xl">
        <h2 className=" text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
          Verificaciones
        </h2>
      </div>

      <div className=" max-w-5xl  mx-auto py-6 flex justify-end">
        <button
          type="button"
          className=" text-xs cursor-pointer p-2 font-semibold rounded-lg bg-orange-600 bg-opacity-10 text-orange-600 hover:bg-opacity-20"
          onClick={() => setOpenTextoVerificacion(true)}
        >
          Texto de verificación
        </button>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mx-auto w-full max-w-5xl  ">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Nombre
              </th>
              <th scope="col" className="px-6 py-3">
                Video
              </th>
              <th scope="col" className="px-6 py-3">
                Dni
              </th>
              <th scope="col" className="px-6 py-3">
                CV
              </th>
              <th scope="col" className="px-6 py-3">
                Contrato
              </th>
            </tr>
          </thead>
          <tbody>
            {verificationsList.map((e) => (
              <tr
                key={e.id}
                className="odd:bg-white  even:bg-gray-50 even:dark:bg-gray-800 border-b "
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  {e.trabajador.name}
                </th>
                <td className="px-6 py-4">
                  {" "}
                  {e.video && (
                    <>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className=" text-blue-600 hover:underline"
                        href={e.video.url}
                      >
                        Ver video{" "}
                      </a>
                    </>
                  )}{" "}
                </td>
                <td className="px-6 py-4">
                  {" "}
                  {e.dni && (
                    <>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className=" text-blue-600 hover:underline"
                        href={e.dni.url}
                      >
                        Ver imagen{" "}
                      </a>
                    </>
                  )}
                </td>
                <td className="px-6 py-4">
                  {e.cv && (
                    <>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className=" text-blue-600 hover:underline"
                        href={e.cv.url}
                      >
                        Ver documento{" "}
                      </a>
                    </>
                  )}
                </td>
                <td className="px-6 py-4">
                  {e.contrato && (
                    <>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className=" text-blue-600 hover:underline"
                        href={e.contrato.url}
                      >
                        Ver documento{" "}
                      </a>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VerificacionTrabajadores;
