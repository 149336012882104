import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const NuevoValor = () => {
  // Valores
  const [name, setName] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescripcionChange = (e) => {
    setDescripcion(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BACKEND}/api/valores`, {
        name,
        descripcion,
        id: Date.now().toString(),
      });

      const nuevoValor = response.data;
      console.log("Valor añadido:", nuevoValor);
      navigate("/home/reglas");
    } catch (error) {
      console.error("Error al añadir la valor:", error);
      setErrorMessage("Error de servidor");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">Añadir Valor</h2>
        {errorMessage && (
          <div className="bg-red-200 text-red-800 py-2 px-4 mb-4 rounded">
            {errorMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">
              Valor <span className=" text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="descripcion" className="block mb-2">
              Descripción
            </label>
            <textarea
              id="descripcion"
              value={descripcion}
              onChange={handleDescripcionChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="flex justify-end">
            <Link
              to="/home/reglas"
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Añadir
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NuevoValor;
