import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../config/configEnvs";

const EditarElementoLink = () => {
  // Valores
  const { id, elementoId } = useParams();
  const [name, setName] = useState("");
  const [links, setLinks] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/links/${id}`);
        const linkData = response.data;
        if (linkData.elemento && elementoId) {
          const elementoEspecifico = linkData.elemento.find(
            (item) => item.id === elementoId
          );
          if (elementoEspecifico) {
            setName(elementoEspecifico.name || "");
            setLinks(elementoEspecifico.link || "");
          }
        }
      } catch (error) {
        console.error("Error al obtener el link:", error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, elementoId]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleLinkChange = (e) => {
    setLinks(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${BACKEND}/api/links/${id}`);
      const link = response.data;
      const elementoActualizado = link.elemento.map((elemento) => {
        if (elemento.id === elementoId) {
          return {
            ...elemento,
            name,
            link: links,
          };
        }
        return elemento;
      });
      const updatedLink = { ...link, elemento: elementoActualizado };
      await axios.put(`${BACKEND}/api/links/${id}`, updatedLink);
      console.log("Elemento actualizado:", elementoActualizado);
      navigate(`/home/links/editar-link/${id}`);
    } catch (error) {
      console.error("Error al actualizar el elemento:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4">Editar Elemento</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">
              Nombre
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="link" className="block mb-2">
              Link
            </label>
            <input
              type="text"
              id="links"
              value={links}
              onChange={handleLinkChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="flex justify-end">
            <Link
              to={`/home/links/editar-link/${id}`}
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 rounded"
              onClick={() => {
                console.log("Cancelado");
              }}
            >
              Cancelar
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-[#ff6400] rounded text-white"
            >
              Actualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarElementoLink;
