import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import axios from "axios";
import moment from "moment";
import "moment-timezone";
import { BACKEND } from "../config/configEnvs";
import Snowfall from "react-snowfall";
const snowflake1 = document.createElement('img')
const snowflake2 = document.createElement('img')
const snowflake3 = document.createElement('img')
const snowflake4 = document.createElement('img')
const snowflake5 = document.createElement('img')
const snowflake6 = document.createElement('img')
snowflake1.src = '/images/christmas/candy-cane.png'
snowflake2.src = '/images/christmas/christmas-bell.png'
snowflake3.src = '/images/christmas/christmas-tree.png'
snowflake4.src = '/images/christmas/gift.png'
snowflake5.src = '/images/christmas/hat.png'
snowflake6.src = '/images/christmas/christmas-ornament.png'

const images = [snowflake1,snowflake2,snowflake3,snowflake4,snowflake5,snowflake6]



ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [registros, setRegistros] = useState({ tardanza: [], puntuales: [] });

  useEffect(() => {
    const fetchRegistros = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/asistencias`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;

        const tardanzasData = data.filter(isTardanza);
        const puntualesData = data.filter(
          (registro) =>
            registro.motivo === "Entrada Trabajo" && !isTardanza(registro)
        );

        setRegistros({ tardanza: tardanzasData, puntuales: puntualesData });
      } catch (error) {
        console.log(error);
      }
    };

    fetchRegistros();
  }, []);

  const tardanzas = {
    labels: ["Puntuales", "Tardanza"],
    datasets: [
      {
        label: ["Puntuales", "Tardanza"],
        data: [registros.puntuales.length, registros.tardanza.length],
        backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const tardanzasOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Tardanzas",
      },
    },
  };

  function isTardanza(registro) {
    const registroDate = moment(registro.fecha)
      .tz("America/Lima")
      .startOf("day");
    return (
      registro.motivo === "Entrada Trabajo" &&
      moment(registro.fecha)
        .tz("America/Lima")
        .isAfter(
          moment(registroDate)
            .tz("America/Lima")
            .set({ hour: 9, minute: 5, second: 0 })
        )
    );
  }

  const data = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Sales",
        data: [12, 19, 8, 15, 10],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  return (
    <div className=" relative">

<div className=" absolute top-0 left-0 w-full h-full">
        <Snowfall
          color="red"
          radius={[8,25]}
         images={images}
         wind={[-1,-0.5]}
          // Applied to the canvas element
          // Controls the number of snowflakes that are created (default 150)
          snowflakeCount={150}
        />
      </div>
      <div className="flex flex-col items-center">
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
      <div className="grid grid-cols-2 gap-4">
        <div className="bg-white p-4 rounded-lg shadow-md">
          <Bar data={data} options={options} />
          <h2 className="text-lg font-bold mb-2">Gráfico 1</h2>
        </div>

        <div className="bg-white p-4 rounded-lg shadow-md">
          <Doughnut data={tardanzas} options={tardanzasOptions} />
          <h2 className="text-lg font-bold mb-2">Gráfico 2</h2>
        </div>

        <div className="bg-white p-4 rounded-lg shadow-md">
          <Line data={data} options={options} />
          <h2 className="text-lg font-bold mb-2">Gráfico 3</h2>
        </div>

        <div className="bg-white p-4 rounded-lg shadow-md">
          <Pie data={tardanzas} options={tardanzasOptions} />
          <h2 className="text-lg font-bold mb-2">Gráfico 4</h2>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Dashboard;
