import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import moment from "moment";
import "moment-timezone";
import { BACKEND } from "../config/configEnvs";

function RegistrosSSHH() {
  const [registrosSSHH, setRegistrosSSHH] = useState([]);
  const [filtroMotivo, setFiltroMotivo] = useState("");
  const [filtroNombre, setFiltroNombre] = useState("");
  const [trabajadores, setTrabajadores] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const registrosPerPage = 50;
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");

  const formatDate = (dateString) => {
    const formattedDate = moment(dateString)
      .tz("America/Lima") // Ajusta al horario de Perú
      .format("YYYY-MM-DD HH:mm:ss"); // Formato deseado
    return formattedDate;
  };

  useEffect(() => {
    const fetchRegistrosSSHH = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/servicioshigienicos`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setRegistrosSSHH(data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchTrabajadores = async () => {
      try {
        const response = await axios.get(`${BACKEND}/api/trabajadores`, {
          params: {
            limit: 1000,
          },
        });
        const data = response.data.docs;
        setTrabajadores(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchRegistrosSSHH();
    fetchTrabajadores();
  }, []);

  const handleMotivoFiltroChange = (event) => {
    setFiltroMotivo(event.target.value);
    setPageNumber(0);
  };

  const handleNombreFiltroChange = (event) => {
    setFiltroNombre(event.target.value);
    setPageNumber(0);
  };

  const handleFechaInicioChange = (event) => {
    setFechaInicio(event.target.value);
    setPageNumber(0);
  };

  const handleFechaFinChange = (event) => {
    setFechaFin(event.target.value);
    setPageNumber(0);
  };

  const registrosFiltrados = registrosSSHH.filter((registro) => {
    return !(
      (filtroMotivo !== "" && registro.motivo !== filtroMotivo) ||
      (filtroNombre !== "" &&
        registro.usuario &&
        registro.usuario.name !== filtroNombre) ||
      (fechaInicio !== "" && moment(registro.fecha).isBefore(fechaInicio)) ||
      (fechaFin !== "" && moment(registro.fecha).isAfter(fechaFin))
    );
  });

  // Pagination logic
  const pageCount = Math.ceil(registrosFiltrados.length / registrosPerPage);
  const offset = pageNumber * registrosPerPage;
  const currentRegistros = registrosFiltrados.slice(
    offset,
    offset + registrosPerPage
  );

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  if (registrosSSHH.length === 0) {
    return <div>No hay registros disponibles.</div>;
  }

  function getMotivoColor(motivo) {
    switch (motivo) {
      case "Salida SSHH":
        return "bg-red-500";
      case "Entrada SSHH":
        return "bg-green-500";
      default:
        return "";
    }
  }

  return (
    <div className="w-full flex flex-col">
      <h2 className="mx-auto text-center text-2xl lg:text-3xl font-semibold mt-4 mb-6">
        Historial de Registros SSHH
      </h2>
      <div className="flex flex-col px-8 my-6 justify-center items-center">
        <div
          className={`flex flex-col md:flex-row filters-container transition-all`}
        >
          <div className="grid lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
            <div className="flex items-center mx-4 my-2">
              <p htmlFor="filtroNombre" className="mr-2 w-28">
                Nombre:
              </p>
              <select
                id="filtroNombre"
                name="filtroNombre"
                value={filtroNombre}
                onChange={handleNombreFiltroChange}
                className="w-36"
              >
                <option value="">Todos</option>
                {trabajadores.map((trabajador) => (
                  <option key={trabajador.id} value={trabajador.name}>
                    {trabajador.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center mx-4 my-2">
              <p htmlFor="filtroNombre" className="mr-2 w-28">
                Motivo:
              </p>
              <select
                id="filtroMotivo"
                name="filtroMotivo"
                value={filtroMotivo}
                onChange={handleMotivoFiltroChange}
                className="w-36"
              >
                <option value="">Todos</option>
                <option value="Entrada SSHH">Entrada SSHH</option>
                <option value="Salida SSHH">Salida SSHH</option>
              </select>
            </div>
            <div className="flex items-center mx-4 my-2">
              <p htmlFor="filtroFechaInicio" className="mr-2 w-28">
                Fecha inicio:
              </p>
              <input
                type="date"
                id="filtroFechaInicio"
                name="filtroFechaInicio"
                value={fechaInicio}
                onChange={handleFechaInicioChange}
                className="w-36"
              />
            </div>
            <div className="flex items-center mx-4 my-2">
              <p htmlFor="filtroFechaFin" className="mr-2 w-28">
                Fecha fin:
              </p>
              <input
                type="date"
                id="filtroFechaFin"
                name="filtroFechaFin"
                value={fechaFin}
                onChange={handleFechaFinChange}
                className="w-36"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex md:hidden px-8">
        <table className="w-full max-w-max mx-auto border-collapse">
          <thead>
            <tr>
              <th className="border-b-2 border-gray-800 p-2">Registros</th>
            </tr>
          </thead>
          <tbody>
            {currentRegistros.map((registro, index) => {
              return (
                <tr
                  key={registro.id}
                  className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
                >
                  <td className="border px-6 flex items-center justify-center border-gray-300 p-2">
                    <div>
                      <div className="flex items-center mb-2">
                        <p className="w-28 pr-2 font-semibold">Nombre:</p>
                        <p className="pl-2">{registro.usuario?.name}</p>
                      </div>
                      <div className="flex items-center mb-2">
                        <p className="w-28 pr-2 font-semibold">Motivo:</p>
                        <p className="pl-2">
                          <span
                            className={`inline-block h-4 w-4 rounded-full mr-2 ${getMotivoColor(
                              registro.motivo
                            )}`}
                          ></span>
                          {registro.motivo}
                        </p>
                      </div>
                      <div className="flex items-center mb-2">
                        <p className="w-28 pr-2 font-semibold">Fecha:</p>
                        <p className="pl-2">{formatDate(registro.fecha)}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="hidden md:flex px-8">
        <table className="w-full max-w-4xl mx-auto border-collapse">
          <thead>
            <tr>
              <th className="border-b-2 border-gray-800 p-2">Nombre</th>
              <th className="border-b-2 border-gray-800 p-2">Motivo</th>
              <th className="border-b-2 border-gray-800 p-2">Fecha y hora</th>
            </tr>
          </thead>
          <tbody>
            {currentRegistros.map((registro, index) => (
              <tr
                key={registro.id}
                className={`${index % 2 === 0 ? "bg-zinc-100" : "bg-white"}`}
              >
                <td className="border border-gray-300 p-2">
                  {registro.usuario?.name}
                </td>
                <td className="border border-gray-300 p-2">
                  <span
                    className={`inline-block h-4 w-4 rounded-full mr-2 ${getMotivoColor(
                      registro.motivo
                    )}`}
                  ></span>
                  {registro.motivo}
                </td>
                <td className="border border-gray-300 p-2">
                  {formatDate(registro.fecha)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="flex items-center justify-center mt-4"
          pageClassName="cursor-pointer mx-2"
          previousClassName="cursor-pointer mx-2"
          nextClassName="cursor-pointer mx-2"
          activeClassName="bg-[#ff6400] text-white rounded-lg px-3 py-1"
          disabledClassName="text-gray-500 cursor-not-allowed mx-2"
          previousLinkClassName="pagination-link"
          nextLinkClassName="pagination-link"
          pageLinkClassName="pagination-link"
          activeLinkClassName="pagination-link-active"
        />
      </div>
    </div>
  );
}

export default RegistrosSSHH;
